import React from 'react';
import { Combobox } from '@dataartdev/uikit/Combobox';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { PropRenderItem, DefaultItem } from '@dataartdev/uikit/Combobox';
import { Tag } from '@dataartdev/uikit/Tag';
import { cn } from 'ui/utils/bem';

const cnSelectItemTag = cn('SelectItemTag');

type Props = {
  containerRef?: any;
  tags: any;
  moreTags: any;
  moreTagsButton: any;
  tagsPlaceholder: string;
  tagsSelected?: string;
  tagsChange?: any;
  industries?: any;
  industriesPlaceholder?: string;
  industriesSelected?: any;
  industriesChange?: any;
  languages?: any;
  languagesPlaceholder?: string;
  languagesChange?: any;
  languagesSelected?: any;
  types?: any;
  typeSelected?: any;
  typeChange?: any;
  dateSelected?: any;
  datePlaceholder?: any;
  dateChange?: any;
  dateCancel?: any;
};

export const renderItemTag: PropRenderItem<DefaultItem> = props => {
  const { item, active, hovered, onClick, onMouseEnter } = props;

  return (
    <div
      className={cnSelectItemTag('Item', { active, hovered })}
      role="option"
      aria-selected={active}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      {!active ? (
        <Tag onCancel={onClick} label={item.title} mode="gray" size="s" />
      ) : (
        <Tag onCancel={onClick} label={item.title} mode="cancel" size="s" />
      )}
    </div>
  );
};

export const Filters: React.FC<Props> = (
  {
    tagsPlaceholder,
    tagsPlaceholderSelected,
    tags,
    moreTags,
    moreTagsButton,
    tagsSelected,
    tagsChange,
    industries,
    industriesPlaceholder,
    industriesSelected,
    industriesChange,
    industriesPlaceholderSelected,
  },
  ref
) => {
  const wrapperFilters = React.useRef<HTMLDivElement | null>(null);

  return (
    <Grid
      cols="12"
      ref={wrapperFilters}
      gap="3xl"
      yAlign="center"
      xAlign="between"
    >
      <GridItem col="12">
        <Grid cols="12" gap="xs">
          {industries?.length > 0 && (
            <GridItem col="3">
              <Combobox
                dropdownClassName="no-scroll"
                multiple
                groupValues
                placeholder={industriesPlaceholder}
                placeholderSelected={industriesPlaceholderSelected}
                items={industries}
                value={industriesSelected}
                getItemLabel={item => item.title}
                onChange={industriesChange}
              />
            </GridItem>
          )}
          {tags?.length > 0 && (
            <GridItem col="3">
              <Combobox
                multiple
                groupValues
                targetDropdownRef={wrapperFilters}
                placeholder={tagsPlaceholder}
                placeholderSelected={tagsPlaceholderSelected}
                items={tags}
                moreItems={moreTags}
                value={tagsSelected}
                getItemLabel={item => item.title}
                dropdownView="columns"
                renderItem={renderItemTag}
                onChange={tagsChange}
                buttonMoreLabel={moreTagsButton}
              />
            </GridItem>
          )}
        </Grid>
      </GridItem>
    </Grid>
  );
};
