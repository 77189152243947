// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { Typography } from '@dataartdev/uikit/Typography';
import { Article } from '../../Article/Article';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { ValuesCard } from '../../ValuesCard/ValuesCard';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const { type, title, text, items, columnsCount, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();
  const cols = +columnsCount === 4 ? 3 : 4;

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Text
          size="xs"
          weight="bold"
          transform="uppercase"
          dangerouslySetInnerHTML={{ __html: title }}
          as="h2"
          className={cnContentBuilderTemplate('Title')}
        />
      )}
      {text && <Article content={text} />}

      {items?.length > 0 && (
        <Grid
          breakpoints={{
            desktop: { cols: 12, gap: 'xl' },
            tablet: { cols: 8, gap: 'm' },
            mobile: { gap: 'm' },
          }}
        >
          {items.map(item => (
            <GridItem
              key={item.id}
              breakpoints={{ desktop: { col: cols }, tablet: { col: cols } }}
            >
              <ValuesCard {...item} size="m" />
            </GridItem>
          ))}
        </Grid>
      )}
    </div>
  );
});

export default ContentBuilderTemplate;
