import './SquadPage.scss';

import { FC, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { PATH } from 'router';
import { mutationMainForm, mutationFileForm } from 'network';
import { useGTMDataSend } from '../../../common/hooks/useGTMDataSend';

import { cn } from 'ui/utils/bem';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';
import { Picture } from '@dataartdev/uikit/Picture';
import { Article } from '../../../common/components/Article/Article';
import { TreeNav } from '@dataartdev/uikit/TreeNav';
import { Typography } from '@dataartdev/uikit/Typography';
import { Mq } from '@dataartdev/uikit/Mq';
import { ContentBuilder } from 'common/components/ContentBuilder/ContentBuilder';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';
import { useSelector } from 'react-redux';
import { dictionariesSelector } from 'store';
import { CtaScrollerBtn } from 'common/components/CtaScrollerBtn';

const cnPage = cn('SquadPage');

type Props = RouteComponentProps & {
  data: any;
};

export const SquadPage: FC<Props> = ({ history, location, data }) => {
  const {
    breadcrumbs,
    title,
    subtitle,
    headerPicture,
    content,
    pageTitle,
    metaTitle,
    tags,
    contentType,
    backgroundColor,
    headerLink,
  } = data || {};

  const pageRef = useRef(null);
  const {
    companySiteForm: formContent,
    companySiteTableOfContent,
  } = useSelector(dictionariesSelector);

  useGTMDataSend(location, metaTitle, tags);

  const inverted = contentType === 0 && backgroundColor !== null;

  return (
    <div className={cnPage({ squad: true })}>
      <Mq query="--desktop">
        <TreeNav title={companySiteTableOfContent} pageRef={pageRef} fixed />
      </Mq>
      <div
        style={{ backgroundColor: '#' + backgroundColor }}
        className={cnPage({ inverted })}
      >
        <div className="container">
          {breadcrumbs && (
            <div className={cnPage('Breadcrumbs')}>
              <Breadcrumbs
                getLabel={page => page.text}
                getLink={page => page.url}
                history={history}
                pages={breadcrumbs}
                inverted={inverted}
              />
            </div>
          )}
          <Grid
            className={cnPage('Head')}
            breakpoints={{
              desktop: { cols: 12, gap: 'xl' },
              tablet: { cols: 8, gap: 'm' },
            }}
          >
            <GridItem breakpoints={{ desktop: { col: 8 }, tablet: { col: 5 } }}>
              {(title || pageTitle) && (
                <Typography.Title
                  size="lg"
                  className={cnPage('Title')}
                  as="h1"
                  parse
                >
                  {pageTitle ?? title}
                </Typography.Title>
              )}
              {subtitle && <Article content={subtitle} />}
              {headerLink?.link && (
                <div className={cnPage('Cta')}>
                  <CtaScrollerBtn
                    title={headerLink.title}
                    link={headerLink.link}
                    view="secondary"
                  />
                </div>
              )}
            </GridItem>
            {headerPicture && (
              <GridItem
                breakpoints={{
                  desktop: { col: 2, colStart: 10 },
                  tablet: { col: 3, colStart: 6 },
                }}
                className={cnPage('Picture')}
              >
                <Picture
                  desktop={headerPicture.url}
                  desktopWebp={headerPicture.urlWebp}
                />
              </GridItem>
            )}
          </Grid>
        </div>
      </div>
      <ContentBuilder
        items={content}
        aliases={PATH}
        titleTag="h2"
        recaptchaKey={recaptchaKey}
        onSubmitForm={async body =>
          await mutationMainForm({
            body: {
              ...body,
              pageId: data.id,
              [`${recaptchaFormKey}`]: body.token,
            },
          })
        }
        onSubmitFileForm={body =>
          mutationFileForm({
            body: {
              ...body,
              pageId: data.id,
              [`${recaptchaFormKey}`]: body.token,
            },
          })
        }
        pageId={data.id}
        dictionariesForm={{
          success: {
            title:
              formContent.companySiteFormLetsTalk
                .companySiteFormLetsTalkSuccessTitle,
            text:
              formContent.companySiteFormLetsTalk
                .companySiteFormLetsTalkSuccessText,
          },
          addInfo: {
            label:
              formContent.companySiteFormLetsTalk
                .companySiteFormLetsTalkAddInfo,
          },
          company: {
            label:
              formContent.companySiteFormLetsTalk
                .companySiteFormLetsTalkCompany,
          },
          email: {
            label: 'Email',
            error:
              formContent.companySiteFormLetsTalk.companySiteFormLetsTalkEmail
                .companySiteFormLetsTalkEmailError,
          },
          name: {
            label:
              formContent.companySiteFormLetsTalk.companySiteFormLetsTalkName,
          },
          phone: {
            label: 'Phone',
            error:
              formContent.companySiteFormLetsTalk
                .companySiteFormPhoneLetsTalkError,
          },
          required:
            formContent.companySiteFormLetsTalk
              .companySiteFormLetsTalkRequiredField,
          button:
            formContent.companySiteFormLetsTalk.companySiteFormLetsTalkSubmit,
          policy:
            formContent.companySiteFormLetsTalk
              .companySiteFormLetsTalkSubscribePolicy,
        }}
        CtaScrollerBtnLink={headerLink?.link}
      />
    </div>
  );
};
