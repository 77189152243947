import './PopupFormBuilder.scss';
import React, { useState } from 'react';
import FormBuilder from '../Form/FormBuilder';
import { Modal } from '@dataartdev/uikit/Modal';
import { mutationMainAllForm } from '../../../network';
import { FormSuccess } from '../Form';
import { cn } from 'ui/utils/bem';
import { Banner } from '../ContentBuilder/models/CTI_111.props';
import { IFormBuilderDict } from '../Form/models';
import { useSelector } from 'react-redux';
import { layoutSelector } from '../../../store';
import { IRoot57 } from '../ContentBuilder/models/CTI_57.props';

const cnPopupFormBuilder = cn('PopupFormBuilder');

interface Props extends Partial<Banner>, Partial<IRoot57> {
  setIsModalOpen: (value: boolean) => void;
  isModalOpen: boolean;
  dictionariesForm?: IFormBuilderDict;
}

export const PopupFormBuilder: React.FC<Props> = props => {
  const [successForm, setSuccessForm] = useState(false);
  const { pageId } = useSelector(layoutSelector) || {};
  const {
    form,
    formTitle,
    formText,
    setIsModalOpen,
    isModalOpen,
    dictionariesForm,
  } = props || {};

  return (
    <Modal
      isOpen={isModalOpen}
      hasOverlay
      scroll
      view="form"
      onOverlayClick={(): void => {
        setIsModalOpen(false);
      }}
      onEsc={(): void => {
        setIsModalOpen(false);
      }}
      onClose={() => {
        setIsModalOpen(false);
      }}
      showCloseButton
      inverted={successForm}
      className={cnPopupFormBuilder('Modal')}
    >
      {!successForm ? (
        <div>
          <FormBuilder
            formTitle={formTitle}
            formText={formText}
            setSuccessForm={setSuccessForm}
            form={form}
            submitText={form?.submitText}
            dictionariesForm={dictionariesForm}
            onSubmitForm={async body =>
              await mutationMainAllForm({
                body: {
                  ...body,
                  pageId,
                },
              })
            }
          />
        </div>
      ) : (
        <FormSuccess
          className={cnPopupFormBuilder('Success')}
          title={form?.successPopup?.title}
          text={form?.successPopup?.text}
          link={form?.successPopup?.link}
          titleSize="lg"
          textSize="m"
          sideGap="xl"
          view=""
        />
      )}
    </Modal>
  );
};
