import './TeamMemberPage.scss';

import React, { useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import { useDataQuery, useGTMDataSend } from 'common/hooks';
import { queryTeamMemberPage } from 'network';
import { dictionariesSelector } from 'store';
import { PATH, redirect404 } from 'router';

import { cn } from 'ui/utils/bem';
import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Article } from '../../../common/components/Article/Article';
import { Picture } from '@dataartdev/uikit/Picture';
import { SocialButton } from '@dataartdev/uikit/SocialButton';
import { Mq } from '@dataartdev/uikit/Mq';

import { ContentBuilderItem } from 'common/components/ContentBuilder/ContentBuilderItem';
import { IconFacebook } from '@dataartdev/uikit/IconFacebook';
import { IconTwitter } from '@dataartdev/uikit/IconTwitter';
import { IconLinkedin } from '@dataartdev/uikit/IconLinkedin';
import { useSelector } from 'react-redux';

const cnTeamMemberPage = cn('TeamMemberPage');

type Props = RouteComponentProps & {};

export const TeamMemberPage: React.FC<Props> = ({ match, history }) => {
  const { data, loading, error } =
    useDataQuery(queryTeamMemberPage, {
      slug: match.params.slug,
    }) || {};

  const { companySiteTeamMember: staticContent } =
    useSelector(dictionariesSelector) || {};

  const {
    breadcrumbs,
    pageTitle,
    bio,
    photo,
    firstName,
    lastName,
    titleForContactPage,
    location,
    facebook,
    twitter,
    linkedIn,
    relatedItems,
    metaTitle,
    tags,
  } = data || {};

  useEffect(() => {
    if (error && error.response && error.response.status === 404) {
      redirect404(history);
    }
  }, [error, history]);

  const path = useLocation();
  useGTMDataSend(path, metaTitle, tags);

  return (
    <div className={cnTeamMemberPage()}>
      {!loading && (
        <div className="container">
          <div className={cnTeamMemberPage('Breadcrumbs')}>
            {breadcrumbs && (
              <Breadcrumbs
                getLabel={page => page.text}
                getLink={page => page.url}
                history={history}
                pages={data.breadcrumbs}
              />
            )}
          </div>
          <div className={cnTeamMemberPage('Body')}>
            <Grid
              breakpoints={{
                mobile: { cols: 2 },
                tablet: { cols: 8, gap: 'm' },
                desktop: { cols: 12, gap: 'xl' },
              }}
            >
              <GridItem
                breakpoints={{
                  mobile: { col: 2 },
                  tablet: { col: 5 },
                  desktop: { col: 6 },
                }}
              >
                <Mq query="--tablet-min">
                  <div className={cnTeamMemberPage('Title')}>
                    <Typography.Title
                      size="lg"
                      dangerouslySetInnerHTML={{ __html: pageTitle }}
                      as="h1"
                    />
                  </div>
                </Mq>
                {bio && (
                  <div className={cnTeamMemberPage('Bio')}>
                    <Article content={bio} />
                  </div>
                )}
              </GridItem>
              <GridItem
                breakpoints={{
                  mobile: { col: 2, order: -1, colStart: 1 },
                  tablet: { col: 3, colStart: 6 },
                  desktop: { col: 6, colStart: 8 },
                }}
              >
                <Mq query="--mobile">
                  <div className={cnTeamMemberPage('Title')}>
                    <Typography.Title
                      size="lg"
                      dangerouslySetInnerHTML={{ __html: pageTitle }}
                      as="h1"
                    />
                  </div>
                </Mq>
                {photo && (
                  <div className={cnTeamMemberPage('Picture')}>
                    <Picture {...photo} />
                  </div>
                )}
                <div className={cnTeamMemberPage('Info')}>
                  <Grid cols="12">
                    <GridItem
                      breakpoints={{
                        mobile: { col: 12, colStart: 1 },
                        tablet: { col: 12, colStart: 1 },
                        desktop: { col: 6, colStart: 1 },
                      }}
                    >
                      <Typography.Text
                        weight="bold"
                        size="m"
                        className={cnTeamMemberPage('InfoName')}
                      >
                        {firstName} {lastName}
                      </Typography.Text>
                      <Typography.Text
                        size="s"
                        className={cnTeamMemberPage('InfoSubtitle')}
                      >
                        {titleForContactPage}
                      </Typography.Text>
                      <Typography.Text
                        size="s"
                        className={cnTeamMemberPage('InfoLocation')}
                      >
                        {location}
                      </Typography.Text>
                    </GridItem>
                    <GridItem
                      breakpoints={{
                        mobile: { col: 12, colStart: 1 },
                        tablet: { col: 12, colStart: 1 },
                        desktop: { col: 6 },
                      }}
                    >
                      <div className={cnTeamMemberPage('Socials')}>
                        {facebook && (
                          <SocialButton
                            icon={IconFacebook}
                            as="a"
                            href={facebook}
                            target="_blank"
                          />
                        )}
                        {twitter && (
                          <SocialButton
                            icon={IconTwitter}
                            as="a"
                            href={twitter}
                            target="_blank"
                          />
                        )}
                        <SocialButton
                          icon={IconLinkedin}
                          as="a"
                          target="_blank"
                          href={linkedIn}
                        />
                      </div>
                    </GridItem>
                  </Grid>
                </div>
              </GridItem>
            </Grid>
          </div>
        </div>
      )}

      {relatedItems?.length > 0 && (
        <ContentBuilderItem
          title={staticContent.companySiteTeamMemberMoreTitle}
          type={28}
          items={relatedItems}
          aliases={PATH}
          background="right-triangle-down"
          topGap="xxl"
          bottomGap="3xxl"
        />
      )}
    </div>
  );
};
