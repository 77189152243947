// @ts-nocheck
import './ContentAnchorCard.scss';

import React from 'react';

import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { cn } from '../../utils/bem';

import { IconChevronArrowDown } from '@dataartdev/uikit/IconChevronArrowDown';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography } from '@dataartdev/uikit/Typography';

export type Props = {
  icon?: any;
  title?: string;
  lineColor?: string;
  view?: string;
};

export const cnContentAnchorCard = cn('ContentAnchorCard');

export const ContentAnchorCard = forwardRefWithAs<Props>((props, ref) => {
  const {
    icon,
    title,
    lineColor,
    view = 'default',
    path,
    onClick,
  } = usePropsHandler(cnContentAnchorCard(), props);

  const Component = path ? 'a' : 'button';

  const options = {};

  if (path) {
    options.to = path;
  }

  return (
    <Component
      className={cnContentAnchorCard({ view })}
      {...options}
      onClick={() => onClick(path)}
    >
      {lineColor && (
        <div
          className={cnContentAnchorCard('Line')}
          style={{ backgroundColor: lineColor }}
        />
      )}
      {title && (
        <Typography.Title
          size="md"
          className={cnContentAnchorCard('Title')}
          as="h3"
        >
          {title}
        </Typography.Title>
      )}
      {icon && (
        <Picture desktop={icon} className={cnContentAnchorCard('Icon')} />
      )}
      <Button
        as="div"
        view="ghost"
        iconRight={IconChevronArrowDown}
        iconSize="xs"
      />
    </Component>
  );
});
