import './ContentBuilderTemplate.scss';

import React from 'react';
import { generatePath } from 'react-router-dom';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { EssenceCard } from '../../EssenceCard/EssenceCard';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { useIntl } from 'react-intl';
import { IRoot23 } from '../models/CTI_23.props';

interface Props extends IRoot23 {
  aliases: any;
}

export const ContentBuilderTemplate: React.FC<Props> = props => {
  const { type, title, items, aliases, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();
  const { locale } = useIntl();

  const options = {
    navigation: true,
    breakpoints: {
      767: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      1200: {
        spaceBetween: 24,
        slidesPerView: 3,
      },
    },
    spaceBetween: 8,
    slidesPerView: 1,
    watchOverflow: true,
  };

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Title
          size="lg"
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        >
          {title}
        </Typography.Title>
      )}
      <Slider {...options}>
        {items &&
          items.map(post => (
            <SliderItem key={post.slug}>
              <EssenceCard
                {...post}
                routePath={generatePath(aliases.EVENTS_PAGE, {
                  slug: post.slug,
                  lang: locale === 'en' ? null : locale,
                })}
                path={generatePath(aliases.BLOG_SINGLE, {
                  slug: post.slug,
                  lang: locale === 'en' ? null : locale,
                })}
                view="filled"
                size="m"
              />
            </SliderItem>
          ))}
      </Slider>
    </div>
  );
};

export default ContentBuilderTemplate;
