/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable radix */
/* eslint-disable no-restricted-properties */
// @ts-nocheck
import './FileCard.scss';

import React, { useState } from 'react';
import parse from 'html-react-parser';

import { IconTrash } from '@dataartdev/uikit/IconTrash';
import { cnMixFocus } from '@dataartdev/uikit/MixFocus';
import { cn } from 'ui/utils/bem';
import { File } from '@dataartdev/uikit/File';
import { Modal } from '@dataartdev/uikit/Modal';
import { Typography } from '@dataartdev/uikit/Typography';
import { Article } from '../Article/Article';
import { Picture } from '@dataartdev/uikit/Picture';
import { FileCardForm } from './FileCardForm';

type Props = {
  title?: string;
  extension?: string;
  date?: string;
  url?: string;
  onSubmit?: any;
  removeHandler?: any;
  recaptchaKey?: any;
  popup?: any;
  view?: string;
};

const cnFileCard = cn('FileCard');

export const FileCard: React.FC<Props> = ({
  title,
  extension,
  date,
  url,
  onSubmit,
  popup,
  picture,
  size,
  view,
  removeHandler,
  recaptchaKey,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successForm, setSuccessForm] = useState(false);

  const bytesToSize = bytes => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (!bytes) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
  };

  const handlerClick = () => {
    if (popup) {
      document.body.style.overflow = 'unset';
      setIsModalOpen(true);
    } else if (url) window.open(url, '_blank');
  };

  const onSubmitHandler = async values => {
    await onSubmit(values).then(() => {
      setSuccessForm(true);
      window.open(url, '_blank');
    });
  };

  return (
    <>
      <button
        type="button"
        className={cnFileCard({ clicked: !!url, view }, [cnMixFocus()])}
        onClick={handlerClick}
      >
        <div className={cnFileCard('Icon')}>
          <File extension={extension} />
        </div>
        <div className={cnFileCard('Inner')}>
          <Typography.Text weight="bold" className={cnFileCard('Title')}>
            {title}
          </Typography.Text>
          <Typography.Text size="s" className={cnFileCard('Text')}>
            {bytesToSize(size) ?? ''}&nbsp;&nbsp;
            {date ? <>Last updated {date}</> : null}
          </Typography.Text>
        </div>
        {removeHandler && (
          <div className={cnFileCard('ActionBtn')} onClick={removeHandler}>
            <span>
              <IconTrash size="s" />
            </span>
          </div>
        )}
      </button>
      {popup && (
        <Modal
          hasOverlay
          view="form"
          inverted={successForm}
          onOpen={() => {
            document.body.style.overflow = 'hidden';
          }}
          onClose={() => {
            document.body.style.overflow = 'unset';
          }}
          isOpen={isModalOpen}
          onOverlayClick={(): void => {
            document.body.style.overflow = 'unset';
            setIsModalOpen(false);
            setSuccessForm(false);
          }}
          onEsc={(): void => {
            setIsModalOpen(false);
            setSuccessForm(false);
            document.body.style.overflow = 'unset';
          }}
          showCloseButton
          className={popup.formType === 3 && 'FileCard-Longer-Popup'}
        >
          <div className={cnFileCard('Left-Side')}>
            <div className={cnFileCard('Left-Side-Top')}>
              {popup.formType === 3 && (
                <Typography.Text
                  size="xs"
                  weight="bold"
                  transform="uppercase"
                  className={cnFileCard('Modal-Subtitle')}
                  as="span"
                >
                  {
                    popup.dictionary.companySiteFormDownloadFile
                      .companySiteFormDownloadFileTitle
                  }
                </Typography.Text>
              )}
              <Typography.Title size="md" className={cnFileCard('Modal-Title')}>
                {popup.title}
              </Typography.Title>
              <Article content={popup.text} />
            </div>
            {popup.formType === 3 && popup.picture && (
              <div className={cnFileCard('Left-Side-Picture')}>
                <Picture
                  desktop={popup.picture.url}
                  desktopWebp={popup.picture.urlWebp}
                  alt={popup.picture.alt}
                  name={popup.picture.name}
                />
              </div>
            )}
          </div>
          <FileCardForm
            url={url}
            onSubmit={onSubmitHandler}
            type={popup.formType}
            dictionaries={popup.dictionary}
            recaptchaKey={recaptchaKey}
          />

          {successForm && (
            <div className={cnFileCard('ModalSuccess')}>
              <Typography.Title
                size="lg"
                className={cnFileCard('Title')}
                as="h3"
              >
                {
                  popup.dictionary.companySiteFormDownloadFile
                    .companySiteFormDownloadFileSuccessTitle
                }
              </Typography.Title>
              <Typography.Text className={cnFileCard('SubTitle')}>
                {
                  popup.dictionary.companySiteFormDownloadFile
                    .companySiteFormDownloadFileSuccessText
                }
              </Typography.Text>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};
