// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-has-content */
import './Banner.scss';

import React, { useState } from 'react';

import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { cn } from '../../utils/bem';

import { TypographyPropSize } from '@dataartdev/uikit/__internal__/cjs-src/components/Typography/Title/Title';
import { Button } from '@dataartdev/uikit/Button';
import { Modal } from '@dataartdev/uikit/Modal';
import { Typography } from '@dataartdev/uikit/Typography';
import { Article } from '../Article/Article';
import { Picture } from '@dataartdev/uikit/Picture';
import { FileCardForm } from '../FileCard/FileCardForm';

// import { IconArrowRight } from '../../icons/IconArrowRight/IconArrowRight';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

export const bannerPropView = ['glassdoor', 'vacancy', 'section'] as const;
export type BannerPropView = typeof bannerPropView[number];
export const bannerPropViewDefault: BannerPropView = bannerPropView[0];

type Props = {
  view?: BannerPropView;
  title?: string;
  titleSize?: TypographyPropSize;
  text?: string;
  labelButton?: string;
  extension?: string;
  date?: string;
  url?: string;
  onSubmit?: any;
  removeHandler?: any;
  recaptchaKey?: any;
  popup?: any;
};

const cnBanner = cn('Banner');

export const DownloadableBanner = forwardRefWithAs<Props>((props, ref) => {
  const {
    view = bannerPropViewDefault,
    title,
    titleSize,
    labelButton,
    text,
    picture,
    pictureLabel,
    buttonIcon = IconArrowRight,
    buttonIconSize = 'm',
    buttonAttrs,
    onClick,
    bgColor,
    inverted,
    className,
    labelButtonSecondary,
    onClickSecondary,
    buttonAttrsSecondary,
    leftColor,
    rightColor,
    subtitle,
    targetBlank,
    link,
    list,
    url,
    onSubmit,
    popup,
    recaptchaKey,
    removeHandler,
    ...otherProps
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successForm, setSuccessForm] = useState(false);

  const handlerClick = () => {
    if (popup) {
      document.body.style.overflow = 'unset';
      setIsModalOpen(true);
    } else if (url) window.open(url, '_blank');
  };

  const onSubmitHandler = async values => {
    await onSubmit(values).then(() => {
      setSuccessForm(true);
      window.open(url, '_blank');
    });
  };

  return (
    <div
      className={cnBanner({ view, inverted }, [className])}
      style={{ backgroundColor: bgColor }}
      {...otherProps}
    >
      {view === 'case' ? (
        targetBlank ? (
          <a
            href={link}
            target="_blank"
            rel="noreferrer noopener"
            className={cnBanner('Link')}
          />
        ) : null
      ) : null}
      <div
        className={
          picture ? 'Banner-Inner Banner-Inner_needPicture' : 'Banner-Inner'
        }
      >
        <Typography.Text
          className={cnBanner('SubTitle')}
          size="xs"
          weight="bold"
          transform="uppercase"
          as="h3"
        >
          {subtitle}
        </Typography.Text>
        <Typography.Title
          className={cnBanner('Title')}
          size={titleSize}
          as="h2"
        >
          {title}
        </Typography.Title>
        <div className={cnBanner('InnerContent')}>
          {text && (
            <Typography.Text className={cnBanner('Text')}>
              {text}
            </Typography.Text>
          )}
          {list && (
            <Typography.Text parse className={cnBanner('List')}>
              {list}
            </Typography.Text>
          )}
        </div>
        {labelButton && (
          <div className={cnBanner('Button')}>
            <Button
              view="link-secondary"
              iconRight={buttonIcon}
              iconSize={buttonIconSize}
              label={labelButton}
              onClick={handlerClick}
              inverted={inverted}
              {...buttonAttrs}
            />
          </div>
        )}
        {labelButtonSecondary && (
          <div className={cnBanner('SecondaryButton')}>
            <Button
              view="link-secondary"
              iconRight={buttonIcon}
              iconSize={buttonIconSize}
              label={labelButtonSecondary}
              onClick={handlerClick}
              inverted={inverted}
              {...buttonAttrsSecondary}
            />
          </div>
        )}
      </div>
      {picture && (
        <div className={cnBanner('Picture')}>
          {pictureLabel && (
            <Typography.Text
              className={cnBanner('Picture-Label')}
              size="xs"
              weight="bold"
              transform="uppercase"
              as="span"
            >
              {pictureLabel}
            </Typography.Text>
          )}
          <Picture {...picture} />
        </div>
      )}
      {popup && (
        <Modal
          hasOverlay
          view="form"
          inverted={successForm}
          onOpen={() => {
            document.body.style.overflow = 'hidden';
          }}
          onClose={() => {
            document.body.style.overflow = 'unset';
          }}
          isOpen={isModalOpen}
          onOverlayClick={(): void => {
            document.body.style.overflow = 'unset';
            setIsModalOpen(false);
            setSuccessForm(false);
          }}
          onEsc={(): void => {
            setIsModalOpen(false);
            setSuccessForm(false);
            document.body.style.overflow = 'unset';
          }}
          showCloseButton
          className={popup.formType === 3 && 'FileCard-Longer-Popup'}
        >
          <div className={cnBanner('Left-Side')}>
            <div className={cnBanner('Left-Side-Top')}>
              {popup.formType === 3 && (
                <Typography.Text
                  size="xs"
                  weight="bold"
                  transform="uppercase"
                  className={cnBanner('Modal-Subtitle')}
                  as="span"
                >
                  {
                    popup.dictionary.companySiteFormDownloadFile
                      .companySiteFormDownloadFileTitle
                  }
                </Typography.Text>
              )}
              <Typography.Title size="md" className={cnBanner('Modal-Title')}>
                {popup.title}
              </Typography.Title>
              <Article content={popup.text} />
            </div>
            {popup.formType === 3 && picture && (
              <div className={cnBanner('Left-Side-Picture')}>
                <Picture
                  desktop={picture.url}
                  desktopWebp={picture.urlWebp}
                  alt={picture.alt}
                  name={picture.name}
                />
              </div>
            )}
          </div>
          <FileCardForm
            url={url}
            onSubmit={onSubmitHandler}
            type={popup.formType}
            dictionaries={popup.dictionary}
            recaptchaKey={recaptchaKey}
          />

          {successForm && (
            <div className={cnBanner('ModalSuccess')}>
              <Typography.Title size="lg" className={cnBanner('Title')} as="h3">
                {
                  popup.dictionary.companySiteFormDownloadFile
                    .companySiteFormDownloadFileSuccessTitle
                }
              </Typography.Title>
              <Typography.Text className={cnBanner('SubTitle')}>
                {
                  popup.dictionary.companySiteFormDownloadFile
                    .companySiteFormDownloadFileSuccessText
                }
              </Typography.Text>
            </div>
          )}
        </Modal>
      )}
    </div>
  );
});
