import React from 'react';
import {
  Redirect,
  matchPath,
  withRouter,
  RouteComponentProps,
  Route,
  generatePath,
} from 'react-router-dom';
import { PATH } from 'router';
import { RouteConfig } from './models';
import { layoutMap } from 'common/layouts';
import { generate } from 'shortid';
import { useSelector } from 'react-redux';
import { layoutKeySelector } from 'store';
import { ErrorPage } from 'common/components';

type Props = RouteComponentProps<
  any,
  any,
  { is404: boolean; is500: boolean; is451: boolean; is400: boolean }
> & {
  routes: Array<RouteConfig>;
};

export const SwitchRoutes = withRouter(
  ({ routes, location, history }: Props) => {
    const { pathname, state } = location;
    const { is404, is500, is451, is400 } = state || {};

    const layoutKey = useSelector(layoutKeySelector);

    let match: any;
    const matchedRoute = routes.find(route => {
      match = matchPath(pathname, {
        path: route.path,
        exact: route.exact,
      });
      return !!match;
    });

    if (!matchedRoute || !match) return null;

    const { layout, redirect, component: Component, update } = matchedRoute;

    const LayoutComponent = layoutMap[layout].component;

    const routerProps = {
      history,
      location,
      match,
    };

    const routeKey =
      update === 'always' ? generate() : update === 'path' ? pathname : '';

    return is451 ? (
      <ErrorPage {...routerProps} errorCode={451} />
    ) : (
      <LayoutComponent
        key={layoutKey}
        {...layoutMap[layout].props}
        {...routerProps}
      >
        {is500 ? (
          <ErrorPage {...routerProps} errorCode={500} />
        ) : is404 && pathname.includes('company/events/') ? (
          <Redirect to={generatePath(PATH.EVENTS)} />
        ) : is404 ? (
          <ErrorPage {...routerProps} errorCode={404} />
        ) : is400 ? (
          <ErrorPage {...routerProps} errorCode={400} />
        ) : redirect ? (
          <Redirect to={redirect} />
        ) : Component ? (
          <Route key={routeKey} component={Component} computedMatch={match} />
        ) : // <Component {...routerProps} key={routeKey} />
        null}
      </LayoutComponent>
    );
  }
);
