// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';

import { cnContentBuilderTemplate } from './index';

import { Typography } from '@dataartdev/uikit/Typography';
import { Button } from '@dataartdev/uikit/Button';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Picture } from '@dataartdev/uikit/Picture';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const { type, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();
  const { picture, subtitle, text, title, button } = props || {};

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: 'xl' },
          tablet: { cols: 12, colGap: 'xl' },
        }}
      >
        <GridItem
          breakpoints={{
            desktop: { col: 8 },
            tablet: { col: 8 },
            mobile: { col: 12 },
          }}
        >
          {title && (
            <Typography.Title
              as="h2"
              size="lg"
              className={cnContentBuilderTemplate('Section-Title')}
            >
              {title}
            </Typography.Title>
          )}
          {subtitle && (
            <Typography.Title
              as="h3"
              size="l"
              className={cnContentBuilderTemplate('SubTitle')}
            >
              {subtitle}
            </Typography.Title>
          )}
          {text && (
            <Typography.Text
              parse
              as="p"
              size="m"
              className={cnContentBuilderTemplate('InnerContent')}
            >
              {text}
            </Typography.Text>
          )}
          {button && (
            <div className={cnContentBuilderTemplate('Cta')}>
              <Button
                label={button?.title}
                iconRight={IconArrowRight}
                as={'a'}
                href={button?.link}
              />
            </div>
          )}
        </GridItem>
        {picture && (
          <GridItem
            className={cnContentBuilderTemplate('Page-Preview')}
            breakpoints={{
              desktop: { col: 4 },
              tablet: { col: 4 },
              mobile: { col: 12 },
            }}
          >
            <Picture {...picture} />
          </GridItem>
        )}
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
