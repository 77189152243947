// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';
import { generatePath } from 'react-router';
import { useIntl } from 'react-intl';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';

import { NewsCard } from '../../NewsCard/NewsCard';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Typography } from '@dataartdev/uikit/Typography';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const {
    type,
    newsTitle,
    news,
    mediaTitle,
    media,
    aliases,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();
  const { locale } = useIntl();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <Grid
        breakpoints={{
          desktop: { cols: 12, colGap: '5xl' },
          tablet: { rowGap: 'xxl' },
          mobile: { rowGap: 'xxl' },
        }}
      >
        {news?.length > 0 && (
          <GridItem
            breakpoints={{ desktop: { col: 4, order: 2 } }}
            className="News"
          >
            {newsTitle && (
              <Typography.Text
                size="xs"
                weight="bold"
                transform="uppercase"
                className="News-Title"
                as="h2"
              >
                {newsTitle}
              </Typography.Text>
            )}

            <div className={cnContentBuilderTemplate('ListSlider')}>
              <Slider navigation watchOverflow autoHeight spaceBetween={1000}>
                {news.map(itemsPress => (
                  <SliderItem>
                    <Grid cols="1" gap="3xl">
                      {itemsPress.map(item => (
                        <GridItem col="1" key={item.id}>
                          <NewsCard
                            {...item}
                            size="s"
                            path={
                              item.parentSlug && item.slug && locale
                                ? generatePath(aliases.NEWSROOM_PAGE, {
                                    // lang: locale === 'en' ? null : locale,
                                    parentSlug: item.parentSlug,
                                    slug: item.slug,
                                  })
                                : null
                            }
                            picture={false}
                          />
                        </GridItem>
                      ))}
                    </Grid>
                  </SliderItem>
                ))}
              </Slider>
            </div>
          </GridItem>
        )}
        {media?.length > 0 && (
          <GridItem breakpoints={{ desktop: { col: 8, order: -1 } }}>
            {mediaTitle && (
              <Typography.Text
                size="xs"
                weight="bold"
                transform="uppercase"
                as="h3"
              >
                {mediaTitle}
              </Typography.Text>
            )}

            <Slider navigation watchOverflow autoHeight spaceBetween={1000}>
              {media.map((group, index) => (
                <SliderItem key={index}>
                  <Grid
                    cols="1"
                    className={cnContentBuilderTemplate('ListBorder')}
                  >
                    {group.map(item => (
                      <GridItem col="1" key={item.id}>
                        <NewsCard
                          {...item}
                          labelButton={'Read more'}
                          path={
                            item.parentSlug && item.slug && locale
                              ? generatePath(aliases.NEWSROOM_PAGE, {
                                  // lang: locale === 'en' ? null : locale,
                                  parentSlug: item.parentSlug,
                                  slug: item.slug,
                                })
                              : null
                          }
                        />
                      </GridItem>
                    ))}
                  </Grid>
                </SliderItem>
              ))}
            </Slider>
          </GridItem>
        )}
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
