// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';
import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { TestimonialsSection } from '../../TestimonialsSection';
import { cnContentBuilderTemplate } from './index';

export type Props = {
  type?: number;
  title?: string;
  items?: any[];
  video?: any[];
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const { type, title, items, video, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();
  const testimonials = items || video;

  return (
    <>
      {testimonials.length > 0 && (
        <div
          className={cnContentBuilderTemplate('CustomTestimonials')}
          id={contentId}
        >
          <TestimonialsSection
            title={title || 'testimonials'}
            items={testimonials}
          />
        </div>
      )}
    </>
  );
});

export default ContentBuilderTemplate;
