// @ts-nocheck

import { cn } from '../../../utils/bem';

const Components = {};

Components[0] = require('./ContentBuilderTemplate0').default;
Components[1] = require('./ContentBuilderTemplate1').default;
Components[2] = require('./ContentBuilderTemplate2').default;
Components[3] = require('./ContentBuilderTemplate3').default;
Components[4] = require('./ContentBuilderTemplate4').default;
Components[5] = require('./ContentBuilderTemplate5').default;
Components[6] = require('./ContentBuilderTemplate6').default;
Components[7] = require('./ContentBuilderTemplate7').default;
Components[8] = require('./ContentBuilderTemplate8').default;
Components[9] = require('./ContentBuilderTemplate9').default;
Components[10] = require('./ContentBuilderTemplate10').default;
Components[11] = require('./ContentBuilderTemplate11').default;
Components[12] = require('./ContentBuilderTemplate12').default;
Components[13] = require('./ContentBuilderTemplate13').default;
Components[14] = require('./ContentBuilderTemplate14').default;
Components[15] = require('./ContentBuilderTemplate15').default;
Components[16] = require('./ContentBuilderTemplate16').default;
Components[17] = require('./ContentBuilderTemplate17').default;
Components[18] = require('./ContentBuilderTemplate18').default;
Components[19] = require('./ContentBuilderTemplate19').default;
Components[20] = require('./ContentBuilderTemplate20').default;
Components[21] = require('./ContentBuilderTemplate21').default;
Components[22] = require('./ContentBuilderTemplate22').default;
Components[23] = require('./ContentBuilderTemplate23').default;
Components[24] = require('./ContentBuilderTemplate24').default;
Components[26] = require('./ContentBuilderTemplate26').default;
Components[27] = require('./ContentBuilderTemplate27').default;
Components[28] = require('./ContentBuilderTemplate28').default;
Components[29] = require('./ContentBuilderTemplate29').default;
Components[30] = require('./ContentBuilderTemplate30').default;
Components[31] = require('./ContentBuilderTemplate31').default;
Components[32] = require('./ContentBuilderTemplate32').default;
Components[33] = require('./ContentBuilderTemplate33').default;
Components[34] = require('./ContentBuilderTemplate34').default;
Components[35] = require('./ContentBuilderTemplate35').default;
Components[36] = require('./ContentBuilderTemplate36').default;
Components[37] = require('./ContentBuilderTemplate37').default;
Components[38] = require('./ContentBuilderTemplate38').default;
Components[39] = require('./ContentBuilderTemplate39').default;
Components[40] = require('./ContentBuilderTemplate40').default;
Components[42] = require('./ContentBuilderTemplate42').default;
Components[44] = require('./ContentBuilderTemplate44').default;
Components[45] = require('./ContentBuilderTemplate45').default;
Components[46] = require('./ContentBuilderTemplate46').default;
Components[47] = require('./ContentBuilderTemplate47').default;
Components[48] = require('./ContentBuilderTemplate48').default;
Components[49] = require('./ContentBuilderTemplate49').default;
Components[50] = require('./ContentBuilderTemplate50').default;
Components[51] = require('./ContentBuilderTemplate51').default;
Components[52] = require('./ContentBuilderTemplate52').default;
Components[53] = require('./ContentBuilderTemplate53').default;
Components[55] = require('./ContentBuilderTemplate55').default;
Components[56] = require('./ContentBuilderTemplate56').default;
Components[57] = require('./ContentBuilderTemplate57').default;
Components[58] = require('./ContentBuilderTemplate58').default;
Components[59] = require('./ContentBuilderTemplate59').default;
Components[60] = require('./ContentBuilderTemplate60').default;
Components[61] = require('./ContentBuilderTemplate61').default;
Components[62] = require('./ContentBuilderTemplate62').default;
Components[63] = require('./ContentBuilderTemplate63').default;
Components[64] = require('./ContentBuilderTemplate64').default;
Components[67] = require('./ContentBuilderTemplate67').default;
Components[69] = require('./ContentBuilderTemplate69').default;
Components[70] = require('./ContentBuilderTemplate70').default;
Components[71] = require('./ContentBuilderTemplate71').default;
Components[76] = require('./ContentBuilderTemplate76').default;
Components[77] = require('./ContentBuilderTemplate77').default;
Components[80] = require('./ContentBuilderTemplate80').default;
Components[104] = require('./ContentBuilderTemplate104').default;
Components[105] = require('./ContentBuilderTemplate105').default;
Components[110] = require('./ContentBuilderTemplate110').default;
Components[111] = require('./ContentBuilderTemplate111').default;
Components[
  '3-secondary'
] = require('./ContentBuilderTemplate3Secondary').default;

export const cnContentBuilderTemplate = cn('ContentBuilderTemplate');

export default Components;
