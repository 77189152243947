// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';

import { cnContentBuilderTemplate } from './index';

import { Typography } from '@dataartdev/uikit/Typography';
import { Button } from '@dataartdev/uikit/Button';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Picture } from '@dataartdev/uikit/Picture';
import { CtaScrollerBtn } from '../../CtaScrollerBtn';
import { Article } from '../../Article/Article';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const {
    type,
    button,
    buttonColor,
    cover,
    subtitle,
    title,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();

  const { title: buttonTitle, link, targetBlank } = button || {};

  const isCTA = link?.includes('#');

  const buttonAttr =
    buttonTitle && link
      ? {
          color: `#${buttonColor}`,
          ...(isCTA
            ? { title: buttonTitle, link: link.replace('#', '') }
            : {
                label: buttonTitle,
                as: 'a',
                href: link,
                target: targetBlank ? '_blank' : '_self',
              }),
        }
      : {};

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: 'xl' },
          tablet: { cols: 12, colGap: 'xl' },
        }}
      >
        <GridItem
          breakpoints={{
            desktop: { col: 8 },
            tablet: { col: 8 },
            mobile: { col: 12 },
          }}
        >
          {title && (
            <Typography.Title
              as="h1"
              size="hg"
              className={cnContentBuilderTemplate('Section-Title')}
            >
              {title}
            </Typography.Title>
          )}
          {subtitle && (
            <Article
              content={subtitle}
              size="l"
              className={cnContentBuilderTemplate('Section-Text')}
            />
          )}
          {button && (
            <div className={cnContentBuilderTemplate('Cta')}>
              {isCTA ? (
                <CtaScrollerBtn {...buttonAttr} />
              ) : (
                <Button {...buttonAttr} iconRight={IconArrowRight} />
              )}
            </div>
          )}
        </GridItem>
        {cover && (
          <GridItem
            className={cnContentBuilderTemplate('Page-Preview')}
            breakpoints={{
              desktop: { col: 4 },
              tablet: { col: 4 },
              mobile: { col: 12 },
            }}
          >
            <Picture {...cover} />
          </GridItem>
        )}
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
