import './ClientTestimonial.scss';

import React from 'react';

import { cn } from 'ui/utils/bem';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography } from '@dataartdev/uikit/Typography';
import { User } from '@dataartdev/uikit/User';

type Props = {
  logoDesktop: string;
  logoDesktopWebp: string;
  quote: string;
  author?: string;
  authorPosition?: string;
  authorPhoto?: object;
};

const cnTestimonial = cn('ClientTestimonial');

export const ClientTestimonial: React.FC<Props> = ({
  logoDesktop,
  logoDesktopWebp,
  quote,
  author,
  authorPosition,
  authorPhoto,
}) => {
  return (
    <div className={cnTestimonial()}>
      <div className={cnTestimonial('Logo')}>
        <Picture desktop={logoDesktop} desktopWebp={logoDesktopWebp} />
      </div>
      <div className={cnTestimonial('Quote')}>
        <Typography.Text size="l">— {quote}</Typography.Text>
      </div>
      {author && (
        <div className={cnTestimonial('Author')}>
          <User
            name={author}
            info={authorPosition}
            avatarUrl={authorPhoto?.url}
          />
        </div>
      )}
    </div>
  );
};
