import './TeamMemberPage.scss';

import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import { useDataQuery, useGTMDataSend } from 'common/hooks';
import { queryTeamAdvisorPage } from 'network';

import { cn } from 'ui/utils/bem';
import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Article } from '../../../common/components/Article/Article';
import { Picture } from '@dataartdev/uikit/Picture';
import { SocialButton } from '@dataartdev/uikit/SocialButton';
import { Mq } from '@dataartdev/uikit/Mq';

import { IconFacebook } from '@dataartdev/uikit/IconFacebook';
import { IconTwitter } from '@dataartdev/uikit/IconTwitter';
import { IconLinkedin } from '@dataartdev/uikit/IconLinkedin';

const cnTeamMemberPage = cn('TeamMemberPage');

type Props = RouteComponentProps & {};

export const TeamAdvisorPage: React.FC<Props> = ({ match, history }) => {
  const { data } =
    useDataQuery(queryTeamAdvisorPage, {
      slug: match.params.slug,
    }) || {};

  const {
    breadcrumbs,
    pageTitle,
    bio,
    photo,
    firstName,
    lastName,
    titleForContactPage,
    location,
    facebook,
    twitter,
    linkedIn,
    metaTitle,
    tags,
  } = data || {};

  const path = useLocation();

  useGTMDataSend(path, metaTitle, tags);

  return (
    <div className={cnTeamMemberPage()}>
      <div className="container">
        <div className={cnTeamMemberPage('Breadcrumbs')}>
          {breadcrumbs && (
            <Breadcrumbs
              getLabel={page => page.text}
              getLink={page => page.url}
              history={history}
              pages={data.breadcrumbs}
            />
          )}
        </div>
        <div className={cnTeamMemberPage('Body')}>
          <Grid
            breakpoints={{
              mobile: { cols: 2 },
              tablet: { cols: 8, gap: 'm' },
              desktop: { cols: 12, gap: 'xl' },
            }}
          >
            <GridItem
              breakpoints={{
                mobile: { col: 2 },
                tablet: { col: 5 },
                desktop: { col: 6 },
              }}
            >
              <Mq query="--tablet-min">
                <div className={cnTeamMemberPage('Title')}>
                  <Typography.Title
                    size="lg"
                    dangerouslySetInnerHTML={{ __html: pageTitle }}
                    as="h1"
                  />
                </div>
              </Mq>
              <div className={cnTeamMemberPage('Bio')}>
                <Article content={bio} />
              </div>
            </GridItem>
            <GridItem
              breakpoints={{
                mobile: { col: 2, order: -1, colStart: 1 },
                tablet: { col: 3, colStart: 6 },
                desktop: { col: 6, colStart: 8 },
              }}
            >
              <Mq query="--mobile">
                <div className={cnTeamMemberPage('Title')}>
                  <Typography.Title size="lg" as="h1">
                    {pageTitle}
                  </Typography.Title>
                </div>
              </Mq>
              {photo && (
                <div className={cnTeamMemberPage('Picture')}>
                  <Picture {...photo} />
                </div>
              )}
              <div className={cnTeamMemberPage('Info')}>
                <Grid cols="12">
                  <GridItem
                    breakpoints={{
                      mobile: { col: 12, colStart: 1 },
                      tablet: { col: 12, colStart: 1 },
                      desktop: { col: 6, colStart: 1 },
                    }}
                  >
                    <Typography.Text
                      weight="bold"
                      size="m"
                      className={cnTeamMemberPage('InfoName')}
                    >
                      {firstName} {lastName}
                    </Typography.Text>
                    <Typography.Text
                      size="s"
                      className={cnTeamMemberPage('InfoSubtitle')}
                      dangerouslySetInnerHTML={{ __html: titleForContactPage }}
                    ></Typography.Text>
                    <Typography.Text
                      size="s"
                      className={cnTeamMemberPage('InfoLocation')}
                    >
                      {location}
                    </Typography.Text>
                  </GridItem>
                  <GridItem
                    breakpoints={{
                      mobile: { col: 12, colStart: 1 },
                      tablet: { col: 12, colStart: 1 },
                      desktop: { col: 6 },
                    }}
                  >
                    <div className={cnTeamMemberPage('Socials')}>
                      {facebook && (
                        <SocialButton
                          icon={IconFacebook}
                          as="a"
                          href={facebook}
                          target="_blank"
                        />
                      )}
                      {twitter && (
                        <SocialButton
                          icon={IconTwitter}
                          as="a"
                          href={twitter}
                          target="_blank"
                        />
                      )}
                      <SocialButton
                        icon={IconLinkedin}
                        as="a"
                        target="_blank"
                        href={linkedIn}
                      />
                    </div>
                  </GridItem>
                </Grid>
              </div>
            </GridItem>
          </Grid>
        </div>
      </div>
    </div>
  );
};
