import React from 'react';
import { Combobox } from '@dataartdev/uikit/Combobox';
import { ChoiceGroup } from '@dataartdev/uikit/ChoiceGroup';
import { CalendarInput } from '@dataartdev/uikit/CalendarInput';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { PropRenderItem, DefaultItem } from '@dataartdev/uikit/Combobox';
import { Tag } from '@dataartdev/uikit/Tag';
import { cnEventsPage } from '../pages/EventsPage';
import { cn } from 'ui/utils/bem';

import { enUS, de, ru, uk, bg } from 'date-fns/locale';
import { useIntl } from 'react-intl';

const locales = {
  en: enUS,
  de: de,
  ru: ru,
  ua: uk,
  bg: bg,
};

const cnSelectItemTag = cn('SelectItemTag');

type Props = {
  containerRef?: any;
  tags: any;
  moreTags: any;
  moreTagsButton: any;
  tagsPlaceholder: string;
  tagsSelected?: string;
  tagsChange?: any;
  industries?: any;
  industriesPlaceholder?: string;
  industriesSelected?: any;
  industriesChange?: any;
  technologies?: any;
  technologiesPlaceholder?: string;
  technologiesChange?: any;
  technologiesSelected?: any;
  types?: any;
  typeSelected?: any;
  typeChange?: any;
  dateSelected?: any;
  datePlaceholder?: any;
  dateChange?: any;
  dateCancel?: any;
};

export const renderItemTag: PropRenderItem<DefaultItem> = props => {
  const { item, active, hovered, onClick, onMouseEnter } = props;

  return (
    <div
      className={cnSelectItemTag('Item', { active, hovered })}
      role="option"
      aria-selected={active}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      {!active ? (
        <Tag onCancel={onClick} label={item.title} mode="gray" />
      ) : (
        <Tag onCancel={onClick} label={item.title} mode="cancel" />
      )}
    </div>
  );
};

export const FilterEvents: React.FC<Props> = (
  {
    containerRef,
    tagsPlaceholder,
    tags,
    moreTags,
    moreTagsButton,
    tagsSelected,
    tagsChange,
    industries,
    industriesPlaceholder,
    industriesSelected,
    industriesChange,
    technologies,
    technologiesSelected,
    technologiesPlaceholder,
    technologiesChange,
    types,
    typeSelected,
    typeChange,
    dateSelected,
    datePlaceholder,
    dateChange,
    dateCancel,
  },
  ref
) => {
  const { locale } = useIntl();

  return (
    <Grid
      cols="12"
      ref={containerRef}
      gap="3xl"
      yAlign="center"
      xAlign="between"
    >
      <GridItem col="6">
        <Grid cols="12" gap="xs">
          {industries?.length > 0 && (
            <GridItem col="6">
              <Combobox
                dropdownClassName="no-scroll"
                multiple
                groupValues
                placeholder={industriesPlaceholder}
                items={industries}
                value={industriesSelected}
                getItemLabel={item => item.title}
                onChange={industriesChange}
              />
            </GridItem>
          )}
          {technologies?.length > 0 && (
            <GridItem col="6">
              <Combobox
                multiple
                groupValues
                placeholder={technologiesPlaceholder}
                items={technologies}
                value={technologiesSelected}
                getItemLabel={item => item.title}
                onChange={technologiesChange}
              />
            </GridItem>
          )}
        </Grid>
      </GridItem>
      <GridItem col="6" colStart="8">
        <div className={cnEventsPage('Filter-Rights')}>
          <ChoiceGroup
            value={typeSelected}
            onChange={typeChange}
            items={types}
            getLabel={item => item.label}
          />
          <CalendarInput
            value={dateSelected}
            placeholder={datePlaceholder}
            onChange={dateChange}
            onCancel={dateCancel}
            locale={locales[locale]}
            type="date-range"
          />
        </div>
      </GridItem>
    </Grid>
  );
};
