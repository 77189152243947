import './ContentBuilderTemplate.scss';

import React from 'react';

import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { CtaBanner } from '../../CtaBanner/CtaBanner';
import { cnContentBuilderTemplate } from './index';
import { IRoot57 } from '../models/CTI_57.props';

export const ContentBuilderTemplate: React.FC<IRoot57> = props => {
  const { type, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <CtaBanner {...props} />
    </div>
  );
};

export default ContentBuilderTemplate;
