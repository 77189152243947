import './SustainabilityPage.scss';

import React, { useRef } from 'react';
import { RouteComponentProps, generatePath } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { dictionariesSelector } from 'store';

import { cn } from 'ui/utils/bem';

import { recaptchaKey, recaptchaFormKey } from 'common/constants';

import { PageCard } from '../../../common/components/PageCard/PageCard';
import { ContentCard } from '../../../common/components/ContentCard/ContentCard';
import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';
import { Typography } from '@dataartdev/uikit/Typography';
import { Picture } from '@dataartdev/uikit/Picture';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { BannerEvent } from '../../../common/components/BannerEvent/BannerEvent';
import { EventCard } from '../../../common/components/EventCard/EventCard';
import { MemberCard } from '@dataartdev/uikit/MemberCard';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { Button } from '@dataartdev/uikit/Button';
import { FileCard } from '../../../common/components';
import { TreeNav } from '@dataartdev/uikit/TreeNav';
import { BackgroundDecor } from '@dataartdev/uikit/BackgroundDecor';
import { Mq } from '@dataartdev/uikit/Mq';
import { useIntl } from 'react-intl';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

import { useDataQuery, useGTMDataSend } from 'common/hooks';
import { querySustainabilityPage, mutationFileForm } from 'network';
import { PATH } from 'router';
import { ProjectsCardsSlider } from 'common/components';

import sbjs from 'sourcebuster';
import { ContentBuilderItem } from '../../../common/components/ContentBuilder/ContentBuilder';

sbjs.init();

type Props = RouteComponentProps & {};

const cnSustainabilityPage = cn('SustainabilityPage');

export const SustainabilityPage: React.FC<Props> = ({ history, location }) => {
  const pageRef = useRef(null);
  const {
    companySiteSustainability,
    companySiteTableOfContent,
    companySiteForm,
  }: any = useSelector(dictionariesSelector) || {};
  const {
    companySiteSustainabilityValuesTitle: valuesTitle,
    companySiteSustainabilityEventsBlockLinkText: eventsBlockLinkText,
    companySiteSustainabilityPoliciesTitle: policiesTitle,
    companySiteSustainabilityReportsTitle: reportsTitle,
  } = companySiteSustainability || {};

  const { loading, data } = useDataQuery(querySustainabilityPage) || {};
  const { locale } = useIntl();

  const {
    breadcrumbs,
    headerPicture,
    headerTitle,
    headerText,
    centricValues,
    areasIntroductionText,
    firstAreaTitle,
    firstAreaText,
    secondAreaTitle,
    secondAreaText,
    secondAreaBanner,
    thirdAreaTitle,
    thirdAreaText,
    helplineText,
    helplineTitle,
    helplineEvents,
    diversificationText,
    diversificationTitle,
    diversificationBlocks,
    diversificationCharts,
    projectsBlocks,
    greenOfficeTitle,
    greenOfficeText,
    greenOfficeEvents,
    contactUsEmail,
    contactUsText,
    contactUsTitle,
    member,
    manifestTitle,
    manifestText,
    manifestFileTitle,
    manifestFile,
    manifestPicture,
    policiesFiles,
    reportsFiles,
    metaTitle,
    tags,
    relatedContent,
  } = data || {};

  const eventsSlider = {
    watchOverflow: true,
    navigation: true,
    slidesPerView: 1,
    spaceBetween: 8,
    breakpoints: {
      1200: {
        spaceBetween: 24,
        slidesPerView: 1,
      },
      767: {
        slidesPerView: 2,
        spaceBetween: 16,
      },
    },
  };

  useGTMDataSend(location, metaTitle, tags);

  const cameFrom = `typ: ${sbjs.get.current.typ} | src: ${sbjs.get.current.src} | mdm: ${sbjs.get.current.mdm} | cmp: ${sbjs.get.current.cmp} | cnt: ${sbjs.get.current.cnt} | trm: ${sbjs.get.current.trm}`;
  const submitButtonID =
    companySiteForm?.companySiteFormDownloadFile
      ?.companySiteFormDownloadFileTrackingID || 'file_download';

  return (
    <React.Fragment>
      {!loading && (
        <div className={cnSustainabilityPage()} ref={pageRef}>
          <Mq query="--desktop">
            <TreeNav
              title={companySiteTableOfContent}
              pageRef={pageRef}
              fixed
            />
          </Mq>

          <div className="container">
            {breadcrumbs && (
              <div className={cnSustainabilityPage('Breadcrumbs')}>
                <Breadcrumbs
                  getLabel={page => page.text}
                  getLink={page => page.url}
                  history={history}
                  pages={data.breadcrumbs}
                />
              </div>
            )}
            {(headerPicture || headerTitle || headerText) && (
              <Grid
                className={cnSustainabilityPage('Header')}
                breakpoints={{
                  desktop: { cols: 12, gap: 'xl' },
                  tablet: { cols: 8, gap: 'm' },
                }}
              >
                {headerPicture && (
                  <GridItem
                    className={cnSustainabilityPage('Header-Picture')}
                    breakpoints={{ desktop: { col: 4 }, tablet: { col: 2 } }}
                  >
                    <Picture {...headerPicture} />
                  </GridItem>
                )}
                {(headerTitle || headerText) && (
                  <GridItem
                    className={cnSustainabilityPage('Header-Content')}
                    breakpoints={{
                      desktop: { col: 7, colStart: 6 },
                      tablet: { col: 6 },
                    }}
                  >
                    {headerTitle && (
                      <Typography.Title
                        size="lg"
                        className={cnSustainabilityPage('Header-Title')}
                        as="h1"
                      >
                        {headerTitle}
                      </Typography.Title>
                    )}
                    {headerText && (
                      <Typography.Text
                        size="l"
                        dangerouslySetInnerHTML={{
                          __html: headerText,
                        }}
                      />
                    )}
                  </GridItem>
                )}
              </Grid>
            )}

            {centricValues?.length > 0 && (
              <div className={cnSustainabilityPage('Values')}>
                {valuesTitle && (
                  <Typography.Text
                    size="xs"
                    weight="bold"
                    transform="uppercase"
                    className={cnSustainabilityPage('Values-Title')}
                    as="h2"
                  >
                    {valuesTitle}
                  </Typography.Text>
                )}
                <Grid
                  className={cnSustainabilityPage('Values-List')}
                  breakpoints={{
                    desktop: { cols: 12, gap: 'xl' },
                    tablet: { cols: 8, colGap: 'm', rowGap: 'xl' },
                    mobile: { rowGap: 'm' },
                  }}
                >
                  {centricValues.map(card => (
                    <GridItem
                      key={card.id}
                      className={cnSustainabilityPage('ValuesCard')}
                      breakpoints={{
                        desktop: { col: 3 },
                        tablet: { col: 4 },
                      }}
                    >
                      <div
                        className={cnSustainabilityPage('ValuesCard-Border')}
                        style={{ backgroundColor: card.color || '#fff' }}
                      ></div>
                      <Typography.Title
                        size="sm"
                        className={cnSustainabilityPage('ValuesCard-Title')}
                        as="h3"
                      >
                        {card.title}
                      </Typography.Title>
                      <Typography.Text
                        className={cnSustainabilityPage('ValuesCard-SubTitle')}
                        weight="bold"
                      >
                        {card.subtitle}
                      </Typography.Text>
                      <Typography.Text
                        className={cnSustainabilityPage('ValuesCard-Text')}
                      >
                        {card.text}
                      </Typography.Text>
                    </GridItem>
                  ))}
                </Grid>
              </div>
            )}
          </div>
          {relatedContent && (
            <ContentBuilderItem
              type={28}
              title={relatedContent.title}
              items={relatedContent.items}
              aliases={PATH}
              topGap="6xl"
              bottomGap="3xxl"
            />
          )}
          <div className="container">
            {areasIntroductionText && (
              <Typography.Title
                size="sm"
                className={cnSustainabilityPage('IntroductionText')}
                parse
              >
                {areasIntroductionText}
              </Typography.Title>
            )}
          </div>
          <div className={cnSustainabilityPage('AdvantagesList')}>
            {(helplineText || helplineTitle || helplineEvents?.length > 0) && (
              <div
                className={cnSustainabilityPage('AdvantagesItem', {
                  first: true,
                })}
              >
                {(firstAreaText || firstAreaTitle) && (
                  <div
                    className={cnSustainabilityPage('AdvantagesItem-Header')}
                  >
                    <BackgroundDecor view="triangle" position="bottom-right" />
                    <div className="container">
                      <Grid cols="12" gap="xl">
                        <GridItem
                          breakpoints={{
                            desktop: { col: 8 },
                            tablet: { col: 12 },
                            mobile: { col: 12 },
                          }}
                        >
                          {firstAreaTitle && (
                            <Typography.Title
                              size="lg"
                              className={cnSustainabilityPage(
                                'AdvantagesItem-Header-Title'
                              )}
                              as="h2"
                            >
                              {firstAreaTitle}
                            </Typography.Title>
                          )}
                          {firstAreaText && (
                            <Typography.Text
                              size="l"
                              className={cnSustainabilityPage(
                                'AdvantagesItem-Header-Text'
                              )}
                            >
                              {firstAreaText}
                            </Typography.Text>
                          )}
                        </GridItem>
                      </Grid>
                    </div>
                  </div>
                )}

                {(helplineText || helplineTitle) && (
                  <div
                    className={cnSustainabilityPage('AdvantagesItem-Events')}
                  >
                    <BackgroundDecor view="triangle" position="top-left" />
                    <div className="container">
                      <Grid breakpoints={{ desktop: { cols: 12, gap: 'xl' } }}>
                        {(helplineText || helplineTitle) && (
                          <GridItem breakpoints={{ desktop: { col: 4 } }}>
                            {helplineTitle && (
                              <Typography.Title
                                size="md"
                                className={cnSustainabilityPage(
                                  'AdvantagesItem-Events-Title'
                                )}
                                as="h2"
                              >
                                {helplineTitle}
                              </Typography.Title>
                            )}
                            {helplineText && (
                              <Typography.Text
                                className={cnSustainabilityPage(
                                  'AdvantagesItem-Events-Text'
                                )}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: helplineText,
                                  }}
                                ></div>
                              </Typography.Text>
                            )}

                            <div
                              className={cnSustainabilityPage(
                                'AdvantagesItem-Events-Button'
                              )}
                            >
                              <Button
                                view="link-secondary"
                                iconRight={IconArrowRight}
                                iconSize={'m'}
                                isLink
                                to={generatePath(PATH.EVENTS, { lang: locale })}
                                label={eventsBlockLinkText}
                              />
                            </div>
                          </GridItem>
                        )}
                        {helplineEvents?.length > 0 && (
                          <GridItem
                            breakpoints={{ desktop: { col: 4, colStart: 6 } }}
                          >
                            <Slider {...eventsSlider} type="rightSide">
                              {helplineEvents.map(event => (
                                <SliderItem key={event.id}>
                                  <EventCard
                                    {...event}
                                    tags={event.tags}
                                    routePath={
                                      event.eventType === 1
                                        ? generatePath(PATH.EVENTS_PAGE, {
                                            slug: event.slug,
                                          })
                                        : null
                                    }
                                    redirectTo={
                                      event.eventType === 0
                                        ? event.fullUrl
                                        : null
                                    }
                                  />
                                </SliderItem>
                              ))}
                            </Slider>
                          </GridItem>
                        )}
                      </Grid>
                    </div>
                  </div>
                )}
                <div
                  className={cnSustainabilityPage(
                    'AdvantagesItem-Diversification'
                  )}
                >
                  <div className="container">
                    {(diversificationTitle || diversificationText) && (
                      <Grid
                        breakpoints={{
                          desktop: { cols: 12, gap: 'xl' },
                          tablet: { cols: 8 },
                          mobile: { cols: 8 },
                        }}
                      >
                        <GridItem
                          breakpoints={{
                            desktop: { col: 7 },
                            tablet: { col: 8 },
                            mobile: { col: 8 },
                          }}
                        >
                          {diversificationTitle && (
                            <Typography.Title
                              size="md"
                              className={cnSustainabilityPage(
                                'AdvantagesItem-Diversification-Title'
                              )}
                              as="h3"
                            >
                              {diversificationTitle}
                            </Typography.Title>
                          )}
                          {diversificationText && (
                            <Typography.Text
                              className={cnSustainabilityPage(
                                'AdvantagesItem-Diversification-Text'
                              )}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: diversificationText,
                                }}
                              ></div>
                            </Typography.Text>
                          )}
                        </GridItem>
                      </Grid>
                    )}
                    {diversificationBlocks?.length > 0 && (
                      <>
                        <Mq query="--tablet-min">
                          <Grid
                            breakpoints={{
                              desktop: { cols: 12, gap: '3xxl' },
                              tablet: { cols: 8, gap: '6xl' },
                              mobile: { gap: 'xxl' },
                            }}
                            className={cnSustainabilityPage(
                              'AdvantagesItem-Diversification-Cards'
                            )}
                          >
                            <GridItem
                              breakpoints={{
                                desktop: { col: 5 },
                                tablet: { col: 4 },
                              }}
                            >
                              <Grid cols="1" gap="xxl">
                                {diversificationBlocks.map((item, index) => (
                                  <>
                                    {(index & 1) === 0 && (
                                      <GridItem key={item.id}>
                                        <ContentCard
                                          title={item.title}
                                          titleTag="h4"
                                          photo={{
                                            desktop: item.backgroundImage.url,
                                            desktopWebp:
                                              item.backgroundImage.urlWebp,
                                          }}
                                          text={item.text}
                                        />
                                      </GridItem>
                                    )}
                                  </>
                                ))}
                              </Grid>
                            </GridItem>
                            <GridItem
                              breakpoints={{
                                desktop: { col: 5 },
                                tablet: { col: 4 },
                              }}
                            >
                              <Grid cols="1" gap="xxl">
                                {diversificationBlocks.map((item, index) => (
                                  <>
                                    {(index & 1) !== 0 && (
                                      <GridItem key={item.id}>
                                        <ContentCard
                                          title={item.title}
                                          titleTag="h4"
                                          photo={{
                                            desktop: item.backgroundImage.url,
                                            desktopWebp:
                                              item.backgroundImage.urlWebp,
                                          }}
                                          text={item.text}
                                        />
                                      </GridItem>
                                    )}
                                  </>
                                ))}
                              </Grid>
                            </GridItem>
                          </Grid>
                        </Mq>
                        <Mq query="--mobile">
                          <Grid
                            breakpoints={{
                              desktop: { cols: 12, gap: '3xxl' },
                              tablet: { cols: 8, gap: '6xl' },
                              mobile: { gap: 'xxl' },
                            }}
                            className={cnSustainabilityPage(
                              'AdvantagesItem-Diversification-Cards'
                            )}
                          >
                            {diversificationBlocks.map((item, index) => (
                              <GridItem key={item.id + 'mobile'}>
                                <ContentCard
                                  title={item.title}
                                  titleTag="h4"
                                  photo={{
                                    desktop: item.backgroundImage.url,
                                    desktopWebp: item.backgroundImage.urlWebp,
                                  }}
                                  text={item.text}
                                />
                              </GridItem>
                            ))}
                          </Grid>
                        </Mq>
                      </>
                    )}
                  </div>
                </div>
                {diversificationCharts?.length > 0 && (
                  <div
                    className={cnSustainabilityPage(
                      'AdvantagesItem-DiversificationCharts'
                    )}
                  >
                    <BackgroundDecor view="triangle" position="top-left" />
                    <div className="container">
                      <Grid
                        breakpoints={{
                          desktop: { cols: 12, gap: 'xl' },
                          tablet: { cols: 8, gap: 'm' },
                          mobile: { gap: 'm' },
                        }}
                      >
                        {diversificationCharts.map(item => (
                          <GridItem
                            breakpoints={{
                              desktop: { col: 4 },
                              tablet: { col: 4 },
                            }}
                            key={item.id}
                          >
                            <div
                              className={cnSustainabilityPage(
                                'AdvantagesItem-DiversificationCharts-Card'
                              )}
                            >
                              <Typography.Text
                                size="xs"
                                transform="uppercase"
                                weight="bold"
                                className={cnSustainabilityPage(
                                  'AdvantagesItem-DiversificationCharts-Title'
                                )}
                              >
                                {item.title}
                              </Typography.Text>
                              <Picture
                                desktop={item.backgroundImage.url}
                                desktopWebp={item.backgroundImage.urlWebp}
                                className={cnSustainabilityPage(
                                  'AdvantagesItem-DiversificationCharts-Picture'
                                )}
                              />
                              <div
                                className={cnSustainabilityPage(
                                  'AdvantagesItem-DiversificationCharts-Text'
                                )}
                                dangerouslySetInnerHTML={{ __html: item.text }}
                              />
                            </div>
                          </GridItem>
                        ))}
                      </Grid>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div
              className={cnSustainabilityPage('AdvantagesItem', {
                second: true,
              })}
            >
              {(secondAreaText || secondAreaTitle) && (
                <div className={cnSustainabilityPage('AdvantagesItem-Header')}>
                  <div className="container">
                    <Grid cols="12" gap="xl">
                      <GridItem
                        breakpoints={{
                          desktop: { col: 8 },
                          tablet: { col: 12 },
                          mobile: { col: 12 },
                        }}
                      >
                        {secondAreaTitle && (
                          <Typography.Title
                            size="lg"
                            className={cnSustainabilityPage(
                              'AdvantagesItem-Header-Title'
                            )}
                            as="h2"
                          >
                            {secondAreaTitle}
                          </Typography.Title>
                        )}
                        {secondAreaText && (
                          <Typography.Text
                            size="l"
                            className={cnSustainabilityPage(
                              'AdvantagesItem-Header-Text'
                            )}
                          >
                            {secondAreaText}
                          </Typography.Text>
                        )}
                      </GridItem>
                    </Grid>
                  </div>
                </div>
              )}
              {secondAreaBanner && (
                <div className={cnSustainabilityPage('AdvantagesItem-Banner')}>
                  <BackgroundDecor view="triangle" position="bottom-left" />
                  <div className="container">
                    <BannerEvent
                      picture={secondAreaBanner.picture}
                      title={secondAreaBanner.title}
                      text={secondAreaBanner.text}
                      date={secondAreaBanner.dateString}
                      time={secondAreaBanner.timeString}
                      link={secondAreaBanner.link}
                      labelButton={secondAreaBanner.linkText}
                    />
                  </div>
                </div>
              )}
              {projectsBlocks?.length > 0 && (
                <div
                  className={cnSustainabilityPage('AdvantagesItem-Projects')}
                >
                  <BackgroundDecor view="triangle" position="top-right" />
                  <div className="container">
                    <ProjectsCardsSlider items={projectsBlocks} />
                  </div>
                </div>
              )}
            </div>

            <div
              className={cnSustainabilityPage('AdvantagesItem', {
                third: true,
              })}
            >
              {(thirdAreaText || thirdAreaTitle) && (
                <div className={cnSustainabilityPage('AdvantagesItem-Header')}>
                  <div className="container">
                    <Grid cols="12" gap="xl">
                      <GridItem
                        breakpoints={{
                          desktop: { col: 8 },
                          tablet: { col: 12 },
                          mobile: { col: 12 },
                        }}
                      >
                        {thirdAreaTitle && (
                          <Typography.Title
                            size="lg"
                            className={cnSustainabilityPage(
                              'AdvantagesItem-Header-Title'
                            )}
                            as="h2"
                          >
                            {thirdAreaTitle}
                          </Typography.Title>
                        )}
                        {thirdAreaText && (
                          <Typography.Text
                            size="l"
                            className={cnSustainabilityPage(
                              'AdvantagesItem-Header-Text'
                            )}
                          >
                            {thirdAreaText}
                          </Typography.Text>
                        )}
                      </GridItem>
                    </Grid>
                  </div>
                </div>
              )}
              {(greenOfficeTitle || greenOfficeText) && (
                <div className={cnSustainabilityPage('AdvantagesItem-Events')}>
                  <BackgroundDecor view="triangle" position="bottom-right" />
                  <div className="container">
                    <Grid breakpoints={{ desktop: { cols: 12, gap: 'xl' } }}>
                      {(greenOfficeTitle || greenOfficeTitle) && (
                        <GridItem breakpoints={{ desktop: { col: 4 } }}>
                          {greenOfficeTitle && (
                            <Typography.Title
                              size="md"
                              className={cnSustainabilityPage(
                                'AdvantagesItem-Events-Title'
                              )}
                              as="h3"
                            >
                              {greenOfficeTitle}
                            </Typography.Title>
                          )}
                          {greenOfficeText && (
                            <Typography.Text
                              className={cnSustainabilityPage(
                                'AdvantagesItem-Events-Text'
                              )}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: greenOfficeText,
                                }}
                              ></div>
                            </Typography.Text>
                          )}

                          <div
                            className={cnSustainabilityPage(
                              'AdvantagesItem-Events-Button'
                            )}
                          >
                            <Button
                              view="link-secondary"
                              iconRight={IconArrowRight}
                              iconSize={'m'}
                              label={eventsBlockLinkText}
                              isLink
                              to={generatePath(PATH.EVENTS, {
                                lang: locale === 'en' ? null : locale,
                              })}
                            />
                          </div>
                        </GridItem>
                      )}
                      {greenOfficeEvents?.length > 0 && (
                        <GridItem
                          breakpoints={{ desktop: { col: 4, colStart: 6 } }}
                        >
                          <Slider {...eventsSlider} type="rightSide">
                            {greenOfficeEvents.map(event => (
                              <SliderItem key={event.id + 'green'}>
                                <EventCard
                                  {...event}
                                  languages={event.languages}
                                  routePath={
                                    event.eventType === 1
                                      ? generatePath(PATH.EVENTS_PAGE, {
                                          slug: event.slug,
                                        })
                                      : null
                                  }
                                  redirectTo={
                                    event.eventType === 0 ? event.fullUrl : null
                                  }
                                />
                              </SliderItem>
                            ))}
                          </Slider>
                        </GridItem>
                      )}
                    </Grid>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={cnSustainabilityPage('Contact')}>
            <BackgroundDecor view="triangle" position="top-left" />
            <div className="container">
              <Grid
                breakpoints={{
                  desktop: { cols: 12, gap: 'xl' },
                  tablet: { cols: 8, gap: 'm' },
                  mobile: { cols: 2, gap: '7xl' },
                }}
              >
                {member && (
                  <GridItem
                    breakpoints={{
                      desktop: { col: 3, colStart: 2 },
                      tablet: { col: 3 },
                      mobile: { col: 2, order: 1 },
                    }}
                  >
                    <MemberCard
                      {...member}
                      routePath={generatePath(PATH.TEAM_MEMBER, {
                        slug: member.slug,
                        lang: locale === 'en' ? null : locale,
                      })}
                    />
                  </GridItem>
                )}
                <GridItem
                  breakpoints={{
                    desktop: { col: 5, colStart: 6 },
                    tablet: { col: 4, colStart: 5 },
                    mobile: { col: 2, order: 0 },
                  }}
                >
                  <Typography.Title
                    size="lg"
                    className={cnSustainabilityPage('Contact-Title')}
                    as="h2"
                  >
                    {contactUsTitle}
                  </Typography.Title>
                  <Typography.Text
                    className={cnSustainabilityPage('Contact-Text')}
                  >
                    {contactUsText}
                  </Typography.Text>
                  {contactUsEmail && (
                    <Typography.Title
                      size="tiny"
                      as="a"
                      href={'mailto:' + contactUsEmail}
                      className={cnSustainabilityPage('Contact-Email')}
                    >
                      {contactUsEmail}
                    </Typography.Title>
                  )}
                </GridItem>
              </Grid>
            </div>
          </div>

          <div className={cnSustainabilityPage('Manifest')}>
            <div className="container">
              <PageCard
                size="l"
                title={manifestTitle}
                titleTag="h2"
                subtitle={manifestText}
                linkText={manifestFileTitle}
                link={manifestFile.url}
                decor={manifestPicture}
                decorPosition="bottomRight"
                buttonAttrs={{ target: '_blank' }}
              />
            </div>
          </div>
          <div className={cnSustainabilityPage('Files')}>
            <div className="container">
              <Grid
                breakpoints={{
                  desktop: { gap: 'xl', cols: 12 },
                  tablet: { gap: 'm', cols: 8 },
                  mobile: { gap: 'xxl', cols: 4 },
                }}
              >
                <GridItem
                  breakpoints={{
                    desktop: { col: 6 },
                    tablet: { col: 4 },
                    mobile: { col: 4 },
                  }}
                >
                  <Typography.Text
                    size="xs"
                    weight="bold"
                    transform="uppercase"
                  >
                    {policiesTitle}
                  </Typography.Text>
                  <Grid
                    breakpoints={{
                      desktop: { gap: 'xs', cols: 1 },
                      tablet: { gap: 'xs', cols: 1 },
                      mobile: { gap: 'xs', cols: 1 },
                    }}
                    className={cnSustainabilityPage('Files-List')}
                  >
                    {policiesFiles.map(file => (
                      <GridItem key={file.url} col="1">
                        <FileCard
                          url={file.url}
                          title={file.name}
                          extension={file.type}
                          size={file.size}
                          date={file.updatedAt}
                          recaptchaKey={recaptchaKey}
                          onSubmit={async body =>
                            await mutationFileForm({
                              body: {
                                ...body,
                                filePath: file.file.url,
                                pageId: data.id,
                                [`${recaptchaFormKey}`]: body.token,
                                cameFrom: cameFrom,
                                trackingId: submitButtonID,
                              },
                            })
                          }
                        />
                      </GridItem>
                    ))}
                  </Grid>
                </GridItem>
                <GridItem
                  breakpoints={{
                    desktop: { col: 6 },
                    tablet: { col: 4 },
                    mobile: { col: 4 },
                  }}
                >
                  <Typography.Text
                    size="xs"
                    weight="bold"
                    transform="uppercase"
                  >
                    {reportsTitle}
                  </Typography.Text>
                  <Grid
                    breakpoints={{
                      desktop: { gap: 's', cols: 1 },
                      tablet: { gap: 's', cols: 1 },
                      mobile: { gap: 's', cols: 1 },
                    }}
                    className={cnSustainabilityPage('Files-List')}
                  >
                    {reportsFiles.map(file => (
                      <GridItem key={file.url} col="1">
                        <FileCard
                          url={file.url}
                          extension={file.type}
                          size={file.size}
                          title={file.name}
                          recaptchaKey={recaptchaKey}
                          date={file.updatedAt}
                          onSubmit={async body =>
                            await mutationFileForm({
                              body: {
                                ...body,
                                filePath: file.file.url,
                                pageId: data.id,
                                [`${recaptchaFormKey}`]: body.token,
                                cameFrom: cameFrom,
                                trackingId: submitButtonID,
                              },
                            })
                          }
                        />
                      </GridItem>
                    ))}
                  </Grid>
                </GridItem>
              </Grid>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
