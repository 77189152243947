import './ContentBuilderTemplate.scss';

import React from 'react';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { cnContentBuilderTemplate } from './index';
import { CategoryCard } from '../../CategoryCard/CategoryCard';
import { IRoot56 } from '../models/CTI_56.props';

export const ContentBuilderTemplate: React.FC<IRoot56> = props => {
  const { type, services, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  return (
    <>
      {services && services.length > 0 && (
        <div
          className={cnContentBuilderTemplate({ type: typeNumber })}
          id={contentId}
        >
          <Grid
            breakpoints={{
              desktop: { cols: 12, gap: 'xl' },
              tablet: { cols: 12, gap: 'xl' },
              mobile: { cols: 12, gap: 'xl' },
            }}
            className={cnContentBuilderTemplate('Categories')}
          >
            {services.map((service, index) => (
              <GridItem
                key={service.id}
                breakpoints={{
                  desktop: { col: 3 },
                  tablet: { col: 6 },
                  mobile: { col: 12 },
                }}
              >
                <CategoryCard {...service} index={index} />
              </GridItem>
            ))}
          </Grid>
        </div>
      )}
    </>
  );
};

export default ContentBuilderTemplate;
