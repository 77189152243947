// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';
import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';

export type Props = {
  type?: number;
  columns?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const {
    aliases,
    background,
    id,
    column,
    title,
    type,
    oneColumn,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const halfColumn = Math.floor(column.length / 2);

  const typeNumber = type.toString();
  const columnsQuantityDesktop = oneColumn ? 12 : 6;
  const columnsQuantityTablet = oneColumn ? 12 : 6;

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Title size="lg" as="h2">
          {title}
        </Typography.Title>
      )}
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: oneColumn ? '' : 'xl' },
          tablet: { cols: 12, gap: oneColumn ? '' : 'xl' },
          mobile: { cols: 12 },
        }}
        className={cnContentBuilderTemplate('List-Text')}
      >
        {oneColumn && (
          <GridItem
            breakpoints={{
              desktop: { col: columnsQuantityDesktop },
              tablet: { col: columnsQuantityTablet },
              mobile: { col: 12 },
            }}
          >
            {column?.map(item => {
              return (
                <Typography.Title size="md" weight="bold" as="span">
                  {item}
                </Typography.Title>
              );
            })}
          </GridItem>
        )}
        {!oneColumn && (
          <>
            <GridItem
              breakpoints={{
                desktop: { col: columnsQuantityDesktop },
                tablet: { col: columnsQuantityTablet },
                mobile: { col: 12 },
              }}
            >
              {column?.map((item, index) => {
                if (index <= halfColumn) {
                  return (
                    <Typography.Title size="tiny" weight="bold" as="span">
                      {item}
                    </Typography.Title>
                  );
                }
              })}
            </GridItem>
            <GridItem
              breakpoints={{
                desktop: { col: columnsQuantityDesktop },
                tablet: { col: columnsQuantityTablet },
                mobile: { col: 12 },
              }}
            >
              {column?.map((item, index) => {
                if (index > halfColumn) {
                  return (
                    <Typography.Title size="tiny" weight="bold" as="span">
                      {item}
                    </Typography.Title>
                  );
                }
              })}
            </GridItem>
          </>
        )}
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
