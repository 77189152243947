import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { DataQueryVariables } from 'common/hooks';
import { toPaginationParams } from '../../helpers';
import { clearFalsyProps, clearUrl } from 'common/utils';

export const queryEventCards: DataQueryFunction = (
  variables: DataQueryVariables | undefined
): Promise<any> => {
  const {
    industriesIds,
    locationsId,
    languagesCode,
    tagId,
    isOnline,
    startDate,
    endDate,
    isArchived,
  } = (variables && variables.filter) || {};
  const paginationParams = toPaginationParams(variables);
  const filterParams = clearFalsyProps({
    locationsId,
    industriesIds,
    languagesCode,
    tagId,
    isOnline,
    startDate,
    endDate,
    isArchived,
  });

  return httpClient.get(clearUrl(`/events/${paginationParams.page}`), {
    params: {
      ...filterParams,
    },
  });
};
