import './AwardCard.scss';
import React from 'react';

import { cn } from '../../utils/bem';
import { Button } from '@dataartdev/uikit/Button';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography } from '@dataartdev/uikit/Typography';
import { Award } from '../ContentBuilder/templates/models/CTI_64';
import { prepareButtonAttrs } from '../../utils/prepareButtonAttrs';

export const cnAwardCard = cn('AwardCard');

export const AwardCard: React.FC<Award> = props => {
  const { title, text, picture, link } = props || {};
  const { title: buttonTitle, link: buttonLink, targetBlank } = link || {};
  const buttonAttr = prepareButtonAttrs(buttonLink, buttonTitle, targetBlank);

  return (
    <div className={cnAwardCard()}>
      {picture && <Picture className={cnAwardCard('Image')} {...picture} />}
      <div className={cnAwardCard('Content')}>
        <div className={cnAwardCard('Inner')}>
          {title && (
            <Typography.Title className={cnAwardCard('Title')} size="md" parse>
              {title}
            </Typography.Title>
          )}
          {text && (
            <Typography.Text className={cnAwardCard('Text')} size="m" parse>
              {text}
            </Typography.Text>
          )}
        </div>
        {Object.keys(buttonAttr).length > 0 && (
          <div className={cnAwardCard('Link')}>
            <Button
              view="link-secondary"
              iconRight={IconArrowRight}
              iconSize="m"
              {...buttonAttr}
            />
          </div>
        )}
      </div>
    </div>
  );
};
