// @ts-nocheck
import './PriceCard.scss';

import React from 'react';
import { dictionariesSelector } from 'store';
import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { cn } from '../../utils/bem';

import { Typography } from '@dataartdev/uikit/Typography';
import { CtaScrollerBtn } from '../CtaScrollerBtn';
import { useSelector } from 'react-redux';

type Props = {
  title: string;
  text: string;
  subTitle: string;
  id: string;
};

export const cnPriceCard = cn('PriceCard');

export const PriceCard = forwardRefWithAs<Props>(
  ({ title, text, subTitle, id }, ref) => {
    const {
      companySiteForm: { companySiteFormPrice },
    }: any = useSelector(dictionariesSelector) || {};

    return (
      <div className={cnPriceCard()}>
        <div className={cnPriceCard('Top')}>
          <Typography.Title parse size="sm">
            {title}
          </Typography.Title>
          <Typography.Text
            parse
            size="m"
            className={cnPriceCard('Description')}
          >
            {text}
          </Typography.Text>
          <Typography.Title parse size="md" className={cnPriceCard('Price')}>
            {subTitle}
          </Typography.Title>
        </div>
        <div className={cnPriceCard('Button')}>
          <CtaScrollerBtn
            link={'expert-form-01'}
            title={companySiteFormPrice || 'Request'}
          />
        </div>
      </div>
    );
  }
);
