// @ts-nocheck
import './TestimonialBlockCard.scss';

import React from 'react';

import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { cn } from '../../utils/bem';

import { IconProps } from '@dataartdev/uikit/Icon';
import { IconClose } from '@dataartdev/uikit/IconClose';
import { IconPlay } from '@dataartdev/uikit/IconPlay';
import { IconYouTube } from '@dataartdev/uikit/IconYouTube';
import { Button } from '@dataartdev/uikit/Button';
import { Modal } from '@dataartdev/uikit/Modal';
import { Mq } from '@dataartdev/uikit/Mq';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography } from '@dataartdev/uikit/Typography';

export type Props = {
  buttonIcon?: IconProps;
  text?: string;
};

export const cnTestimonialBlockCard = cn('TestimonialBlockCard');

export const TestimonialBlockCard = forwardRefWithAs<Props>((props, ref) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const {
    title,
    buttonIcon = IconYouTube,
    text,
    linkText,
    video,
  } = usePropsHandler(cnTestimonialBlockCard(), props);

  return (
    <React.Fragment>
      <div className={cnTestimonialBlockCard({})}>
        {video.picture && Object.keys(video.picture).length > 0 && (
          <div className={cnTestimonialBlockCard('Left')}>
            <div className={cnTestimonialBlockCard('Picture')}>
              <Mq query="--tablet-min">
                <Button
                  view="primary"
                  form="circle"
                  size={'3xxl'}
                  iconSize={'l'}
                  iconRight={IconPlay}
                  onlyIcon
                  className={cnTestimonialBlockCard('Picture-Button')}
                  onClick={(): void => setIsModalOpen(true)}
                />
              </Mq>
              <Mq query="--mobile">
                <Button
                  view="primary"
                  form="circle"
                  size={'xxl'}
                  iconSize={'m'}
                  iconRight={IconPlay}
                  onlyIcon
                  className={cnTestimonialBlockCard('Picture-Button')}
                  onClick={(): void => setIsModalOpen(true)}
                />
              </Mq>
              {<Picture {...video.picture} cover />}
              {video.videoUrl && (
                <Modal
                  className={cnTestimonialBlockCard('Modal')}
                  isOpen={isModalOpen}
                  hasOverlay
                  width="full"
                  onOverlayClick={(): void => setIsModalOpen(false)}
                  onEsc={(): void => setIsModalOpen(false)}
                >
                  <iframe
                    title="modal-iframe"
                    src={video.videoUrl}
                    frameBorder={'0'}
                    width="100%"
                    height="100%"
                  ></iframe>

                  <Button
                    view="ghost"
                    iconRight={IconClose}
                    className="Modal-Close"
                    onClick={(): void => setIsModalOpen(false)}
                  />
                </Modal>
              )}
            </div>
          </div>
        )}
        <div className={cnTestimonialBlockCard('Right')}>
          <div className={cnTestimonialBlockCard('Border')} />
          {title && (
            <Typography.Title
              className={cnTestimonialBlockCard('Title')}
              size="lg"
              as="h2"
            >
              {title}
            </Typography.Title>
          )}
          {text && (
            <Typography.Text className={cnTestimonialBlockCard('SubTitle')}>
              {text}
            </Typography.Text>
          )}

          {video && (
            <div className={cnTestimonialBlockCard('Button')}>
              <Button
                view="link-secondary"
                iconSize={'xs'}
                as="a"
                href={video.url}
                label={linkText}
                iconRight={buttonIcon}
                target="_blank"
              />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
});
