import './EventsPage.scss';

import React, { useState, useEffect } from 'react';
import { RouteComponentProps, generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { SiteBlock } from '../../../common/components/SiteBlock/SiteBlock';
import { Typography } from '@dataartdev/uikit/Typography';
import { Slider, SliderItem, renderFraction } from '@dataartdev/uikit/Slider';
import { EventCard } from '../../../common/components/EventCard/EventCard';
import { Mq } from '@dataartdev/uikit/Mq';
import { Button } from '@dataartdev/uikit/Button';
import { Loader } from '@dataartdev/uikit/Loader';
import { Tag } from '@dataartdev/uikit/Tag';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { CalendarPropValue } from '@dataartdev/uikit/Calendar/Calendar';

import { IconFilter } from '@dataartdev/uikit/IconFilter';

import { cn } from 'ui/utils/bem';
import { isDateRange, formatDate, parseDate } from 'ui/utils/date';
import { isDefined } from 'ui/utils/type-guards';

import { useInfiniteScroll } from '@dataartdev/uikit/useInfiniteScroll';

import { PATH, getUrlFilter, updateQueryParams } from 'router';
import { dictionariesSelector } from 'store';
import {
  queryEventsPage,
  queryEventCards,
  queryEventArchiveCards,
  queryEventsArchivePage,
} from 'network';
import { useDataQuery, useDataPagination, useGTMDataSend } from 'common/hooks';

import { FilterEvents } from '../components/FilterEvents';

import { FilterModal } from 'common/components';

import { EventsForm } from '../components/EventsForm';
import { NoEvents } from '../components/NoEvents';

export const cnEventsPage = cn('EventsPage');
export const cnFilterModal = cn('FilterModal');

type Props = RouteComponentProps & {};

const sliderParams = {
  spaceBetween: 100,
  watchOverflow: true,
  navigation: true,
  pagination: {
    clickable: true,
    type: 'fraction',
    renderFraction: renderFraction,
  },
};

export const EventsPage: React.FC<Props> = ({ location, history, match }) => {
  const urlFilter = getUrlFilter(location);

  const { companySiteEvents: staticContent } =
    useSelector(dictionariesSelector) || {};

  const [items, setItems] = useState<any>([]);
  const [itemsFiltered, setItemsFiltered] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [technologiesSelected, setTechnologiesSelected] = useState<any>();
  const [industriesSelected, setIndustriesSelected] = useState<any>();
  const [typeSelected, setTypeSelected] = useState<any | null>(null);
  const [formPosition, setFormPosition] = useState<any | null>(null);
  const [dateSelected, setDateSelected] = useState<
    CalendarPropValue | undefined
  >(undefined);
  const [modalIndustries, setModalIndustries] = React.useState(null);
  const [dateFormatted, setDateFormatted] = React.useState(null);
  const [modalTechnologies, setModalTechnologies] = React.useState(null);
  const filterModalRef = React.useRef(null);

  const eventTypes = [
    {
      label: staticContent?.companySiteEventsFilterAll,
      value: null,
    },
    {
      label: staticContent?.companySiteEventsFilterOnline,
      value: 'true',
    },
    {
      label: staticContent?.companySiteEventsFilterOffline,
      value: 'false',
    },
  ];

  const {
    companySiteEventsNothingFound: noEventsTitle,
    companySiteEventsNothingFoundSubtitle: noEventsSubtitle,
  } = staticContent || {};

  const { data, loading, filter: filterData, updateFilter, totals } =
    useDataQuery(
      match.path === PATH.EVENTS_ARCHIVE
        ? queryEventsArchivePage
        : queryEventsPage,
      {
        filter: {
          technologiesIds: urlFilter.technologiesIds,
          industriesIds: urlFilter.industriesIds,
          isOnline: urlFilter.isOnline,
          startDate: urlFilter.startDate,
          endDate: urlFilter.endDate,
        },
      },
      { skip: currentPage > 1 }
    ) || {};

  const {
    pagination,
    updateFilter: updateFilterFeed,
    filter: filterCards,
    updatePagination,
    data: dataCards,
    loading: loadingCards,
    error,
  } = useDataPagination(
    match.path === PATH.EVENTS_ARCHIVE
      ? queryEventArchiveCards
      : queryEventCards,
    {
      filter: {
        industriesIds: urlFilter.industriesIds,
        technologiesIds: urlFilter.technologiesIds,
        isOnline: urlFilter.isOnline,
        startDate: urlFilter.startDate,
        endDate: urlFilter.endDate,
      },
    },
    {
      skip: currentPage < 2,
    }
  );

  const { bannerEvents, filter, metaTitle, tags, relatedLinks } = data || {};

  const { industries: filterIndustries, technologies: filterTechnologies } =
    filter || {};

  const changeFilter = filter => {
    // setItems([]);
    // setItemsFiltered([]);
    setCurrentPage(1);
    setFormPosition(null);
    updateFilter(filter);
    updateFilterFeed(filter);
    setTypeSelected(null);
  };

  const resetFilters = () => {
    const data = {
      industriesIds: '',
      technologiesIds: '',
      isOnline: '',
      startDate: '',
      endDate: '',
    };
    updateQueryParams(history, data);
    changeFilter(data);
  };

  const resetForm = () => {
    setIndustriesSelected([]);
    setModalIndustries([]);
    setModalTechnologies([]);
    hideModalFilter();
    changeFilter({
      industriesIds: '',
      technologiesIds: '',
      isOnline: '',
      startDate: '',
      endDate: '',
    });
  };

  const submitModalForm = () => {
    setIndustriesSelected(modalIndustries);
    setModalTechnologies(modalTechnologies);
    changeFilter({
      industriesIds: modalIndustries?.map(item => item.id),
      technologiesIds: modalTechnologies?.map(item => item.id),
    });
    hideModalFilter();
  };

  const showModalFilter = () => {
    if (filterModalRef) {
      filterModalRef.current.show();
    }
  };

  const hideModalFilter = () => {
    if (filterModalRef) {
      filterModalRef.current.hide();
    }
  };

  useEffect(() => {
    if (dateSelected?.length) {
      let valueFormatted = '';

      if (isDefined(dateSelected[0])) {
        valueFormatted += formatDate(dateSelected[0]);
      }

      if (isDefined(dateSelected[1])) {
        if (isDefined(dateSelected[0])) valueFormatted += ' - ';
        valueFormatted += formatDate(dateSelected[1]);
      }

      setDateFormatted(valueFormatted);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelected]);

  // useEffect(() => {
  //   if (!loading) {
  //     console.log('resetfilters');
  //     resetFilters();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [history.location.search]);

  // console.log(history);

  useEffect(() => {
    if (dataCards?.events) {
      setItems(prev => [...prev, ...dataCards.events]);
    } else {
      setItems([]);
    }
    if (dataCards?.filteredEvents) {
      setItemsFiltered(prev => [...prev, ...dataCards.filteredEvents]);
    } else {
      setItemsFiltered([]);
    }
  }, [dataCards]);

  useEffect(() => {
    if (data?.page?.events) {
      setItems(prev => data.page.events);
    } else {
      setItems([]);
    }
    if (data?.page?.filteredEvents) {
      setItemsFiltered(prev => data.page.filteredEvents);
    } else {
      setItemsFiltered([]);
    }
  }, [data]);

  useEffect(() => {
    if (items.length && !formPosition) {
      items.length > 9
        ? setFormPosition(9)
        : setFormPosition(items?.length - 1);
    }
  }, [items]);

  useEffect(() => {
    let params = urlFilter.industriesIds;
    if (typeof urlFilter.industriesIds === 'string') {
      params = [params];
    }

    setIndustriesSelected(
      filterIndustries?.filter(item =>
        params.some(i => item.id.toString() === i)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, filterIndustries]);

  useEffect(() => {
    let params = urlFilter.technologiesIds;
    if (typeof params === 'string') {
      params = [params];
    }

    setTechnologiesSelected(
      filterTechnologies?.filter(item =>
        params.some(i => item.id.toString() === i)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, filterTechnologies]);

  useEffect(() => {
    setTypeSelected(
      // eslint-disable-next-line eqeqeq
      eventTypes.find(el => el.value == filterCards.isOnline) ?? eventTypes[0]
    );

    const initDateArray = [];

    if (filterCards.startDate)
      initDateArray.push(parseDate(filterCards.startDate));
    if (filterCards.endDate) initDateArray.push(parseDate(filterCards.endDate));
    if (initDateArray.length) setDateSelected(initDateArray);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCards, history]);

  useEffect(() => {
    setCurrentPage(pagination.page);
  }, [pagination]);

  useEffect(() => {
    updateQueryParams(history, filterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, filterData]);

  const [sentryRef] = useInfiniteScroll({
    loading: loadingCards,
    hasNextPage: pagination?.page < totals,
    onLoadMore: () => {
      updatePagination({ page: pagination.page + 1 });
    },
    disabled: !!error,
  });

  useGTMDataSend(location, metaTitle, tags);

  return (
    <React.Fragment>
      <div className={cnEventsPage()}>
        <div className="container">
          <Grid
            breakpoints={{ tablet: { cols: 2 }, mobile: { cols: 2 } }}
            yAlign="center"
          >
            <GridItem>
              <div className={cnEventsPage('Nav')}>
                <div className={cnEventsPage('Nav-Item')}>
                  <Typography.Title
                    size="lg"
                    as="link"
                    to={generatePath(PATH.EVENTS, match.params)}
                    className={
                      location.pathname ===
                      generatePath(PATH.EVENTS, match.params)
                        ? 'active'
                        : null
                    }
                  >
                    {staticContent.companySiteEventsTitle}
                  </Typography.Title>
                </div>
                <div className={cnEventsPage('Nav-Item')}>
                  <Typography.Title
                    size="lg"
                    as="link"
                    className={
                      location.pathname ===
                      generatePath(PATH.EVENTS_ARCHIVE, match.params)
                        ? 'active'
                        : null
                    }
                    to={generatePath(PATH.EVENTS_ARCHIVE, match.params)}
                  >
                    {staticContent.companySiteEventsArchiveTitle}
                  </Typography.Title>
                </div>
              </div>
            </GridItem>
            <Mq query="--tablet-max">
              <GridItem className={cnEventsPage('FilterButton')}>
                <Button
                  view="ghost"
                  iconRight={IconFilter}
                  active={
                    technologiesSelected?.length > 0 ||
                    industriesSelected?.length > 0 ||
                    !!dateSelected ||
                    typeSelected
                  }
                  onClick={showModalFilter}
                />

                <FilterModal
                  ref={filterModalRef}
                  onSubmitForm={submitModalForm}
                  onResetForm={resetForm}
                  industries={{
                    title: staticContent.companySiteEventsFilterIndustries,
                    items: filterIndustries,
                    onChange: ({ value }) => {
                      setModalIndustries(value);
                    },
                    value: modalIndustries,
                  }}
                  technologies={{
                    title: staticContent.companySiteEventsFilterTechnologies,
                    items: filterTechnologies,
                    onChange: ({ value }) => {
                      setModalTechnologies(value);
                    },
                    value: modalTechnologies,
                  }}
                  types={{
                    value: typeSelected,
                    items: eventTypes,
                    onChange: ({ value }) => {
                      changeFilter({
                        isOnline: value.value,
                      });
                    },
                  }}
                  datepicker={{
                    value: dateSelected,
                    onChange: ({ value }) => {
                      changeFilter({
                        startDate: isDateRange(value)
                          ? formatDate(value[0])
                          : value,
                        endDate:
                          isDateRange(value) && isDefined(value[1])
                            ? formatDate(value[1])
                            : null,
                      });

                      setDateSelected(value);
                    },
                    onCancel: e => {
                      e.stopPropagation();
                      setDateSelected(null);
                      changeFilter({
                        startDate: null,
                        endDate: null,
                      });
                    },
                    placeholder: staticContent.companySiteEventsFilterDate,
                  }}
                />
              </GridItem>
            </Mq>
          </Grid>
          <Mq query="--desktop">
            <div className={cnEventsPage('Slider')}>
              <Slider {...sliderParams}>
                {bannerEvents &&
                  bannerEvents.length > 0 &&
                  bannerEvents.map(event => (
                    <SliderItem key={event.id + 'slider'}>
                      <EventCard
                        routePath={generatePath(PATH.EVENTS_PAGE, {
                          slug: event.slug,
                        })}
                        size="l"
                        {...event}
                      />
                    </SliderItem>
                  ))}
              </Slider>
            </div>
            {filter && (
              <div className={cnEventsPage('Filters')}>
                <FilterEvents
                  industries={filterIndustries}
                  industriesSelected={industriesSelected}
                  industriesPlaceholder={
                    staticContent.companySiteEventsFilterIndustries
                  }
                  industriesChange={({ value }) => {
                    setIndustriesSelected(value);
                    changeFilter({
                      industriesIds: value?.map(item => item.id),
                    });
                  }}
                  technologies={filterTechnologies}
                  technologiesSelected={technologiesSelected}
                  technologiesPlaceholder={
                    staticContent.companySiteEventsFilterTechnologies
                  }
                  technologiesChange={({ value }) => {
                    setTechnologiesSelected(value);
                    changeFilter({
                      technologiesIds: value?.map(item => item.id),
                    });
                  }}
                  types={eventTypes}
                  typeSelected={typeSelected}
                  typeChange={({ value }) => {
                    changeFilter({
                      isOnline: value.value,
                    });
                  }}
                  dateSelected={dateSelected}
                  dateChange={({ value }) => {
                    changeFilter({
                      startDate: isDateRange(value)
                        ? formatDate(value[0])
                        : value,
                      endDate:
                        isDateRange(value) && isDefined(value[1])
                          ? formatDate(value[1])
                          : null,
                    });

                    setDateSelected(value);
                  }}
                  dateCancel={e => {
                    e.stopPropagation();
                    setDateSelected(null);
                    changeFilter({
                      startDate: null,
                      endDate: null,
                    });
                  }}
                  datePlaceholder={staticContent.companySiteEventsFilterDate}
                />
              </div>
            )}
          </Mq>

          <Mq query="--tablet-max">
            {(dateSelected ||
              technologiesSelected?.length > 0 ||
              industriesSelected?.length > 0) && (
              <div className={cnEventsPage('Controls-Selected')}>
                <Typography.Text
                  size="xs"
                  weight="bold"
                  transform="uppercase"
                  className={cnEventsPage('Controls-Selected-Title')}
                  as="h2"
                >
                  Search results for:
                </Typography.Text>
                <div className={cnEventsPage('Controls-Selected-List')}>
                  {industriesSelected?.length > 0 &&
                    industriesSelected.map(item => (
                      <div key={item.id + item.title}>
                        <Tag
                          onCancel={() => {
                            const actualArray = industriesSelected.filter(
                              w => w.id !== item.id
                            );
                            setIndustriesSelected(actualArray);
                            changeFilter({
                              industriesIds: actualArray?.map(item => item.id),
                            });
                          }}
                          label={item.title}
                          mode="cancel"
                        />
                      </div>
                    ))}

                  {technologiesSelected?.length > 0 &&
                    technologiesSelected.map(item => (
                      <div key={item.id + item.title}>
                        <Tag
                          onCancel={() => {
                            const actualArray = technologiesSelected.filter(
                              w => w.id !== item.id
                            );
                            setTechnologiesSelected(actualArray);
                            changeFilter({
                              technologiesIds: actualArray?.map(
                                item => item.id
                              ),
                            });
                          }}
                          label={item.title}
                          mode="cancel"
                        />
                      </div>
                    ))}

                  {dateSelected && (
                    <div>
                      <Tag
                        onCancel={() => {
                          setDateSelected(null);
                          changeFilter({
                            startDate: '',
                            endDate: '',
                          });
                        }}
                        label={dateFormatted}
                        mode="cancel"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </Mq>

          {!loading && (
            <div className={cnEventsPage('Body')}>
              {itemsFiltered?.length > 0 && (
                <div className={cnEventsPage('Cards')}>
                  <Grid
                    breakpoints={{
                      desktop: { cols: 12, gap: 'xl' },
                      tablet: { cols: 8, colGap: 'm', rowGap: 'xl' },
                      mobile: { cols: 1, gap: 'm' },
                    }}
                  >
                    {itemsFiltered.map((event, index) => (
                      <>
                        <GridItem
                          breakpoints={{
                            desktop: { col: 4 },
                            tablet: { col: 4 },
                            mobile: { col: 1 },
                          }}
                          key={event.id + 'default'}
                        >
                          <EventCard
                            routePath={generatePath(PATH.EVENTS_PAGE, {
                              slug: event.slug,
                            })}
                            {...event}
                          />
                        </GridItem>
                      </>
                    ))}
                  </Grid>
                  <div ref={sentryRef} />
                </div>
              )}

              {items?.length > 0 && (
                <div className={cnEventsPage('Cards')}>
                  {itemsFiltered.length > 0 ? (
                    <Typography.Title
                      size="lg"
                      className={cnEventsPage('Cards-Title')}
                      as="h2"
                    >
                      Смотрите также:
                    </Typography.Title>
                  ) : null}

                  <Grid
                    breakpoints={{
                      desktop: { cols: 12, gap: 'xl' },
                      tablet: { cols: 8, colGap: 'm', rowGap: 'xl' },
                      mobile: { cols: 1, gap: 'm' },
                    }}
                  >
                    {items.map((event, index) => (
                      <>
                        <GridItem
                          breakpoints={{
                            desktop: { col: 4 },
                            tablet: { col: 4 },
                            mobile: { col: 1 },
                          }}
                          key={'more' + event.id + index}
                        >
                          <EventCard
                            routePath={generatePath(PATH.EVENTS_PAGE, {
                              slug: event.slug,
                            })}
                            {...event}
                          />
                        </GridItem>
                        {index === formPosition && (
                          <GridItem
                            breakpoints={{
                              desktop: { col: 4 },
                              tablet: { col: 4 },
                              mobile: { col: 1 },
                            }}
                            key={event.id + 'default'}
                          >
                            <EventsForm />
                          </GridItem>
                        )}
                      </>
                    ))}
                  </Grid>
                </div>
              )}

              {!items?.length && !itemsFiltered?.length && (
                <SiteBlock
                  useMargin
                  topGap="3xl"
                  className={cnEventsPage('Empty')}
                >
                  <Grid
                    breakpoints={{
                      desktop: { cols: 12, gap: 'xl' },
                      tablet: { cols: 8, rowGap: 'xxl' },
                      mobile: { rowGap: 'xxl' },
                    }}
                    cols="1"
                  >
                    <GridItem
                      breakpoints={{ desktop: { col: 7 }, tablet: { col: 8 } }}
                      col="1"
                    >
                      <NoEvents
                        title={noEventsTitle}
                        subtitle={noEventsSubtitle}
                        relatedLinks={relatedLinks}
                      />
                    </GridItem>
                    <GridItem
                      breakpoints={{
                        desktop: { col: 4, colStart: 9 },
                        tablet: { col: 4 },
                      }}
                      col="1"
                    >
                      <EventsForm />
                    </GridItem>
                  </Grid>
                </SiteBlock>
              )}

              {totals > currentPage && <div ref={sentryRef} />}
            </div>
          )}

          {(loading || loadingCards) && (
            <SiteBlock topGap="xxl">
              <Loader />
            </SiteBlock>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
