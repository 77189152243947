import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { DataQueryVariables } from 'common/hooks';
import { clearUrl, clearFalsyProps } from 'common/utils';

export const queryClientsPageMore: DataQueryFunction = (
  variables: DataQueryVariables | undefined
): Promise<any> => {
  const props = clearFalsyProps(variables || {});

  return httpClient.get(clearUrl(`/clients`), {
    params: {
      ...props,
    },
  });
};
