// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { Typography } from '@dataartdev/uikit/Typography';
import { Article } from '../../Article/Article';
import { Picture } from '@dataartdev/uikit/Picture';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const { type, title, text, picture, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  return (
    <>
      <Grid breakpoints={{ desktop: { cols: 12 } }}>
        <GridItem breakpoints={{ desktop: { col: 8 } }}>
          <div
            className={cnContentBuilderTemplate({ type: typeNumber })}
            id={contentId}
          >
            {title && (
              <Typography.Title
                size="lg"
                className={cnContentBuilderTemplate('Title')}
                as="h2"
              >
                {title}
              </Typography.Title>
            )}
            <div className={cnContentBuilderTemplate('Row')}>
              {text && (
                <Article
                  content={text}
                  className={cnContentBuilderTemplate('SubTitle')}
                />
              )}
              {picture && <Picture {...picture} />}
            </div>
          </div>
        </GridItem>
      </Grid>
    </>
  );
});

export default ContentBuilderTemplate;
