import './ResourceForm.scss';

import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import { Typography } from '@dataartdev/uikit/Typography';
import { Picture } from '@dataartdev/uikit/Picture';

import { useSelector } from 'react-redux';
import { layoutSelector } from 'store';

import { Form, FormBody } from '../../Form';
import { TextField } from '@dataartdev/uikit/TextField';
import { Button } from '@dataartdev/uikit/Button';
import { Checkbox } from '@dataartdev/uikit/Checkbox';

import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { mutationResourceForm } from 'network';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Article } from '../../Article/Article';

import { cnModalPage } from '../ModalPage';
import TagManager from 'react-gtm-module';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';

import ReCAPTCHA from 'react-google-recaptcha';
import sbjs from 'sourcebuster';
import { usePagePath } from '../../../hooks/usePagePath';

sbjs.init();

type Props = {
  onSubmitForm: (val: any) => void;
  modalData: object;
};

export const ResourceForm: React.FC<Props> = (
  { onSubmitForm, modalData },
  ref
) => {
  const [successForm, setSuccessForm] = useState<boolean>(false);
  const { pageId }: any = useSelector(layoutSelector) || {};
  const reCaptchaRef = useRef(null);

  const { dictionary, file }: any = modalData;
  const { companySiteFormDownloadFile: staticContent } = dictionary;

  const pagePath = usePagePath();

  const cameFrom = `typ: ${sbjs.get.current.typ} | src: ${sbjs.get.current.src} | mdm: ${sbjs.get.current.mdm} | cmp: ${sbjs.get.current.cmp} | cnt: ${sbjs.get.current.cnt} | trm: ${sbjs.get.current.trm}`;
  const submitButtonID =
    staticContent?.companySiteFormDownloadFileTrackingID || 'file_download';

  const onSubmit = async (values, actions) => {
    const token = await reCaptchaRef.current.executeAsync();

    await mutationResourceForm({
      body: {
        ...values,
        pageId,
        [`${recaptchaFormKey}`]: token,
        cameFrom: cameFrom,
        trackingId: submitButtonID,
      },
    }).then(() => {
      window.open(file.url, '_blank');
      TagManager.dataLayer({
        dataLayer: {
          event: 'send_form',
          form_name: submitButtonID,
          page_path: pagePath,
          tags: 'not set',
        },
      });
      actions.resetForm();
      setSuccessForm(true);
    });
  };

  const schema = [
    yup.object({
      email: yup
        .string()
        .email(
          staticContent.companySiteFormDownloadFileEmail
            .companySiteFormDownloadFileEmailError
        )
        .required(staticContent.companySiteFormDownloadFileRequiredField)
        .nullable(),
      name: yup
        .string()
        .min(3)
        .nullable(),
      company: yup
        .string()
        .min(3)
        .nullable(),
      privacyAgreement: yup
        .boolean()
        .oneOf([true], 'on')
        .required(),
    }),
    yup.object({
      email: yup
        .string()
        .email(
          staticContent.companySiteFormDownloadFileEmail
            .companySiteFormDownloadFileEmailError
        )
        .required(staticContent.companySiteFormDownloadFileRequiredField)
        .nullable(),
      name: yup
        .string()
        .min(3)
        .nullable(),
      privacyAgreement: yup
        .boolean()
        .oneOf([true], 'on')
        .required(),
    }),
    yup.object({
      email: yup
        .string()
        .email(
          staticContent.companySiteFormDownloadFileEmail
            .companySiteFormDownloadFileEmailError
        )
        .required(staticContent.companySiteFormDownloadFileRequiredField)
        .nullable(),
      name: yup
        .string()
        .min(3)
        .nullable(),
      company: yup
        .string()
        .min(3)
        .nullable(),
      privacyAgreement: yup
        .boolean()
        .oneOf([true], 'on')
        .required(),
    }),
  ];

  const initValues = {
    email: '',
    name: '',
    company: '',
    privacyAgreement: false,
  };

  const largeForm = modalData.formType == 4;
  const leftSideCols = largeForm ? 5 : 12;
  const rightSideCols = largeForm ? 7 : 12;

  return (
    <div className={cnModalPage({ resource: true, largeForm: largeForm })}>
      {modalData.title && (
        <Typography.Text
          size="xs"
          weight="bold"
          transform="uppercase"
          as="h3"
          className={cnModalPage('Title')}
        >
          {modalData.title}
        </Typography.Text>
      )}
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: largeForm ? '6xl' : 'xl' },
          tablet: { cols: 1, gap: 'xl' },
          mobile: { cols: 1, gap: 'xl' },
        }}
      >
        <GridItem breakpoints={{ desktop: { col: leftSideCols } }}>
          {modalData.subtitle && (
            <Typography.Title
              as="h2"
              size="md"
              className={cnModalPage('Title')}
            >
              {modalData.subtitle}
            </Typography.Title>
          )}
          {modalData.text && (
            <Article className={cnModalPage('Text')} content={modalData.text} />
          )}
          {modalData.picture && largeForm && (
            <Picture
              className={cnModalPage('PicturePreview')}
              {...modalData.picture}
            />
          )}
        </GridItem>
        <GridItem breakpoints={{ desktop: { col: rightSideCols } }}>
          <div className={cnModalPage('Body')}>
            <Form
              enableReinitialize
              initialValues={initValues}
              validationSchema={schema[modalData.formType]}
              onSubmit={onSubmit}
              validateOnChange
            >
              {({
                isSubmitting,
                errors,
                handleChange,
                handleBlur,
                values,
                touched,
                dirty,
              }) => {
                return (
                  <FormBody>
                    {(modalData.formType == 2 || largeForm) && (
                      <TextField
                        name="name"
                        onChange={({ e }) => handleChange(e)}
                        onBlur={handleBlur}
                        value={values.name}
                        label={staticContent.companySiteFormDownloadFileName}
                        view="outline"
                        width="full"
                        caption={touched.name && errors.name}
                        status={touched.name && !!errors.name && 'alert'}
                      />
                    )}
                    <TextField
                      name="email"
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values.email}
                      label="E-mail"
                      view="outline"
                      width="full"
                      required
                      caption={touched.email && errors.email}
                      status={touched.email && !!errors.email && 'alert'}
                    />
                    <div className={cnModalPage('Bottom')}>
                      <Checkbox
                        required
                        label={
                          staticContent.companySiteFormDownloadFileSubscribePolicy
                        }
                        onChange={({ e }) => handleChange(e)}
                        name="privacyAgreement"
                        checked={values.privacyAgreement}
                      />
                      <div className={cnModalPage('Button')}>
                        <Button
                          id={
                            staticContent.companySiteFormDownloadFileTrackingID
                          }
                          iconRight={IconArrowRight}
                          disabled={
                            isSubmitting ||
                            !dirty ||
                            !!Object.keys(errors).length
                          }
                          label={
                            staticContent.companySiteFormDownloadFileSubmit
                          }
                          type="submit"
                        />
                        <ReCAPTCHA
                          ref={reCaptchaRef}
                          sitekey={recaptchaKey}
                          size="invisible"
                        />
                      </div>
                    </div>
                  </FormBody>
                );
              }}
            </Form>
          </div>
        </GridItem>
      </Grid>
      {successForm && (
        <div className={cnModalPage('Success')}>
          <Typography.Title size="md" className={cnModalPage('Title')}>
            {staticContent.companySiteFormDownloadFileSuccessTitle}
          </Typography.Title>
          <Typography.Text className={cnModalPage('SubTitle')}>
            {staticContent.companySiteFormDownloadFileSuccessText}
          </Typography.Text>
        </div>
      )}
    </div>
  );
};
