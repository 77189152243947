import './ColorfullCard.scss';

import React from 'react';
import { Typography } from '@dataartdev/uikit/Typography';
import { Article } from '../Article/Article';
import { cn } from 'ui/utils/bem';

type Props = {
  background?: string;
  isInverted?: boolean;
  title?: string;
  description?: string;
  image?: string;
};

const cnColorfullCard = cn('ColorfullCard');

export const ColorfullCard: React.FC<Props> = props => {
  const { background, isInverted, title, description, image } = props || {};
  const textColor = isInverted ? '#fff' : '#0f161a';

  return (
    <div
      className={cnColorfullCard()}
      style={{ background: background, color: textColor }}
    >
      <Typography.Title size="sm" as="h3">
        {title}
      </Typography.Title>
      {description && <Article content={description} />}
      {image && <img src={image} alt={title} />}
    </div>
  );
};
