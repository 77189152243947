// @ts-nocheck
import './HeaderMenuMobile.scss';

import React, { useState } from 'react';
import { generatePath } from 'react-router-dom';

import { PropsWithJsxAttributes } from '../../../utils/types/PropsWithJsxAttributes';
import { cn } from '../../../utils/bem';
import { useChoiceGroup } from '@dataartdev/uikit/useChoiceGroup';
import { IconArrowDown } from '@dataartdev/uikit/IconArrowDown';
import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography } from '@dataartdev/uikit/Typography';

export const cnHeaderMenuMobile = cn('HeaderMenuMobile');

export type Item = {
  title?: string;
  nodeUrl?: string;
  target?: string;
  externalLink?: boolean;
  onClick?: React.EventHandler<React.MouseEvent>;
  children?: never;
};

type MenuProps = PropsWithJsxAttributes<
  {
    items: Item[];
  },
  'nav'
>;

export const HeaderMenuMobile: React.FC<MenuProps> = ({
  items,
  className,
  onClick,
  match,
  language,
  languages,
  ...otherProps
}) => {
  const setParams = ({ externalLink, nodeUrl, targetBlank }) => {
    return {
      onClick,
      as: 'a',
      href: nodeUrl || externalLink,
      target: targetBlank ? '_blank' : '_self',
      // width: 'full',
      // isLink: !!nodeUrl,
      // as: !!nodeUrl ? 'link' : 'a',
      // to: nodeUrl ? nodeUrl ?? '/404' : null,
      // target: targetBlank ? '_blank' : null,
      // href: externalLink,
    };
  };

  const [openedKeys, setOpenedKeys] = useState<boolean>(null);

  const { getOnChange, getChecked } = useChoiceGroup({
    value: openedKeys,
    getKey: item => {
      return item.node?.title || item?.title;
    },
    callBack: params => {
      setOpenedKeys(params.value);
    },
    multiple: true,
  });

  return (
    <nav className={cnHeaderMenuMobile(null, [className])}>
      <ul className={cnHeaderMenuMobile('List')}>
        {items.map((item, index) => {
          const {
            type,
            node: { title, nodeUrl, externalLink, targetBlank, logo, label },
            nodes,
            columns,
            sideColumn,
            additionalNodes,
          } = item || {};
          const tagWrapperAttrs = setParams({
            externalLink,
            nodeUrl,
            targetBlank,
          });

          return (
            <li
              className={cnHeaderMenuMobile('Item')}
              key={cnHeaderMenuMobile('Item', { index })}
            >
              <div className={cnHeaderMenuMobile('ItemWrapper')}>
                <Button
                  className={cnHeaderMenuMobile('Link', {})}
                  view="transparent"
                  as="a"
                  label={title}
                  size="s"
                  align="left"
                  {...tagWrapperAttrs}
                />

                {(nodes?.length > 0 || columns?.length > 0) && (
                  <Button
                    className={cnHeaderMenuMobile('CollapseIcon', {
                      active: getChecked(item),
                    })}
                    view="transparent"
                    size="s"
                    iconRight={IconArrowDown}
                    onClick={e => getOnChange(item)(e)}
                  />
                )}
              </div>
              {nodes && (
                <ul
                  className={cnHeaderMenuMobile('SubList', {
                    active: getChecked(item),
                  })}
                >
                  {nodes.map(
                    (
                      {
                        title,
                        nodeUrl,
                        externalLink,
                        targetBlank,
                        logo,
                        label,
                      },
                      index
                    ) => {
                      const tagWrapperAttrs = setParams({
                        externalLink,
                        targetBlank,
                        nodeUrl,
                      });
                      return (
                        <li
                          className={cnHeaderMenuMobile('SubItem')}
                          key={cnHeaderMenuMobile('SubItem', { index })}
                        >
                          <Button
                            className={cnHeaderMenuMobile('Link', {})}
                            view="text"
                            align="left"
                            size="s"
                            label={title}
                            {...tagWrapperAttrs}
                            exact
                          />
                        </li>
                      );
                    }
                  )}
                </ul>
              )}
              <ul
                className={cnHeaderMenuMobile('SubList', {
                  active: getChecked(item),
                })}
              >
                {columns &&
                  [...columns, sideColumn]?.map((item, index) => {
                    const { title, nodes } = item || {};
                    return (
                      <li
                        className={cnHeaderMenuMobile('Item')}
                        key={cnHeaderMenuMobile('Item', { index })}
                      >
                        <div className={cnHeaderMenuMobile('ItemWrapper')}>
                          <Button
                            className={cnHeaderMenuMobile('Link', {})}
                            view="transparent"
                            // as="a"
                            label={title}
                            size="s"
                            align="left"
                          />
                          <Button
                            className={cnHeaderMenuMobile('CollapseIcon', {
                              active: getChecked(item),
                            })}
                            view="transparent"
                            size="s"
                            iconRight={IconArrowDown}
                            onClick={e => getOnChange(item)(e)}
                          />
                        </div>
                        <ul
                          className={cnHeaderMenuMobile('SubList', {
                            active: getChecked(item),
                          })}
                        >
                          {nodes.map(
                            (
                              {
                                title,
                                nodeUrl,
                                externalLink,
                                targetBlank,
                                logo,
                                label,
                              },
                              index
                            ) => {
                              const tagWrapperAttrs = setParams({
                                externalLink,
                                targetBlank,
                                nodeUrl,
                              });

                              return (
                                <li
                                  className={cnHeaderMenuMobile('SubItem')}
                                  key={cnHeaderMenuMobile('SubItem', { index })}
                                >
                                  <Button
                                    className={cnHeaderMenuMobile('Link', {})}
                                    view="text"
                                    align="left"
                                    size="s"
                                    // label={title}
                                    {...tagWrapperAttrs}
                                    exact
                                  >
                                    {logo && (
                                      <img
                                        src={logo.urlWebp || logo.url}
                                        alt={title}
                                        className="Picture"
                                      />
                                    )}
                                    {title}
                                    {logo && <IconOpenInNew />}
                                  </Button>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </li>
                    );
                  })}
                {additionalNodes &&
                  additionalNodes.map(
                    ({
                      title,
                      nodeUrl,
                      externalLink,
                      targetBlank,
                      logo,
                      label,
                    }) => {
                      const tagWrapperAttrs = setParams({
                        externalLink,
                        targetBlank,
                        nodeUrl,
                      });
                      return (
                        <li className={cnHeaderMenuMobile('SubItem')}>
                          <Button
                            className={cnHeaderMenuMobile('Link', {})}
                            view="text"
                            align="left"
                            size="s"
                            label={title}
                            {...tagWrapperAttrs}
                            exact
                          />
                        </li>
                      );
                    }
                  )}
              </ul>
            </li>
          );
        })}
      </ul>
      <div className={cnHeaderMenuMobile('Languages')}>
        {languages?.map(lang => {
          const isActive =
            language.title.toLowerCase() === lang.title.toLowerCase();
          return (
            <a
              href={lang.link}
              target={lang.targetBlank ? '_blank' : '_self'}
              className={cnHeaderMenuMobile('Languages-Item', {
                Active: isActive,
              })}
              key={lang.id}
            >
              <Typography.Text
                size="xs"
                weight="bold"
                transform="uppercase"
                onClick={() => localStorage.setItem('currentLang', lang.slug)}
              >
                {lang.title === '' ? 'EN' : lang.title.toUpperCase()}
              </Typography.Text>
            </a>
          );
        })}
      </div>
    </nav>
  );
};
