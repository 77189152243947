import { Location } from 'history';
import queryString from 'query-string';
import { camelizeKeys } from 'humps';

export const getUrlFilter = (location: Location<any>) => {
  const {
    page,
    industriesIds,
    languagesCode,
    tagsIds,
    isOnline,
    startDate,
    endDate,
    technologiesIds,
    isArchived,
  } = camelizeKeys(queryString.parse(location.search)) as any;

  return {
    page: Number(page || ''),
    industriesIds: industriesIds || '',
    technologiesIds: technologiesIds || '',
    languagesCode: languagesCode || '',
    tagsIds: tagsIds || '',
    isOnline: isOnline || '',
    startDate: startDate || '',
    endDate: endDate || '',
    isArchived: isArchived || '',
  };
};
