// @ts-nocheck
import './ContentBuilderTemplate.scss';
import './ContentBuilderTemplate69.scss';

import React from 'react';
import { cn } from '@bem-react/classname';
import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid } from '@dataartdev/uikit/Grid';
import { GridItem } from '@dataartdev/uikit/Grid';
import { VideoTestimonialCard } from '../../VideoTestimonialCard/VideoTestimonialCard';
import { IRoot69 } from './models/CTI_69';

const cnVideoTestimonialsCards = cn('VideoTestimonialsCards');

export const ContentBuilderTemplate: React.FC<IRoot69> = props => {
  const { type, title, contentId, items, background } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <Grid
        breakpoints={{
          desktop: { cols: 12 },
          tablet: { cols: 12 },
          mobile: { cols: 12 },
        }}
      >
        {title && (
          <GridItem
            breakpoints={{
              desktop: { col: 10 },
              tablet: { col: 8 },
              mobile: { col: 12 },
            }}
          >
            <Typography.Title size="lg">{title}</Typography.Title>
          </GridItem>
        )}
        <GridItem
          breakpoints={{
            desktop: { col: 12 },
            tablet: { col: 12 },
            mobile: { col: 12 },
          }}
          className={cnVideoTestimonialsCards('List')}
        >
          <Grid
            breakpoints={{
              desktop: { cols: 12, gap: 'xl' },
              tablet: { cols: 12, gap: 'xl' },
              mobile: { cols: 12, gap: 'xl' },
            }}
          >
            {items?.length > 0 &&
              items.map(testimonial => (
                <GridItem
                  key={testimonial.id}
                  breakpoints={{
                    desktop: { col: 6 },
                    tablet: { col: 6 },
                    mobile: { col: 12 },
                  }}
                >
                  <VideoTestimonialCard {...testimonial} />
                </GridItem>
              ))}
          </Grid>
        </GridItem>
      </Grid>
    </div>
  );
};

export default ContentBuilderTemplate;
