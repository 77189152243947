import './ContentBuilderTemplate.scss';
import React from 'react';
import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { cnContentBuilderTemplate } from './index';
import { ServicePreviewCard } from '../../ServicePreviewCard/ServicePreviewCard';
import { nanoid } from '@reduxjs/toolkit';
import { Typography } from '@dataartdev/uikit/Typography';
import { IRoot55 } from '../models/CTI_55.props';

export const ContentBuilderTemplate = forwardRefWithAs<IRoot55>(props => {
  const {
    type,
    title,
    text,
    button,
    label,
    titleII,
    textII,
    buttonII,
    labelII,
    contentId,
    sectionTitle,
    picture,
    pictureII,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const cardData = [
    {
      title: title,
      text: text,
      button: button,
      label: label,
      picture: picture,
    },
    {
      title: titleII,
      text: textII,
      button: buttonII,
      label: labelII,
      picture: pictureII,
    },
  ];

  const typeNumber = type.toString();

  const gridSize = (title || text) && (titleII || textII) ? 6 : 12;

  return (
    <div className={cnContentBuilderTemplate({ type: typeNumber })}>
      <Grid
        id={contentId}
        breakpoints={{
          desktop: { cols: 12, gap: 'xl' },
          tablet: { cols: 12, gap: 'xl' },
          mobile: { cols: 12, gap: 'xl' },
        }}
        className={cnContentBuilderTemplate('ServicePreviews')}
      >
        {sectionTitle && (
          <GridItem
            breakpoints={{
              desktop: { col: 8 },
              tablet: { col: 10 },
              mobile: { col: 12 },
            }}
          >
            <Typography.Title size="lg" id={contentId}>
              {sectionTitle}
            </Typography.Title>
          </GridItem>
        )}
        {cardData.map(card => {
          const { title, text } = card;
          if (title || text) {
            return (
              <GridItem
                key={nanoid(6)}
                breakpoints={{
                  desktop: { col: gridSize },
                  tablet: { col: gridSize },
                  mobile: { col: 12 },
                }}
              >
                <ServicePreviewCard {...card} />
              </GridItem>
            );
          }
          return null;
        })}
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
