import './ContentBuilderTemplate.scss';
import './ContentBuilderTemplate111.scss';
import React from 'react';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { ContentBanner } from '../../ContentBanner/ContentBanner';
import { Typography } from '@dataartdev/uikit/Typography';
import { cn } from '../../../utils/bem';
import { IRoot111 } from '../models/CTI_111.props';

const cnBannerCarouselSection = cn('BannerCarouselSection');

export const ContentBuilderTemplate: React.FC<IRoot111> = props => {
  const { type, banners = [], title, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  const options = {
    watchOverflow: true,
    navigation: true,
    spaceBetween: 0,
    slidesPerView: 1,
  };

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Title
          className={cnContentBuilderTemplate('Title')}
          size="lg"
          as="h2"
        >
          {title}
        </Typography.Title>
      )}
      <div className={cnBannerCarouselSection()}>
        {banners.length === 1 ? (
          <ContentBanner {...banners[0]} />
        ) : (
          <Slider {...options} className={cnBannerCarouselSection('Carousel')}>
            {banners.map(banner => (
              <SliderItem>
                <ContentBanner {...banner} />
              </SliderItem>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default ContentBuilderTemplate;
