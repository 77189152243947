// @ts-nocheck

import './ExternalCard.scss';

import React from 'react';

import { cn } from '../../utils/bem';

import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';
import { getSizeByMap } from '@dataartdev/uikit/__internal__/cjs-src/utils/getSizeByMap';
import { Button } from '@dataartdev/uikit/Button';
import { User } from '@dataartdev/uikit/User';
import { Typography, TitleProps } from '@dataartdev/uikit/Typography';

export const cardPropSize = ['m'] as const;
export type CardPropSize = typeof cardPropSize[number];
export const cardPropSizeDefault: CardPropSize = cardPropSize[0];

export const cardPropView = ['default', 'text'] as const;
export type CardPropView = typeof cardPropView[number];
export const cardPropViewDefault: CardPropView = cardPropView[0];

type Props = {
  className?: string;
  size?: CardPropSize;
  view?: CardPropView;
  intro?: string;
  text?: string;
  path?: string;
  date?: string;
  location?: string;
  labelButton?: string;
  picture?: any;
};

const sizeMapTitle: Record<Props, TitleProps> = {
  m: 'md',
};

export const cnExternalCard = cn('ExternalCard');

export const ExternalCard = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const {
      className,
      size = cardPropSizeDefault,
      view = cardPropViewDefault,
      text,
      link,
      linkText,
      onClickButton,
      authorName,
      authorPosition,
      authorPhoto,
      members,
      title,
      // ...otherProps
    } = props;

    return (
      <div className={cnExternalCard({ size, view }, [className])}>
        <div className={cnExternalCard('Body')}>
          <Typography.Title
            size={getSizeByMap(sizeMapTitle, size)}
            className={cnExternalCard('Title')}
            as="h3"
          >
            {text ?? title}
          </Typography.Title>
          {link && (
            <div className={cnExternalCard('Button')}>
              {Object.keys(link).length > 0 && typeof link !== 'string' ? (
                <Button
                  view="link-secondary"
                  as="a"
                  label={link.title}
                  href={link.link}
                  iconRight={IconOpenInNew}
                  onClick={onClickButton}
                  iconSize="xs"
                  target={link.targetBlank ? '_blank' : null}
                />
              ) : (
                <Button
                  view="link-secondary"
                  as="a"
                  label={linkText}
                  href={link}
                  iconRight={IconOpenInNew}
                  onClick={onClickButton}
                  iconSize="xs"
                  target={link.targetBlank ? '_blank' : null}
                />
              )}
            </div>
          )}
        </div>
        <div className={cnExternalCard('User')}>
          {members ? (
            <>
              {members.map(author => (
                <User
                  size="xs"
                  avatarUrl={author.photo?.desktop2x}
                  key={`${author.firstName} ${author.lastName}`}
                  name={`${author.firstName} ${author.lastName}`}
                  info={author.title}
                />
              ))}
            </>
          ) : (
            <User
              size="xs"
              name={authorName}
              info={authorPosition}
              avatarUrl={authorPhoto?.url}
            />
          )}
        </div>
      </div>
    );
  }
);
