import './BlogSingleForm.scss';

import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import { Typography } from '@dataartdev/uikit/Typography';

import { cn } from 'ui/utils/bem';

import { useSelector } from 'react-redux';
import { dictionariesSelector, layoutSelector } from 'store';

import { Form, FormBody } from '../../../common/components';
import { TextField } from '@dataartdev/uikit/TextField';
import { Button } from '@dataartdev/uikit/Button';
import { Checkbox } from '@dataartdev/uikit/Checkbox';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';

import { mutationBlogSingleForm } from 'network';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

import TagManager from 'react-gtm-module';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';
import ReCAPTCHA from 'react-google-recaptcha';
import sbjs from 'sourcebuster';
import { usePagePath } from '../../../common/hooks/usePagePath';
sbjs.init();

type Props = {
  pageId?: any;
  tags?: any;
};

const cnBlogSingleForm = cn('BlogSingleForm');

export const BlogSingleForm: React.FC<Props> = (props, ref) => {
  const { companySiteBlogPost: staticContent } =
    useSelector(dictionariesSelector) || {};
  const { tags } = props;

  const { companySiteBlogPostForm: formDictionaries } = staticContent || {};

  const { companySiteBlogPostFormSubscribeEvent: eventFormDictionaries } =
    formDictionaries || {};

  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [successForm, setSuccessForm] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  const { pageId }: any = useSelector(layoutSelector) || {};
  const reCaptchaRef = useRef(null);

  const pagePath = usePagePath();

  const cameFrom = `typ: ${sbjs.get.current.typ} | src: ${sbjs.get.current.src} | mdm: ${sbjs.get.current.mdm} | cmp: ${sbjs.get.current.cmp} | cnt: ${sbjs.get.current.cnt} | trm: ${sbjs.get.current.trm}`;
  const submitID =
    eventFormDictionaries?.companySiteBlogPostFormSubscribeEventTrackingID ||
    '';

  const onSubmit = async (values, actions) => {
    const token = await reCaptchaRef.current.executeAsync();
    await mutationBlogSingleForm({
      body: {
        privacyAgreement: checked,
        email,
        name,
        pageId,
        [`${recaptchaFormKey}`]: token,
        cameFrom: cameFrom,
        trackingId: submitID,
      },
    }).then(() => {
      setSuccessForm(true);
      actions.resetForm();

      TagManager.dataLayer({
        dataLayer: {
          event: 'send_form',
          form_name: 'newsletter_form',
          page_path: pagePath,
          tags: tags,
        },
      });
    });
  };

  const schema = yup.object({
    email: yup
      .string()
      .email(
        eventFormDictionaries.companySiteBlogPostFormSubscribeEventEmail
          .companySiteBlogPostFormSubscribeEventEmailError
      )
      .required(
        eventFormDictionaries.companySiteBlogPostFormRequiredField ||
          'Required Field'
      )
      .nullable(),
    name: yup
      .string()
      .min(3)
      .required(
        eventFormDictionaries.companySiteBlogPostFormRequiredField ||
          'Required Field'
      )
      .nullable(),
    policy: yup
      .boolean()
      .oneOf([true], 'on')
      .required(),
  });

  return (
    <div className={cnBlogSingleForm()}>
      <Typography.Title size="md" className={cnBlogSingleForm('Title')} as="h2">
        {eventFormDictionaries.companySiteBlogPostFormSubscribeEventTitle}
      </Typography.Title>
      <div className={cnBlogSingleForm('Body')}>
        <Form
          enableReinitialize
          initialValues={{ email: '', name: '', policy: false }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, errors, isValid, dirty, setFieldValue }) => {
            return (
              <FormBody>
                <Grid
                  breakpoints={{
                    desktop: { cols: 12, gap: 'm' },
                    tablet: { cols: 8, gap: 'm' },
                    mobile: { cols: 1, gap: 'm' },
                  }}
                >
                  <GridItem
                    breakpoints={{ desktop: { col: 12 }, tablet: { col: 4 } }}
                  >
                    <TextField
                      name="name"
                      onChange={({ value }) => {
                        setFieldValue('name', value);
                        setName(value);
                      }}
                      value={name}
                      label={
                        eventFormDictionaries.companySiteBlogPostFormSubscribeEventName
                      }
                      view="outline"
                      width="full"
                      required
                      caption={errors.name}
                      status={!!errors.name && 'alert'}
                    />
                  </GridItem>
                  <GridItem
                    breakpoints={{ desktop: { col: 12 }, tablet: { col: 4 } }}
                  >
                    <TextField
                      name="email"
                      onChange={({ value }) => {
                        setFieldValue('email', value);
                        setEmail(value);
                      }}
                      value={email}
                      label="E-mail"
                      view="outline"
                      width="full"
                      required
                      caption={errors.email}
                      status={!!errors.email && 'alert'}
                    />
                  </GridItem>
                  <GridItem
                    breakpoints={{ desktop: { col: 12 }, tablet: { col: 8 } }}
                    className={cnBlogSingleForm('Bottom')}
                  >
                    <Checkbox
                      required
                      label={
                        eventFormDictionaries.companySiteBlogPostFormSubscribeEventSubscribePolicy
                      }
                      onChange={({ checked }: { checked: boolean }) => {
                        setFieldValue('policy', checked);
                        setChecked(checked);
                      }}
                      checked={checked}
                    />
                    <div className={cnBlogSingleForm('Button')}>
                      <Button
                        id={submitID}
                        iconRight={IconArrowRight}
                        disabled={isSubmitting || !dirty || !isValid}
                        label="Subscribe"
                        type="submit"
                      />
                    </div>
                  </GridItem>
                </Grid>
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  sitekey={recaptchaKey}
                  size="invisible"
                />
              </FormBody>
            );
          }}
        </Form>
      </div>
      {successForm && (
        <div className={cnBlogSingleForm({ success: true })}>
          <Typography.Title
            size="md"
            className={cnBlogSingleForm('Title')}
            as="h2"
          >
            {
              eventFormDictionaries.companySiteBlogPostFormSubscribeEventSuccessTitle
            }
          </Typography.Title>
          {eventFormDictionaries.companySiteBlogPostFormSubscribeEventSuccessText && (
            <Typography.Text className={cnBlogSingleForm('SubTitle')}>
              {
                eventFormDictionaries.companySiteBlogPostFormSubscribeEventSuccessText
              }
            </Typography.Text>
          )}
        </div>
      )}
    </div>
  );
};
