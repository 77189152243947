import './CasePage.scss';

import React, { useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NavLink, generatePath } from 'react-router-dom';

import { dictionariesSelector } from 'store';

import { PATH, redirect404 } from 'router';

import { cn as classNames } from 'ui/utils/bem';
import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { useDataQuery, useGTMDataSend } from 'common/hooks';
import { queryCasePage, mutationFileForm, mutationMainForm } from 'network';
import { Typography } from '@dataartdev/uikit/Typography';
import { MemberCard } from '@dataartdev/uikit/MemberCard';
import { ListLinks } from '@dataartdev/uikit/ListLinks';
import { Article } from '../../../common/components/Article/Article';
import { TreeNav } from '@dataartdev/uikit/TreeNav';
import { FileCard } from '../../../common/components';
import { TagGroup } from '@dataartdev/uikit/TagGroup';
import { Picture } from '@dataartdev/uikit/Picture';
import { SocialsShare } from '@dataartdev/uikit/SocialsShare';
import { Mq } from '@dataartdev/uikit/Mq';
import { NoteText } from '../../../common/components/NoteText/NoteText';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';

import sbjs from 'sourcebuster';
import { DesignCSPage } from '../../industries/pages';
import { useIntl } from 'react-intl';
import { ContentBuilderItem } from '../../../common/components/ContentBuilder/ContentBuilderItem';

sbjs.init();

const cnCasePage = classNames('CasePage');

type Props = RouteComponentProps & {};

export const CasePage: React.FC<Props> = ({ match, history, location }) => {
  const {
    companySiteCaseStudy: staticContent,
    companySiteTableOfContent,
    companySiteForm,
  } = useSelector(dictionariesSelector) || {};

  const { data, loading, error } = useDataQuery(queryCasePage, {
    slug: match.params.slug,
  });

  const { locale } = useIntl();

  const {
    breadcrumbs,
    title,
    achievements,
    clientDescription,
    challenge,
    members,
    servicesBlock,
    conclusion,
    files,
    tags,
    clientTitle,
    industry,
    services,
    clientLogo,
    solution,
    relatedCaseStudies,
    text,
    contentCarouselShotCode,
    contentCarousel,
    contentCarouselText,
    sideChat,
    techStock,
    locationText,
    metaTitle,
    designed,
    slug,
    clientSlug,
    form,
  } = data || {};

  useEffect(() => {
    if (error && error.response && error.response.status === 404) {
      redirect404(history);
    }
  }, [error, history]);

  const pageRef = useRef(null);

  useGTMDataSend(location, metaTitle);

  const cameFrom = `typ: ${sbjs.get.current.typ} | src: ${sbjs.get.current.src} | mdm: ${sbjs.get.current.mdm} | cmp: ${sbjs.get.current.cmp} | cnt: ${sbjs.get.current.cnt} | trm: ${sbjs.get.current.trm}`;
  const submitButtonID =
    companySiteForm?.companySiteFormDownloadFile
      ?.companySiteFormDownloadFileTrackingID || 'file_download';

  return (
    <React.Fragment>
      {!loading && !designed && (
        <React.Fragment>
          <div className={cnCasePage()}>
            <Mq query="--desktop">
              <TreeNav
                title={companySiteTableOfContent}
                pageRef={pageRef}
                fixed
              />
            </Mq>
            <div className="container">
              <div className={cnCasePage('Breadcrumbs')}>
                {breadcrumbs && (
                  <Breadcrumbs
                    getLabel={page => page.text}
                    getLink={page => page.url}
                    history={history}
                    pages={data.breadcrumbs}
                  />
                )}
              </div>
              <div className={cnCasePage('Body')}>
                <Grid
                  breakpoints={{
                    desktop: { cols: 12, gap: 'xl' },
                    tablet: { cols: 8 },
                  }}
                >
                  <GridItem
                    breakpoints={{ desktop: { col: 8 }, tablet: { col: 8 } }}
                  >
                    {title && (
                      <Typography.Title
                        size="lg"
                        className={cnCasePage('Title')}
                        as="h1"
                      >
                        {title}
                      </Typography.Title>
                    )}

                    <Mq query="--tablet-max">
                      <Grid breakpoints={{ tablet: { cols: 8, gap: 'm' } }}>
                        <GridItem breakpoints={{ tablet: { col: 5 } }}>
                          <div className={cnCasePage('InfoBlock')}>
                            {clientTitle && (
                              <div className={cnCasePage('InfoBlock-Item')}>
                                <div className={cnCasePage('InfoBlock-Left')}>
                                  <Typography.Text
                                    weight="bold"
                                    transform="uppercase"
                                    size="xs"
                                    as="h2"
                                  >
                                    {
                                      staticContent.companySiteCaseStudyCompanyName
                                    }
                                  </Typography.Text>
                                </div>
                                <div className={cnCasePage('InfoBlock-right')}>
                                  <NavLink
                                    to={generatePath(PATH.CLIENT_PAGE, {
                                      slug: clientSlug,
                                      lang: locale === 'en' ? null : locale,
                                    })}
                                  >
                                    <Typography.Text weight="bold" size="m">
                                      {clientTitle}
                                    </Typography.Text>
                                  </NavLink>
                                </div>
                              </div>
                            )}
                            {locationText && (
                              <div className={cnCasePage('InfoBlock-Item')}>
                                <div className={cnCasePage('InfoBlock-Left')}>
                                  <Typography.Text
                                    weight="bold"
                                    transform="uppercase"
                                    size="xs"
                                    as="h2"
                                  >
                                    {staticContent.companySiteCaseStudyLocation}
                                  </Typography.Text>
                                </div>
                                <div className={cnCasePage('InfoBlock-right')}>
                                  <Typography.Text weight="bold" size="m">
                                    {locationText}
                                  </Typography.Text>
                                </div>
                              </div>
                            )}
                            {industry && (
                              <div className={cnCasePage('InfoBlock-Item')}>
                                <div className={cnCasePage('InfoBlock-Left')}>
                                  <Typography.Text
                                    weight="bold"
                                    transform="uppercase"
                                    size="xs"
                                    as="h2"
                                  >
                                    {staticContent.companySiteCaseStudyIndustry}
                                  </Typography.Text>
                                </div>
                                <div className={cnCasePage('InfoBlock-right')}>
                                  <NavLink
                                    to={generatePath(PATH.INDUSTRIES_PAGE, {
                                      slug: industry.slug,
                                    })}
                                  >
                                    <Typography.Text weight="bold" size="m">
                                      {industry.title}
                                    </Typography.Text>
                                  </NavLink>
                                </div>
                              </div>
                            )}
                            {services?.length > 0 && (
                              <div className={cnCasePage('InfoBlock-Item')}>
                                <div className={cnCasePage('InfoBlock-Left')}>
                                  <Typography.Text
                                    weight="bold"
                                    transform="uppercase"
                                    size="xs"
                                    as="h2"
                                  >
                                    {staticContent.companySiteCaseStudyServices}
                                  </Typography.Text>
                                </div>
                                <div className={cnCasePage('InfoBlock-right')}>
                                  {services.map(page => (
                                    <NavLink
                                      key={page.slug}
                                      to={generatePath(PATH.SERVICES_PAGE, {
                                        slug: page.slug,
                                      })}
                                    >
                                      <Typography.Text weight="bold" size="m">
                                        {page.title}
                                      </Typography.Text>
                                    </NavLink>
                                  ))}
                                </div>
                              </div>
                            )}
                            {techStock?.length > 0 && (
                              <div className={cnCasePage('InfoBlock-Item')}>
                                <div className={cnCasePage('InfoBlock-Left')}>
                                  <Typography.Text
                                    weight="bold"
                                    transform="uppercase"
                                    size="xs"
                                    as="h2"
                                    nowrap
                                  >
                                    {
                                      staticContent.companySiteCaseStudyTechnologies
                                    }
                                  </Typography.Text>
                                </div>
                                <div className={cnCasePage('InfoBlock-right')}>
                                  <TagGroup items={techStock} gap="xs" />
                                </div>
                              </div>
                            )}
                          </div>
                        </GridItem>
                        <GridItem breakpoints={{ tablet: { col: 3 } }}>
                          {clientLogo && Object.keys(clientLogo).length > 0 && (
                            <NavLink
                              to={generatePath(PATH.CLIENT_PAGE, {
                                slug: clientSlug,
                                lang: locale === 'en' ? null : locale,
                              })}
                            >
                              <div className={cnCasePage('Logo')}>
                                <Picture {...clientLogo} />
                              </div>
                            </NavLink>
                          )}
                        </GridItem>
                      </Grid>
                    </Mq>

                    {achievements && (
                      <div
                        className={cnCasePage('Advantages')}
                        style={{ backgroundColor: '#' + achievements.color }}
                      >
                        {achievements.columnI && (
                          <div className={cnCasePage('Advantages-Column')}>
                            {achievements.columnI.title && (
                              <Typography.Title
                                size="lg"
                                className={cnCasePage('Advantages-Title')}
                                as="h2"
                              >
                                {achievements.columnI.title}
                              </Typography.Title>
                            )}
                            {achievements.columnI.subTitle && (
                              <Typography.Title
                                size="tiny"
                                className={cnCasePage('Advantages-SubTitle')}
                                as="h2"
                              >
                                {achievements.columnI.subTitle}
                              </Typography.Title>
                            )}
                            {achievements.columnI.text && (
                              <Typography.Title
                                size="tiny"
                                className={cnCasePage('Advantages-Text')}
                                as="h2"
                              >
                                {achievements.columnI.text}
                              </Typography.Title>
                            )}
                          </div>
                        )}
                        {achievements.columnII && (
                          <div className={cnCasePage('Advantages-Column')}>
                            {achievements.columnII.title && (
                              <Typography.Title
                                size="lg"
                                className={cnCasePage('Advantages-Title')}
                                as="h2"
                              >
                                {achievements.columnII.title}
                              </Typography.Title>
                            )}
                            {achievements.columnII.subTitle && (
                              <Typography.Title
                                size="tiny"
                                className={cnCasePage('Advantages-SubTitle')}
                                as="h2"
                              >
                                {achievements.columnII.subTitle}
                              </Typography.Title>
                            )}
                            {achievements.columnII.text && (
                              <Typography.Title
                                size="tiny"
                                className={cnCasePage('Advantages-Text')}
                                as="h2"
                              >
                                {achievements.columnII.text}
                              </Typography.Title>
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    {clientDescription && (
                      <NoteText
                        strings={clientDescription.strings}
                        title={clientDescription.title}
                        titleTag="h3"
                        content={clientDescription.text}
                      />
                    )}
                    {challenge && (
                      <NoteText
                        strings={challenge.strings}
                        title={challenge.title}
                        titleTag="h3"
                        content={challenge.text}
                      />
                    )}
                    {solution && (
                      <NoteText
                        strings={solution.strings}
                        title={solution.title}
                        titleTag="h3"
                        content={solution.text}
                      />
                    )}

                    <div className={cnCasePage('Content')} ref={pageRef}>
                      <Article
                        content={text}
                        slider={{
                          code: contentCarouselShotCode,
                          items: contentCarousel,
                          text: contentCarouselText,
                        }}
                        chatTooltips={sideChat.length > 0 && sideChat}
                      />
                    </div>

                    {members?.length > 0 && (
                      <div className={cnCasePage('Members')}>
                        <Typography.Text
                          size="xs"
                          weight="bold"
                          transform="uppercase"
                          className={cnCasePage('SectionTitle')}
                          as="h2"
                        >
                          {staticContent.companySiteCaseStudyMembersTitle}
                        </Typography.Text>

                        <Grid
                          breakpoints={{
                            desktop: { cols: 12, rowGap: 'xl', colGap: '2xl' },
                            tablet: { cols: 12, rowGap: 'm', colGap: 'xs' },
                            mobile: { cols: 1, gap: 'xl' },
                          }}
                        >
                          {members.map(member => (
                            <GridItem
                              key={member.id}
                              breakpoints={{
                                desktop: { col: 4 },
                                tablet: { col: 4 },
                              }}
                            >
                              <MemberCard
                                {...member}
                                routePath={PATH.TEAM_MEMBER}
                              />
                            </GridItem>
                          ))}
                        </Grid>
                      </div>
                    )}
                    {servicesBlock && (
                      <div className={cnCasePage('ServicesBlock')}>
                        <Typography.Text
                          size="xs"
                          weight="bold"
                          transform="uppercase"
                          className={cnCasePage('SectionTitle')}
                          as="h2"
                        >
                          {servicesBlock.title}
                        </Typography.Text>
                        <Typography.Text
                          className={cnCasePage('SectionSubTitle')}
                        >
                          {servicesBlock.subtitle}
                        </Typography.Text>
                        <Grid
                          breakpoints={{
                            desktop: { cols: 12, gap: 'xl' },
                            tablet: { cols: 8, gap: 'm' },
                          }}
                        >
                          <GridItem
                            breakpoints={{
                              desktop: { col: 6 },
                              tablet: { col: 4 },
                            }}
                          >
                            <ListLinks
                              items={servicesBlock.columnI}
                              getLabel={item => item.title}
                              getPath={item => item.link}
                            />
                          </GridItem>
                          <GridItem
                            breakpoints={{
                              desktop: { col: 6 },
                              tablet: { col: 4 },
                            }}
                          >
                            <ListLinks
                              items={servicesBlock.columnII}
                              getLabel={item => item.title}
                              getPath={item => item.link}
                            />
                          </GridItem>
                        </Grid>
                        <Article content={servicesBlock.text} />
                      </div>
                    )}
                    {conclusion && (
                      <NoteText
                        title={conclusion.title}
                        strings={conclusion.strings}
                        titleTag="h3"
                        content={conclusion.text}
                      />
                    )}
                    {files?.length > 0 && (
                      <Grid
                        breakpoints={{
                          desktop: { gap: 'xs', cols: 1 },
                          tablet: { gap: 'xs', cols: 1 },
                          mobile: { gap: 'xs', cols: 1 },
                        }}
                        className={cnCasePage('Files')}
                      >
                        {files.map(file => (
                          <GridItem key={file.file.url} col="1">
                            <FileCard
                              url={file.file.url}
                              title={file.title}
                              extension={file.file.type}
                              size={file.file.size}
                              date={file.file.updatedAt}
                              onSubmit={async body =>
                                await mutationFileForm({
                                  body: {
                                    ...body,
                                    filePath: file.file.url,
                                    pageId: data.id,
                                    [`${recaptchaFormKey}`]: body.token,
                                    cameFrom: cameFrom,
                                    trackingId: submitButtonID,
                                  },
                                })
                              }
                              recaptchaKey={recaptchaKey}
                            />
                          </GridItem>
                        ))}
                      </Grid>
                    )}
                  </GridItem>
                  <GridItem
                    breakpoints={{
                      desktop: { col: 4, colStart: 9 },
                      tablet: { col: 8 },
                    }}
                  >
                    <div className={cnCasePage('RightBox')}>
                      <Mq query="--desktop">
                        {(clientTitle ||
                          locationText ||
                          industry ||
                          services?.length > 0 ||
                          techStock?.length > 0) && (
                          <div className={cnCasePage('InfoBlock')}>
                            {clientTitle && (
                              <div className={cnCasePage('InfoBlock-Item')}>
                                <div className={cnCasePage('InfoBlock-Left')}>
                                  <Typography.Text
                                    weight="bold"
                                    transform="uppercase"
                                    size="xs"
                                  >
                                    {
                                      staticContent.companySiteCaseStudyCompanyName
                                    }
                                  </Typography.Text>
                                </div>
                                <div className={cnCasePage('InfoBlock-right')}>
                                  <NavLink
                                    to={generatePath(PATH.CLIENT_PAGE, {
                                      slug: clientSlug,
                                      lang: locale === 'en' ? null : locale,
                                    })}
                                  >
                                    <Typography.Text weight="bold" size="m">
                                      {clientTitle}
                                    </Typography.Text>
                                  </NavLink>
                                </div>
                              </div>
                            )}
                            {locationText && (
                              <div className={cnCasePage('InfoBlock-Item')}>
                                <div className={cnCasePage('InfoBlock-Left')}>
                                  <Typography.Text
                                    weight="bold"
                                    transform="uppercase"
                                    size="xs"
                                  >
                                    {staticContent.companySiteCaseStudyLocation}
                                  </Typography.Text>
                                </div>
                                <div className={cnCasePage('InfoBlock-right')}>
                                  <Typography.Text weight="bold" size="m">
                                    {locationText}
                                  </Typography.Text>
                                </div>
                              </div>
                            )}
                            {industry && (
                              <div className={cnCasePage('InfoBlock-Item')}>
                                <div className={cnCasePage('InfoBlock-Left')}>
                                  <Typography.Text
                                    weight="bold"
                                    transform="uppercase"
                                    size="xs"
                                  >
                                    {staticContent.companySiteCaseStudyIndustry}
                                  </Typography.Text>
                                </div>
                                <div className={cnCasePage('InfoBlock-right')}>
                                  <NavLink
                                    to={generatePath(PATH.INDUSTRIES_PAGE, {
                                      slug: industry.slug,
                                      lang: locale === 'en' ? null : locale,
                                    })}
                                  >
                                    <Typography.Text weight="bold" size="m">
                                      {industry.title}
                                    </Typography.Text>
                                  </NavLink>
                                </div>
                              </div>
                            )}
                            {services?.length > 0 && (
                              <div className={cnCasePage('InfoBlock-Item')}>
                                <div className={cnCasePage('InfoBlock-Left')}>
                                  <Typography.Text
                                    weight="bold"
                                    transform="uppercase"
                                    size="xs"
                                  >
                                    {staticContent.companySiteCaseStudyServices}
                                  </Typography.Text>
                                </div>
                                <div className={cnCasePage('InfoBlock-right')}>
                                  {services.map(page => (
                                    <NavLink
                                      key={page.slug}
                                      to={generatePath(PATH.SERVICES_PAGE, {
                                        slug: page.slug,
                                        lang: locale === 'en' ? null : locale,
                                      })}
                                    >
                                      <Typography.Text weight="bold" size="m">
                                        {page.title}
                                      </Typography.Text>
                                    </NavLink>
                                  ))}
                                </div>
                              </div>
                            )}
                            {techStock?.length > 0 && (
                              <div className={cnCasePage('InfoBlock-Item')}>
                                <div className={cnCasePage('InfoBlock-Left')}>
                                  <Typography.Text
                                    weight="bold"
                                    transform="uppercase"
                                    size="xs"
                                    nowrap
                                  >
                                    {
                                      staticContent.companySiteCaseStudyTechnologies
                                    }
                                  </Typography.Text>
                                </div>
                                <div className={cnCasePage('InfoBlock-right')}>
                                  <TagGroup items={techStock} gap="xs" />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        {clientLogo && Object.keys(clientLogo).length > 0 && (
                          <NavLink
                            to={generatePath(PATH.CLIENT_PAGE, {
                              slug: clientSlug,
                              lang: locale === 'en' ? null : locale,
                            })}
                          >
                            <div className={cnCasePage('Logo')}>
                              <Picture {...clientLogo} />
                            </div>
                          </NavLink>
                        )}
                      </Mq>

                      {relatedCaseStudies?.length > 0 && (
                        <div className={cnCasePage('Recommendation')}>
                          {staticContent && (
                            <div className={cnCasePage('RecommendationTitle')}>
                              <Typography.Text
                                size="xs"
                                weight="bold"
                                transform="uppercase"
                                as="span"
                              >
                                {staticContent.companySiteCaseStudyMoreCases}
                              </Typography.Text>
                            </div>
                          )}
                          <div className={cnCasePage('RecommendationList')}>
                            {relatedCaseStudies.map(item => (
                              <Typography.Title
                                size="tiny"
                                key={item.id}
                                as="link"
                                to={generatePath(PATH.CASE_PAGE, {
                                  slug: item.slug,
                                  lang: locale === 'en' ? null : locale,
                                })}
                              >
                                {item.title}
                              </Typography.Title>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </GridItem>
                </Grid>
              </div>
              <div className={cnCasePage('Bottom')}>
                <Grid
                  breakpoints={{ desktop: { cols: 2 }, tablet: { cols: 2 } }}
                  yAlign="center"
                >
                  <GridItem className={cnCasePage('BottomLeft')}>
                    {tags && <TagGroup items={tags} />}
                  </GridItem>
                  <GridItem className={cnCasePage('BottomRight')}>
                    <SocialsShare
                      title={staticContent?.companySiteCaseStudyShare}
                      url={window.location.href}
                    />
                  </GridItem>
                </Grid>
              </div>
              {form && (
                <ContentBuilderItem
                  aliases={PATH}
                  topGap="xxl"
                  type="3"
                  pageId={data.id}
                  title={form?.title}
                  text={form?.text}
                  member={form?.member}
                  recaptchaKey={recaptchaKey}
                  onSubmitForm={async body =>
                    await mutationMainForm({
                      body: {
                        ...body,
                        [`${recaptchaFormKey}`]: body.token,
                        pageId: data.id,
                      },
                    })
                  }
                  dictionariesForm={{
                    firstName: {
                      label:
                        companySiteForm.companySiteFormLetsTalk
                          .companySiteFormLetsTalkFirstName,
                    },
                    lastName: {
                      label:
                        companySiteForm.companySiteFormLetsTalk
                          .companySiteFormLetsTalkLastName,
                    },
                    message: {
                      label:
                        companySiteForm.companySiteFormLetsTalk
                          .companySiteFormLetsTalkMessage,
                    },
                    success: {
                      title:
                        companySiteForm.companySiteFormLetsTalk
                          .companySiteFormLetsTalkSuccessTitle,
                      text:
                        companySiteForm.companySiteFormLetsTalk
                          .companySiteFormLetsTalkSuccessText,
                    },
                    addInfo: {
                      label:
                        companySiteForm.companySiteFormLetsTalk
                          .companySiteFormLetsTalkAddInfo,
                    },
                    company: {
                      label:
                        companySiteForm.companySiteFormLetsTalk
                          .companySiteFormLetsTalkCompany,
                    },
                    email: {
                      label: 'Email',
                      error:
                        companySiteForm.companySiteFormLetsTalk
                          .companySiteFormLetsTalkEmail
                          .companySiteFormLetsTalkEmailError,
                    },
                    name: {
                      label:
                        companySiteForm.companySiteFormLetsTalk
                          .companySiteFormLetsTalkName,
                    },
                    phone: {
                      label: 'Phone',
                      error:
                        companySiteForm.companySiteFormLetsTalk
                          .companySiteFormPhoneLetsTalkError,
                    },
                    required:
                      companySiteForm.companySiteFormLetsTalk
                        .companySiteFormLetsTalkRequiredField,
                    button:
                      companySiteForm.companySiteFormLetsTalk
                        .companySiteFormLetsTalkSubmit,
                    submitButtonID:
                      companySiteForm.companySiteFormLetsTalk
                        .companySiteFormLetsTalkTrackingID,
                    policy:
                      companySiteForm.companySiteFormLetsTalk
                        .companySiteFormLetsTalkSubscribePolicy,
                  }}
                />
              )}
            </div>
          </div>
        </React.Fragment>
      )}
      {!loading && designed && <DesignCSPage data={data} />}
    </React.Fragment>
  );
};
