import './LandingFooterShort.scss';

import React from 'react';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Typography } from '@dataartdev/uikit/Typography';
import { Picture } from '@dataartdev/uikit/Picture';
import { FooterShort } from './models/footer_short';
import { cn } from 'ui/utils/bem';

const cnLandingFooterShort = cn('LandingFooterShort');

export const LandingFooterShort: React.FC<FooterShort> = props => {
  const { copyright, emoji } = props || {};

  const copyrightWithDate = copyright.replace(
    '&copy;',
    `&copy; ${new Date().getFullYear()} `
  );

  return (
    <footer className={cnLandingFooterShort()}>
      <div className="container">
        <Grid
          breakpoints={{
            desktop: { cols: 12, colGap: 'xl', rowGap: 'xl' },
            tablet: { cols: 12, colGap: 'xl', rowGap: 'xl' },
            mobile: { cols: 12, gap: 'm' },
          }}
        >
          <GridItem
            breakpoints={{
              desktop: { col: 1 },
              tablet: { col: 1 },
              mobile: { col: 2 },
            }}
          >
            <Picture className={cnLandingFooterShort('Icon')} {...emoji} />
          </GridItem>
          <GridItem
            breakpoints={{
              desktop: { col: 11 },
              tablet: { col: 11 },
              mobile: { col: 9 },
            }}
          >
            <Typography.Text as="span" size="m" parse>
              {copyrightWithDate}
            </Typography.Text>
          </GridItem>
        </Grid>
      </div>
    </footer>
  );
};
