import './LocationPage.scss';

import { useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';

import { Slider, SliderItem } from '@dataartdev/uikit/Slider';

import { useDataQuery, useGTMDataSend } from 'common/hooks';
import { queryLocationPage, mutationMainForm } from 'network';
import { PATH, redirect404 } from 'router';
import { dictionariesSelector } from 'store';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';

import { cn } from 'ui/utils/bem';
import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';
import { Typography } from '@dataartdev/uikit/Typography';
import { MemberCard } from '@dataartdev/uikit/MemberCard';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Picture } from '@dataartdev/uikit/Picture';
import { Button } from '@dataartdev/uikit/Button';
import { ContentBuilder } from 'common/components/ContentBuilder/ContentBuilder';
import { CtaScrollerBtn } from '../../../common/components/CtaScrollerBtn';

import { IconPin } from '@dataartdev/uikit/IconPin';
import { IconPhone } from '@dataartdev/uikit/IconPhone';
import { IconEmail } from '@dataartdev/uikit/IconEmail';

export const cnLocationPage = cn('LocationPage');
export const cnLocationInfo = cn('LocationInfo');

type Props = RouteComponentProps & {};

export const LocationPage: React.FC<Props> = ({ match, history }) => {
  const { companySiteSingleLocation: staticContent } =
    useSelector(dictionariesSelector) || {};
  const { companySiteForm: formContent } = useSelector(dictionariesSelector);

  const { data, loading, error } =
    useDataQuery(queryLocationPage, {
      slug: match.params.slug,
    }) || {};

  const {
    breadcrumbs,
    title,
    address,
    googleMap,
    phone,
    email,
    supportEmail,
    members,
    officePhotos,
    fax,
    content,
    contactUs,
    text,
    longVersion,
    metaTitle,
    tags,
  } = data || {};

  const params = {
    // autoHeight: true,
    watchOverflow: true,
    spaceBetween: 40,
    navigation: true,
    pagination: {
      clickable: true,
      type: 'fraction',
      renderFraction: (currentClass, totalClass, index, total) => {
        return `<span class=${currentClass}>${index}</span> <span class="delimiter"></span> <span class=${totalClass}>${total}</span>`;
      },
    },
  };

  useEffect(() => {
    if (error && error.response && error.response.status === 404) {
      redirect404(history);
    }
  }, [error, history]);

  const path = useLocation();
  useGTMDataSend(path, metaTitle, tags);

  return (
    <>
      {!loading && (
        <div className={cnLocationPage()}>
          <div className={cnLocationPage('Breadcrumbs')}>
            <div className="container">
              {breadcrumbs && (
                <Breadcrumbs
                  getLabel={page => page.text}
                  getLink={page => page.url}
                  history={history}
                  pages={data.breadcrumbs}
                />
              )}
            </div>
          </div>
          <div className={cnLocationPage('Body')}>
            <div className="container">
              <Grid
                breakpoints={{
                  tablet: {
                    cols: 8,
                    colGap: 'm',
                  },
                  desktop: {
                    cols: 12,
                    colGap: 'xl',
                    rowGap: '3xxl',
                  },
                }}
              >
                <GridItem
                  breakpoints={{
                    tablet: {
                      col: 4,
                      order: -1,
                    },
                    desktop: { col: 7 },
                  }}
                >
                  <Typography.Title
                    size="lg"
                    className={cnLocationPage('Title')}
                    as="h1"
                  >
                    {title}
                  </Typography.Title>
                  <Typography.Title
                    size="tiny"
                    className={cnLocationPage('Address')}
                  >
                    {address}
                  </Typography.Title>
                  {googleMap && (
                    <div className={cnLocationPage('GoogleMapLink')}>
                      <Button
                        view="link-secondary"
                        iconRight={IconPin}
                        iconSize={'xs'}
                        label={
                          staticContent.companySiteSingleLocationGoogleMapText
                        }
                        as="a"
                        target="_blank"
                        href={googleMap}
                      />
                    </div>
                  )}
                  <Typography.Text
                    className={cnLocationPage('Description')}
                    parse
                  >
                    {text}
                  </Typography.Text>
                </GridItem>
                <GridItem
                  breakpoints={{
                    tablet: {
                      col: 4,
                      order: 0,
                    },
                    desktop: { col: 4, colStart: 9 },
                  }}
                >
                  <div className={cnLocationInfo()}>
                    <div className={cnLocationInfo('Title')}>
                      <Typography.Text
                        transform="uppercase"
                        size="xs"
                        weight="bold"
                        as="h2"
                      >
                        {staticContent.companySiteSingleLocationContactUsTitle}
                      </Typography.Text>
                    </div>
                    <div className={cnLocationInfo('List')}>
                      {phone && (
                        <div className={cnLocationInfo('Item')}>
                          <div className={cnLocationInfo('ContactType')}>
                            <IconPhone size="xs" />
                            <Typography.Text
                              transform="uppercase"
                              size="xs"
                              weight="bold"
                            >
                              {
                                staticContent.companySiteSingleLocationPhoneTitle
                              }
                            </Typography.Text>
                          </div>
                          <div className={cnLocationInfo('ContactLink')}>
                            <Typography.Text
                              size="m"
                              weight="bold"
                              as="a"
                              href={'tel:' + phone}
                            >
                              {phone}
                            </Typography.Text>
                          </div>
                        </div>
                      )}
                      {fax && (
                        <div className={cnLocationInfo('Item')}>
                          <div className={cnLocationInfo('ContactType')}>
                            <IconPhone size="xs" />
                            <Typography.Text
                              transform="uppercase"
                              size="xs"
                              weight="bold"
                            >
                              {staticContent.companySiteSingleLocationFaxTitle}
                            </Typography.Text>
                          </div>
                          <div className={cnLocationInfo('ContactLink')}>
                            <Typography.Text
                              size="m"
                              weight="bold"
                              as="a"
                              href={'tel:' + fax}
                            >
                              {fax}
                            </Typography.Text>
                          </div>
                        </div>
                      )}
                      {email && (
                        <div className={cnLocationInfo('Item')}>
                          <div className={cnLocationInfo('ContactType')}>
                            <IconEmail size="xs" />
                            <Typography.Text
                              transform="uppercase"
                              size="xs"
                              weight="bold"
                            >
                              {
                                staticContent.companySiteSingleLocationEmailTitle
                              }
                            </Typography.Text>
                          </div>
                          <div className={cnLocationInfo('ContactLink')}>
                            <Typography.Text
                              size="m"
                              weight="bold"
                              as="a"
                              href={'mailto:' + email}
                            >
                              {email}
                            </Typography.Text>
                          </div>
                        </div>
                      )}
                      {supportEmail && (
                        <div className={cnLocationInfo('Item')}>
                          <div className={cnLocationInfo('ContactType')}>
                            <IconEmail size="xs" />
                            <Typography.Text
                              transform="uppercase"
                              size="xs"
                              weight="bold"
                            >
                              {
                                staticContent.companySiteSingleLocationEmailTitle
                              }
                            </Typography.Text>
                          </div>
                          <div className={cnLocationInfo('ContactLink')}>
                            <Typography.Text size="m" weight="bold">
                              {supportEmail}
                            </Typography.Text>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={cnLocationInfo('ContactBtn')}>
                    {contactUs && (
                      <CtaScrollerBtn
                        title={contactUs?.title}
                        link={contactUs?.link}
                        linkType={contactUs?.linkType}
                        color={contactUs?.color}
                      />
                    )}
                  </div>
                </GridItem>
                <GridItem
                  breakpoints={{
                    tablet: {
                      col: 3,
                      order: -1,
                      row: 2,
                      colStart: 6,
                    },
                    desktop: { col: 3 },
                  }}
                >
                  {members && members.length > 0 && (
                    <Slider
                      watchOverflow
                      spaceBetween={40}
                      pagination={{ clickable: true }}
                      className="LocationMembersSlider"
                    >
                      {members.map(member => (
                        <SliderItem key={member.id}>
                          <MemberCard
                            view="transparent"
                            {...member}
                            contacts
                            showTitle
                          />
                        </SliderItem>
                      ))}
                    </Slider>
                  )}
                </GridItem>
                <GridItem
                  breakpoints={{
                    tablet: {
                      col: 8,
                      order: 3,
                      rowStart: 3,
                    },
                    desktop: {
                      col: 8,
                      colStart: 5,
                    },
                  }}
                >
                  {officePhotos && (
                    <Slider {...params} className="LocationPhotosSlider">
                      {officePhotos.map((photo, index) => (
                        <SliderItem key={photo.url}>
                          <Picture
                            desktop={photo.url}
                            desktopWebp={photo.urlWebp}
                          />
                        </SliderItem>
                      ))}
                    </Slider>
                  )}
                </GridItem>
              </Grid>
            </div>
          </div>
          {longVersion && content?.length > 0 && (
            <div className={cnLocationInfo('Content')}>
              <ContentBuilder
                items={content}
                aliases={PATH}
                // studyCardBtnText={studyCard.companySiteCaseStudyCardText}
                recaptchaKey={recaptchaKey}
                onSubmitForm={async body =>
                  await mutationMainForm({
                    body: { ...body, pageId: data.id },
                    [`${recaptchaFormKey}`]: body.token,
                  })
                }
                pageId={data.id}
                dictionariesForm={{
                  success: {
                    title:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormLetsTalkSuccessTitle,
                    text:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormLetsTalkSuccessText,
                  },
                  addInfo: {
                    label:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormLetsTalkAddInfo,
                  },
                  company: {
                    label:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormLetsTalkCompany,
                  },
                  email: {
                    label: 'Email',
                    error:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormLetsTalkEmail
                        .companySiteFormLetsTalkEmailError,
                  },
                  name: {
                    label:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormLetsTalkName,
                  },
                  phone: {
                    label: 'Phone',
                    error:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormPhoneLetsTalkError,
                  },
                  required:
                    formContent.companySiteFormLetsTalk
                      .companySiteFormLetsTalkRequiredField,
                  button:
                    formContent.companySiteFormLetsTalk
                      .companySiteFormLetsTalkSubmit,
                  policy:
                    formContent.companySiteFormLetsTalk
                      .companySiteFormLetsTalkSubscribePolicy,
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
