import React from 'react';

import { EventCard } from '../EventCard/EventCard';
import { FeaturedCard } from '../FeaturedCard/FeaturedCard';
import { TestimonialCard } from '../TestimonialCard/TestimonialCard';
import { VideoCard } from '../VideoCard/VideoCard';
import { ICard } from '../ContentBuilder/models/CTI_28.props';

// interface Props  {
//   items?: any;
//   getLabel?: (item: any) => string;
//   getPath?: (item: any) => string;
// }

// old
// Post = 0,
// ExternalPost = 1,
// Video = 2,
// Event = 3,
// Landing = 4,
// News = 5,
// Offering = 6,
// Testimonial = 7

// new
// 0, "Blog Post"
// 2, "Video"
// 3, "Event"
// 4, "Landing"
// 7, "Testimonial"
// 8, "News"
// 10, "Case Study"

export const EssenceCard: React.FC<ICard> = props => {
  const contentType = props.contentType || props.type?.id;

  switch (contentType) {
    case 0:
    case 1:
    case 4:
    case 5:
    case 6:
    case 8:
    case 9:
    case 10:
      return <FeaturedCard {...props} />;

    case 2:
      return <VideoCard {...props} />;

    case 3:
      return <EventCard {...props} />;

    case 7:
      return <TestimonialCard {...props} />;

    default:
      return <>none contentType in EssenceCard</>;
  }
};
