import './LightCard.scss';
import React from 'react';

import { cn } from '../../utils/bem';
import { Typography } from '@dataartdev/uikit/Typography';
import { Picture } from '@dataartdev/uikit/Picture';
import { Button } from '@dataartdev/uikit/Button';
import { Item } from '../ContentBuilder/models/CTI_77.props';
import { prepareButtonAttrs } from '../../utils/prepareButtonAttrs';

export const cnLightCard = cn('LightCard');

export const LightCard: React.FC<Item> = ({ title, text, link, icon }) => {
  const buttonAttr = prepareButtonAttrs(
    link?.link,
    link?.title,
    link?.targetBlank
  );
  return (
    <div className={cnLightCard()}>
      {icon && (
        <div className={cnLightCard('Icon')}>
          <Picture {...icon} />
        </div>
      )}
      <div className={cnLightCard('Inner')}>
        {title && (
          <Typography.Title as="h3" size="md" className={cnLightCard('Title')}>
            {title}
          </Typography.Title>
        )}
        {text && (
          <Typography.Text as="p" size="m" className={cnLightCard('Text')}>
            {text}
          </Typography.Text>
        )}
      </div>
      {Object.keys(buttonAttr).length > 0 && (
        <Button
          type="primary"
          className={cnLightCard('Button')}
          {...buttonAttr}
        />
      )}
    </div>
  );
};
