import './ContentBuilderTemplate.scss';
import './ContentBuilderTemplate70.scss';

import React, { useState } from 'react';

import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cn } from '@bem-react/classname';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Typography } from '@dataartdev/uikit/Typography';
import { Picture } from '@dataartdev/uikit/Picture';

import { cnContentBuilderTemplate } from './index';
import { IRoot70 } from '../models/CTI_70.props';
import { screenSelector } from '../../../../store';
import { useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';

const cnServiceDetails = cn('ServiceDetails');

export const ContentBuilderTemplate: React.FC<IRoot70> = props => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { type, contentId, id, picture, items, background } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();
  const { isDesktop, isMobile, isTablet } = useSelector(screenSelector);

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: 'xl' },
          tablet: { cols: 12, gap: 'xl' },
          mobile: { cols: 12, gap: 'xl' },
        }}
      >
        <GridItem
          breakpoints={{
            desktop: { col: 6 },
            tablet: { col: 6 },
            mobile: { col: 12 },
          }}
          className={cnServiceDetails('Nav')}
        >
          {items?.length > 0 &&
            items.map((item, index) => {
              return (
                <div
                  key={nanoid()}
                  onMouseEnter={() => setActiveIndex(index)}
                  onMouseLeave={() => setActiveIndex(null)}
                >
                  <Typography.Title
                    size="lg"
                    className={cnServiceDetails('Nav-Item', {
                      active: index === activeIndex,
                    })}
                  >
                    <span>{item.title}</span>
                  </Typography.Title>
                </div>
              );
            })}
        </GridItem>
        <GridItem
          breakpoints={{
            desktop: { col: 5, colStart: 8 },
            tablet: { col: 6 },
            mobile: { col: 12 },
          }}
          className={cnServiceDetails('Content')}
        >
          <div className={cnServiceDetails('Infos')}>
            <Picture
              className={cnServiceDetails('Image', {
                hidden: typeof activeIndex === 'number',
              })}
              {...picture}
            />
            <div className={cnServiceDetails('Infos-List')}>
              {items?.length > 0 &&
                items.map((info, index) => {
                  return (
                    <p
                      key={nanoid()}
                      style={{ opacity: index === activeIndex ? 1 : 0 }}
                    >
                      {info.text}
                    </p>
                  );
                })}
            </div>
          </div>
        </GridItem>
      </Grid>
    </div>
  );
};

export default ContentBuilderTemplate;
