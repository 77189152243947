/* eslint-disable jsx-a11y/anchor-has-content */
import './MainPage.scss';

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDataQuery } from 'common/hooks';
import { useSelector } from 'react-redux';
import { dictionariesSelector, landingBreadcrumbsSelector } from 'store';
import { queryHomePage } from 'network';
import { PATH, redirect404 } from 'router';

import { ContentBuilder } from '../../../common/components/ContentBuilder/ContentBuilder';
import { mutationMainForm } from '../../../network';
import { Loader } from '@dataartdev/uikit/Loader';
import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';

import { cn } from 'ui/utils/bem';

const cnMainPage = cn('MainPage');

type Props = RouteComponentProps;

export const MainPage: React.FC<Props> = ({ history, location }) => {
  const { loading, data, error } = useDataQuery(queryHomePage) || {};
  const { companySiteForm } = useSelector(dictionariesSelector) || {};
  const breadcrumbs = useSelector(landingBreadcrumbsSelector) || {};

  const pageId = window.UMB_PAGE_ID || '';
  const isBreadcrumbsEmpty = Object.keys(breadcrumbs).length === 0;

  const {
    companySiteFormLetsTalkName,
    companySiteFormLetsTalkAddInfo,
    companySiteFormLetsTalkEmail: { companySiteFormLetsTalkEmailError },
    companySiteFormLetsTalkTrackingID,
    companySiteFormLetsTalkShowAll,
    companySiteFormLetsTalkRequiredField,
    companySiteFormLetsTalkSubmit,
    companySiteFormLetsTalkHideAll,
    companySiteFormLetsTalkCompany,
    companySiteFormLetsTalkSuccessTitle,
    companySiteFormLetsTalkSuccessText,
    companySiteFormLetsTalkTitle,
    companySiteFormLetsTalkText,
    companySiteFormLetsTalkCompanyErrors: {
      companySiteFormLetsTalkCompanyMinCharactersError,
      companySiteFormLetsTalkCompanyMaxCharactersError,
    },
    companySiteFormLetsTalkSubscribePolicy,
    companySiteFormLetsTalkPhone: { companySiteFormPhoneLetsTalkError },
    companySiteFormLetsTalkFirstName,
    companySiteFormLetsTalkLastName,
    companySiteFormLetsTalkMessage,
  } = companySiteForm?.companySiteFormLetsTalk || {};

  const dictionariesForm = {
    name: {
      label: companySiteFormLetsTalkName,
    },
    firstName: {
      label: companySiteFormLetsTalkFirstName,
    },
    lastName: {
      label: companySiteFormLetsTalkLastName,
    },
    phone: {
      label: 'Phone',
      error: companySiteFormPhoneLetsTalkError,
    },
    company: {
      label: companySiteFormLetsTalkCompany,
      error: {
        minError: companySiteFormLetsTalkCompanyMinCharactersError,
        maxError: companySiteFormLetsTalkCompanyMaxCharactersError,
      },
    },
    email: {
      label: 'Email',
      error: companySiteFormLetsTalkEmailError,
    },
    message: {
      label: companySiteFormLetsTalkMessage,
    },
    addInfo: {
      label: companySiteFormLetsTalkAddInfo,
    },
    success: {
      title: companySiteFormLetsTalkSuccessTitle,
      text: companySiteFormLetsTalkSuccessText,
    },
    required: companySiteFormLetsTalkRequiredField,
    button: companySiteFormLetsTalkSubmit,
    submitButtonID: companySiteFormLetsTalkTrackingID,
    policy: companySiteFormLetsTalkSubscribePolicy,
  };

  useEffect(() => {
    if (error && error.response && error.response.status === 404) {
      redirect404(history);
    }

    if (error && error.response && error.response.status === 400) {
      history.replace({ ...history.location, state: { is400: true } });
    }
  }, [error, history]);

  return (
    <React.Fragment>
      {loading && <Loader />}
      {!loading && (
        <div className={cnMainPage()}>
          {!isBreadcrumbsEmpty && (
            <div className="container">
              <div className={cnMainPage('Breadcrumbs')}>
                <Breadcrumbs
                  getLabel={page => page.title}
                  getLink={page => page.link}
                  history={history}
                  pages={breadcrumbs}
                  onClick={(page, e) => {
                    e.preventDefault();
                    window.location = page.link;
                  }}
                />
              </div>
            </div>
          )}
          <ContentBuilder
            pageId={pageId}
            items={data?.content}
            aliases={PATH}
            onSubmitForm={async body =>
              await mutationMainForm({
                body: { ...body },
              })
            }
            dictionariesForm={dictionariesForm}
          />
        </div>
      )}
    </React.Fragment>
  );
};
