// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { ContentCard } from '../../ContentCard/ContentCard';
import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Typography } from '@dataartdev/uikit/Typography';

import { cnContentBuilderTemplate } from './index';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>(props => {
  const {
    type,
    title,
    text,
    text2,
    columnI,
    columnII,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Title
          size="lg"
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        >
          {title}
        </Typography.Title>
      )}
      {text && (
        <Typography.Title
          className={cnContentBuilderTemplate('SubTitle')}
          size="sm"
          parse
          as="h3"
        >
          {text}
        </Typography.Title>
      )}
      {text2 && (
        <Typography.Text
          className={cnContentBuilderTemplate('SubTitle2')}
          parse
        >
          {text2}
        </Typography.Text>
      )}
      <Grid
        className={cnContentBuilderTemplate('Blocks')}
        breakpoints={{
          desktop: { cols: 12, colGap: '3xxl' },
          tablet: { cols: 8, colGap: 's' },
          mobile: { cols: 1, rowGap: '3xxl' },
        }}
      >
        {columnI?.length > 0 && (
          <GridItem breakpoints={{ desktop: { col: 5 }, tablet: { col: 4 } }}>
            <Grid rowGap="3xxl" cols="1">
              {columnI.map(item => (
                <GridItem col="1">
                  <ContentCard {...item} titleTag="h4" titleSize="md" />
                </GridItem>
              ))}
            </Grid>
          </GridItem>
        )}

        {columnII?.length > 0 && (
          <GridItem
            breakpoints={{
              desktop: { col: 5, colStart: 7 },
              tablet: { col: 4 },
            }}
          >
            <Grid rowGap="3xxl" cols="1">
              {columnII.map(item => (
                <GridItem col="1">
                  <ContentCard {...item} titleTag="h4" titleSize="md" />
                </GridItem>
              ))}
            </Grid>
          </GridItem>
        )}
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
