import './ContentBuilderTemplate.scss';

import React from 'react';
import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { EssenceCard } from '../../EssenceCard/EssenceCard';
import { IRoot28, ICard } from '../models/CTI_28.props';

export const ContentBuilderTemplate = forwardRefWithAs<IRoot28>(props => {
  const { type, title, items = [], linkTitle, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  const options = {
    navigation: items?.length > 3,
    arrows: true,
    breakpoints: {
      767: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      1200: {
        spaceBetween: 24,
        slidesPerView: 3,
      },
    },
    spaceBetween: 8,
    slidesPerView: 1,
    watchOverflow: true,
  };

  const generateParams = (options: ICard) => {
    switch (options.type.id) {
      case 0:
      case 1:
      case 4:
      case 5:
      case 6:
      case 8:
      case 9:
      case 10:
        return {
          ...options,
          linkTitle: 'learn more',
        };

      case 2:
        return {
          ...options,
          view: 'filled',
          size: 'm',
          linkTitle: linkTitle || 'watch on youtube',
          url: options.url,
          videoUrl: options.url,
          postDate: null,
        };

      case 3:
        return {
          ...options,
          redirectTo: options.url,
        };

      default:
        return {
          ...options,
        };
    }
  };

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Title
          size="lg"
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        >
          {title}
        </Typography.Title>
      )}
      {items && (
        <Slider {...options}>
          {items.map(post => (
            <SliderItem key={post.id}>
              <EssenceCard {...generateParams(post)} />
            </SliderItem>
          ))}
        </Slider>
      )}
    </div>
  );
});

export default ContentBuilderTemplate;
