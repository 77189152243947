import './AboutUsPage.scss';

import React from 'react';
import parse from 'html-react-parser';
import { RouteComponentProps } from 'react-router-dom';

import { useDataQuery, useGTMDataSend } from 'common/hooks';
import { queryAboutUsPage } from 'network';

import { cn } from 'ui/utils/bem';
import { PageCard } from '../../../common/components/PageCard/PageCard';
import { BackgroundDecor } from '@dataartdev/uikit/BackgroundDecor';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { Article } from '../../../common/components/Article/Article';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import Marquee from 'react-fast-marquee';
import { SiteBlock } from '../../../common/components/SiteBlock/SiteBlock';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { useSelector } from 'react-redux';

import { dictionariesSelector } from 'store';

export const cnAboutUsPage = cn('AboutUsPage');

type Props = RouteComponentProps & {};

export const AboutUsPage: React.FC<Props> = ({ match, location }) => {
  const { companySiteAboutUs: staticContent }: any =
    useSelector(dictionariesSelector) || {};

  const { data, loading } =
    useDataQuery(queryAboutUsPage, {
      slug: match.params.slug,
    }) || {};

  const {
    headerText,
    principlesLink,
    principlesText,
    principlesTitle,
    whyDataartText,
    whyDataartTitle,
    whyDataartMedia,
    whyDataartLink,
    facts,
    solutionLinkText,
    solutionText,
    solutionMainText,
    recognitions,
    metaTitle,
    tags,
  } = data || {};

  const factsSlider = {
    slidesPerView: 'auto',
    spaceBetween: 24,
    navigation: true,
    watchOverflow: true,
  };

  const cardsOption = {
    watchOverflow: true,
    navigation: true,
    slidesPerView: 'auto',
    spaceBetween: 16,
    breakpoints: {
      767: {
        slidesPerGroup: 3,
      },
      1200: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 24,
      },
    },
  };

  const renderTitle = () =>
    parse(headerText, {
      replace: domNode => {
        const { attribs }: any = domNode;
        if (attribs && attribs.class === 'changing-line-brakets') {
        }
      },
    });

  useGTMDataSend(location, metaTitle, tags);

  return (
    <div className={cnAboutUsPage()}>
      {!loading && (
        <>
          {headerText && (
            <SiteBlock
              breakpoints={{
                desktop: { topGap: '7xl', bottomGap: 'xl' },
                tablet: { topGap: '7xl', bottomGap: 'xl' },
                mobile: { topGap: '3xl', bottomGap: 'xxl' },
              }}
            >
              <div className="container">
                <Grid cols="12">
                  <GridItem
                    breakpoints={{
                      desktop: { col: 11 },
                      tablet: { col: 12 },
                      mobile: { col: 12 },
                    }}
                  >
                    <Typography.Title
                      size="lg"
                      className={cnAboutUsPage('MainTitle')}
                      as="h1"
                    >
                      {renderTitle()}
                    </Typography.Title>
                  </GridItem>
                </Grid>
              </div>
            </SiteBlock>
          )}
          <SiteBlock
            topGap="7xl"
            breakpoints={{
              desktop: { bottomGap: '5xl' },
              tablet: { bottomGap: '5xl' },
              mobile: { bottomGap: '4xl' },
            }}
          >
            <BackgroundDecor view="left-triangle-down-right-side" />
            <div className="container">
              <Grid
                breakpoints={{
                  desktop: { cols: 12, gap: 'xl' },
                  tablet: { cols: 8, gap: 'm' },
                  mobile: { cols: 1, gap: '4xl' },
                }}
              >
                <GridItem
                  breakpoints={{ desktop: { col: 6 }, tablet: { col: 5 } }}
                >
                  {principlesTitle && (
                    <SiteBlock bottomGap="xl" topGap="0">
                      <Typography.Text
                        size="xs"
                        weight="bold"
                        transform="uppercase"
                        as="h2"
                      >
                        {principlesTitle}
                      </Typography.Text>
                    </SiteBlock>
                  )}
                  {principlesText && (
                    <SiteBlock bottomGap="2xl" useMargin topGap="0">
                      <Article content={principlesText} />
                    </SiteBlock>
                  )}

                  {principlesLink && (
                    <Button
                      view="link-secondary"
                      label={principlesLink.title}
                      as={principlesLink.targetBlank ? 'a' : null}
                      isLink={!principlesLink.targetBlank}
                      to={
                        !principlesLink.targetBlank ? principlesLink.link : null
                      }
                      href={
                        principlesLink.targetBlank ? principlesLink.link : null
                      }
                      target={principlesLink.targetBlank ? '_blank' : null}
                      iconRight={IconArrowRight}
                    />
                  )}
                </GridItem>
                {whyDataartLink && (
                  <GridItem
                    breakpoints={{
                      desktop: { col: 5, colStart: 8 },
                      tablet: { col: 3 },
                    }}
                  >
                    <PageCard
                      title={whyDataartTitle}
                      titleTag="h1"
                      subtitle={whyDataartText}
                      linkText={whyDataartLink.title}
                      link={whyDataartLink.link}
                      decor={whyDataartMedia}
                      buttonAttrs={{
                        target: whyDataartLink.targetBlank,
                      }}
                    />
                  </GridItem>
                )}
              </Grid>
            </div>
          </SiteBlock>
          {facts?.length > 0 && (
            <SiteBlock
              breakpoints={{
                desktop: { bottomGap: '6xl', topGap: '6xl' },
                tablet: { bottomGap: '6xl', topGap: '6xl' },
                mobile: { bottomGap: '3xl', topGap: '7xl' },
              }}
              className={cnAboutUsPage('Facts')}
            >
              <BackgroundDecor view="left-triangle-up-7" />

              <div className="container">
                {staticContent.companySiteAboutUsFactsTitle && (
                  <Typography.Text
                    size="xs"
                    weight="bold"
                    transform="uppercase"
                    className={cnAboutUsPage('Facts-Title')}
                    as="h2"
                  >
                    {staticContent.companySiteAboutUsFactsTitle}
                  </Typography.Text>
                )}

                <Slider {...factsSlider}>
                  {facts.map(item => (
                    <SliderItem key={item.id}>
                      {item.contentType === 2 ? (
                        <div
                          className={cnAboutUsPage('Facts-Circle')}
                          style={{ backgroundColor: item.color }}
                        >
                          {item.title && (
                            <Typography.Text
                              size="xs"
                              weight="bold"
                              transform="uppercase"
                              as="h3"
                            >
                              {item.title}
                            </Typography.Text>
                          )}
                          <Typography.Title
                            size="sm"
                            className={cnAboutUsPage('Facts-Circle-Title')}
                            as="h4"
                          >
                            {item.text}
                          </Typography.Title>
                        </div>
                      ) : item.contentType === 1 ? (
                        <div className={cnAboutUsPage('Facts-Content')}>
                          {item.picture && <Picture {...item.picture} />}
                          <Typography.Title
                            size="sm"
                            className={cnAboutUsPage('Facts-Content-Title')}
                            as="h3"
                          >
                            {item.text}
                          </Typography.Title>
                        </div>
                      ) : item.contentType === 0 ? (
                        <div className={cnAboutUsPage('Facts-Slider2')}>
                          {item.pictures?.length > 0 && (
                            <div
                              className={cnAboutUsPage('Facts-Slider2-Inner2')}
                            >
                              <div
                                className={cnAboutUsPage('Facts-Slider2-Inner')}
                              >
                                <Marquee loop={100} speed={100}>
                                  {item.pictures.map(pic => (
                                    <Picture {...pic} key={pic.url} />
                                  ))}
                                </Marquee>
                              </div>
                            </div>
                          )}
                          <Typography.Text>{item.text}</Typography.Text>
                        </div>
                      ) : null}
                    </SliderItem>
                  ))}
                </Slider>
              </div>
            </SiteBlock>
          )}

          <SiteBlock
            topGap="xxl"
            breakpoints={{
              desktop: { bottomGap: '2xl' },
              tablet: { bottomGap: 'xxl' },
            }}
            className={cnAboutUsPage('Clients')}
          >
            <BackgroundDecor view="left-triangle-up-0" />

            <div className="container">
              <Grid
                breakpoints={{
                  desktop: { cols: 12, gap: 'xl' },
                  tablet: { cols: 8, gap: 'm' },
                  mobile: { cols: 1, gap: 'xxl' },
                }}
              >
                <GridItem
                  breakpoints={{ desktop: { col: 6 }, tablet: { col: 5 } }}
                  className={cnAboutUsPage('Clients-Left')}
                >
                  {solutionMainText && (
                    <Typography.Title
                      size="lg"
                      className={cnAboutUsPage('Clients-Title')}
                    >
                      {solutionMainText}
                    </Typography.Title>
                  )}

                  {solutionLinkText && (
                    <Button
                      view="link-secondary"
                      label={solutionLinkText.title}
                      as={solutionLinkText.targetBlank ? 'a' : null}
                      isLink={!solutionLinkText.targetBlank}
                      to={
                        !solutionLinkText.targetBlank
                          ? solutionLinkText.link
                          : null
                      }
                      href={
                        solutionLinkText.targetBlank
                          ? solutionLinkText.link
                          : null
                      }
                      target={solutionLinkText.targetBlank ? '_blank' : null}
                      iconRight={IconArrowRight}
                    />
                  )}
                </GridItem>
                <GridItem
                  breakpoints={{
                    desktop: { col: 5, colStart: 8 },
                    tablet: { col: 3 },
                  }}
                >
                  <div className={cnAboutUsPage('Clients-List')}>
                    {solutionText.map(item => (
                      <Typography.Text
                        key={item}
                        className={cnAboutUsPage('Clients-List-Item')}
                      >
                        {item}
                      </Typography.Text>
                    ))}
                  </div>
                </GridItem>
              </Grid>
            </div>
          </SiteBlock>

          {recognitions?.length > 0 && (
            <SiteBlock
              bottomGap="3xxl"
              breakpoints={{
                desktop: { topGap: 'xxl' },
                tablet: { topGap: '3xl' },
                mobile: { topGap: '3xxl' },
              }}
              className={cnAboutUsPage('Cards')}
            >
              <div className="container">
                <Typography.Text
                  size="xs"
                  weight="bold"
                  transform="uppercase"
                  className={cnAboutUsPage('Cards-Title')}
                  as="h2"
                >
                  {staticContent.companySiteAboutUsRecognitionTitle}
                </Typography.Text>
                <Slider {...cardsOption}>
                  {recognitions.map(item => (
                    <SliderItem key={item.id}>
                      <PageCard
                        lineColor={false}
                        view="secondary"
                        title={item.title}
                        subtitle={item.text}
                        picture={item.picture}
                        link={item.link}
                      />
                    </SliderItem>
                  ))}
                </Slider>
              </div>
            </SiteBlock>
          )}
        </>
      )}
    </div>
  );
};
