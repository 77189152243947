import './WhyDataart.scss';

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useDataQuery, useGTMDataSend } from 'common/hooks';
import { queryWhyDataart } from 'network';
import { dictionariesSelector } from 'store';

import { cn } from 'ui/utils/bem';
import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';

import { TestimonialBlockCard } from '../../../common/components/TestimonialBlockCard/TestimonialBlockCard';
import { ContentCard } from '../../../common/components/ContentCard/ContentCard';
import { ContentAnchorCard } from '../../../common/components/ContentAnchorCard/ContentAnchorCard';
import { EssenceCard } from '../../../common/components/EssenceCard/EssenceCard';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { Article } from '../../../common/components/Article/Article';
import { BackgroundDecor } from '@dataartdev/uikit/BackgroundDecor';
import { Tooltip } from '@dataartdev/uikit/Tooltip';
import { PageCard } from '../../../common/components/PageCard/PageCard';
import { SiteBlock } from '../../../common/components/SiteBlock/SiteBlock';

import ClientIcon from 'assets/img/why_dataart/client_icon.svg';
import DomainIcon from 'assets/img/why_dataart/domain_icon.svg';
import EngineerIcon from 'assets/img/why_dataart/engineer_icon.svg';
import FlexibilityIcon from 'assets/img/why_dataart/flexibility_icon.svg';
import SpeedIcon from 'assets/img/why_dataart/speed_icon.svg';
import PeopleIcon from 'assets/img/why_dataart/people_icon.svg';
import LilGuy from 'assets/img/why_dataart/lil_decor.svg';

import parse from 'html-react-parser';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();
export const cnWhyDataart = cn('WhyDataart');

type Props = RouteComponentProps & {};

export const WhyDataart: React.FC<Props> = ({ match, history, location }) => {
  const { data, loading } =
    useDataQuery(queryWhyDataart, {
      slug: match.params.slug,
    }) || {};

  const { companySiteWhyDataart: staticContent } =
    useSelector(dictionariesSelector) || {};

  const {
    breadcrumbs,
    clientFocusTitle,
    clientFocusNotes,
    clientFocusVideo,
    domaiExpertiseItems,
    qualityTitle,
    qualityLeftTitle,
    qualityLeftText,
    qualityRightTitle,
    qualityRightText,
    qualityRightBuble,
    flexibilityLeftText,
    flexibilityItems,
    speedColumnI,
    speedColumnII,
    cultureBlocks,
    videos,
    recognitions,
    metaTitle,
    tags,
  } = data || {};

  useEffect(() => {
    const hash = history.location.hash;
    // Check if there is a hash and if an element with that id exists
    const el = hash && document.getElementById(hash.substr(1));
    if (el) {
      const y = el.getBoundingClientRect().top - 120;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [history.location.hash, loading]); // Fires every time hash changes

  const goToElement = hash => {
    // Check if there is a hash and if an element with that id exists
    const el = hash && document.getElementById(hash.substr(1));
    if (el) {
      const y = el.getBoundingClientRect().top - 120;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const sliderConfig = {
    watchOverflow: true,
    navigation: true,
    spaceBetween: 54,
    breakpoints: {
      1200: {
        spaceBetween: 24,
      },
    },
  };

  const cardsOption = {
    watchOverflow: true,
    navigation: true,
    slidesPerView: 'auto',
    spaceBetween: 16,
    breakpoints: {
      767: {
        slidesPerGroup: 3,
      },
      1200: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 24,
      },
    },
  };

  useGTMDataSend(location, metaTitle, tags);

  return (
    <div className={cnWhyDataart()}>
      {!loading && (
        <div>
          <div className={cnWhyDataart('Breadcrumbs')}>
            {breadcrumbs && (
              <div className="container">
                <Breadcrumbs
                  getLabel={page => page.text}
                  getLink={page => page.url}
                  history={history}
                  pages={data.breadcrumbs}
                />
              </div>
            )}
          </div>
          <SiteBlock topGap="3xl" bottomGap="6xl">
            <div className="container">
              <Grid
                breakpoints={{
                  desktop: { cols: 12, gap: 'xl' },
                  tablet: { cols: 8, gap: 'm' },
                  mobile: { cols: 2, colGap: 'm', rowGap: 'xl' },
                }}
              >
                <GridItem
                  breakpoints={{
                    desktop: { col: 4 },
                    tablet: { col: 4 },
                    mobile: { col: 1 },
                  }}
                >
                  <ContentAnchorCard
                    onClick={path => goToElement(path)}
                    title={staticContent.companySiteWhyDataartClientFocusTitle}
                    icon={ClientIcon}
                    lineColor="#284086"
                    path="#clients"
                  />
                </GridItem>
                <GridItem
                  breakpoints={{
                    desktop: { col: 4 },
                    tablet: { col: 4 },
                    mobile: { col: 1 },
                  }}
                >
                  <ContentAnchorCard
                    onClick={path => goToElement(path)}
                    title={
                      staticContent.companySiteWhyDataartDomainExpertiseTitle
                    }
                    icon={DomainIcon}
                    lineColor="#F0503C"
                    path="#domain"
                  />
                </GridItem>
                <GridItem
                  breakpoints={{
                    desktop: { col: 4 },
                    tablet: { col: 4 },
                    mobile: { col: 1 },
                  }}
                >
                  <ContentAnchorCard
                    onClick={path => goToElement(path)}
                    title={staticContent.companySiteWhyDataartQualityTitle}
                    icon={EngineerIcon}
                    lineColor="#FFB133"
                    path="#engineer"
                  />
                </GridItem>
                <GridItem
                  breakpoints={{
                    desktop: { col: 4 },
                    tablet: { col: 4 },
                    mobile: { col: 1 },
                  }}
                >
                  <ContentAnchorCard
                    onClick={path => goToElement(path)}
                    title={staticContent.companySiteWhyDataartFlexebilityTitle}
                    icon={FlexibilityIcon}
                    lineColor="#2BC6BF"
                    path="#flexibility"
                  />
                </GridItem>
                <GridItem
                  breakpoints={{
                    desktop: { col: 4 },
                    tablet: { col: 4 },
                    mobile: { col: 1 },
                  }}
                >
                  <ContentAnchorCard
                    onClick={path => goToElement(path)}
                    title={staticContent.companySiteWhyDataartSpeedTitle}
                    icon={SpeedIcon}
                    lineColor="#284086"
                    path="#speed"
                  />
                </GridItem>
                <GridItem
                  breakpoints={{
                    desktop: { col: 4 },
                    tablet: { col: 4 },
                    mobile: { col: 1 },
                  }}
                >
                  <ContentAnchorCard
                    onClick={path => goToElement(path)}
                    title={staticContent.companySiteWhyDataartCultureTitle}
                    icon={PeopleIcon}
                    lineColor="#F0503C"
                    path="#people"
                  />
                </GridItem>
              </Grid>
            </div>
          </SiteBlock>
          <SiteBlock
            topGap="5xl"
            bottomGap="xxl"
            breakpoints={{
              desktop: { topGap: '5xl', bottomGap: 'xxl' },
              tablet: { topGap: '5xl', bottomGap: 'xxl' },
              mobile: { topGap: '5xl', bottomGap: '5xl' },
            }}
            className={cnWhyDataart('Clients')}
            id="clients"
          >
            <BackgroundDecor view="right-triangle-down-1" />
            <div className="container">
              <Typography.Text
                size="xs"
                weight="bold"
                transform="uppercase"
                as="h2"
              >
                {staticContent.companySiteWhyDataartClientFocusTitle}
              </Typography.Text>
              {clientFocusTitle && (
                <Typography.Title
                  size="sm"
                  className={cnWhyDataart('Clients-Title')}
                  as="p"
                >
                  {clientFocusTitle}
                </Typography.Title>
              )}

              <Grid
                breakpoints={{
                  desktop: { cols: 12, gap: 'xl' },
                  tablet: { cols: 8, gap: 'm' },
                  mobile: { cols: 1, gap: '6xl' },
                }}
              >
                {clientFocusNotes?.length > 0 && (
                  <GridItem
                    breakpoints={{
                      desktop: { col: 5 },
                      tablet: { col: 4 },
                      mobile: { col: 1 },
                    }}
                  >
                    <div className={cnWhyDataart('Clients-List')}>
                      {clientFocusNotes.map(item => (
                        <div
                          className={cnWhyDataart('Clients-List-Item')}
                          key={item.id}
                        >
                          <Typography.Title
                            size="tiny"
                            className={cnWhyDataart('Clients-List-Item-Title')}
                            as="h3"
                          >
                            {item.title}
                          </Typography.Title>
                          <Article content={item.text} />
                        </div>
                      ))}
                    </div>
                  </GridItem>
                )}
                {clientFocusVideo && (
                  <GridItem
                    breakpoints={{
                      desktop: { col: 4, colStart: 8 },
                      tablet: { col: 4 },
                      mobile: { col: 1 },
                    }}
                  >
                    <EssenceCard
                      {...clientFocusVideo}
                      postDate={null}
                      tags={null}
                    />
                  </GridItem>
                )}
              </Grid>
            </div>
          </SiteBlock>

          {domaiExpertiseItems?.length > 0 && (
            <SiteBlock
              topGap="5xl"
              bottomGap="xxl"
              breakpoints={{
                desktop: { topGap: '3xl', bottomGap: '5xl' },
                tablet: { topGap: '6xl', bottomGap: '5xl' },
                mobile: { topGap: '6xl', bottomGap: '5xl' },
              }}
              className={cnWhyDataart('Domain')}
              id="domain"
            >
              <BackgroundDecor view="left-rombus-up" />
              <div className="container">
                <Typography.Text
                  size="xs"
                  weight="bold"
                  transform="uppercase"
                  className={cnWhyDataart('Domain-Title')}
                  as="h2"
                >
                  {staticContent.companySiteWhyDataartDomainExpertiseTitle}
                </Typography.Text>

                <Grid
                  breakpoints={{
                    desktop: { cols: 12, gap: 'xl' },
                    tablet: { cols: 8, gap: 'm' },
                    mobile: { col: 1, gap: 'xl' },
                  }}
                >
                  {domaiExpertiseItems.map(item => (
                    <GridItem
                      breakpoints={{
                        desktop: { col: 4 },
                        tablet: { col: 4 },
                        mobile: { col: 1 },
                      }}
                      key={item.id}
                    >
                      <ContentCard
                        title={item.title}
                        titleTag="h3"
                        lineColor={item.color}
                        text={item.text}
                        picture={item.picture}
                        view="filled"
                      />
                    </GridItem>
                  ))}
                </Grid>
              </div>
            </SiteBlock>
          )}

          <SiteBlock
            topGap="5xl"
            bottomGap="xxl"
            breakpoints={{
              desktop: { topGap: '6xl', bottomGap: '5xl' },
              tablet: { topGap: '6xl', bottomGap: '5xl' },
              mobile: { topGap: '6xl', bottomGap: '5xl' },
            }}
            className={cnWhyDataart('Engineer')}
            id="engineer"
          >
            <BackgroundDecor view="right-triangle-down-0" />
            <div className="container">
              <Typography.Text
                size="xs"
                weight="bold"
                transform="uppercase"
                className={cnWhyDataart('Engineer-Title')}
                as="h2"
              >
                {staticContent.companySiteWhyDataartQualityTitle}
              </Typography.Text>
              <Typography.Text
                size="l"
                className={cnWhyDataart('Engineer-Text')}
              >
                {qualityTitle}
              </Typography.Text>
              <Grid
                breakpoints={{
                  desktop: { cols: 12, gap: 'xl' },
                  tablet: { cols: 8, gap: '6xl' },
                  mobile: { cols: 1, gap: '3xl' },
                }}
              >
                <GridItem
                  breakpoints={{ desktop: { col: 5 }, tablet: { col: 4 } }}
                >
                  <ContentCard
                    title={qualityLeftTitle}
                    titleTag="h3"
                    text={qualityLeftText}
                  />
                </GridItem>
                <GridItem
                  breakpoints={{
                    desktop: { col: 5, colStart: 7 },
                    tablet: { col: 4 },
                  }}
                >
                  <ContentCard
                    title={qualityRightTitle}
                    text={qualityRightText}
                  />

                  <div className={cnWhyDataart('Engineer-Decor')}>
                    <Tooltip staticPosition directionPosition={'upStartRight'}>
                      {qualityRightBuble}
                    </Tooltip>
                    <img src={LilGuy} alt={qualityRightBuble} />
                  </div>
                </GridItem>
              </Grid>
            </div>
          </SiteBlock>

          {(!!flexibilityLeftText || flexibilityItems?.length > 0) && (
            <SiteBlock
              topGap="5xl"
              bottomGap="xxl"
              breakpoints={{
                desktop: { topGap: '5xl', bottomGap: '5xl' },
                tablet: { topGap: '5xl', bottomGap: '3xl' },
                mobile: { topGap: '5xl', bottomGap: '0' },
              }}
              className={cnWhyDataart('Flexebility')}
              id="flexibility"
            >
              <BackgroundDecor view="left-triangle-up-3" />
              <div className="container">
                <Typography.Text
                  size="xs"
                  weight="bold"
                  transform="uppercase"
                  className={cnWhyDataart('Flexebility-Title')}
                  as="h2"
                >
                  {staticContent.companySiteWhyDataartFlexebilityTitle}
                </Typography.Text>
                <Grid
                  breakpoints={{
                    desktop: { cols: 12, gap: 'xl' },
                  }}
                >
                  {flexibilityLeftText && (
                    <GridItem breakpoints={{ desktop: { col: 6 } }}>
                      <Typography.Title size="lg">
                        {parse(flexibilityLeftText)}
                      </Typography.Title>
                    </GridItem>
                  )}
                  {flexibilityItems?.length > 0 && (
                    <GridItem breakpoints={{ desktop: { col: 5 } }}>
                      <Grid
                        breakpoints={{
                          desktop: { cols: 1, gap: '3xl' },
                          tablet: { cols: 2, gap: '5xl' },
                          mobile: { cols: 1, gap: '3xl' },
                        }}
                      >
                        {flexibilityItems.map(item => (
                          <GridItem col="1" key={item.id}>
                            <ContentCard {...item} titleTag="h3" />
                          </GridItem>
                        ))}
                      </Grid>
                    </GridItem>
                  )}
                </Grid>
              </div>
            </SiteBlock>
          )}

          {(speedColumnI?.length > 0 || speedColumnII?.length > 0) && (
            <SiteBlock
              id="speed"
              topGap="5xl"
              bottomGap="xxl"
              breakpoints={{
                desktop: { topGap: '6xl', bottomGap: '5xl' },
                tablet: { topGap: 'xxl', bottomGap: '5xl' },
                mobile: { topGap: '3xxl', bottomGap: '5xl' },
              }}
              className={cnWhyDataart('Speed')}
            >
              <BackgroundDecor view="right-triangle-down-0" />
              <div className="container">
                <Typography.Text
                  size="xs"
                  weight="bold"
                  transform="uppercase"
                  className={cnWhyDataart('Speed-Title')}
                  as="h2"
                >
                  {staticContent.companySiteWhyDataartSpeedTitle}
                </Typography.Text>
                <Grid
                  breakpoints={{
                    desktop: { cols: 12, gap: 'xl' },
                    tablet: { cols: 2, gap: '6xl' },
                    mobile: { gap: 'xxl' },
                  }}
                >
                  {speedColumnI?.length > 0 && (
                    <GridItem breakpoints={{ desktop: { col: 5 } }}>
                      {speedColumnI.map(item => (
                        <ContentCard
                          {...item}
                          key={item.id}
                          titleTag="h3"
                        ></ContentCard>
                      ))}
                    </GridItem>
                  )}
                  <GridItem breakpoints={{ desktop: { col: 5, colStart: 7 } }}>
                    {speedColumnII.map(item => (
                      <ContentCard
                        {...item}
                        key={item.id}
                        titleTag="h3"
                      ></ContentCard>
                    ))}
                  </GridItem>
                </Grid>
              </div>
            </SiteBlock>
          )}

          {cultureBlocks?.length > 0 && (
            <SiteBlock
              topGap="5xl"
              bottomGap="xxl"
              className={cnWhyDataart('People')}
              id="people"
            >
              <div className="container">
                <Typography.Text
                  size="xs"
                  weight="bold"
                  transform="uppercase"
                  className={cnWhyDataart('People-Title')}
                  as="h2"
                >
                  {staticContent.companySiteWhyDataartCultureTitle}
                </Typography.Text>
                <Grid
                  breakpoints={{
                    desktop: { cols: 12, gap: 'xl' },
                    tablet: { cols: 2, colGap: 'm', rowGap: 'xl' },
                    mobile: { cols: 1, colGap: 'm', rowGap: 'xl' },
                  }}
                >
                  {cultureBlocks.map(item => (
                    <GridItem
                      breakpoints={{
                        desktop: { col: 4 },
                        tablet: { col: 1 },
                        mobile: { col: 1 },
                      }}
                      key={item.id}
                    >
                      <ContentCard
                        {...item}
                        view="secondary"
                        style={{ backgroundColor: item.color }}
                      ></ContentCard>
                    </GridItem>
                  ))}
                </Grid>
              </div>
            </SiteBlock>
          )}
          {videos?.length > 0 && (
            <SiteBlock
              topGap="3xl"
              bottomGap="3xxl"
              className={cnWhyDataart('Testimon')}
            >
              <BackgroundDecor view="left-triangle-up-0" />
              <div className="container">
                <Typography.Text
                  size="xs"
                  weight="bold"
                  transform="uppercase"
                  className={cnWhyDataart('Testimon-Title')}
                  as="h2"
                >
                  {staticContent.companySiteWhyDataartTestimonialsTitle}
                </Typography.Text>

                <div className={cnWhyDataart('Slider')}>
                  <Slider {...sliderConfig}>
                    {videos.map(item => (
                      <SliderItem key={item.id}>
                        <TestimonialBlockCard {...item} />
                      </SliderItem>
                    ))}
                  </Slider>
                </div>
              </div>
            </SiteBlock>
          )}
          {recognitions?.length > 0 && (
            <SiteBlock
              bottomGap="3xxl"
              topGap="0"
              className={cnWhyDataart('Cards')}
            >
              <div className="container">
                <Typography.Text
                  size="xs"
                  weight="bold"
                  transform="uppercase"
                  className={cnWhyDataart('Cards-Title')}
                  as="h2"
                >
                  {staticContent.companySiteWhyDataartRecognitionsTitle}
                </Typography.Text>
                <Slider {...cardsOption}>
                  {recognitions.map(item => (
                    <SliderItem key={item.id}>
                      <PageCard
                        lineColor={false}
                        view="secondary"
                        title={item.title}
                        subtitle={item.text}
                        picture={item.picture}
                        link={item.link}
                      />
                    </SliderItem>
                  ))}
                </Slider>
              </div>
            </SiteBlock>
          )}
        </div>
      )}
    </div>
  );
};
