import React, { forwardRef } from 'react';
import { Link } from 'react-scroll';
import { Button, ButtonPropView } from '@dataartdev/uikit/Button';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

type Props = {
  title?: string;
  link?: string;
  linkType?: string;
  color?: string;
  view?: ButtonPropView;
  className?: string;
};

export const CtaScrollerBtn = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { title, link, linkType, color, view = 'primary', className } = props;

    let offset = -120;

    if (window.innerWidth < 768) {
      offset = -80;
    }

    if (linkType === 'outline') {
      return (
        <Link
          activeClass="active"
          to={link}
          spy={false}
          smooth={true}
          offset={offset}
          duration={800}
        >
          <Button
            id="anchorbutton"
            ref={ref}
            label={title}
            outlineColor={color}
            view="outlined"
            iconRight={IconArrowRight}
            iconSize="s"
            className={className}
          />
        </Link>
      );
    } else {
      return (
        <Link
          activeClass="active"
          to={link}
          spy={false}
          smooth={true}
          offset={offset}
          duration={800}
        >
          <Button
            id="anchorbutton"
            label={title}
            iconRight={IconArrowRight}
            view={view}
            ref={ref}
            className={className}
          />
        </Link>
      );
    }
  }
);
