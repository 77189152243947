import { createSelector } from '@reduxjs/toolkit';

//LAYOUT
export const layoutSelector = (state: any) => state.layout;

export const layoutKeySelector = createSelector(
  layoutSelector,
  (state: { key: any }) => state.key
);

export const dictionariesSelector = createSelector(
  layoutSelector,
  (state: { dictionaries: any }) => state.dictionaries?.companySite || {}
);

// export const menuSelector = createSelector(
//   layoutSelector,
//   (state: { menuItems: any }) => state.menuItems
// );

export const menuSelector = createSelector(
  layoutSelector,
  (state: { menu: any }) => state.menu
);

export const landingFooterSelector = createSelector(
  layoutSelector,
  (state: { footer: any }) => state.footer
);

export const landingBreadcrumbsSelector = createSelector(
  layoutSelector,
  (state: { breadcrumbs: any }) => state.breadcrumbs
);

export const languagesSelector = createSelector(
  layoutSelector,
  (state: { languages: any }) => state.languages
);

export const headerInvertedSelector = createSelector(
  layoutSelector,
  (state: { headerInverted: any }) => state.headerInverted
);

export const showFooterFormSelector = createSelector(
  layoutSelector,
  (state: { showFooterForm: any }) => state.showFooterForm
);

export const metaTitlePageSelector = createSelector(
  layoutSelector,
  (state: { metaTitlePage: any }) => state.metaTitlePage
);

export const pageSelector = createSelector(
  layoutSelector,
  (state: { page: any }) => state.page
);

export const modalDataSelector = createSelector(
  layoutSelector,
  (state: { modalData: any }) => state.modalData
);

export const modalSelector = createSelector(
  layoutSelector,
  (state: { modal: any }) => state.modal
);

//SCREEN
export const screenSelector = (state: any) => state.screen;
export const isMobileSelector = createSelector(
  screenSelector,
  (state: { isMobile: any }) => state.isMobile
);
export const isTabletSelector = createSelector(
  screenSelector,
  (state: { isTablet: any }) => state.isTablet
);
export const isDesktopSelector = createSelector(
  screenSelector,
  (state: { isDesktop: any }) => state.isDesktop
);
export const isDesktopXLSelector = createSelector(
  screenSelector,
  (state: { isDesktopXL: any }) => state.isDesktopXL
);
export const screenWidthSelector = createSelector(
  screenSelector,
  (state: { width: any }) => state.width
);
