/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
// @ts-nocheck
import './NewsCard.scss';

import React from 'react';
import { NavLink } from 'react-router-dom';

import { cn } from '../../utils/bem';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Article } from '../Article/Article';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography, TitleProps } from '@dataartdev/uikit/Typography';
import { getSizeByMap } from '@dataartdev/uikit/__internal__/cjs-src/utils/getSizeByMap';

export const cardPropSize = ['m', 's'] as const;
export type CardPropSize = typeof cardPropSize[number];
export const cardPropSizeDefault: CardPropSize = cardPropSize[0];

type Props = {
  className?: string;
  size?: CardPropSize;
  intro?: string;
  title?: string;
  path?: string;
  date?: string;
  location?: string;
  labelButton?: string;
  picture?: any;
};

const sizeMapTitle: Record<Props, TitleProps> = {
  s: 'tiny',
  m: 'sm',
};

export const cnNewsCard = cn('NewsCard');

export const NewsCard = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const {
      className,
      size = cardPropSizeDefault,
      intro,
      title,
      path,
      picture,
      date,
      location,
      labelButton,
      // ...otherProps
    } = props;

    return (
      <div className={cnNewsCard({ size }, [className])}>
        {path && <NavLink className={cnNewsCard('Link')} to={path} />}
        {picture && (
          <div className={cnNewsCard('Picture')}>
            <Picture {...picture} />
          </div>
        )}
        <div className={cnNewsCard('Body')}>
          <div className={cnNewsCard('Head')}>
            {date && (
              <Typography.Text size="xs" weight="bold" transform="uppercase">
                {date}
              </Typography.Text>
            )}
            {location && (
              <Typography.Text size="xs" weight="bold" transform="uppercase">
                {location}
              </Typography.Text>
            )}
          </div>
          <Typography.Title
            size={getSizeByMap(sizeMapTitle, size)}
            className={cnNewsCard('Title')}
            as="h2"
          >
            {title}
          </Typography.Title>
          {intro && (
            <Article content={intro} className={cnNewsCard('Description')} />
          )}
          {path && labelButton && (
            <div className={cnNewsCard('Button')}>
              <Button
                view="link-secondary"
                label={labelButton}
                iconRight={IconArrowRight}
                isLink={!!path}
                to={path}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);
