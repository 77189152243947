import './FilterModal.scss';

import React, { useState, useImperativeHandle, forwardRef } from 'react';

import { cn } from 'ui/utils/bem';
import { IconProps } from '@dataartdev/uikit/Icon';
import { Button } from '@dataartdev/uikit/Button';
import { Modal } from '@dataartdev/uikit/Modal';
import { CheckboxGroup } from '@dataartdev/uikit/CheckboxGroup';
import { ChoiceGroup } from '@dataartdev/uikit/ChoiceGroup';
import { CalendarInput } from '@dataartdev/uikit/CalendarInput';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { IconClose } from '@dataartdev/uikit/IconClose';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

import { useDispatch } from 'react-redux';
import { setHeaderInverted } from 'store';

import { enUS, de, ru, uk, bg } from 'date-fns/locale';
import { useIntl } from 'react-intl';

const locales = {
  en: enUS,
  de: de,
  ru: ru,
  ua: uk,
  bg: bg,
};

type Props = {
  items: any;
  iconTitle?: React.FC<IconProps>;
  industries?: any;
};

const cnFilterModal = cn('FilterModal');

export const FilterModal = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    industries,
    technologies,
    onSubmitForm,
    onResetForm,
    types,
    datepicker,
    tags,
    ...otherProps
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const { locale } = useIntl();

  useImperativeHandle(ref, () => ({
    show(): void {
      setIsModalOpen(true);
    },
    hide(): void {
      setIsModalOpen(false);
    },
  }));

  return (
    <div ref={ref}>
      <Modal
        hasOverlay
        width="full"
        rootClassName={'FilterModalWrapper'}
        onOpen={() => {
          dispatch(setHeaderInverted(true));
          document.body.style.overflow = 'hidden';
        }}
        onClose={() => {
          dispatch(setHeaderInverted(false));
          document.body.style.overflow = 'unset';
        }}
        isOpen={isModalOpen}
        onOverlayClick={(): void => {
          dispatch(setHeaderInverted(false));
          setIsModalOpen(false);
        }}
        visibleHeader
        onEsc={(): void => {
          dispatch(setHeaderInverted(false));
          setIsModalOpen(false);
          document.body.style.overflow = 'unset';
        }}
        {...otherProps}
      >
        <div className={cnFilterModal()}>
          <div className="container">
            <Grid cols="2" yAlign="center" className={cnFilterModal('Head')}>
              <GridItem>
                <Typography.Title size="lg" as="h2">
                  Filter
                </Typography.Title>
              </GridItem>
              <GridItem className={cnFilterModal('Controls-Button')}>
                <Button
                  view="ghost"
                  iconRight={IconClose}
                  inverted
                  onClick={(): void => setIsModalOpen(false)}
                />
              </GridItem>
            </Grid>
            <div className={cnFilterModal('Body')}>
              {(types || datepicker) && (
                <div className={cnFilterModal('TypesAndDate')}>
                  <ChoiceGroup
                    value={types.value}
                    onChange={types.onChange}
                    items={types.items}
                    getLabel={item => item.label}
                    inverted
                  />
                  <CalendarInput
                    value={datepicker.value}
                    placeholder={datepicker.placeholder}
                    onChange={datepicker.onChange}
                    onCancel={datepicker.onCancel}
                    type="date-range"
                    locale={locales[locale]}
                  />
                </div>
              )}
              {industries && (
                <div className={cnFilterModal('Category')}>
                  <Typography.Text
                    size="xs"
                    weight="bold"
                    transform="uppercase"
                    className={cnFilterModal('Category-Title')}
                    as="h2"
                  >
                    {industries.title}
                  </Typography.Text>
                  <CheckboxGroup
                    value={industries.value}
                    items={industries.items}
                    getLabel={item => item.title}
                    onChange={industries.onChange}
                    className={cnFilterModal('Category-Industries')}
                    direction="row-wrap"
                    inverted
                  />
                </div>
              )}
              {technologies && (
                <div className={cnFilterModal('Category')}>
                  <Typography.Text
                    size="xs"
                    weight="bold"
                    transform="uppercase"
                    className={cnFilterModal('Category-Title')}
                    as="h2"
                  >
                    {technologies.title}
                  </Typography.Text>
                  <CheckboxGroup
                    value={technologies.value}
                    items={technologies.items}
                    getLabel={item => item.title}
                    onChange={technologies.onChange}
                    className={cnFilterModal('Category-Industries')}
                    direction="row-wrap"
                    inverted
                  />
                </div>
              )}

              {tags && (
                <div className={cnFilterModal('Category')}>
                  <Typography.Text
                    size="xs"
                    weight="bold"
                    transform="uppercase"
                    className={cnFilterModal('Category-Title')}
                    as="h2"
                  >
                    {tags.title}
                  </Typography.Text>
                  <CheckboxGroup
                    value={tags.value}
                    items={tags.items}
                    getLabel={item => item.title}
                    onChange={tags.onChange}
                    className={cnFilterModal('Category-Tags')}
                    direction="row-wrap"
                    inverted
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={cnFilterModal('Bottom')}>
          <Button
            view="secondary"
            iconRight={IconArrowRight}
            label={'Submit'}
            width="full"
            onClick={onSubmitForm}
            align="center"
          />
          <Button
            view="transparent"
            label={'Clear All'}
            inverted
            onClick={onResetForm}
          />
        </div>
      </Modal>
    </div>
  );
});
