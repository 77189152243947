// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { FileCard } from '../../FileCard';
import { mutationFileForm } from '../../../../network';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const {
    title,
    columnI,
    columnII,
    type,
    recaptchaKey,
    picture,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const onSubmitFileForm = body => {
    mutationFileForm({
      body: {
        ...body,
      },
    });
  };

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <Typography.Title
        size="lg"
        className={cnContentBuilderTemplate('Title')}
        as="h2"
      >
        {title}
      </Typography.Title>
      <Grid
        breakpoints={{
          desktop: { cols: 2, colGap: 'xs', rowGap: 'xl' },
          tablet: { cols: 2, rowGap: 'xs', colGap: 'xl' },
          mobile: { cols: 2, gap: 'xs' },
        }}
      >
        {columnI.map(item => (
          <GridItem key={item.id} col="1">
            <FileCard
              {...item}
              {...item.file}
              picture={picture}
              extension={item.file.type}
              date={item.file.updatedAt}
              onSubmit={async body =>
                onSubmitFileForm({ ...body, filePath: item.file.url })
              }
              recaptchaKey={recaptchaKey}
            />
          </GridItem>
        ))}
        {columnII.map(item => (
          <GridItem key={item.id} col="1">
            <FileCard
              {...item}
              {...item.file}
              picture={picture}
              date={item.file.updatedAt}
              extension={item.file.type}
              onSubmit={async body =>
                onSubmitFileForm({ ...body, filePath: item.file.url })
              }
              recaptchaKey={recaptchaKey}
            />
          </GridItem>
        ))}
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
