import React from 'react';

import { EventCard } from '../EventCard/EventCard';
import { FeaturedCard } from '../FeaturedCard/FeaturedCard';
import { TestimonialCard } from '../TestimonialCard/TestimonialCard';
import { VideoCard } from '../VideoCard/VideoCard';
import { ICard } from '../ContentBuilder/models/CTI_28.props';
import { CardPropSize, CardPropView } from './models';

interface Props extends ICard {
  view?: CardPropView;
  size?: CardPropSize;
  contentType?: number;
  linkTitle?: string;
  videoUrl?: string;
  youTubeEmbededUrl?: string;
}

export const EssenceCard: React.FC<Props> = props => {
  const contentType = props.contentType || props.type?.id;

  switch (contentType) {
    case 0:
    case 1:
    case 4:
    case 5:
    case 6:
    case 8:
    case 9:
    case 10:
      return <FeaturedCard {...props} />;

    case 2:
      return <VideoCard {...props} />;

    case 3:
      return <EventCard {...props} />;

    case 7:
      return <TestimonialCard {...props} />;

    default:
      return <>none contentType in EssenceCard</>;
  }
};
