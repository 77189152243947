import './EventFormRecordVideo.scss';

import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import { Typography } from '@dataartdev/uikit/Typography';

import { cn } from 'ui/utils/bem';

import { useSelector } from 'react-redux';
import { dictionariesSelector, layoutSelector } from 'store';

import { Form, FormBody } from '../../../common/components';
import { TextField } from '@dataartdev/uikit/TextField';
import { Button } from '@dataartdev/uikit/Button';
import { Checkbox } from '@dataartdev/uikit/Checkbox';
import { Mq } from '@dataartdev/uikit/Mq';
import { Modal } from '@dataartdev/uikit/Modal';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';

import { mutationEventFormRecordVideo } from 'network';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { IconClose } from '@dataartdev/uikit/IconClose';
import TagManager from 'react-gtm-module';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';
import ReCAPTCHA from 'react-google-recaptcha';
import sbjs from 'sourcebuster';
import { usePagePath } from '../../../common/hooks/usePagePath';
sbjs.init();

type Props = {
  video?: any;
  tags?: any;
};

const cnEventFormRecordVideo = cn('EventFormRecordVideo');

export const EventFormRecordVideo: React.FC<Props> = (props, ref) => {
  const { video, tags, ...otherProps } = props;
  const { companySiteEvents: staticContent } =
    useSelector(dictionariesSelector) || {};
  const {
    companySiteEventsFormWatchTrackingID: submitButtonID,
  } = staticContent?.companySiteEventsForm?.companySiteEventsFormWatch;

  const [
    successForm,
    // setSuccessForm
  ] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalVideoOpen, setIsModalVideoOpen] = useState(false);
  const reCaptchaRef = useRef(null);
  const { pageId }: any = useSelector(layoutSelector) || {};

  const pagePath = usePagePath();

  const cameFrom = `typ: ${sbjs.get.current.typ} | src: ${sbjs.get.current.src} | mdm: ${sbjs.get.current.mdm} | cmp: ${sbjs.get.current.cmp} | cnt: ${sbjs.get.current.cnt} | trm: ${sbjs.get.current.trm}`;

  const onSubmit = async values => {
    const token = await reCaptchaRef.current.executeAsync();
    await mutationEventFormRecordVideo({
      body: {
        ...values,
        [`${recaptchaFormKey}`]: token,
        pageId,
        cameFrom: cameFrom,
        trackingId: submitButtonID || 'webinar_recording',
      },
    }).then(() => {
      setIsModalVideoOpen(true);
      setIsModalOpen(false);

      TagManager.dataLayer({
        dataLayer: {
          event: 'send_form',
          form_name: submitButtonID || 'webinar_recording',
          page_path: pagePath,
          tags: tags,
        },
      });
    });
  };

  const schema = yup.object({
    email: yup
      .string()
      .email(
        staticContent.companySiteEventsForm.companySiteEventsFormWatch
          .companySiteEventsFormWatchEmail.companySiteEventsFormWatchEmailError
      )
      .required(
        staticContent.companySiteEventsForm.companySiteEventsFormWatch
          .companySiteEventsFormWatchRequiredField
      )
      .min(3)
      .nullable(),
    name: yup
      .string()
      .required(
        staticContent.companySiteEventsForm.companySiteEventsFormWatch
          .companySiteEventsFormWatchRequiredField
      )
      .min(3)
      .nullable(),
    company: yup
      .string()
      .required(
        staticContent.companySiteEventsForm.companySiteEventsFormWatch
          .companySiteEventsFormWatchRequiredField
      )
      .min(3)
      .nullable(),
    privacyAgreement: yup
      .boolean()
      .oneOf([true], 'on')
      .required(),
  });

  return (
    <>
      <Mq query="--desktop">
        <Button
          label={'Watch the Recording'}
          width="full"
          align="center"
          view="secondary"
          onClick={() => setIsModalOpen(true)}
        />
      </Mq>
      <Mq query="--tablet-max">
        <div className={cnEventFormRecordVideo('ToggleButton')}>
          <div className="container">
            <Button
              width="full"
              label={'Watch the Recording'}
              align="center"
              view="secondary"
              onClick={() => setIsModalOpen(true)}
            />
          </div>
        </div>
      </Mq>
      <Modal
        view="form"
        showCloseButton
        isOpen={isModalOpen}
        onOverlayClick={(): void => setIsModalOpen(false)}
        onEsc={(): void => {
          setIsModalOpen(false);
          document.body.style.overflow = 'unset';
        }}
        hasOverlay
        rootClassName={'EventFormRecordVideoModal'}
        onOpen={() => {
          document.body.style.overflow = 'hidden';
        }}
        onClose={() => {
          document.body.style.overflow = 'unset';
        }}
        {...otherProps}
      >
        <div className={cnEventFormRecordVideo()}>
          <Typography.Title
            size="md"
            className={cnEventFormRecordVideo('Title')}
            as="h2"
          >
            {
              staticContent.companySiteEventsForm.companySiteEventsFormWatch
                .companySiteEventsFormWatchTitle
            }
          </Typography.Title>
          <Typography.Text className={cnEventFormRecordVideo('Text')}>
            {
              staticContent.companySiteEventsForm.companySiteEventsFormWatch
                .companySiteEventsFormWatchText
            }
          </Typography.Text>
          <div className={cnEventFormRecordVideo('Body')}>
            <Form
              enableReinitialize
              initialValues={{
                email: '',
                name: '',
                company: '',
                privacyAgreement: false,
              }}
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {({
                isSubmitting,
                errors,
                dirty,
                isValid,
                handleChange,
                handleBlur,
                touched,
                values,
              }) => {
                return (
                  <FormBody>
                    <Grid
                      breakpoints={{
                        desktop: { cols: 12, gap: 'm' },
                        tablet: { cols: 8, gap: 'm' },
                        mobile: { cols: 1, gap: 'm' },
                      }}
                    >
                      <GridItem
                        breakpoints={{
                          desktop: { col: 12 },
                          tablet: { col: 8 },
                        }}
                      >
                        <TextField
                          name="name"
                          onChange={({ e }) => handleChange(e)}
                          onBlur={handleBlur}
                          value={values.name}
                          label={
                            staticContent.companySiteEventsForm
                              .companySiteEventsFormWatch
                              .companySiteEventsFormWatchName
                          }
                          view="outline"
                          width="full"
                          required
                          caption={touched.name && errors.name}
                          status={touched.name && !!errors.name && 'alert'}
                        />
                      </GridItem>
                      <GridItem
                        breakpoints={{
                          desktop: { col: 12 },
                          tablet: { col: 8 },
                        }}
                      >
                        <TextField
                          name="email"
                          onChange={({ e }) => handleChange(e)}
                          onBlur={handleBlur}
                          value={values.email}
                          label="E-mail"
                          view="outline"
                          width="full"
                          required
                          caption={touched.email && errors.email}
                          status={touched.email && !!errors.email && 'alert'}
                        />
                      </GridItem>
                      <GridItem
                        breakpoints={{
                          desktop: { col: 12 },
                          tablet: { col: 8 },
                        }}
                      >
                        <TextField
                          name="company"
                          onChange={({ e }) => handleChange(e)}
                          onBlur={handleBlur}
                          value={values.company}
                          label={
                            staticContent.companySiteEventsForm
                              .companySiteEventsFormWatch
                              .companySiteEventsFormWatchCompany
                          }
                          view="outline"
                          width="full"
                          required
                          caption={touched.company && errors.company}
                          status={
                            touched.company && !!errors.company && 'alert'
                          }
                        />
                      </GridItem>
                      <GridItem
                        breakpoints={{
                          desktop: { col: 12 },
                          tablet: { col: 8 },
                        }}
                        className={cnEventFormRecordVideo('Bottom')}
                      >
                        <Checkbox
                          required
                          label={
                            staticContent.companySiteEventsForm
                              .companySiteEventsFormWatch
                              .companySiteEventsFormWatchSubscribePolicy
                          }
                          onChange={({ e }) => handleChange(e)}
                          onBlur={handleBlur}
                          name="privacyAgreement"
                          checked={values.privacyAgreement}
                        />
                        <div className={cnEventFormRecordVideo('Button')}>
                          <Button
                            id={submitButtonID}
                            iconRight={IconArrowRight}
                            disabled={isSubmitting || !dirty || !isValid}
                            label={
                              staticContent.companySiteEventsForm
                                .companySiteEventsFormWatch
                                .companySiteEventsFormWatchSubmit
                            }
                            type="submit"
                          />
                          <ReCAPTCHA
                            ref={reCaptchaRef}
                            sitekey={recaptchaKey}
                            size="invisible"
                          />
                        </div>
                      </GridItem>
                    </Grid>
                  </FormBody>
                );
              }}
            </Form>
          </div>
          {successForm && (
            <div className={cnEventFormRecordVideo('Success')}>
              <Typography.Title
                size="md"
                className={cnEventFormRecordVideo('Title')}
                as="h2"
              >
                {
                  staticContent.companySiteEventsForm.companySiteEventsFormWatch
                    .companySiteEventsFormWatchSuccessTitle
                }
              </Typography.Title>
              <Typography.Text className={cnEventFormRecordVideo('SubTitle')}>
                {
                  staticContent.companySiteEventsForm.companySiteEventsFormWatch
                    .companySiteEventsFormWatchSuccessMessage
                }
              </Typography.Text>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        className={cnEventFormRecordVideo('Modal')}
        isOpen={isModalVideoOpen}
        hasOverlay
        width="full"
        view="iframe"
        onOverlayClick={(): void => setIsModalVideoOpen(false)}
        onEsc={(): void => setIsModalVideoOpen(false)}
      >
        <iframe
          title="modal-iframe"
          src={video.videoUrl}
          frameBorder={'0'}
          width="100%"
          height="100%"
        ></iframe>

        <Button
          view="ghost"
          iconRight={IconClose}
          className="Modal-Close"
          onClick={(): void => setIsModalVideoOpen(false)}
        />
      </Modal>
    </>
  );
};
