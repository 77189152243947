import './MaterialItem.scss';

import React from 'react';
import { Typography } from '@dataartdev/uikit/Typography';
import { cn } from 'ui/utils/bem';

export const cnMaterialItem = cn('MaterialItem');
type imgResource = {
  alt?: string | any;
  id: number;
  name: string;
  size: number;
  type: string;
  updatedAt: string;
  url: string;
  urlRelative: string;
  urlWebp: string;
  urlWebpRelative: string;
};
type Props = {
  pNGTitle: string;
  ePSTitle: string;
  sVGTitle: string;
  logoPreview: imgResource;
  pNGResource: imgResource;
  ePSResource: imgResource;
  sVGResource: imgResource;
  isWhite: boolean;
};

export const MaterialItem: React.FC<Props> = props => {
  const {
    logoPreview,
    pNGTitle,
    ePSTitle,
    sVGTitle,
    pNGResource,
    ePSResource,
    sVGResource,
    isWhite,
  } = props || {};

  const download = (url: string, name: string) => {
    const element = document.createElement('a');

    fetch(url, {})
      .then(res => res.blob()) // Gets the response and returns it as a blob
      .then(blob => {
        element.href = URL.createObjectURL(blob);
        element.download = name;
        element.click();
      });
  };

  return (
    <div className={cnMaterialItem()}>
      <div className={cnMaterialItem('Picture', { Black: isWhite })}>
        <img src={logoPreview?.urlWebp} alt={logoPreview?.name} />
      </div>
      <div className={cnMaterialItem('Links')}>
        <Typography.Text size="s" as="span" className={cnMaterialItem('Label')}>
          Download:
        </Typography.Text>
        <Typography.Text
          size="s"
          weight="bold"
          transform="uppercase"
          as="span"
          className={cnMaterialItem('Link-List')}
        >
          {pNGResource?.url && (
            <span
              onClick={e => {
                e.preventDefault();
                download(
                  pNGResource.url,
                  `${pNGResource.name}.${pNGResource.type}`
                );
              }}
              className={cnMaterialItem('File')}
            >
              {pNGTitle}
            </span>
          )}
          {ePSResource?.url && (
            <a
              onClick={e => {
                e.preventDefault();
                download(
                  ePSResource.url,
                  `${ePSResource.name}.${ePSResource.type}`
                );
              }}
              className={cnMaterialItem('File')}
            >
              {ePSTitle}
            </a>
          )}
          {sVGResource?.url && (
            <a
              onClick={e => {
                e.preventDefault();
                download(
                  sVGResource.url,
                  `${sVGResource.name}.${sVGResource.type}`
                );
              }}
              className={cnMaterialItem('File')}
            >
              {sVGTitle}
            </a>
          )}
        </Typography.Text>
      </div>
    </div>
  );
};
