import './CaseStudies.scss';

import React from 'react';

import { cn } from 'ui/utils/bem';
import { PATH } from 'router';

import { CaseCard } from '../CaseCard/CaseCard';
import { BackgroundDecor } from '@dataartdev/uikit/BackgroundDecor';
import { Typography } from '@dataartdev/uikit/Typography';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { generatePath } from 'react-router';
import { useIntl } from 'react-intl';

type Props = {
  title?: string;
  buttonTitle?: string;
  items?: any;
};

const cnCaseStudies = cn('CaseStudies');

const sliderConfig = {
  watchOverflow: true,
  navigation: true,
  slidesPerView: 'auto',
  spaceBetween: 8,
  breakpoints: {
    767: {
      spaceBetween: 16,
    },
    1200: {
      spaceBetween: 24,
    },
  },
};

export const CaseStudies: React.FC<Props> = (
  { title, items, buttonTitle },
  ref
) => {
  const { locale } = useIntl();

  return (
    <div className={cnCaseStudies()}>
      <BackgroundDecor view="right-triangle-down-3" />
      <div className="container">
        {title && (
          <Typography.Text
            className={cnCaseStudies('Title')}
            size="xs"
            weight="bold"
            transform="uppercase"
            as="h2"
          >
            {title}
          </Typography.Text>
        )}
        {items?.length > 0 && (
          <div className={cnCaseStudies('Slider')}>
            <Slider {...sliderConfig}>
              {items.map(item => (
                <SliderItem key={item.id}>
                  <CaseCard
                    {...item}
                    buttonTitle={buttonTitle}
                    routePath={generatePath(PATH.CASE_PAGE, {
                      slug: item.slug,
                      lang: locale === 'en' ? null : locale,
                    })}
                  />
                </SliderItem>
              ))}
            </Slider>
          </div>
        )}
      </div>
    </div>
  );
};
