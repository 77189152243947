import './ContentBuilderTemplate.scss';

import React from 'react';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { Picture } from '@dataartdev/uikit/Picture';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { IRoot32 } from '../models/CTI_32.props';

export const ContentBuilderTemplate: React.FC<IRoot32> = props => {
  const { type, title, picture, isFullWidth, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();
  const columnWidth = isFullWidth ? 12 : 8;

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: 'xl' },
          tablet: { cols: 12, gap: 'xl' },
          mobile: { cols: 12, gap: 'm' },
        }}
      >
        <GridItem
          breakpoints={{
            desktop: { col: columnWidth },
            tablet: { col: 12 },
            mobile: { col: 12 },
          }}
        >
          <div className={cnContentBuilderTemplate('Title2')}>
            {title && (
              <Typography.Title size="md" as="h3">
                {title}
              </Typography.Title>
            )}
          </div>
        </GridItem>
        <GridItem
          breakpoints={{
            desktop: { col: columnWidth },
            tablet: { col: 12 },
            mobile: { col: 12 },
          }}
        >
          {picture && <Picture {...picture} />}
        </GridItem>
      </Grid>
    </div>
  );
};

export default ContentBuilderTemplate;
