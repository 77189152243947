import { DataMutationFunction } from 'network/models';
import { httpClient } from 'network';

export const mutationBlogForm: DataMutationFunction = (
  variables = {}
): Promise<any> => {
  const { body } = variables;

  return httpClient.post('/blog/submit', body);
};
