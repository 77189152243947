import './ContentBuilderTemplate.scss';

import React from 'react';

import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { CtaScrollerBtn } from '../../CtaScrollerBtn';
import { IRoot36 } from '../models/CTI_36.props';
import { prepareButtonAttrs } from '../../../utils/prepareButtonAttrs';
import { Button } from '@dataartdev/uikit/Button';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

export const ContentBuilderTemplate: React.FC<IRoot36> = props => {
  const { type, link, linkType, color, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();
  const buttonAttr = prepareButtonAttrs(
    link?.link,
    link?.title,
    link?.targetBlank
  );

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {'isCTA' in buttonAttr ? (
        <CtaScrollerBtn {...buttonAttr} linkType={linkType} color={color} />
      ) : (
        <Button iconRight={IconArrowRight} {...buttonAttr} />
      )}
    </div>
  );
};

export default ContentBuilderTemplate;
