// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';
import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { NoteText } from '../../NoteText/NoteText';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { cnContentBuilderTemplate } from './index';

export type Props = {
  type?: number;
  title?: string;
  text?: string;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const { type, title, text, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <Grid
        breakpoints={{
          desktop: { cols: 12 },
          tablet: { cols: 12 },
          mobile: { cols: 12 },
        }}
      >
        <GridItem
          breakpoints={{
            desktop: { col: 8 },
            tablet: { col: 12 },
            mobile: { col: 12 },
          }}
        >
          <NoteText title={title} content={text} />
        </GridItem>
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
