// @ts-nocheck
import './BannerEvent.scss';

import React from 'react';

import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { cn } from '../../utils/bem';

import { Typography } from '@dataartdev/uikit/Typography';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

export const bannerEventPropView = ['default'] as const;
export type BannerEventPropView = typeof bannerEventPropView[number];
export const bannerEventPropViewDefault: BannerEventPropView =
  bannerEventPropView[0];

type Props = {
  view?: BannerEventPropView;
  title?: string;
  text?: string;
  labelButton?: string;
  link?: string;
  picture?: any;
};

const cnBannerEvent = cn('BannerEvent');

export const BannerEvent = forwardRefWithAs<Props>((props, ref) => {
  const {
    view = bannerEventPropViewDefault,
    title,
    labelButton,
    link,
    picture,
    text,
    date,
    time,
  } = props;

  return (
    <div className={cnBannerEvent({ view })}>
      <div className={cnBannerEvent('Picture')}>
        {picture && <Picture {...picture} />}
      </div>
      <div className={cnBannerEvent('Inner')}>
        <Typography.Title className={cnBannerEvent('Title')} size="lg" as="h2">
          {title}
        </Typography.Title>
        {text && (
          <Typography.Text className={cnBannerEvent('Text')}>
            {text}
          </Typography.Text>
        )}
        <div className={cnBannerEvent('Date')}>
          {date && <Typography.Title size="md">{date}</Typography.Title>}
          {time && <Typography.Title size="md">{time}</Typography.Title>}
        </div>
        {labelButton && (
          <div className={cnBannerEvent('Button')}>
            <Button
              view="primary"
              iconRight={IconArrowRight}
              iconSize={'m'}
              label={labelButton}
              target="_blank"
              as="a"
              href={link}
            />
          </div>
        )}
      </div>
    </div>
  );
});
