import './NewsRoomSinglePage.scss';

import React, { useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import { queryNewsRoomSinglePage } from 'network';
import { useDataQuery, useGTMDataSend } from 'common/hooks';

import { cn } from 'ui/utils/bem';

import { redirect404 } from 'router';

import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';
import { SocialsShare } from '@dataartdev/uikit/SocialsShare';
import { TagGroup } from '@dataartdev/uikit/TagGroup';
import { Typography } from '@dataartdev/uikit/Typography';
import { Picture } from '@dataartdev/uikit/Picture';
import { Article } from '../../../common/components/Article/Article';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Button } from '@dataartdev/uikit/Button';
import { MemberCard } from '@dataartdev/uikit/MemberCard';
import { Mq } from '@dataartdev/uikit/Mq';
import { NoteText } from '../../../common/components/NoteText/NoteText';

import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';
import { useSelector } from 'react-redux';

import { dictionariesSelector } from 'store';

const cnNewsRoomSinglePage = cn('NewsRoomSinglePage');

type Props = RouteComponentProps & {};

export const NewsRoomSinglePage: React.FC<Props> = ({ match, history }) => {
  const { companySiteNewsroom: staticContent }: any =
    useSelector(dictionariesSelector) || {};

  const { data, loading, error } = useDataQuery(queryNewsRoomSinglePage, {
    slug: match.params.slug,
    parentSlug: match.params.parentSlug,
  });

  const {
    breadcrumbs,
    date,
    link,
    location,
    picture,
    tags,
    text,
    title,
    companies,
    staff,
    metaTitle,
  } = data || {};

  const linkParams = () => {
    if (link) {
      return {
        label: link.title,
        iconRight: IconOpenInNew,
        iconSize: 'xs',
        as: 'a',
        href: link.link,
        target: link.targetBlank ? '_blank' : null,
      };
    }

    return null;
  };

  useEffect(() => {
    if (error && error.response && error.response.status === 404) {
      redirect404(history);
    }
  }, [error, history]);

  const path = useLocation();
  useGTMDataSend(path, metaTitle, tags);

  return (
    <React.Fragment>
      {!loading && (
        <div className={cnNewsRoomSinglePage()}>
          <div className="container">
            {breadcrumbs && (
              <div className={cnNewsRoomSinglePage('Breadcrumbs')}>
                <Breadcrumbs
                  getLabel={page => page.text}
                  getLink={page => page.url}
                  history={history}
                  pages={data.breadcrumbs}
                />
              </div>
            )}

            <Grid
              breakpoints={{
                desktop: { cols: 12, gap: 'xl' },
                tablet: { cols: 8, gap: 'm' },
              }}
            >
              <GridItem
                breakpoints={{ desktop: { col: 8 }, tablet: { col: 8 } }}
              >
                <div className={cnNewsRoomSinglePage('Head')}>
                  {(date || location) && (
                    <div className={cnNewsRoomSinglePage('Head-Data')}>
                      {date && (
                        <Typography.Title size="tiny">{date}</Typography.Title>
                      )}
                      {location && (
                        <Typography.Title size="tiny">
                          {location}
                        </Typography.Title>
                      )}
                    </div>
                  )}
                  {title && (
                    <Typography.Title size="lg" as="h1">
                      {title}
                    </Typography.Title>
                  )}

                  {link && (
                    <Button
                      view="link-secondary"
                      {...linkParams()}
                      className={cnNewsRoomSinglePage('Head-Link')}
                    />
                  )}

                  {picture && (
                    <Mq query="--tablet-max">
                      <GridItem
                        breakpoints={{
                          desktop: { col: 4 },
                          tablet: { col: 3 },
                        }}
                      >
                        <Picture
                          {...picture}
                          className={cnNewsRoomSinglePage('Picture')}
                        />
                      </GridItem>
                    </Mq>
                  )}
                </div>

                {text && (
                  <div>
                    <Article
                      content={text}
                      className={cnNewsRoomSinglePage('Article')}
                    />
                  </div>
                )}
                {companies?.length > 0 && (
                  <div className={cnNewsRoomSinglePage('BlocksBottom')}>
                    {companies.map(item => (
                      <NoteText
                        title={item.title}
                        titleTag="h2"
                        content={item.text}
                        link={item.link}
                        twitter={item.twitter}
                        facebook={item.facebook}
                      />
                    ))}
                  </div>
                )}
                {staff?.length > 0 && (
                  <div className={cnNewsRoomSinglePage('BlocksBottom')}>
                    <Typography.Text
                      size="xs"
                      weight="bold"
                      transform="uppercase"
                      className={cnNewsRoomSinglePage('BlocksBottom-Title')}
                      as="h2"
                    >
                      {staticContent?.companySiteNewsroomMediaContactText}
                    </Typography.Text>

                    {staff.map(item => (
                      <MemberCard {...item} size="xs" view="ghost" socials />
                    ))}
                  </div>
                )}
              </GridItem>
              {picture && (
                <Mq query="--desktop">
                  <GridItem
                    breakpoints={{ desktop: { col: 4 }, tablet: { col: 3 } }}
                    className={cnNewsRoomSinglePage('PictureDesktop')}
                  >
                    <Picture
                      {...picture}
                      className={cnNewsRoomSinglePage('Picture')}
                    />
                  </GridItem>
                </Mq>
              )}
            </Grid>

            <div className={cnNewsRoomSinglePage('Bottom')}>
              <Grid cols="2" yAlign="center">
                {tags && (
                  <GridItem
                    breakpoints={{ mobile: { col: 2 } }}
                    className={cnNewsRoomSinglePage('BottomLeft')}
                  >
                    {tags.length > 0 && (
                      <div className={cnNewsRoomSinglePage('Tags')}>
                        <TagGroup items={tags} />
                      </div>
                    )}
                  </GridItem>
                )}
                <GridItem
                  breakpoints={{ mobile: { col: 2 } }}
                  className={cnNewsRoomSinglePage('BottomRight')}
                >
                  <SocialsShare
                    title={staticContent.companySiteNewsroomNewsShare}
                    url={window.location.href}
                  />
                </GridItem>
              </Grid>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
