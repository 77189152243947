// @ts-nocheck
import './TwitterCard.scss';

import React from 'react';

import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { cn } from '../../utils/bem';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { IconTwitter } from '@dataartdev/uikit/IconTwitter';
import { IconYouTube } from '@dataartdev/uikit/IconYouTube';
import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';
import { Button } from '@dataartdev/uikit/Button';
import { Typography } from '@dataartdev/uikit/Typography';
import { User } from '@dataartdev/uikit/User';

export type Props = {};

export const cnTwitterCard = cn('TwitterCard');

export const TwitterCard = forwardRefWithAs<Props>((props, ref) => {
  const {
    firstName,
    lastName,
    photo,
    text,
    linkTitle,
    type = 'page',
    linkUrl,
    targetBlank,
  } = usePropsHandler(cnTwitterCard(), props);

  let buttonIcon;
  let iconSize = 's';

  if (type === 'twitter') {
    buttonIcon = IconTwitter;
  }
  if (type === 'blog') {
    buttonIcon = IconArrowRight;
  }
  if (type === 'youtube') {
    buttonIcon = IconYouTube;
  }

  if (type === 'page') {
    buttonIcon = IconOpenInNew;
    iconSize = 'xs';
  }

  return (
    <React.Fragment>
      <div className={cnTwitterCard()}>
        <User
          size="s"
          className={cnTwitterCard('User')}
          name={`${firstName} ${lastName}`}
          avatarUrl={photo?.desktop2x}
        />
        <Typography.Text className={cnTwitterCard('Text')}>
          {text}
        </Typography.Text>
        <div className={cnTwitterCard('Bottom')}>
          <Button
            view="link-secondary"
            as="a"
            href={linkUrl}
            iconSize={iconSize}
            label={linkTitle}
            iconRight={buttonIcon}
            target={targetBlank ? '_blank' : null}
          />
        </div>
      </div>
    </React.Fragment>
  );
});
