import './PartnerCard.scss';

import React from 'react';
import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { cn } from '../../utils/bem';
import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography } from '@dataartdev/uikit/Typography';
import { Partner } from '../ContentBuilder/models/CTI_17.props';

interface Props extends Partner {
  path: {
    link?: string;
    title?: string;
    icon?: null;
    targetBlank?: boolean;
  };
}

export const cnPartnerCard = cn('PartnerCard');

export const PartnerCard: React.FC<Props> = props => {
  const { title, picture, path } = usePropsHandler(cnPartnerCard(), props);

  return (
    <div className={cnPartnerCard()}>
      {path && (
        <a
          href={path.link}
          target={path.targetBlank ? '_blank' : '_self'}
          rel="noreferrer noopener"
          className={cnPartnerCard('Link')}
        />
      )}
      {picture && <Picture {...picture} className={cnPartnerCard('Photo')} />}
      {title && (
        <Typography.Text
          weight="bold"
          className={cnPartnerCard('Title')}
          as="h3"
        >
          {title}
        </Typography.Text>
      )}
      {path && (
        <div className={cnPartnerCard('Button')}>
          <Button
            view="link-secondary"
            iconRight={path?.icon === null ? undefined : IconOpenInNew}
            as="a"
            iconSize="xs"
            href={path.link}
            label={path.title}
            target={path.targetBlank ? '_blank' : '_self'}
            rel="noreferrer noopener"
          />
        </div>
      )}
    </div>
  );
};
