import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { DataQueryVariables } from 'common/hooks';
import { toPaginationParams } from '../../helpers';
import { clearUrl } from 'common/utils';

export const queryAllCasesFeed: DataQueryFunction = (
  variables: DataQueryVariables | undefined
): Promise<any> => {
  const paginationParams = toPaginationParams(variables);

  return httpClient.get(clearUrl(`/newsroom/newsInMedia`), {
    params: {
      ...paginationParams,
    },
  });
};
