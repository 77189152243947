import './ModalPage.scss';

import React, { useEffect, useState } from 'react';

import { cn } from 'ui/utils/bem';

import {
  modalDataSelector,
  modalSelector,
  setModalData,
  setModal,
  layoutSelector,
} from 'store';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@dataartdev/uikit/Modal';

import { WebinarForm } from './modals/WebinarForm';
import { ClutchForm } from './modals/ClutchForm';
import { RedirectForm } from './modals/RedirectForm';
import { ResourceForm } from './modals/ResourceForm';

export const cnModalPage = cn('ModalPage');

export const ModalPage: React.FC = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invertedModal, setInvertedModal] = useState(false);
  const modalQuery = useSelector(modalSelector);
  const modalPage = useSelector(modalDataSelector);
  const modalData: any = modalQuery || modalPage;

  const pageData = useSelector(layoutSelector);
  const { pageId } = pageData || {};

  let updateModalData = {};
  if (pageId) {
    updateModalData = { ...modalData, id: pageId };
  }

  const showingModals = JSON.parse(localStorage.getItem('MODAL_DATA')) ?? [];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (modalData) {
      const delay = modalQuery ? 0 : modalData?.delay || 0;
      if (
        !modalQuery &&
        !showingModals.includes(modalData.id) &&
        !isModalOpen
      ) {
        setTimeout(() => {
          setIsModalOpen(true);
          localStorage.setItem(
            'MODAL_DATA',
            JSON.stringify([...showingModals, modalData.id])
          );
        }, delay * 1000);
      } else if (modalQuery && !isModalOpen) {
        setIsModalOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData, modalQuery]);

  return (
    <>
      {modalData && (
        <Modal
          view="form"
          hasOverlay
          scroll
          isOpen={isModalOpen}
          onOverlayClick={(): void => {
            document.body.style.overflow = 'unset';
            setIsModalOpen(false);
            dispatch(setModal(null));
            dispatch(setModalData(null));
          }}
          onEsc={(): void => {
            document.body.style.overflow = 'unset';
            setIsModalOpen(false);
            dispatch(setModal(null));
            dispatch(setModalData(null));
          }}
          showCloseButton
          inverted={invertedModal}
        >
          {modalData.popupType === 1 && <ResourceForm modalData={modalData} />}
          {modalData.popupType === 3 && <RedirectForm modalData={modalData} />}
          {modalData.popupType === 2 && (
            <WebinarForm
              onSubmitForm={val => setInvertedModal(val ?? false)}
              modalData={modalData}
            />
          )}
          {modalData.popupType === 0 && (
            <ClutchForm
              onSubmitForm={val => setInvertedModal(val ?? false)}
              modalData={updateModalData}
            />
          )}
        </Modal>
      )}
    </>
  );
};
