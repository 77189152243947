// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { Typography } from '@dataartdev/uikit/Typography';
import { Button } from '@dataartdev/uikit/Button';
import { Article } from '../../Article/Article';
import { Picture } from '@dataartdev/uikit/Picture';
import { Mq } from '@dataartdev/uikit/Mq';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const {
    type,
    subtitle,
    title,
    text,
    link,
    linkText,
    targetBlank,
    color,
    picture,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {subtitle && (
        <Typography.Title
          size="lg"
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        >
          {subtitle}
        </Typography.Title>
      )}
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: '2xxl' },
          tablet: { cols: 12, gap: 'm' },
          mobile: { gap: 'm' },
        }}
      >
        <GridItem
          breakpoints={{ desktop: { col: 6 }, tablet: { col: 6 } }}
          className={cnContentBuilderTemplate('LeftSection')}
        >
          <div className={cnContentBuilderTemplate('Title2')}>
            {title && (
              <Typography.Title size="sm" as="h2">
                {title}
              </Typography.Title>
            )}
          </div>
          <Mq query="--tablet-min">
            {link && (
              <div className={cnContentBuilderTemplate('Button')}>
                <Button
                  view="link-primary"
                  isLink={!targetBlank}
                  label={linkText}
                  to={link}
                  href={targetBlank ? link : null}
                  target={targetBlank && '_blank'}
                  as={targetBlank ? 'a' : null}
                  iconRight={IconArrowRight}
                />
              </div>
            )}
          </Mq>
        </GridItem>
        <GridItem
          breakpoints={{
            desktop: { col: 6 },
            tablet: { col: 6 },
            mobile: { col: 1 },
          }}
        >
          {text && (
            <Article
              content={text}
              className={cnContentBuilderTemplate('SubTitle')}
            />
          )}

          <Mq query="--mobile">
            {link && (
              <div className={cnContentBuilderTemplate('Button')}>
                <Button
                  view="link-primary"
                  isLink={!targetBlank}
                  label={linkText}
                  to={link}
                  href={targetBlank ? link : null}
                  target={targetBlank && '_blank'}
                  as={targetBlank ? 'a' : null}
                  iconRight={IconArrowRight}
                />
              </div>
            )}
          </Mq>
        </GridItem>
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
