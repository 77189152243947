// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';
import { generatePath } from 'react-router-dom';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { Typography } from '@dataartdev/uikit/Typography';
import { Article } from '../../Article/Article';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { ListLinks } from '@dataartdev/uikit/ListLinks';
import { Button } from '@dataartdev/uikit/Button';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const {
    type,
    title,
    text,
    aliases,
    titleI,
    columnI,
    titleII,
    columnII,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Text
          size="xs"
          weight="bold"
          transform="uppercase"
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        >
          {title}
        </Typography.Text>
      )}
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: 'xl' },
          tablet: { cols: 8, rowGap: '3xl', colGap: 'm' },
          mobile: { cols: 2 },
        }}
      >
        {text && (
          <GridItem
            breakpoints={{
              desktop: { col: 4 },
              tablet: { col: 8 },
              mobile: { col: 2 },
            }}
            className={cnContentBuilderTemplate('Column')}
          >
            <Article content={text} />
          </GridItem>
        )}
        {columnI?.length > 0 && (
          <GridItem
            breakpoints={{
              desktop: { col: 8 },
              tablet: { col: 4 },
              mobile: { col: 2 },
            }}
            className={cnContentBuilderTemplate('ColumnSide')}
          >
            <Typography.Title
              size="lg"
              className={cnContentBuilderTemplate('Title')}
              as="h2"
            >
              {titleI}
            </Typography.Title>
            <div className={cnContentBuilderTemplate('SplitList')}>
              <ListLinks
                items={columnI}
                getLabel={item => item.title}
                getPath={item =>
                  generatePath(aliases.SERVICES_PAGE, {
                    slug: item.slug,
                  })
                }
              />
            </div>
          </GridItem>
        )}
        {columnII?.length > 0 && (
          <GridItem
            breakpoints={{
              desktop: { col: 4 },
              tablet: { col: 4 },
              mobile: { col: 2 },
            }}
            className={cnContentBuilderTemplate('ColumnSide')}
          >
            <Typography.Title
              size="lg"
              className={cnContentBuilderTemplate('Title')}
              as="h2"
            >
              {titleII}
            </Typography.Title>
            <ListLinks
              items={columnII}
              getLabel={item => item.title}
              getPath={item =>
                generatePath(aliases.SERVICES_PAGE, {
                  slug: item.slug,
                })
              }
            />
          </GridItem>
        )}
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
