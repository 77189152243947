import React, { useLayoutEffect, useEffect } from 'react';
import { Router, BrowserRouter } from 'react-router-dom';
import { history } from 'services/history/history';
import { Provider as StoreProvider } from 'react-redux';
import { store } from 'store';
import { UserProvider } from './UserProvider';
import { Theme, presetDefault } from '@dataartdev/uikit/Theme';

import ScrollToTop from 'ui/utils/scrollToTop';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { LocalizedRouter } from 'i18n';

const gtmParams = { id: process.env.REACT_APP_GTM || '' };

const App: React.FC = () => {
  // clear initial history state
  useLayoutEffect(() => {
    if (localStorage.getItem('currentLang') === 'en') {
      localStorage.setItem('currentLang', '');
    }

    if (history.location.state) {
      history.replace({ ...history.location, state: undefined });
    }
  }, []); // eslint-disable-line

  return (
    <GTMProvider state={gtmParams}>
      <StoreProvider store={store}>
        <LocalizedRouter
          RouterComponent={BrowserRouter}
          defaultLanguage={localStorage.getItem('currentLang')}
          history={history}
        >
          <ScrollToTop />
          <Theme preset={presetDefault}>
            <UserProvider history={history} />
          </Theme>
        </LocalizedRouter>
      </StoreProvider>
    </GTMProvider>
  );
};

export default App;
