import './ContentBanner.scss';

import React, { useCallback, useState } from 'react';
import { cn } from '../../utils/bem';
import { Typography } from '@dataartdev/uikit/Typography';
import { Picture } from '@dataartdev/uikit/Picture';
import { Button } from '@dataartdev/uikit/Button';
import { hasLabel, prepareButtonAttrs } from '../../utils/prepareButtonAttrs';
import { CtaScrollerBtn } from '../CtaScrollerBtn';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { PopupFormBuilder } from '../CtaBanner/PopupFormBuilder';
import { Banner } from '../ContentBuilder/models/CTI_111.props';

export const cnContentBanner = cn('ContentBanner');

export const ContentBanner: React.FC<Banner> = props => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    picture,
    isInverted,
    title,
    text,
    color,
    button,
    pictureMobilePaddings,
    picturePaddings,
    form,
  } = props || {};
  const buttonAttr = prepareButtonAttrs(
    button?.link,
    button?.title,
    button?.targetBlank
  );

  const modalHandler = useCallback(() => {
    setIsModalOpen(prev => !prev);
  }, [setIsModalOpen]);

  return (
    <div
      className={cnContentBanner({
        isImageMobilePushedOnTop: pictureMobilePaddings,
        isInverted: isInverted,
      })}
    >
      <div
        className={cnContentBanner('Inner')}
        style={{ backgroundColor: color }}
      >
        <div className={cnContentBanner('Content')}>
          {title && (
            <Typography.Title
              size="lg"
              as="h2"
              className={cnContentBanner('Title')}
            >
              {title}
            </Typography.Title>
          )}
          {text && (
            <Typography.Text
              size="m"
              as="p"
              className={cnContentBanner('Text')}
            >
              {text}
            </Typography.Text>
          )}
          <div className={cnContentBanner('Cta')}>
            {!form &&
              Object.keys(buttonAttr).length > 0 &&
              ('isCTA' in buttonAttr ? (
                <CtaScrollerBtn {...buttonAttr} view="secondary" />
              ) : (
                <Button {...buttonAttr} iconSize="m" view="secondary" />
              ))}
            {form && hasLabel(buttonAttr) && (
              <Button
                label={buttonAttr.label}
                onClick={modalHandler}
                iconRight={IconArrowRight}
                iconSize="m"
                view="secondary"
              />
            )}
          </div>
          <PopupFormBuilder
            {...props}
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
          />
        </div>
        {picture && (
          <div
            className={cnContentBanner('Image', {
              isImageMobilePushedOnTop: pictureMobilePaddings,
              isImageHasPaddings: picturePaddings,
            })}
          >
            <Picture {...picture} />
          </div>
        )}
      </div>
    </div>
  );
};
