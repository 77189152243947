import React from 'react';
import { cn } from '../../utils/bem';
import './FeaturedCard.scss';
import { Typography } from '@dataartdev/uikit/Typography';
import { Tag } from '@dataartdev/uikit/Tag';
import { Picture } from '@dataartdev/uikit/Picture';
import { Case, Logo } from '../ContentBuilder/models/CTI_18.props';
import { Item } from '../ContentBuilder/models/CTI_104.props';
import { TagGroup } from '@dataartdev/uikit/TagGroup';
import { ICard } from '../ContentBuilder/models/CTI_28.props';
import { ITag } from '@commonTypes/common';
import { IPicture } from '@commonTypes/picture';

const cnFeaturedCard = cn('FeaturedCard');

function isItem(props: Item | Case | ICard): props is Item {
  return (props as Item).tag !== undefined;
}

function isCase(props: Item | Case | ICard): props is Case {
  return (props as Case).fullUrl !== undefined;
}

function isCard(props: Item | Case | ICard): props is ICard {
  return (props as ICard).url !== undefined;
}

interface CardProps {
  link?: string;
  picture?: Logo | IPicture;
  type?: string;
  title?: string;
  tag?: string;
  tags?: ITag[];
}

const FeaturedCardContent: React.FC<CardProps> = props => {
  const { link, picture, type, title, tag, tags } = props || {};
  return (
    <a href={link} className={cnFeaturedCard()}>
      {picture && <Picture {...picture} />}
      <div className={cnFeaturedCard('Inner')}>
        <div className={cnFeaturedCard('Top')}>
          {type && (
            <Typography.Text
              parse
              as="span"
              size="xs"
              transform="uppercase"
              weight="bold"
              className={cnFeaturedCard('Subtitle')}
            >
              {type}
            </Typography.Text>
          )}
          {title && (
            <Typography.Title
              size="tiny"
              as="h3"
              className={cnFeaturedCard('Title')}
            >
              {title}
            </Typography.Title>
          )}
        </div>
        {tag && (
          <Tag mode="link" label={tag} className={cnFeaturedCard('Tag')} />
        )}
        {tags && <TagGroup items={tags} />}
      </div>
    </a>
  );
};
export const FeaturedCard: React.FC<Item | Case | ICard> = props => {
  if (isItem(props)) {
    const { tag = '', text = '', type = '', link = '/', picture } = props || {};
    return (
      <FeaturedCardContent
        link={link}
        picture={picture}
        type={type}
        title={text}
        tag={tag}
      />
    );
  }
  if (isCase(props)) {
    const { title, logo, categoryText, tags = [], fullUrl = '/' } = props || {};
    return (
      <FeaturedCardContent
        link={fullUrl}
        picture={logo}
        type={categoryText}
        title={title}
        tag={tags[0]?.title}
      />
    );
  }
  if (isCard(props)) {
    const { title, tags = [], type, picture, url } = props || {};

    return (
      <FeaturedCardContent
        link={url}
        picture={picture}
        type={type.name}
        title={title}
        tags={tags}
      />
    );
  }
  return null;
};
