import './Footer.scss';

import React from 'react';

import { useSelector } from 'react-redux';
import { dictionariesSelector, menuSelector } from 'store';

import { SocialButton } from '@dataartdev/uikit/SocialButton';
import { Picture } from '@dataartdev/uikit/Picture';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Typography } from '@dataartdev/uikit/Typography';
import { Button } from '@dataartdev/uikit/Button';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Combobox } from '@dataartdev/uikit/Combobox';
import { Article } from '../../components/Article/Article';
import { cn } from 'ui/utils/bem';
import { FooterCOM } from './models/footer_com';

const cnFooter = cn('Footer');

export const Footer: React.FC<FooterCOM> = props => {
  const menu = useSelector(menuSelector) || {};
  const { companySiteFooter } = useSelector(dictionariesSelector) || {};

  const { languages } = menu || {};
  const language = window.INITIAL_STATE.languages.find(
    w => w.slug === localStorage.getItem('currentLang')
  );

  const { companySiteFooterSeeAll: seeAll } = companySiteFooter;

  const {
    socialLinks,
    columnITitleLink,
    columnILinks,
    columnIITitleLink,
    columnIILinks,
    pagesLinks,
    columnIVTitleLink,
    columnIVLinks,
    locations,
    locationsLink,
    copyright,
  } = props || {};

  return (
    <div className={cnFooter()}>
      <div className="container">
        <Grid breakpoints={{ desktop: { cols: 12, gap: 'xl' } }}>
          {socialLinks?.length > 0 && (
            <GridItem
              breakpoints={{ desktop: { colStart: 2, col: 12 } }}
              className={cnFooter('Top')}
            >
              <div className={cnFooter('Socials')}>
                {socialLinks.map(social => (
                  <SocialButton
                    key={social.id}
                    icon={() => <Picture {...social.icon} />}
                    as="a"
                    href={social.link}
                  />
                ))}
              </div>
            </GridItem>
          )}

          <GridItem
            breakpoints={{ desktop: { col: 12, colStart: 2 } }}
            className={cnFooter('Menu')}
          >
            <div className={cnFooter('Nav')}>
              <div className={cnFooter('Nav-Column')}>
                {columnITitleLink && (
                  <Typography.Text
                    // as={!columnITitleLink.targetBlank ? 'link' : 'a'}
                    // to={!columnITitleLink.targetBlank && columnITitleLink.link}
                    as="a"
                    href={columnITitleLink.link}
                    target={columnITitleLink.targetBlank ? '_blank' : '_self'}
                    size="xs"
                    weight="bold"
                    transform="uppercase"
                    className={cnFooter('Nav-Title')}
                  >
                    {columnITitleLink.title}
                  </Typography.Text>
                )}
                {columnILinks?.length > 0 && (
                  <div className={cnFooter('Nav-List')}>
                    {columnILinks.map(item => (
                      <div className={cnFooter('Nav-Item')} key={item.id}>
                        <Typography.Text
                          // as={!item.targetBlank ? 'link' : 'a'}
                          // to={!item.targetBlank && item.link}
                          as="a"
                          size="s"
                          href={item.link}
                          target={item.targetBlank ? '_blank' : '_self'}
                          key={item.id}
                        >
                          {item.title}
                        </Typography.Text>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className={cnFooter('Nav-Column')}>
                {pagesLinks?.length > 0 && (
                  <div className={cnFooter('Nav-List')}>
                    {pagesLinks.map(item => (
                      <div className={cnFooter('Nav-Primary')} key={item.id}>
                        <Typography.Text
                          // as={!item.targetBlank ? 'link' : 'a'}
                          // to={!item?.targetBlank && item.link}
                          as="a"
                          target={item.targetBlank ? '_blank' : '_self'}
                          href={item.link}
                          size="xs"
                          weight="bold"
                          transform="uppercase"
                          key={item.id}
                        >
                          {item.title}
                        </Typography.Text>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className={cnFooter('Nav-Column')}>
                {columnIITitleLink && (
                  <Typography.Text
                    // as={!columnITitleLink.targetBlank ? 'link' : 'a'}
                    // to={!columnITitleLink.targetBlank && columnITitleLink.link}
                    as="a"
                    href={columnIITitleLink.link}
                    target={columnIITitleLink.targetBlank ? '_blank' : '_self'}
                    size="xs"
                    weight="bold"
                    transform="uppercase"
                    className={cnFooter('Nav-Title')}
                  >
                    {columnIITitleLink.title}
                  </Typography.Text>
                )}
                {columnIILinks?.length > 0 && (
                  <>
                    <div className={cnFooter('Nav-List')}>
                      {columnIILinks.map(item => (
                        <div className={cnFooter('Nav-Item')} key={item.id}>
                          <Typography.Text
                            // as={!item.targetBlank ? 'link' : 'a'}
                            // to={!item.targetBlank && item.link}
                            as="a"
                            href={item.link}
                            target={item.targetBlank ? '_blank' : '_self'}
                            size="s"
                            key={item.id}
                          >
                            {item.title}
                          </Typography.Text>
                        </div>
                      ))}
                      <div className={cnFooter('Nav-See-All')}>
                        <Button
                          view="link-secondary"
                          as="a"
                          label={seeAll || 'see all'}
                          href={columnIITitleLink?.link}
                          iconRight={IconArrowRight}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className={cnFooter('Nav-Column')}>
                {columnIVTitleLink && (
                  <Typography.Text
                    // as={!columnITitleLink.targetBlank ? 'link' : 'a'}
                    // to={!columnITitleLink.targetBlank && columnITitleLink.link}
                    as="a"
                    href={columnIVTitleLink.link}
                    target={columnIVTitleLink.targetBlank ? '_blank' : '_self'}
                    size="xs"
                    weight="bold"
                    transform="uppercase"
                    className={cnFooter('Nav-Title')}
                  >
                    {columnIVTitleLink.title}
                  </Typography.Text>
                )}
                {columnIVLinks?.length > 0 && (
                  <div className={cnFooter('Nav-List')}>
                    {columnIVLinks.map(item => (
                      <div className={cnFooter('Nav-Item')} key={item.id}>
                        <Typography.Text
                          // as={!item.targetBlank ? 'link' : 'a'}
                          // to={!item.targetBlank && item.link}
                          as="a"
                          href={item.link}
                          target={item.targetBlank ? '_blank' : '_self'}
                          size="s"
                          key={item.id}
                        >
                          {item.title}
                        </Typography.Text>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </GridItem>
          <GridItem
            breakpoints={{ desktop: { col: 12, colStart: 2 } }}
            className={cnFooter('Locations')}
          >
            {locations?.length > 0 && (
              <Grid
                breakpoints={{
                  desktop: { cols: 12, gap: 'xl' },
                  tablet: { cols: 9, colGap: 'xxl', rowGap: '3xl' },
                  mobile: { cols: 2, colGap: 'm', rowGap: 'xl' },
                }}
              >
                {locations?.length > 0 &&
                  locations.map(item => (
                    <GridItem
                      breakpoints={{ desktop: { col: 2 }, tablet: { col: 3 } }}
                      key={item.id}
                    >
                      <Typography.Title
                        size="tiny"
                        className={cnFooter('Locations-Name')}
                      >
                        {item.title}
                      </Typography.Title>

                      <div>
                        <Typography.Text
                          size="s"
                          as="a"
                          href={'tel:' + item.phone}
                        >
                          {item.phone}
                        </Typography.Text>
                      </div>

                      <div>
                        <Typography.Text
                          size="s"
                          as="a"
                          href={'mailto:' + item.email}
                        >
                          {item.email}
                        </Typography.Text>
                      </div>
                    </GridItem>
                  ))}

                {locationsLink && (
                  <GridItem
                    breakpoints={{ desktop: { col: 2 }, tablet: { col: 3 } }}
                  >
                    <Button
                      view="link-secondary"
                      // isLink={!locationsLink.targetBlank}
                      // to={!!locationsLink.targetBlank ? '' : locationsLink.link}
                      as="a"
                      label={locationsLink.title}
                      href={
                        !locationsLink.targetBlank ? locationsLink.link : ''
                      }
                      iconRight={IconArrowRight}
                    />
                  </GridItem>
                )}
              </Grid>
            )}
          </GridItem>
          <GridItem
            breakpoints={{
              desktop: { col: 12 },
            }}
            className={cnFooter('Bottom')}
          >
            <Grid
              breakpoints={{
                desktop: { cols: 12, gap: 'xl' },
                tablet: { cols: 12, gap: 'xl' },
                mobile: { cols: 12, gap: 'm' },
              }}
            >
              <GridItem
                breakpoints={{
                  desktop: { col: 1 },
                  tablet: { col: 1 },
                  mobile: { col: 12 },
                }}
              >
                {languages?.length > 1 && (
                  <div className={cnFooter('Langs')}>
                    <Combobox
                      view="transparent"
                      dropdownView="transparent"
                      items={languages.filter(w => w.title !== language?.title)}
                      getItemLabel={item => item.title}
                      value={language}
                      onChange={({ value }) => {
                        window.location = value.link;
                      }}
                    />
                  </div>
                )}
              </GridItem>
              {copyright && (
                <GridItem
                  breakpoints={{
                    desktop: { col: 7 },
                    tablet: { col: 11 },
                    mobile: { col: 12 },
                  }}
                >
                  <Article content={copyright} size="s" />
                </GridItem>
              )}
            </Grid>
          </GridItem>
        </Grid>
      </div>
    </div>
  );
};
