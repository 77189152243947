import React from 'react';
import './ColoredCategoryCard.scss';
import { cn } from '../../utils/bem';
import { Typography } from '@dataartdev/uikit/Typography';
import { Button } from '@dataartdev/uikit/Button';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { prepareButtonAttrs } from '../../utils/prepareButtonAttrs';
import { Item } from '../ContentBuilder/models/CTI_105.props';

const cnColoredCategoryCard = cn('ColoredCategoryCard');

export const ColoredCategoryCard: React.FC<Item> = props => {
  const { link, title, text, picture, color, isInverted } = props || {};
  const buttonAttr = prepareButtonAttrs(
    link?.link,
    link?.title,
    link?.targetBlank
  );
  return (
    <div
      className={cnColoredCategoryCard({ isInverted: isInverted })}
      style={{ backgroundColor: color }}
    >
      <div className={cnColoredCategoryCard('Top')}>
        {title && (
          <Typography.Title
            parse
            className={cnColoredCategoryCard('Title')}
            size="sm"
            as="h3"
          >
            {title}
          </Typography.Title>
        )}
        {text && (
          <Typography.Text
            parse
            className={cnColoredCategoryCard('Text')}
            size="m"
            as="p"
          >
            {text}
          </Typography.Text>
        )}
      </div>
      {Object.keys(buttonAttr).length > 0 && (
        <Button
          view="link-secondary"
          className={cnColoredCategoryCard('Link')}
          iconRight={IconArrowRight}
          {...buttonAttr}
        />
      )}
      {picture && (
        <div
          className={cnColoredCategoryCard('Image')}
          style={{ backgroundImage: `url(${picture.urlWebp || picture.url})` }}
        />
      )}
    </div>
  );
};
