import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { DataQueryVariables } from 'common/hooks';
import qs from 'qs';
import { toPaginationParams } from 'network/helpers';
import { clearUrl, clearFalsyProps } from 'common/utils';

export const queryBlogPage: DataQueryFunction = (
  variables: DataQueryVariables | undefined
): Promise<any> => {
  const { industriesIds, tagsIds } = (variables && variables.filter) || {};
  const paginationParams = toPaginationParams(variables);

  const filterParams = clearFalsyProps({
    industriesIds,
    tagsIds,
  });

  if (filterParams.tagsIds) filterParams.tagsIds = [...filterParams.tagsIds];

  return httpClient.get(clearUrl(`/blog`), {
    params: {
      ...filterParams,
      ...paginationParams,
    },
    paramsSerializer: params => {
      return qs.stringify(params);
    },
  });
};
