// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { Banner } from '../../Banner';
import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const {
    type,
    title,
    text,
    picture,
    linkTitle,
    link,
    targetBlank,
    color,
    isInverted,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <Banner
        inverted={isInverted}
        bgColor={color}
        view="section"
        title={title}
        titleSize="md"
        text={text}
        picture={picture}
        labelButton={linkTitle}
        buttonIcon={IconOpenInNew}
        buttonIconSize="xs"
        targetBlank={targetBlank}
        link={link}
        onClick={e => {
          e.preventDefault();
          if (targetBlank) {
            window.open(link, '_blank');
          } else {
            window.location = link;
          }
        }}
      />
    </div>
  );
});

export default ContentBuilderTemplate;
