import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { DataQueryVariables } from 'common/hooks';
import { clearUrl } from 'common/utils';

export const queryIndustriesPage: DataQueryFunction = (
  variables: DataQueryVariables | undefined
): Promise<any> => {
  return httpClient.get(clearUrl('/industries/'));
};
