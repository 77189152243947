import './ClutchForm.scss';

import React, { useRef, useState } from 'react';
import { Typography } from '@dataartdev/uikit/Typography';

import { useSelector } from 'react-redux';
import { dictionariesSelector, layoutSelector } from 'store';

import { Form, FormBody } from '../../Form';
import { TextField } from '@dataartdev/uikit/TextField';
import { Button } from '@dataartdev/uikit/Button';
import { Checkbox } from '@dataartdev/uikit/Checkbox';
import { Picture } from '@dataartdev/uikit/Picture';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';

import { mutationClutchForm } from 'network';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

import { cnModalPage } from '../ModalPage';

import parse from 'html-react-parser';
import { schemaLetsTalkAll } from 'common/utils';

import TagManager from 'react-gtm-module';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';
import ReCAPTCHA from 'react-google-recaptcha';

import sbjs from 'sourcebuster';
import { useDataPagination } from '../../../hooks';
import { usePagePath } from '../../../hooks/usePagePath';

sbjs.init();

type Props = {
  onSubmitForm: (val: any) => void;
  modalData: any;
};

export const ClutchForm: React.FC<Props> = (
  { onSubmitForm, modalData },
  ref
) => {
  const { companySiteForm }: any = useSelector(dictionariesSelector) || {};
  const { companySiteFormClutch: staticContent } = companySiteForm;
  const [successForm, setSuccessForm] = useState<boolean>(false);
  const { pageId }: any = useSelector(layoutSelector) || {};
  const reCaptchaRef = useRef(null);

  const pagePath = usePagePath();

  const cameFrom = `typ: ${sbjs.get.current.typ} | src: ${sbjs.get.current.src} | mdm: ${sbjs.get.current.mdm} | cmp: ${sbjs.get.current.cmp} | cnt: ${sbjs.get.current.cnt} | trm: ${sbjs.get.current.trm}`;
  const submitButtonID = staticContent?.companySiteFormClutchTrackingID || '';

  const onSubmit = async (values, actions) => {
    const token = await reCaptchaRef.current.executeAsync();

    await mutationClutchForm({
      body: {
        ...values,
        pageId,
        [`${recaptchaFormKey}`]: token,
        cameFrom: cameFrom,
        trackingId: submitButtonID,
      },
    }).then(() => {
      onSubmitForm(true);
      setSuccessForm(true);
      TagManager.dataLayer({
        dataLayer: {
          event: 'send_form',
          form_name: 'subscribe_form',
          page_path: pagePath,
          tags: 'not set',
        },
      });

      actions.resetForm();
    });
  };

  const schema = schemaLetsTalkAll(
    staticContent.companySiteFormClutchRequiredField,
    staticContent.companySiteFormClutchRequiredField,
    staticContent.companySiteFormClutchRequiredField
  );

  const sliderOptions = {
    watchOverflow: true,
    spaceBetween: 24,
    autoHeight: true,
    pagination: {
      clickable: true,
    },
  };

  const initValues = {
    email: '',
    name: '',
    company: '',
    phone: '',
    additionalInfo: '',
    privacyAgreement: false,
  };

  return (
    <div className={cnModalPage({ clutch: true })}>
      <Grid breakpoints={{ desktop: { cols: 12, gap: '5xl' } }}>
        <GridItem breakpoints={{ desktop: { col: 5 } }}>
          <Typography.Title size="md" className={cnModalPage('Title')}>
            {modalData.title}
          </Typography.Title>
          <Typography.Text className={cnModalPage('Text')}>
            {modalData.text}
          </Typography.Text>

          <div className={cnModalPage('Clutch-Pictures')}>
            {modalData.picture.map(item => (
              <Picture {...item} key={item.id} />
            ))}
          </div>

          {modalData.testimonials?.length > 0 && (
            <Slider className={cnModalPage('Slider')} {...sliderOptions}>
              {modalData.testimonials.map(item => (
                <SliderItem key={item.id}>
                  <Typography.Text
                    weight="bold"
                    className={cnModalPage('Slider-Text')}
                  >
                    {parse(item.text)}
                  </Typography.Text>
                  <Typography.Text className={cnModalPage('Slider-Title')}>
                    {item.title}
                  </Typography.Text>
                </SliderItem>
              ))}
            </Slider>
          )}
        </GridItem>
        <GridItem breakpoints={{ desktop: { col: 7 } }}>
          <div className={cnModalPage('Body')}>
            <Form
              enableReinitialize
              initialValues={initValues}
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {({
                isSubmitting,
                errors,
                handleChange,
                handleBlur,
                values,
                touched,
                dirty,
              }) => {
                return (
                  <FormBody>
                    <Grid
                      breakpoints={{
                        desktop: { cols: 12, gap: 'm' },
                        tablet: { cols: 8, gap: 'm' },
                        mobile: { cols: 1, gap: 'm' },
                      }}
                    >
                      <GridItem
                        breakpoints={{
                          desktop: { col: 12 },
                          tablet: { col: 8 },
                        }}
                      >
                        <TextField
                          name="name"
                          onChange={({ e }) => handleChange(e)}
                          onBlur={handleBlur}
                          value={values.name}
                          label={staticContent.companySiteFormClutchName}
                          view="outline"
                          width="full"
                          caption={touched.name && errors.name}
                          status={touched.name && !!errors.name && 'alert'}
                        />
                      </GridItem>
                      <GridItem
                        breakpoints={{
                          desktop: { col: 12 },
                          tablet: { col: 8 },
                        }}
                      >
                        <TextField
                          name="phone"
                          onChange={({ e }) => handleChange(e)}
                          value={values.phone}
                          onBlur={handleBlur}
                          label={staticContent.companySiteFormClutchPhone}
                          view="outline"
                          width="full"
                          type="tel"
                          caption={touched.phone && errors.phone}
                          status={touched.phone && !!errors.phone && 'alert'}
                        />
                      </GridItem>
                      <GridItem
                        breakpoints={{
                          desktop: { col: 12 },
                          tablet: { col: 8 },
                        }}
                      >
                        <TextField
                          name="email"
                          onChange={({ e }) => handleChange(e)}
                          value={values.email}
                          onBlur={handleBlur}
                          label={staticContent.companySiteFormClutchEmail}
                          view="outline"
                          width="full"
                          required
                          caption={touched.email && errors.email}
                          status={touched.email && !!errors.email && 'alert'}
                        />
                      </GridItem>
                      <GridItem
                        breakpoints={{
                          desktop: { col: 12 },
                          tablet: { col: 8 },
                        }}
                      >
                        <TextField
                          name="company"
                          onChange={({ e }) => handleChange(e)}
                          value={values.company}
                          onBlur={handleBlur}
                          label={staticContent.companySiteFormClutchCompany}
                          view="outline"
                          width="full"
                          caption={touched.company && errors.company}
                          status={
                            touched.company && !!errors.company && 'alert'
                          }
                        />
                      </GridItem>
                      <GridItem
                        breakpoints={{
                          desktop: { col: 12 },
                          tablet: { col: 8 },
                        }}
                      >
                        {' '}
                        <TextField
                          name="additionalInfo"
                          onChange={({ e }) => handleChange(e)}
                          value={values.additionalInfo}
                          onBlur={handleBlur}
                          label={staticContent.companySiteFormClutchAddInfo}
                          view="outline"
                          width="full"
                          caption={
                            touched.additionalInfo && errors.additionalInfo
                          }
                          status={
                            touched.additionalInfo &&
                            !!errors.additionalInfo &&
                            'alert'
                          }
                        />
                      </GridItem>
                      <GridItem
                        breakpoints={{
                          desktop: { col: 12 },
                          tablet: { col: 8 },
                        }}
                        className={cnModalPage('Bottom')}
                      >
                        <Checkbox
                          required
                          label={
                            staticContent.companySiteFormClutchSubscribePolicy
                          }
                          onBlur={handleBlur}
                          onChange={({ e }) => handleChange(e)}
                          name="privacyAgreement"
                          checked={values.privacyAgreement}
                        />
                        <ReCAPTCHA
                          ref={reCaptchaRef}
                          sitekey={recaptchaKey}
                          // onChange={value => {
                          //   console.log('$$$$', isSubmitting, value);
                          //   setSubmitting(false);
                          // }}
                          size="invisible"
                        />
                        <div className={cnModalPage('Button')}>
                          <Button
                            id={staticContent.companySiteFormClutchTrackingID}
                            iconRight={IconArrowRight}
                            disabled={
                              isSubmitting ||
                              !dirty ||
                              !!Object.keys(errors).length
                            }
                            label={'Submit'}
                            type="submit"
                          />
                        </div>
                      </GridItem>
                    </Grid>
                  </FormBody>
                );
              }}
            </Form>
          </div>
        </GridItem>
      </Grid>
      {successForm && (
        <div className={cnModalPage('Success')}>
          <Typography.Title size="md" className={cnModalPage('Title')}>
            {staticContent.companySiteFormClutchSuccessTitle}
          </Typography.Title>
          <Typography.Text className={cnModalPage('SubTitle')}>
            {staticContent.companySiteFormClutchSuccessText}
          </Typography.Text>
        </div>
      )}
    </div>
  );
};
