import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { Article } from '../../Article/Article';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { ValuesCard } from '../../ValuesCard/ValuesCard';
import { IRoot2 } from '../models/CTI_2.props';

export const ContentBuilderTemplate = forwardRefWithAs<IRoot2>(props => {
  const {
    type,
    withoutColors,
    title,
    text,
    items,
    columnsCount = 3,
    id: pageID,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();

  let mobileCol = 12,
    tableCol = 6,
    desktopCol = 3;

  if (+columnsCount === 3) {
    desktopCol = 4;
  }

  if (+columnsCount === 2) {
    desktopCol = 6;
  }

  return (
    <div
      id={contentId}
      className={cnContentBuilderTemplate({
        type: typeNumber,
        gray: withoutColors,
      })}
    >
      <Grid
        breakpoints={{
          desktop: { cols: 12 },
          tablet: { cols: 12 },
          mobile: { cols: 12 },
        }}
      >
        <GridItem
          breakpoints={{
            desktop: { col: 8 },
            tablet: { col: 12 },
            mobile: { col: 12 },
          }}
        >
          {!withoutColors && title && (
            <Typography.Title
              as="h2"
              size="lg"
              weight="bold"
              transform="uppercase"
              parse
            >
              {title}
            </Typography.Title>
          )}
          {withoutColors && title && (
            <Typography.Title size="lg" as="h2" parse>
              {title}
            </Typography.Title>
          )}
          {text && <Article content={text} />}
        </GridItem>
      </Grid>

      {items && items?.length > 0 && !withoutColors && (
        <Grid
          breakpoints={{
            desktop: { cols: 12, gap: 'xl' },
            tablet: { cols: 12, gap: 'm' },
            mobile: { gap: 'm' },
          }}
        >
          {!withoutColors &&
            items.map(item => (
              <GridItem
                key={item.id}
                breakpoints={{
                  desktop: { col: desktopCol },
                  tablet: { col: tableCol },
                  mobile: { col: mobileCol },
                }}
              >
                <ValuesCard
                  {...item}
                  size={+pageID === 5137 ? 'm' : 's'}
                  picture={item?.backgroundImage || item?.picture}
                />
              </GridItem>
            ))}
        </Grid>
      )}

      {items && items?.length > 0 && withoutColors && (
        <Grid
          breakpoints={{
            desktop: { cols: 10, gap: 'xl' },
            tablet: { cols: 8, gap: 'm' },
            mobile: { gap: 'm' },
          }}
        >
          {withoutColors &&
            items.map(item => (
              <GridItem
                key={item.id}
                breakpoints={{ desktop: { col: 2 }, tablet: { col: 4 } }}
              >
                <div className={cnContentBuilderTemplate('Item')}>
                  <img src={item.picture?.url} alt={item.picture?.alt} />
                  {item.title && (
                    <Typography.Title size="tiny" as="h3" parse>
                      {item.title}
                    </Typography.Title>
                  )}
                </div>
              </GridItem>
            ))}
        </Grid>
      )}
    </div>
  );
});

export default ContentBuilderTemplate;
