import './MainLayout.scss';
import './MainHeader.scss';

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setScreenWidth, showFooterFormSelector } from 'store';
import { MainForm } from './MainForm';
import { ModalPage } from 'common/components';
import { MainHeader } from './MainHeader';
import { LandingFooterMain } from './LandingFooterMain';

type Props = RouteComponentProps & {
  children: React.ReactNode;
};

export const MainLayout: React.FC<Props> = props => {
  const { children, ...routeProps } = props;
  const dispatch = useDispatch();
  const showForm = useSelector(showFooterFormSelector);

  useEffect(() => {
    const onWindowResize = () => {
      dispatch(setScreenWidth(window.innerWidth));
    };

    window.addEventListener('resize', onWindowResize);
    onWindowResize();
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [dispatch]);

  return (
    <div className={classNames('out')}>
      <MainHeader {...routeProps} />
      <div className="page-inner">{children}</div>
      {showForm && <MainForm />}
      <ModalPage />
      <LandingFooterMain />
    </div>
  );
};
