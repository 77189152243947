import './ContentBuilderTemplate.scss';

import React from 'react';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { Article } from '../../Article/Article';
import { Button } from '@dataartdev/uikit/Button';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { IRoot0 } from '../models/CTI_0.props';
import { prepareButtonAttrs } from '../../../utils/prepareButtonAttrs';
import { CtaScrollerBtn } from '../../CtaScrollerBtn';

export const ContentBuilderTemplate: React.FC<IRoot0> = props => {
  const {
    type,
    title,
    text,
    isFullWidth,
    contentId,
    link,
    linkTitle,
    targetBlank,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();
  const columnWidth = isFullWidth ? 12 : 8;
  const buttonAttr = prepareButtonAttrs(link, linkTitle, targetBlank);

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <Grid
        breakpoints={{
          desktop: { cols: 12 },
          tablet: { cols: 12 },
          mobile: { cols: 12 },
        }}
      >
        <GridItem
          breakpoints={{
            desktop: { col: columnWidth },
            tablet: { col: 12 },
            mobile: { col: 12 },
          }}
        >
          {title && (
            <Typography.Title size="lg" as="h2">
              {title}
            </Typography.Title>
          )}
          {text && <Article content={text} />}
          {Object.keys(buttonAttr).length > 0 &&
            ('isCTA' in buttonAttr ? (
              <CtaScrollerBtn {...buttonAttr} />
            ) : (
              <Button
                view="link-secondary"
                iconRight={IconArrowRight}
                className={cnContentBuilderTemplate('More-Link')}
                {...buttonAttr}
              />
            ))}
        </GridItem>
      </Grid>
    </div>
  );
};

export default ContentBuilderTemplate;
