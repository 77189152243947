import './FilterTag.scss';
import React from 'react';
import { Typography } from '@dataartdev/uikit/Typography';
import { cn } from '../../utils/bem';
import { useHistory, useLocation } from 'react-router-dom';

interface Props {
  title: string;
  slug: string;
  active: boolean;
}

const cnFilterTag = cn('FilterTag');

export const FilterTag: React.FC<Props> = ({ title, slug, active = false }) => {
  const history = useHistory();
  const { hash } = useLocation();
  const tagHandler = () => {
    if (slug === hash.substring(1)) {
      history.push(`#`);
    } else {
      history.push(`#${slug}`);
    }
  };

  return (
    <button
      id={slug}
      className={cnFilterTag()}
      onClick={tagHandler}
      data-active={active}
    >
      <Typography.Text as="span" size="xs" transform="uppercase" weight="bold">
        {title}
      </Typography.Text>
    </button>
  );
};
