import './ContentBuilderTemplate.scss';

import React from 'react';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { Article } from '../../Article/Article';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { IRoot71 } from '../models/CTI_71.props';

export const ContentBuilderTemplate: React.FC<IRoot71> = props => {
  const { type, body, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {body && (
        <Grid
          breakpoints={{
            desktop: { cols: 12, gap: 'xl' },
            tablet: { cols: 12, gap: 'xl' },
            mobile: { cols: 12, gap: 'xl' },
          }}
        >
          <GridItem
            breakpoints={{
              desktop: { col: 12 },
              tablet: { col: 12 },
              mobile: { col: 12 },
            }}
          >
            <Article content={body} />
          </GridItem>
        </Grid>
      )}
    </div>
  );
};

export default ContentBuilderTemplate;
