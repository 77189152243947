/* eslint-disable jsx-a11y/anchor-is-valid */
import './BlogSingle.scss';

import React, { useMemo, useEffect, useRef, useState } from 'react';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { dictionariesSelector } from 'store';
import TagManager from 'react-gtm-module';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import { useDataQuery, useGTMDataSend } from 'common/hooks';
import { queryBlogPost, mutationFileForm } from 'network';

import { EssenceCard } from '../../../common/components/EssenceCard/EssenceCard';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { cn } from 'ui/utils/bem';
import { Typography } from '@dataartdev/uikit/Typography';
import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';
import { PATH, redirect404 } from 'router';
import { Tag } from '@dataartdev/uikit/Tag';
import { TagGroup } from '@dataartdev/uikit/TagGroup';
import { Picture } from '@dataartdev/uikit/Picture';
import { Article } from '../../../common/components/Article/Article';
import { User } from '@dataartdev/uikit/User';
import { SocialsShare } from '@dataartdev/uikit/SocialsShare';
import { Mq } from '@dataartdev/uikit/Mq';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { CollapseGroup } from '@dataartdev/uikit/CollapseGroup';
import { TreeNav } from '@dataartdev/uikit/TreeNav';
import { BackgroundDecor } from '@dataartdev/uikit/BackgroundDecor';
import { BlogSingleForm } from '../components/BlogSingleForm';
import { CtaScrollerBtn } from '../../../common/components/CtaScrollerBtn';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';
import { FileCard } from '../../../common/components';

import sbjs from 'sourcebuster';
import { usePagePath } from '../../../common/hooks/usePagePath';

sbjs.init();

type Props = RouteComponentProps<{ slug: string }> & {};

export const cnBlogSingle = cn('BlogSingle');

export const BlogSingle: React.FC<Props> = ({ match, location, history }) => {
  const {
    companySiteBlogPost: staticContent,
    companySiteTableOfContent,
    companySiteForm,
  } = useSelector(dictionariesSelector) || {};

  //TODO убрать костыль
  const { data, loading, error } = useDataQuery(queryBlogPost, {
    slug:
      match.params.slug === 'static-content'
        ? 'test-1-why-every-company-needs-a-data-governance-strategy'
        : match.params.slug,
  });

  const {
    breadcrumbs,
    postDate,
    readingTime,
    title,
    tags,
    picture,
    text,
    description,
    similarPosts,
    realatedItems,
    faq,
    members,
    contentPosts,
    sideItems,
    metaTitle,
    document,
  } = data || {};

  useEffect(() => {
    if (error && error.response && error.response.status === 404) {
      redirect404(history);
    }

    if (error && error.response && error.response.status === 400) {
      history.replace({ ...history.location, state: { is400: true } });
    }
  }, [error, history]);

  const stringTags = tags
    ?.map(i => i.title)
    .join(', ')
    .trim();

  const pagePath = usePagePath();

  useEffect(() => {
    if (stringTags) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'blog_tag_visit',
          page_path: pagePath,
          page_title: metaTitle || 'Blog',
          tags: stringTags,
        },
      });
    }
  }, [tags, metaTitle]);

  useGTMDataSend(location, metaTitle, stringTags);
  const dataLayerTags = tags?.map(tag => tag.title).join(', ');

  const tagsRender = useMemo(
    () => (
      <React.Fragment>
        {tags && (
          <div className={cnBlogSingle('tags')}>
            <TagGroup items={tags} />
          </div>
        )}
      </React.Fragment>
    ),
    [tags]
  );

  const authorsRender = useMemo(
    () => (
      <React.Fragment>
        {members?.length > 0 && (
          <div className={cnBlogSingle('authors')}>
            <div className={cnBlogSingle('authorsTitle')}>
              {staticContent && (
                <Typography.Text
                  transform="uppercase"
                  size="xs"
                  weight="bold"
                  as="h2"
                >
                  {staticContent.companySiteBlogPostAuthorTitle}
                </Typography.Text>
              )}
            </div>
            <div className={cnBlogSingle('authorsList')}>
              {members.map(author => (
                <User
                  avatarUrl={author.photo?.desktop2x}
                  key={`${author.firstName} ${author.lastName}`}
                  name={`${author.firstName} ${author.lastName}`}
                  info={author.title}
                />
              ))}
            </div>
          </div>
        )}
      </React.Fragment>
    ),
    [members, staticContent]
  );

  const pageRef = useRef(null);

  let splitContent = [];
  if (text?.includes('fileSplitter')) {
    splitContent = text.split('fileSplitter');
  } else {
    splitContent = [text];
  }

  let documents: Array<any> = [];
  if (Array.isArray(document)) {
    documents = document;
  } else {
    documents.push(document);
  }

  const cameFrom = `typ: ${sbjs.get.current.typ} | src: ${sbjs.get.current.src} | mdm: ${sbjs.get.current.mdm} | cmp: ${sbjs.get.current.cmp} | cnt: ${sbjs.get.current.cnt} | trm: ${sbjs.get.current.trm}`;
  const submitButtonID =
    companySiteForm?.companySiteFormDownloadFile
      ?.companySiteFormDownloadFileTrackingID || 'file_download';

  const onSubmitFileForm = body => {
    mutationFileForm({
      body: {
        ...body,
        pageId: data.id,
        [`${recaptchaFormKey}`]: body.token,
        cameFrom: cameFrom,
        trackingId: submitButtonID,
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'send_form',
        form_name: submitButtonID,
        tags: stringTags || '',
      },
    });
  };

  return (
    <React.Fragment>
      {!loading && (
        <div className={cnBlogSingle()}>
          <Mq query="--desktop">
            <TreeNav
              title={companySiteTableOfContent}
              pageRef={pageRef}
              fixed
            />
          </Mq>
          {data && (
            <div className="container">
              <div className={cnBlogSingle('breadcrumbs')}>
                {breadcrumbs && (
                  <Breadcrumbs
                    getLabel={page => page.text}
                    getLink={page => page.url}
                    history={history}
                    pages={data.breadcrumbs}
                  />
                )}
              </div>
              <div className={cnBlogSingle('head')}>
                {postDate && (
                  <div className={cnBlogSingle('date')}>
                    <Typography.Text weight="semibold" size="xs">
                      {postDate}
                    </Typography.Text>
                  </div>
                )}
                {readingTime && readingTime !== 0 && staticContent && (
                  <div className={cnBlogSingle('tag')}>
                    <Tag
                      label={`${readingTime} ${staticContent.companySiteBlogPostTimeRead.replace(
                        '{0}',
                        ''
                      )}`}
                      mode="info"
                    />
                  </div>
                )}
                {title && (
                  <div className={cnBlogSingle('title')}>
                    <Typography.Title size="lg" as="h1">
                      {title}
                    </Typography.Title>
                  </div>
                )}
              </div>
              <div className={cnBlogSingle('body')}>
                <Grid cols="12" gap="xl">
                  <GridItem
                    breakpoints={{
                      mobile: { col: 12 },
                      tablet: { col: 12 },
                      desktop: { col: 8 },
                    }}
                  >
                    {tagsRender}
                    {description && (
                      <div className={cnBlogSingle('subTitle')}>
                        <Typography.Text
                          size="l"
                          dangerouslySetInnerHTML={{ __html: description }}
                        />
                      </div>
                    )}

                    {picture && (
                      <div className={cnBlogSingle('picture')}>
                        <Picture {...picture} />
                      </div>
                    )}

                    <Mq query="--tablet-max">{authorsRender}</Mq>

                    {text && (
                      <div className={cnBlogSingle('text')} ref={pageRef}>
                        {splitContent?.map((item, index) => {
                          return (
                            match.params.slug !== 'static-content' && (
                              <>
                                <Article
                                  content={item}
                                  contentPostsPath={post =>
                                    post.slug
                                      ? generatePath(PATH.BLOG_SINGLE, {
                                          slug: post.slug,
                                        })
                                      : null
                                  }
                                  references={{
                                    path: PATH,
                                    items: sideItems,
                                  }}
                                  contentPosts={contentPosts}
                                />
                                {documents?.[index] && (
                                  <FileCard
                                    {...documents?.[index]}
                                    {...documents?.[index].file}
                                    picture={documents?.[index].popup.picture}
                                    extension={documents?.[index].file.type}
                                    date={documents?.[index].date}
                                    onSubmit={async body =>
                                      onSubmitFileForm({
                                        ...body,
                                        filePath: documents?.[index].file.url,
                                      })
                                    }
                                    recaptchaKey={recaptchaKey}
                                  />
                                )}
                              </>
                            )
                          );
                        })}
                        {faq?.length > 0 && (
                          <div className={cnBlogSingle('Faq')}>
                            <Typography.Text
                              size="xs"
                              weight="bold"
                              transform="uppercase"
                              className={cnBlogSingle('Faq-Title')}
                              as="h2"
                            >
                              faq
                            </Typography.Text>
                            <CollapseGroup
                              hoverEffect
                              size="m"
                              items={faq}
                              getItemLabel={item => item.title}
                              getItemContent={item => {
                                return (
                                  <>
                                    <Article content={item.text} />
                                    {item.button && (
                                      <div className={cnBlogSingle('Cta')}>
                                        <CtaScrollerBtn
                                          isPopup={item.isPopup}
                                          title={
                                            item.button?.title || 'Get in Touch'
                                          }
                                          link={item.button?.link}
                                        />
                                      </div>
                                    )}
                                  </>
                                );
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </GridItem>
                  <GridItem
                    breakpoints={{
                      mobile: {
                        col: 12,
                      },
                      tablet: {
                        col: 12,
                      },
                      desktop: {
                        col: 4,
                        colStart: 9,
                      },
                    }}
                  >
                    {similarPosts?.length > 0 || members?.length > 0 ? (
                      <div className={cnBlogSingle('SideBar')}>
                        <Mq query="--desktop">{authorsRender}</Mq>
                        <div className={cnBlogSingle('FormSubscribe')}>
                          <BlogSingleForm
                            pageId={data.id}
                            tags={dataLayerTags}
                          />
                        </div>

                        {similarPosts?.length > 0 && (
                          <div className={cnBlogSingle('Recommendation')}>
                            {staticContent && (
                              <div
                                className={cnBlogSingle('recommendationTitle')}
                              >
                                <Typography.Text
                                  transform="uppercase"
                                  size="xs"
                                  weight="bold"
                                  as="span"
                                >
                                  {
                                    staticContent.companySiteBlogPostSimilarPostsTitle
                                  }
                                </Typography.Text>
                              </div>
                            )}
                            <div className={cnBlogSingle('RecommendationList')}>
                              {similarPosts.map((post, index) => (
                                <Typography.Title
                                  size="tiny"
                                  key={post.id}
                                  as="link"
                                  to={generatePath(PATH.BLOG_SINGLE, {
                                    slug: post.slug,
                                  })}
                                >
                                  {post.title}
                                </Typography.Title>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                  </GridItem>
                </Grid>
              </div>
              <div className={cnBlogSingle('bottom')}>
                <Grid cols="2" yAlign="center">
                  <GridItem
                    breakpoints={{ mobile: { col: 2 } }}
                    className={cnBlogSingle('bottomLeft')}
                  >
                    {tagsRender}
                  </GridItem>
                  <GridItem
                    breakpoints={{ mobile: { col: 2 } }}
                    className={cnBlogSingle('bottomRight')}
                  >
                    <SocialsShare
                      title={
                        staticContent
                          ? staticContent.companySiteBlogPostShare
                          : null
                      }
                      url={window.location.href}
                    />
                  </GridItem>
                </Grid>
              </div>
            </div>
          )}

          {realatedItems?.length > 0 && (
            <div className={cnBlogSingle('Slider')}>
              <BackgroundDecor view="right-triangle-down" />
              <div className="container">
                {staticContent && (
                  <div className={cnBlogSingle('SliderTitle')}>
                    <Typography.Text
                      transform="uppercase"
                      size="xs"
                      weight="bold"
                    >
                      {staticContent.companySiteBlogPostRelatedContentTitle}
                    </Typography.Text>
                  </div>
                )}

                <div className={cnBlogSingle('SliderBody')}>
                  <Slider
                    navigation
                    breakpoints={{
                      767: {
                        spaceBetween: 16,
                        slidesPerView: 2,
                      },
                      1200: {
                        spaceBetween: 24,
                        slidesPerView: 3,
                      },
                    }}
                    spaceBetween={16}
                    slidesPerView={1}
                    watchOverflow
                  >
                    {realatedItems.map(post => (
                      <SliderItem key={post.slug}>
                        <EssenceCard
                          {...post}
                          view="filled"
                          size="m"
                          routePath={generatePath(PATH.EVENTS_PAGE, {
                            slug: post.slug,
                          })}
                          path={generatePath(PATH.BLOG_SINGLE, {
                            slug: post.slug,
                          })}
                          linkTitle={
                            post.contentType === 2
                              ? staticContent.companySiteBlogPostSimilarVideoTitle
                              : staticContent.companySiteBlogPostSimilarPostsTitle
                          }
                        />
                      </SliderItem>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
