// @ts-nocheck
import './ListLinks.scss';

import React from 'react';

// import { IconArrowRight } from '../../icons/IconArrowRight/IconArrowRight';
// import { IconOpenInNew } from '../../icons/IconOpenInNew/IconOpenInNew';
// import { cn } from '../../utils/bem';
// import { Button } from '../Button/Button';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';
import { cn } from '../../utils/bem';
import { Button } from '@dataartdev/uikit/Button';

type Props = {
  items?: any;
  getLabel?: (item: any) => string;
  getPath?: (item: any) => string;
  onLinkClick?: (label: string) => void;
};

const cnListLinks = cn('ListLinks');

export const ListLinks: React.FC<Props> = props => {
  const { items, getLabel, getPath, onLinkClick } = props;

  const linkAttrs = item => {
    const pathLink = getPath?.(item);
    const { targetBlank, labelColor, labelText, hideLabel, label } = item;

    if (pathLink && !targetBlank) {
      return {
        as: 'a',
        href: pathLink,
        iconRight: IconOpenInNew,
        target: '_self',
        size: 's',
        ...(label && {
          badge: {
            text: label.value,
            inverted: !!label.isInverted,
            color: label.labelColor,
          },
        }),
      };
    }

    if (pathLink && targetBlank) {
      return {
        as: 'a',
        href: pathLink,
        iconRight: IconOpenInNew,
        target: '_blank',
        size: 's',
        ...(label && {
          badge: {
            text: label.value,
            inverted: !!label.isInverted,
            color: label.labelColor,
          },
        }),
      };
    }

    if (!hideLabel && labelText) {
      return {
        badge: {
          text: labelText,
          color: labelColor,
        },
        align: 'between',
        disabled: true,
      };
    }

    return {
      disabled: true,
    };
  };

  return (
    <div className={cnListLinks()}>
      {items.map(item => {
        const label = getLabel(item);
        return (
          <div className={cnListLinks('Item')} key={item.id}>
            <Button
              view="link-primary"
              label={label}
              width="full"
              {...linkAttrs(item)}
              onClick={onLinkClick ? () => onLinkClick(label) : undefined}
            />
          </div>
        );
      })}
    </div>
  );
};
