import './EventsForm.scss';

import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import { Typography } from '@dataartdev/uikit/Typography';

import { cn } from 'ui/utils/bem';

import { useSelector } from 'react-redux';
import {
  dictionariesSelector,
  isTabletSelector,
  isDesktopSelector,
  layoutSelector,
} from 'store';

import { Form, FormBody } from '../../../common/components';
import { TextField } from '@dataartdev/uikit/TextField';
import { Button } from '@dataartdev/uikit/Button';
import { Checkbox } from '@dataartdev/uikit/Checkbox';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';

import TagManager from 'react-gtm-module';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';
import ReCAPTCHA from 'react-google-recaptcha';

import { mutationEventsForm } from 'network';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

import sbjs from 'sourcebuster';
import { usePagePath } from '../../../common/hooks/usePagePath';
sbjs.init();

type Props = {
  selectItems: any;
  tags?: any;
  view?: any;
};

const cnEventsForm = cn('EventsForm');

export const EventsForm: React.FC<Props> = (props, ref) => {
  const { tags, view } = props;
  const { companySiteEvents: staticContent } =
    useSelector(dictionariesSelector) || {};
  const isTablet = useSelector(isTabletSelector);
  const isDesktop = useSelector(isDesktopSelector);
  const reCaptchaRef = useRef(null);
  const [successForm, setSuccessForm] = useState<boolean>(false);
  const { pageId }: any = useSelector(layoutSelector) || {};

  const pagePath = usePagePath();

  const cameFrom = `typ: ${sbjs.get.current.typ} | src: ${sbjs.get.current.src} | mdm: ${sbjs.get.current.mdm} | cmp: ${sbjs.get.current.cmp} | cnt: ${sbjs.get.current.cnt} | trm: ${sbjs.get.current.trm}`;
  const submitButtonID =
    staticContent?.companySiteEventsForm?.companySiteEventsFormUpdates
      ?.companySiteEventsFormUpdatesTrackingID || '';

  const onSubmit = async (values, actions) => {
    const token = await reCaptchaRef.current.executeAsync();
    await mutationEventsForm({
      body: {
        ...values,
        [`${recaptchaFormKey}`]: token,
        pageId,
        cameFrom: cameFrom,
        trackingId: submitButtonID,
      },
    }).then(() => {
      setSuccessForm(true);
      actions.resetForm();
      TagManager.dataLayer({
        dataLayer: {
          event: 'send_form',
          form_name: 'subscribe_form',
          page_path: pagePath,
          tags: tags,
        },
      });
    });
  };

  const schema = yup.object({
    email: yup
      .string()
      .email(
        staticContent.companySiteEventsForm.companySiteEventsFormUpdates
          .companySiteEventsFormUpdatesEmail
          .companySiteEventsFormUpdatesEmailError
      )
      .required(
        staticContent.companySiteEventsForm.companySiteEventsFormUpdates
          .companySiteEventsFormUpdatesRequiredField
      )
      .nullable(),
    name: yup
      .string()
      .min(3)
      .required(
        staticContent.companySiteEventsForm.companySiteEventsFormUpdates
          .companySiteEventsFormUpdatesRequiredField
      )
      .nullable(),
    privacyAgreement: yup
      .boolean()
      .oneOf([true], 'on')
      .required(),
  });

  return (
    <div className={cnEventsForm({ view })}>
      <Typography.Title size="md" className={cnEventsForm('Title')} as="h2">
        {
          staticContent.companySiteEventsForm.companySiteEventsFormUpdates
            .companySiteEventsFormUpdatesTitle
        }
      </Typography.Title>
      <div className={cnEventsForm('Body')}>
        <Form
          enableReinitialize
          initialValues={{ email: '', name: '', privacyAgreement: false }}
          validationSchema={schema}
          onSubmit={onSubmit}
          validateOnChange
        >
          {({
            isSubmitting,
            errors,
            dirty,
            isValid,
            touched,
            values,
            handleChange,
            handleBlur,
          }) => {
            return (
              <FormBody>
                <Grid
                  breakpoints={{
                    desktop: { cols: 12, gap: 'm' },
                    tablet: { cols: 8, gap: 'm' },
                    mobile: { cols: 1, gap: 'm' },
                  }}
                >
                  <GridItem
                    breakpoints={{
                      desktop: { col: 12 },
                      tablet: { col: view === 'row' ? 4 : 8 },
                    }}
                  >
                    <TextField
                      name="name"
                      value={values.name}
                      label={
                        staticContent.companySiteEventsForm
                          .companySiteEventsFormUpdates
                          .companySiteEventsFormUpdatesName
                      }
                      view="outline"
                      width="full"
                      required
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      caption={touched.name && errors.name}
                      status={touched.name && !!errors.name && 'alert'}
                    />
                  </GridItem>
                  <GridItem
                    breakpoints={{
                      desktop: { col: 12 },
                      tablet: { col: view === 'row' ? 4 : 8 },
                    }}
                  >
                    <TextField
                      name="email"
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values.email}
                      label="E-mail"
                      view="outline"
                      width="full"
                      required
                      caption={touched.email && errors.email}
                      status={touched.email && !!errors.email && 'alert'}
                    />
                  </GridItem>
                  <GridItem
                    breakpoints={{ desktop: { col: 12 }, tablet: { col: 8 } }}
                    className={cnEventsForm('Bottom')}
                  >
                    <Checkbox
                      required
                      label={
                        staticContent.companySiteEventsForm
                          .companySiteEventsFormUpdates
                          .companySiteEventsFormUpdatesSubscribePolicy
                      }
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      name="privacyAgreement"
                      checked={values.privacyAgreement}
                    />
                    <Button
                      id={submitButtonID}
                      iconRight={IconArrowRight}
                      disabled={isSubmitting || !dirty || !isValid}
                      type="submit"
                      label={
                        view === 'row' && (isTablet || isDesktop)
                          ? 'Subscribe'
                          : null
                      }
                    />
                    <ReCAPTCHA
                      ref={reCaptchaRef}
                      sitekey={recaptchaKey}
                      size="invisible"
                    />
                  </GridItem>
                </Grid>
              </FormBody>
            );
          }}
        </Form>
      </div>
      {successForm && (
        <div className={cnEventsForm({ success: true })}>
          <Typography.Title size="md" className={cnEventsForm('Title')} as="h2">
            {
              staticContent.companySiteEventsForm.companySiteEventsFormUpdates
                .companySiteEventsFormUpdatesSuccessTitle
            }
          </Typography.Title>
          <Typography.Text className={cnEventsForm('SubTitle')}>
            {
              staticContent.companySiteEventsForm.companySiteEventsFormUpdates
                .companySiteEventsFormUpdatesSuccessText
            }
          </Typography.Text>
        </div>
      )}
    </div>
  );
};
