// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';
import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { generatePath } from 'react-router-dom';
import { PATH } from 'router';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { ListLinks } from '../../ListLinks/ListLinks';
import { PageCard } from '../../PageCard/PageCard';
import { useIntl } from 'react-intl';
export type Props = {
  type?: number;
  calendlyType?: string;
  url?: string;
  buttonLabel?: string;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const {
    type,
    background,
    industries,
    relatedItems,
    text,
    title,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const externalIndustries = industries.map(industry => ({
    ...industry,
    targetBlank: false,
  }));

  const typeNumber = type.toString();
  const { locale } = useIntl();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <Typography.Title
        size="lg"
        className={cnContentBuilderTemplate('Section-Title')}
        as="h2"
      >
        {title}
      </Typography.Title>
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: 'xl' },
          tablet: { gap: '6xl', col: 1 },
          mobile: { gap: '6xl', col: 1 },
        }}
      >
        <GridItem breakpoints={{ desktop: { col: 5 } }}>
          <Typography.Text className={cnContentBuilderTemplate('Section-Text')}>
            {text}
          </Typography.Text>
          <ListLinks
            items={externalIndustries}
            getLabel={item => item.title}
            getPath={item =>
              generatePath(PATH.INDUSTRIES_PAGE, {
                slug: item.slug,
                lang: locale === 'en' ? null : locale,
              })
            }
          />
        </GridItem>
        {relatedItems?.length > 0 && (
          <GridItem breakpoints={{ desktop: { col: 6, colStart: 7 } }}>
            <Grid cols="1" gap="2xl">
              {relatedItems.map(item => {
                const itemData = item.item || item;
                return (
                  <GridItem col="1" key={itemData.id}>
                    <PageCard
                      size="s"
                      view="row"
                      {...itemData}
                      link={
                        itemData.slug
                          ? generatePath(PATH.BLOG_SINGLE, {
                              slug: itemData.slug,
                              lang: locale === 'en' ? null : locale,
                            })
                          : null
                      }
                      linkText={itemData.detailLinkTitle || 'Read More'}
                    />
                  </GridItem>
                );
              })}
            </Grid>
          </GridItem>
        )}
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
