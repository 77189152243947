// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { ValuesCard } from '../../ValuesCard/ValuesCard';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const { type, title, text, items, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Title
          size="lg"
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        >
          {title}
        </Typography.Title>
      )}
      {text && (
        <Typography.Text
          size="l"
          dangerouslySetInnerHTML={{ __html: text }}
          className={cnContentBuilderTemplate('SubTitle')}
        />
      )}
      {items && items.length > 0 && (
        <Grid
          className={cnContentBuilderTemplate('Cards')}
          breakpoints={{
            desktop: { cols: 12, gap: 'xl' },
            tablet: { cols: 8, rowGap: '5xl' },
            mobile: { rowGap: '4xl', cols: 2 },
          }}
        >
          {items.map(item => (
            <GridItem
              breakpoints={{
                desktop: { col: 3 },
                tablet: { col: 4 },
                mobile: { col: 1 },
              }}
              key={item.id}
            >
              <ValuesCard
                title={item.title}
                number={item.number}
                subtitle={item.subtitle}
                text={item.texr}
                size="l"
                view="transparent"
              />
            </GridItem>
          ))}
        </Grid>
      )}
    </div>
  );
});

export default ContentBuilderTemplate;
