import { createReducer } from '@reduxjs/toolkit';
import { BREAKPOINT } from 'common/constants';
import { generate } from 'shortid';
import {
  setDictionaries,
  setScreenWidth,
  setMenu,
  setLanguages,
  setInitialData,
  setPageId,
  setMetaData,
  setModalData,
  setModal,
  setHeaderInverted,
  setShowFooterForm,
  setMetaTitlePage,
  setLandingFooter,
  setLandingBreadcrumbs,
} from './actions';

export const layoutReducer = createReducer<any>(
  {
    key: generate(),
    pageId: null,
    forceActivation: false,
    headerInverted: false,
    showFooterForm: false,
    metaTitlePage: null,
  },
  {
    [setDictionaries.type]: (state, action) => ({
      ...state,
      dictionaries: Object.assign(
        {},
        state.dictionaries,
        action.payload.companySite
      ),
    }),
    [setPageId.type]: (state, action) => ({
      ...state,
      pageId: action.payload,
    }),
    [setMenu.type]: (state, action) => ({
      ...state,
      menu: action.payload,
    }),
    [setLandingFooter.type]: (state, action) => ({
      ...state,
      footer: action.payload,
    }),
    [setLandingBreadcrumbs.type]: (state, action) => ({
      ...state,
      breadcrumbs: action.payload,
    }),
    [setLanguages.type]: (state, action) => ({
      ...state,
      languages: action.payload,
    }),
    [setInitialData.type]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [setMetaData.type]: (state, action) => ({
      ...state,
      page: action.payload,
    }),
    [setModalData.type]: (state, action) => ({
      ...state,
      modalData: action.payload,
    }),
    [setModal.type]: (state, action) => ({
      ...state,
      modal: action.payload,
    }),
    [setHeaderInverted.type]: (state, action) => ({
      ...state,
      headerInverted: action.payload,
    }),
    [setMetaTitlePage.type]: (state, action) => ({
      ...state,
      metaTitlePage: action.payload,
    }),
    [setShowFooterForm.type]: (state, action) => ({
      ...state,
      showFooterForm: action.payload,
    }),
  }
);

export const screenReducer = createReducer<any>(
  {
    width: 0,
    isMobile: false,
    isDesktop: false,
  },
  {
    [setScreenWidth.type]: (_, action) => ({
      width: action.payload,
      isMobile: action.payload <= BREAKPOINT.MOBILE,
      isDesktop: action.payload > BREAKPOINT.TABLET,
      isTablet:
        action.payload > BREAKPOINT.MOBILE &&
        action.payload <= BREAKPOINT.TABLET,
    }),
  }
);
