// @ts-nocheck
import './ClientCard.scss';

import React from 'react';
import { NavLink } from 'react-router-dom';

import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { cn } from '../../utils/bem';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography } from '@dataartdev/uikit/Typography';

export type Props = {
  logo: any;
  description?: string;
  btnText?: string;
};

export const cnClientCard = cn('ClientCard');

export const ClientCard = forwardRefWithAs<Props>(props => {
  const {
    logo,
    description,
    btnText = 'Learn more',
    routePath,
    isPageHidden,
  } = usePropsHandler(cnClientCard(), props);

  return (
    <div className={cnClientCard({ isPageHidden })}>
      {routePath && !isPageHidden && (
        <NavLink to={routePath} className={cnClientCard('OverlayLink')} />
      )}
      <div className={cnClientCard('Photo')}>
        {logo && <Picture {...logo} />}
      </div>
      {description && (
        <div className={cnClientCard('Inner')}>
          <div className={cnClientCard('Text')}>
            <Typography.Text size="s">{description}</Typography.Text>
          </div>
          <div className={cnClientCard('Button')}>
            <Button
              view="link-secondary"
              color="primary"
              iconRight={IconArrowRight}
              iconSize="m"
              label={btnText}
            />
          </div>
        </div>
      )}
    </div>
  );
});
