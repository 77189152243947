import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { DataQueryVariables } from 'common/hooks';
import { clearUrl } from 'common/utils';

export const querySingleService: DataQueryFunction = (
  variables: DataQueryVariables | undefined
): Promise<any> => {
  const { slug } = variables || {};

  return httpClient.get(clearUrl(`/services/${slug}`));
};
