// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { Typography } from '@dataartdev/uikit/Typography';
import { Article } from '../../Article/Article';
import { TestimonialCard } from '../../TestimonialCard/TestimonialCard';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const { type, title, text, testimonials, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  const options = {
    navigation: true,
    spaceBetween: 8,
    breakpoints: {
      767: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      1199: {
        spaceBetween: 24,
        slidesPerView: 3,
      },
    },
  };

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Title
          size="lg"
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        >
          {title}
        </Typography.Title>
      )}
      {text && (
        <Article
          content={text}
          className={cnContentBuilderTemplate('SubTitle')}
        />
      )}
      <Slider {...options}>
        {testimonials.map(item => (
          <SliderItem key={item.id}>
            <TestimonialCard {...item} />
          </SliderItem>
        ))}
      </Slider>
    </div>
  );
});

export default ContentBuilderTemplate;
