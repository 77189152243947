import './ContentBuilderTemplate.scss';
import './ContentBuilderTemplate105.scss';

import React from 'react';
import { cn } from '../../../utils/bem';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { cnContentBuilderTemplate } from './index';
import { ColoredCategoryCard } from '../../ColoredCategoryCard/ColoredCategoryCard';
import { Link } from '@dataartdev/uikit/Link';
import { IRoot105 } from '../models/CTI_105.props';

const cnCategoriesSection = cn('CategoriesSection');

export const ContentBuilderTemplate: React.FC<IRoot105> = props => {
  const { type, title, items, link, contentId, is3Columns } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  return (
    <div className={cnContentBuilderTemplate({ type: typeNumber })}>
      <div className={cnCategoriesSection()}>
        <Grid
          breakpoints={{
            desktop: { cols: 12, gap: 'xl' },
            tablet: { cols: 12, gap: 'xl' },
            mobile: { cols: 12, gap: 'xl' },
          }}
        >
          <GridItem
            breakpoints={{
              desktop: { col: 12 },
              tablet: { col: 12 },
              mobile: { col: 12 },
            }}
            className={cnCategoriesSection('Top')}
          >
            {title && (
              <Typography.Title size="lg" as="h2" parse id={contentId}>
                {title}
              </Typography.Title>
            )}
            {link?.link && (
              <Link href={link?.link} className={cnCategoriesSection('Link')}>
                {link.title}
              </Link>
            )}
          </GridItem>
          <GridItem
            breakpoints={{
              desktop: { col: 12 },
              tablet: { col: 12 },
              mobile: { col: 12 },
            }}
          >
            <Grid
              breakpoints={{
                desktop: { cols: 12, gap: 'xl' },
                tablet: { cols: 12, gap: 'xl' },
                mobile: { cols: 12, gap: 'xl' },
              }}
            >
              {items &&
                items.length > 0 &&
                items.map(item => (
                  <GridItem
                    key={item.id}
                    breakpoints={{
                      desktop: { col: is3Columns ? 4 : 3 },
                      tablet: { col: 6 },
                      mobile: { col: 12 },
                    }}
                  >
                    <ColoredCategoryCard {...item} />
                  </GridItem>
                ))}
            </Grid>
          </GridItem>
        </Grid>
      </div>
    </div>
  );
};

export default ContentBuilderTemplate;
