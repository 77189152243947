// @ts-nocheck
import './DiagramsBlock.scss';

import React from 'react';

import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { cn } from '../../utils/bem';

import { Typography } from '@dataartdev/uikit/Typography';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';

export type Props = {
  items?: any;
};

export const cnDiagramsBlock = cn('DiagramsBlock');

export const DiagramsBlock = forwardRefWithAs<Props>((props, ref) => {
  const { items, doubleArrows } = props || {};

  const options = {
    allowTouchMove: true,
    watchOverflow: false,
    navigation: true,
    spaceBetween: 24,
    slidesPerView: 1.25,
    breakpoints: {
      767: {
        slidesPerView: 2.25,
        spaceBetween: 24,
      },
      1199: {
        slidesPerView: 4,
      },
    },
  };

  return (
    <React.Fragment>
      <div className={cnDiagramsBlock()}>
        <Slider {...options}>
          {items.map(item => (
            <SliderItem key={item.id}>
              <div
                className={cnDiagramsBlock('Item', {
                  doubleArrows: item.doubleArrow || doubleArrows,
                })}
              >
                <div
                  className={cnDiagramsBlock('Head', {
                    doubleArrows: item.doubleArrow || doubleArrows,
                  })}
                >
                  <Typography.Text
                    size="m"
                    className={cnDiagramsBlock('Title')}
                    weight="bold"
                    as="h3"
                  >
                    {item.title}
                  </Typography.Text>
                </div>
                <div className={cnDiagramsBlock('List')}>
                  {item.items.map(i => (
                    <div className={cnDiagramsBlock('List-Item')} key={i}>
                      <Typography.Text
                        size="m"
                        className={cnDiagramsBlock('Text')}
                      >
                        {i}
                      </Typography.Text>
                    </div>
                  ))}
                </div>
              </div>
            </SliderItem>
          ))}
        </Slider>
      </div>
    </React.Fragment>
  );
});
