import './ContentBuilderTemplate.scss';

import React from 'react';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Picture } from '@dataartdev/uikit/Picture';
import { IRoot48 } from '../models/CTI_48.props';
import { Article } from '../../Article/Article';

export const ContentBuilderTemplate: React.FC<IRoot48> = props => {
  const {
    type,
    picture,
    text,
    title,
    isFullWidth,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();
  const columnWidth = isFullWidth ? 12 : 8;

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <Grid
        breakpoints={{
          desktop: { cols: 1, gap: '3xxl' },
          tablet: { cols: 1, gap: 'xxl' },
          mobile: { cols: 1, gap: '3xl' },
        }}
        className={cnContentBuilderTemplate('List-Text-Showcase')}
      >
        <GridItem
          breakpoints={{
            desktop: { col: 1 },
            tablet: { col: 1 },
            mobile: { col: 1 },
          }}
          className={cnContentBuilderTemplate('Showcase-Item')}
        >
          <Grid
            breakpoints={{
              desktop: { cols: columnWidth, gap: 'xl' },
              tablet: { cols: 12, gap: 'xl' },
              mobile: { cols: 12, gap: 'xl' },
            }}
          >
            <GridItem
              breakpoints={{
                desktop: { col: 4 },
                tablet: { col: 6 },
                mobile: { col: 12 },
              }}
            >
              {title && (
                <Typography.Title size="md" weight="bold" as="h2" parse>
                  {title}
                </Typography.Title>
              )}
              {text && (
                <Article
                  className={cnContentBuilderTemplate('Showcase-Content')}
                  content={text}
                />
              )}
            </GridItem>
            <GridItem
              breakpoints={{
                desktop: { col: 8 },
                tablet: { col: 6 },
                mobile: { col: 12 },
              }}
            >
              {picture && <Picture {...picture} />}
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </div>
  );
};

export default ContentBuilderTemplate;
