// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { Typography } from '@dataartdev/uikit/Typography';
import { Article } from '../../Article/Article';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { cn } from '../../../utils/bem';
import Marquee from 'react-fast-marquee';

export type Props = {
  type?: number;
};

const cnComponent = cn('Cards');

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { type, title, text, partners, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Title
          size="lg"
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        >
          {title}
        </Typography.Title>
      )}
      {text && (
        <Grid breakpoints={{ desktop: { cols: 12 } }}>
          <GridItem breakpoints={{ desktop: { col: 8 } }}>
            <Article
              content={text}
              className={cnContentBuilderTemplate('SubTitle')}
            />
          </GridItem>
        </Grid>
      )}

      <Grid
        breakpoints={{
          desktop: { cols: 12 },
          tablet: { cols: 8 },
          mobile: { cols: 2 },
        }}
        className={cnComponent({ hideTopBorder: partners.length <= 4 })}
      >
        {partners.map((partner, index) => {
          if (!isOpen && index > 7) return false;

          const componentProps = {
            ['data-badge-text' as string]: null,
          };

          if (partner.isNew) {
            componentProps.class = 'Partner_badge';
            componentProps.style = {
              ['--button-badge-color' as string]: partner.isNewColor,
              ['--button-badge-inverted' as string]: partner.isInverted
                ? '#fff'
                : null,
            };
            componentProps['data-badge-text'] = partner.isNewText;
          }

          return (
            <GridItem
              breakpoints={{
                desktop: { col: 3 },
                tablet: { col: 4 },
                mobile: { col: 1 },
              }}
              key={partner.id}
            >
              {partner.link ? (
                <a
                  href={partner.link}
                  target="_blank"
                  title={partner.title}
                  rel="noreferrer"
                  {...componentProps}
                >
                  <Picture {...partner.picture} />
                </a>
              ) : (
                <div className="WrapperPicture" {...componentProps}>
                  <Picture {...partner.picture} />
                </div>
              )}
            </GridItem>
          );
        })}
      </Grid>
      {partners?.length > 8 && !isOpen && (
        <Button
          onClick={() => setIsOpen(true)}
          view="borderY"
          width="full"
          size="l"
          data-text="View more"
        >
          <Marquee loop={100} speed={50}>
            {Array.from({ length: 50 }, (_, i) => (
              <Typography.Text
                key={i}
                as="span"
                weight="bold"
                transform="uppercase"
                size="xs"
              >
                View more
              </Typography.Text>
            ))}
          </Marquee>
        </Button>
      )}
    </div>
  );
});

export default ContentBuilderTemplate;
