import './SingleIndustries.scss';

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PATH, redirect404 } from 'router';
import { Link } from 'react-scroll';

import { useDataQuery, useGTMDataSend } from 'common/hooks';
import { querySingleIndustries, mutationMainForm } from 'network';

import { cn } from 'ui/utils/bem';

import { SiteBlock } from '../../../common/components/SiteBlock/SiteBlock';
import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';
import { Mq } from '@dataartdev/uikit/Mq';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Article } from '../../../common/components/Article/Article';
import { Button } from '@dataartdev/uikit/Button';
import { BackgroundDecor } from '@dataartdev/uikit/BackgroundDecor';
import { ContentBuilder } from 'common/components/ContentBuilder/ContentBuilder';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { IconSoon } from '@dataartdev/uikit/IconSoon';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';
import { useSelector } from 'react-redux';
import { dictionariesSelector } from 'store';
import { AnimatedCircle } from './AnimatedCircle';

const cnSingleIndustries = cn('SingleIndustries');

type Props = RouteComponentProps & {};

export const SingleIndustries: React.FC<Props> = ({
  match,
  history,
  location,
}) => {
  const { data, loading, error } =
    useDataQuery(querySingleIndustries, { slug: match.params.slug }) || {};
  const { companySiteForm: formContent } = useSelector(dictionariesSelector);
  const { companySiteCaseStudy: studyCard } = useSelector(dictionariesSelector);

  const {
    breadcrumbs,
    pageTitle,
    description,
    circleColor,
    circleText,
    circleValue,
    content,
    isInverted,
    aboutText,
    aboutTitle,
    aboutValues,
    links,
    aboutLink,
    metaTitle,
    tags,
    circles,
  } = data || {};

  useEffect(() => {
    if (error && error.response && error.response.status === 404) {
      redirect404(history);
    }
  }, [error, history]);

  // useGTMDataSend(location, metaTitle, tags);

  let offset = -120;

  if (window.innerWidth < 768) {
    offset = -80;
  }

  const [circle_1, circle_2, circle_3, ...otherCircles] = circles || [];

  return (
    <div className={cnSingleIndustries()}>
      {!loading && (
        <>
          <div className="container">
            <div className={cnSingleIndustries('Breadcrumbs')}>
              {breadcrumbs && (
                <Breadcrumbs
                  getLabel={page => page.text}
                  getLink={page => page.url}
                  history={history}
                  pages={breadcrumbs}
                />
              )}
            </div>
            <Typography.Title
              size="hg"
              className={cnSingleIndustries('Title')}
              as="h1"
              parse
            >
              {pageTitle}
            </Typography.Title>
            <Grid
              className={cnSingleIndustries('Head')}
              breakpoints={{
                desktop: { cols: 12, gap: 'xl' },
                tablet: { cols: 8, gap: '4xl' },
                mobile: { cols: 1, gap: 'xl' },
              }}
            >
              {description && (
                <GridItem
                  breakpoints={{ desktop: { col: 8 }, tablet: { col: 8 } }}
                >
                  <Article
                    content={description}
                    className={cnSingleIndustries('Text')}
                  />
                </GridItem>
              )}
              {links?.length > 0 && (
                <GridItem
                  breakpoints={{ desktop: { col: 6 }, tablet: { col: 5 } }}
                >
                  <Grid
                    breakpoints={{
                      desktop: { cols: 2, rowGap: 'm', colGap: 'xl' },
                      tablet: { cols: 1, gap: 'm' },
                      mobile: { cols: 2, gap: 'm' },
                    }}
                  >
                    {links.map(item => (
                      <GridItem col="1" key={item.id}>
                        <Button
                          className={cnSingleIndustries('Squad-Link')}
                          view="link-primary"
                          isLink={!!item.path}
                          to={item.path}
                          label={item.title}
                          disabled={!!!item.path}
                          iconRight={item.path ? IconArrowRight : IconSoon}
                          inline
                        />
                      </GridItem>
                    ))}
                  </Grid>
                </GridItem>
              )}
              <GridItem
                breakpoints={{
                  desktop: { col: 3, colStart: 10, row: 2, rowStart: 1 },
                  tablet: { col: 3, colStart: 6 },
                }}
              >
                {circles?.length > 0 && <AnimatedCircle circles={circles} />}
              </GridItem>
            </Grid>
          </div>
          <SiteBlock
            className={cnSingleIndustries('About')}
            topGap="6xl"
            bottomGap="4xl"
          >
            <BackgroundDecor view="right-triangle-down-0" />
            <div className="container">
              <Mq query="--tablet-max">
                {aboutTitle && (
                  <Typography.Title
                    size="md"
                    className={cnSingleIndustries('About-Title')}
                    as="h2"
                  >
                    {aboutTitle}
                  </Typography.Title>
                )}
              </Mq>
              <Grid
                breakpoints={{
                  desktop: { cols: 12, gap: 'xl' },
                  tablet: { cols: 2, gap: '6xl' },
                  mobile: { gap: '3xl' },
                }}
              >
                <GridItem breakpoints={{ desktop: { col: 6 } }}>
                  <Mq query="--desktop">
                    {aboutTitle && (
                      <Typography.Title
                        size="md"
                        className={cnSingleIndustries('About-Title')}
                        as="h2"
                      >
                        {aboutTitle}
                      </Typography.Title>
                    )}
                  </Mq>
                  {aboutText && (
                    <Article
                      className={cnSingleIndustries('About-Article')}
                      content={aboutText}
                    />
                  )}
                  {aboutLink && (
                    <Mq query="--tablet-min">
                      {aboutLink.link.includes('#') ? (
                        <Link
                          activeClass="active"
                          to="expert-form-01"
                          spy={false}
                          smooth={true}
                          offset={offset}
                          duration={800}
                        >
                          <Button
                            label={aboutLink.title}
                            view="outlined"
                            iconRight={IconArrowRight}
                            iconSize="s"
                            className={cnSingleIndustries('OutlineBtn')}
                          />
                        </Link>
                      ) : (
                        <Button
                          as={aboutLink.link.includes('http') && 'a'}
                          isLink={!aboutLink.link.includes('http')}
                          to={aboutLink.link}
                          href={aboutLink.link}
                          iconRight={IconArrowRight}
                          view="link-primary"
                          target={aboutLink.targetBlank ? '_blank' : null}
                          label={aboutLink.title}
                          decorBorder={circleColor}
                        />
                      )}
                    </Mq>
                  )}
                </GridItem>

                {aboutValues?.length > 0 && (
                  <GridItem breakpoints={{ desktop: { col: 5, colStart: 7 } }}>
                    <ul className={cnSingleIndustries('AboutValues')}>
                      {aboutValues.map(item => (
                        <li key={item}>
                          <Typography.Text>{item}</Typography.Text>
                        </li>
                      ))}
                    </ul>
                  </GridItem>
                )}
                {aboutLink && (
                  <Mq query="--mobile">
                    <GridItem col="1">
                      <Button
                        as="a"
                        href={aboutLink.link}
                        iconRight={IconArrowRight}
                        view="link-primary"
                        target={aboutLink.targetBlank ? '_blank' : null}
                        label={aboutLink.title}
                        decorBorder={circleColor}
                      />
                    </GridItem>
                  </Mq>
                )}
              </Grid>
            </div>
          </SiteBlock>
          {content?.length > 0 && (
            <div className={cnSingleIndustries('Content')}>
              <ContentBuilder
                items={content}
                aliases={PATH}
                studyCardBtnText={studyCard.companySiteCaseStudyCardText}
                recaptchaKey={recaptchaKey}
                onSubmitForm={async body =>
                  await mutationMainForm({
                    body: { ...body, pageId: data.id },
                    [`${recaptchaFormKey}`]: body.token,
                  })
                }
                pageId={data.id}
                dictionariesForm={{
                  success: {
                    title:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormLetsTalkSuccessTitle,
                    text:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormLetsTalkSuccessText,
                  },
                  addInfo: {
                    label:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormLetsTalkAddInfo,
                  },
                  company: {
                    label:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormLetsTalkCompany,
                  },
                  email: {
                    label: 'Email',
                    error:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormLetsTalkEmail
                        .companySiteFormLetsTalkEmailError,
                  },
                  name: {
                    label:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormLetsTalkName,
                  },
                  phone: {
                    label: 'Phone',
                    error:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormPhoneLetsTalkError,
                  },
                  required:
                    formContent.companySiteFormLetsTalk
                      .companySiteFormLetsTalkRequiredField,
                  button:
                    formContent.companySiteFormLetsTalk
                      .companySiteFormLetsTalkSubmit,
                  policy:
                    formContent.companySiteFormLetsTalk
                      .companySiteFormLetsTalkSubscribePolicy,
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
