// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-has-content */
import './Banner.scss';

import React from 'react';

import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { cn } from '../../utils/bem';

import { Typography } from '@dataartdev/uikit/Typography';
import { TypographyPropSize } from '@dataartdev/uikit/__internal__/cjs-src/components/Typography/Title/Title';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

export const bannerPropView = ['glassdoor', 'vacancy', 'section'] as const;
export type BannerPropView = typeof bannerPropView[number];
export const bannerPropViewDefault: BannerPropView = bannerPropView[0];

type Props = {
  view?: BannerPropView;
  title?: string;
  titleSize?: TypographyPropSize;
  text?: string;
  labelButton?: string;
};

const cnBanner = cn('Banner');

export const Banner = forwardRefWithAs<Props>((props, ref) => {
  const {
    view = bannerPropViewDefault,
    title,
    titleSize,
    labelButton,
    text,
    picture,
    pictureLabel,
    buttonIcon = IconArrowRight,
    buttonIconSize = 'm',
    buttonAttrs,
    onClick,
    bgColor,
    inverted,
    className,
    labelButtonSecondary,
    onClickSecondary,
    buttonAttrsSecondary,
    leftColor,
    rightColor,
    subtitle,
    targetBlank,
    link,
    list,
    ...otherProps
  } = props;

  return (
    <div
      className={cnBanner({ view, inverted }, [className])}
      style={{ backgroundColor: bgColor }}
      {...otherProps}
    >
      {/*{view === 'case' ? (*/}
      {/*  targetBlank ? (*/}
      {/*    <a*/}
      {/*      href={link}*/}
      {/*      target="_blank"*/}
      {/*      rel="noreferrer noopener"*/}
      {/*      className={cnBanner('Link')}*/}
      {/*    />*/}
      {/*  ) : null*/}
      {/*) : null}*/}
      <div
        className={
          picture ? 'Banner-Inner Banner-Inner_needPicture' : 'Banner-Inner'
        }
      >
        <Typography.Text
          className={cnBanner('SubTitle')}
          size="xs"
          weight="bold"
          transform="uppercase"
          as="h3"
        >
          {subtitle}
        </Typography.Text>
        <Typography.Title
          className={cnBanner('Title')}
          size={titleSize}
          as="h2"
        >
          {title}
        </Typography.Title>
        <div className={cnBanner('InnerContent')}>
          {text && (
            <Typography.Text className={cnBanner('Text')}>
              {text}
            </Typography.Text>
          )}
          {list && (
            <Typography.Text parse className={cnBanner('List')}>
              {list}
            </Typography.Text>
          )}
        </div>
        {labelButton && (
          <div className={cnBanner('Button')}>
            <Button
              view="link-secondary"
              iconRight={buttonIcon}
              iconSize={buttonIconSize}
              label={labelButton}
              onClick={e => {
                e.preventDefault();
                if (targetBlank) {
                  window.open(link, '_blank');
                } else {
                  window.location = link;
                }
              }}
              inverted={inverted}
              {...buttonAttrs}
            />
          </div>
        )}
        {labelButtonSecondary && (
          <div className={cnBanner('SecondaryButton')}>
            <Button
              view="link-secondary"
              iconRight={buttonIcon}
              iconSize={buttonIconSize}
              label={labelButtonSecondary}
              onClick={onClickSecondary}
              inverted={inverted}
              {...buttonAttrsSecondary}
            />
          </div>
        )}
      </div>
      {picture && (
        <div className={cnBanner('Picture')}>
          {pictureLabel && (
            <Typography.Text
              className={cnBanner('Picture-Label')}
              size="xs"
              weight="bold"
              transform="uppercase"
              as="span"
            >
              {pictureLabel}
            </Typography.Text>
          )}
          <Picture {...picture} />
        </div>
      )}
    </div>
  );
});
