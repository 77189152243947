import './GetInTouchForm.scss';

import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { schemaLetsTalk, schemaLetsTalkAll } from 'common/utils';
import { mutationMainForm } from 'network';
import { layoutSelector, dictionariesSelector } from 'store';

import { Button } from '@dataartdev/uikit/Button';
import { TextField } from '@dataartdev/uikit/TextField';
import { Pagination } from '@dataartdev/uikit/Pagination';
import { Typography } from '@dataartdev/uikit/Typography';
import { Checkbox } from '@dataartdev/uikit/Checkbox';
import { Modal } from '@dataartdev/uikit/Modal';
import { Form, FormBody } from '../Form';

import { IconEye } from '@dataartdev/uikit/IconEye';
import { IconEyeClosed } from '@dataartdev/uikit/IconEyeClosed';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { IconArrowLeft } from '@dataartdev/uikit/IconArrowLeft';

import { recaptchaKey, recaptchaFormKey } from 'common/constants';
import ReCAPTCHA from 'react-google-recaptcha';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { cn } from 'ui/utils/bem';

import sbjs from 'sourcebuster';
import { usePagePath } from '../../hooks/usePagePath';
sbjs.init();

type Props = {
  cnSelector?: any;
  title?: string;
  subTitle?: string;
  titleSize?: string;
  view?: string;
  showPrevButton?: boolean;
};

export const cnGetInTouchForm = cn('GetInTouchForm');

export const GetInTouchForm: React.FC<Props> = ({
  cnSelector = cnGetInTouchForm,
  titleSize = 'lg',
  view,
  showPrevButton,
  successModal,
  dictionaries,
}) => {
  const { pageId }: any = useSelector(layoutSelector) || {};
  const { companySiteForm: formContent } = useSelector(dictionariesSelector);

  const [currentStep, setCurrentStep] = useState(1);
  const [showAllFields, setShowAllFields] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const reCaptchaRef = useRef(null);

  const {
    title,
    subTitle,
    phone,
    email,
    required,
    name,
    submit,
    additionalInfo,
    toggleButton,
    company,
    policyField,
    success,
    trackId,
  } = dictionaries || {};

  const stepsTotal = 6;

  const schema = schemaLetsTalk(email?.error, required?.error);

  const schemaAll = schemaLetsTalkAll(email?.error, required?.error);

  const pagePath = usePagePath();

  const sendDataToGTM = useGTMDispatch();

  const cameFrom = `typ: ${sbjs.get.current.typ} | src: ${sbjs.get.current.src} | mdm: ${sbjs.get.current.mdm} | cmp: ${sbjs.get.current.cmp} | cnt: ${sbjs.get.current.cnt} | trm: ${sbjs.get.current.trm}`;
  const submitButtonID =
    formContent?.companySiteFormContactUs?.companySiteFormContactUsTrackingID;

  const onSubmit = async (values, actions) => {
    if (!showAllFields && currentStep < 6) {
      setCurrentStep(currentStep + 1);
      actions.validateForm();
    } else {
      const token = await reCaptchaRef.current.executeAsync();

      await mutationMainForm({
        body: {
          ...values,
          pageId: pageId,
          [`${recaptchaFormKey}`]: token,
          cameFrom: cameFrom,
          trackingId: submitButtonID,
        },
      })
        .then(() => {
          setSuccessForm(true);
          setCurrentStep(1);

          sendDataToGTM({
            event: 'send_form',
            form_name: trackId || 'contact_us_form',
            page_path: pagePath,
            tags: 'not set',
          });
        })
        .catch(() => alert('error, look at network'));

      actions.resetForm();
    }
  };

  const initialValues = {
    name: '',
    phone: '',
    company: '',
    email: '',
    additionalInfo: '',
    privacyAgreement: false,
  };

  return (
    <>
      {title && (
        <Typography.Title
          size={titleSize}
          className={cnSelector('Title')}
          as="h2"
        >
          {title}
        </Typography.Title>
      )}
      {subTitle && (
        <Typography.Text size="s" className={cnSelector('SubTitle')} parse>
          {subTitle}
        </Typography.Text>
      )}
      <Form
        enableReinitialize
        initialValues={initialValues}
        validationSchema={showAllFields ? schemaAll : schema[currentStep - 1]}
        onSubmit={onSubmit}
        validateOnBlur={false}
      >
        {({
          isSubmitting,
          errors,
          handleChange,
          handleBlur,
          values,
          setErrors,
          touched,
          dirty,
        }) => {
          return (
            <FormBody
              className={cnSelector('Form', { opened: showAllFields, view })}
            >
              <div className={cnSelector('Inner')}>
                <div className={cnSelector('Body')}>
                  {(currentStep === 1 || showAllFields) && (
                    <TextField
                      name="name"
                      value={values.name}
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      label={name?.label}
                      view="outline"
                      width="full"
                      caption={errors.name}
                      status={!!errors.name && 'alert'}
                    />
                  )}
                  {(currentStep === 2 || showAllFields) && (
                    <TextField
                      name="phone"
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values.phone}
                      label={phone?.label}
                      view="outline"
                      width="full"
                      type="tel"
                      caption={touched.phone && errors.phone}
                      status={!!errors.phone && touched.phone && 'alert'}
                    />
                  )}
                  {(currentStep === 3 || showAllFields) && (
                    <TextField
                      name="email"
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values.email}
                      label={email?.label}
                      view="outline"
                      width="full"
                      required
                      type="text"
                      caption={touched.email && errors.email}
                      status={!!errors.email && touched.email && 'alert'}
                    />
                  )}
                  {(currentStep === 4 || showAllFields) && (
                    <TextField
                      name="company"
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values.company}
                      label={company?.label}
                      view="outline"
                      width="full"
                      type="tel"
                      caption={touched.company && errors.company}
                      status={touched.company && !!errors.company && 'alert'}
                    />
                  )}
                  {(currentStep === 5 || showAllFields) && (
                    <TextField
                      name="additionalInfo"
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values.additionalInfo}
                      label={additionalInfo?.label}
                      view="outline"
                      width="full"
                      caption={touched.additionalInfo && errors.additionalInfo}
                      status={
                        touched.additionalInfo &&
                        !!errors.additionalInfo &&
                        'alert'
                      }
                    />
                  )}
                  {(currentStep === 6 || showAllFields) && (
                    <Checkbox
                      required
                      label={policyField?.label}
                      name="privacyAgreement"
                      onChange={({ e }) => handleChange(e)}
                      checked={values.privacyAgreement}
                    />
                  )}
                  {!showAllFields && (
                    <Pagination
                      className={cnSelector('Pagination')}
                      currentPage={currentStep}
                      totalPages={stepsTotal}
                      size="xs"
                      hideControls
                    />
                  )}
                </div>
                <div className={cnSelector('Bottom')}>
                  <div className={cnSelector('Toggle')}>
                    <Button
                      label={
                        showAllFields ? toggleButton?.hide : toggleButton?.show
                      }
                      view="link-secondary"
                      iconSize={'s'}
                      iconRight={showAllFields ? IconEyeClosed : IconEye}
                      onClick={() => setShowAllFields(!showAllFields)}
                    />
                  </div>
                  <div className={cnSelector('Button')}>
                    {!showAllFields && showPrevButton && currentStep > 1 && (
                      <Button
                        iconRight={IconArrowLeft}
                        onClick={() => {
                          setCurrentStep(currentStep - 1);
                          setErrors({});
                        }}
                      />
                    )}
                    <Button
                      id={currentStep === 6 ? submitButtonID : null}
                      iconRight={IconArrowRight}
                      disabled={
                        !!Object.keys(errors).length ||
                        isSubmitting ||
                        (showAllFields && !!Object.keys(errors).length) ||
                        (showAllFields && !dirty)
                      }
                      label={
                        showAllFields
                          ? submit?.default
                          : currentStep < 6
                          ? submit?.next
                          : submit?.default
                      }
                      type="submit"
                    />
                    <ReCAPTCHA
                      ref={reCaptchaRef}
                      sitekey={recaptchaKey}
                      size="invisible"
                    />
                  </div>
                </div>
              </div>
            </FormBody>
          );
        }}
      </Form>

      {successForm && (
        <>
          {successModal ? (
            <Modal
              view="secondary"
              hasOverlay
              onOpen={() => {
                document.body.style.overflow = 'hidden';
              }}
              onClose={() => {
                document.body.style.overflow = 'unset';
              }}
              isOpen={successForm}
              onOverlayClick={(): void => {
                setSuccessForm(false);
                document.body.style.overflow = 'unset';
              }}
              onEsc={(): void => {
                setSuccessForm(false);
                document.body.style.overflow = 'unset';
              }}
              showCloseButton
              inverted
            >
              <div className={cnGetInTouchForm('ModalSuccess')}>
                <Typography.Title
                  size="lg"
                  className={cnGetInTouchForm('Title')}
                  as="h2"
                >
                  {success.title}
                </Typography.Title>
                <Typography.Text className={cnGetInTouchForm('SubTitle')}>
                  {success.subtitle}
                </Typography.Text>
              </div>
            </Modal>
          ) : (
            <div className={cnSelector('Success')}>
              <Typography.Title
                size="lg"
                className={cnSelector('Title')}
                as="h2"
              >
                {success.title}
              </Typography.Title>
              <Typography.Text className={cnSelector('SubTitle')}>
                {success.subtitle}
              </Typography.Text>
            </div>
          )}
        </>
      )}
    </>
  );
};
