import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { DataQueryVariables } from 'common/hooks';
import { clearUrl } from 'common/utils';

const pageId = window.UMB_PAGE_ID;

export const queryPlainPage: DataQueryFunction = (
  variables: DataQueryVariables | undefined
): Promise<any> => {
  const { slug } = variables || {};
  return httpClient.get(clearUrl(`/landing/content/${pageId || '27081'}`));
};
