import './LocationsForm.scss';

import React from 'react';

import { GetInTouchForm } from 'common/components';

import { cn } from 'ui/utils/bem';

type Props = {};

const cnLocationsForm = cn('LocationsForm');

export const LocationsForm: React.FC<Props> = ({ dictionaries }) => {
  return (
    <div className={cnLocationsForm()}>
      <div className={cnLocationsForm('Inner')}>
        <GetInTouchForm
          cnSelector={cnLocationsForm}
          successModal
          showPrevButton
          dictionaries={{
            title: dictionaries.companySiteFormContactUsTitle,
            subTitle: dictionaries.companySiteFormContactUsText,
            phone: {
              label: 'Phone',
              error:
                dictionaries.companySiteFormContactUsPhone
                  .companySiteFormContactUsPhoneError,
            },
            email: {
              label: 'Email',
              error:
                dictionaries.companySiteFormContactUsEmail
                  .companySiteFormContactUsEmailError,
            },
            required: {
              error: dictionaries.companySiteFormContactUsRequiredField,
            },
            name: { label: dictionaries.companySiteFormContactUsName },
            submit: {
              default: dictionaries.companySiteFormContactUsSubmit,
              next: dictionaries.companySiteFormContactUsBtnText,
            },
            additionalInfo: {
              label: dictionaries.companySiteFormContactUsAddInfo,
            },
            toggleButton: {
              show: dictionaries.companySiteFormContactUsShowAll,
              hide: dictionaries.companySiteFormContactUsHideAll,
            },
            company: {
              label: dictionaries.companySiteFormContactUsCompany,
            },
            policyField: {
              label: dictionaries.companySiteFormContactUsSubscribePolicy,
            },
            success: {
              title: dictionaries.companySiteFormContactUsSuccessTitle,
              subtitle: dictionaries.companySiteFormContactUsSuccessText,
            },
          }}
        />
      </div>
    </div>
  );
};
