import './ContentBuilderTemplate.scss';

import React from 'react';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { Article } from '../../Article/Article';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { Button } from '@dataartdev/uikit/Button';
import { IconSoon } from '@dataartdev/uikit/IconSoon';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { IRoot18 } from '../models/CTI_18.props';
import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { FeaturedCard } from '../../FeaturedCard/FeaturedCard';

export const ContentBuilderTemplate = forwardRefWithAs<IRoot18>(props => {
  const { type, title, text, cases, links, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  const sliderConfig = {
    watchOverflow: true,
    navigation: true,
    slidesPerView: 1,
    spaceBetween: 8,
    breakpoints: {
      767: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      1200: {
        spaceBetween: 24,
        slidesPerView: 4,
      },
    },
  };

  return (
    <div className={cnContentBuilderTemplate({ type: typeNumber })}>
      {title && (
        <Typography.Title
          id={contentId}
          size="lg"
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        >
          {title}
        </Typography.Title>
      )}
      {text && (
        <Article
          content={text}
          className={cnContentBuilderTemplate('SubTitle')}
        />
      )}
      {cases && cases?.length > 0 && (
        <Slider {...sliderConfig}>
          {cases.map(item => (
            <SliderItem key={item.id}>
              <FeaturedCard {...item} />
            </SliderItem>
          ))}
        </Slider>
      )}
      {links && links?.length > 0 && (
        <div className={cnContentBuilderTemplate('Links')}>
          <div>
            {links.map(item => (
              <Button
                key={item.id}
                view="link-primary"
                iconRight={item.path ? IconArrowRight : IconSoon}
                isLink={!!item.path}
                disabled={!item.path}
                to={item.path ? item.path : null}
                label={item.title}
              ></Button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
});

export default ContentBuilderTemplate;
