import './ContentBuilderTemplate.scss';

import React from 'react';
import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { EssenceCard } from '../../EssenceCard/EssenceCard';
import { Button } from '@dataartdev/uikit/Button';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { prepareButtonAttrs } from '../../../utils/prepareButtonAttrs';
import { IRoot13 } from '../models/CTI_13';

export const ContentBuilderTemplate0 = forwardRefWithAs<IRoot13>(props => {
  const { type, title, videos, link, contentId, moreTitle } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  const options = {
    watchOverflow: true,
    navigation: true,
    spaceBetween: 24,
    slidesPerView: 1,
    breakpoints: {
      767: {
        slidesPerView: 'auto',
        spaceBetween: 24,
      },
      1199: {
        slidesPerView: 4,
      },
    },
  };

  const buttonAttrs = prepareButtonAttrs(
    link?.link,
    link?.title,
    link?.targetBlank
  );

  return (
    <div className={cnContentBuilderTemplate({ type: typeNumber })}>
      {title && (
        <Typography.Title
          id={contentId}
          size="lg"
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        >
          {title}
        </Typography.Title>
      )}
      {videos && videos?.length > 0 && (
        <Slider {...options}>
          {videos.map(video => (
            <SliderItem key={video.videoUrl}>
              <EssenceCard
                {...video}
                view="filled"
                linkTitle={video.videoSectionLinkText}
                youTubeEmbededUrl={video.videoUrl}
              />
            </SliderItem>
          ))}
          {link && (
            <SliderItem>
              <a
                href={link.link}
                target="_blank"
                className={cnContentBuilderTemplate('BlockCard')}
                rel="noreferrer"
              >
                <Typography.Title as="h3" weight="bold" size="tiny" parse>
                  {moreTitle}
                </Typography.Title>
                <Button
                  view="link-secondary"
                  inverted
                  size="m"
                  iconRight={IconArrowRight}
                  as="div"
                  {...buttonAttrs}
                />
              </a>
            </SliderItem>
          )}
        </Slider>
      )}
    </div>
  );
});

export default ContentBuilderTemplate0;
