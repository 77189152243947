// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { Typography } from '@dataartdev/uikit/Typography';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate0 = forwardRefWithAs<Props>((props, ref) => {
  const { type, title, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Title size="sm" as="h2">
          {title}
        </Typography.Title>
      )}
    </div>
  );
});

export default ContentBuilderTemplate0;
