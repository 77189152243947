import './SingleService.scss';

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PATH, redirect404 } from 'router';
import { dictionariesSelector } from 'store';

import { useDataQuery, useGTMDataSend } from 'common/hooks';
import {
  querySingleService,
  mutationMainForm,
  mutationFileForm,
} from 'network';

import { cn } from 'ui/utils/bem';

import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
// import { Article } from '../../Article/Article';
import { Article } from '../../../common/components/Article/Article';
import { ContentBuilder } from 'common/components/ContentBuilder/ContentBuilder';
import { Picture } from '@dataartdev/uikit/Picture';
import { CtaScrollerBtn } from 'common/components/CtaScrollerBtn';
import { useSelector } from 'react-redux';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';

const cnSingleService = cn('SingleService');

type Props = RouteComponentProps & {};

export const SingleService: React.FC<Props> = ({
  match,
  history,
  location,
}) => {
  const { companySiteForm: formContent } = useSelector(dictionariesSelector);

  const { data, loading, error } =
    useDataQuery(querySingleService, { slug: match.params.slug }) || {};

  const {
    breadcrumbs,
    title,
    text,
    headerLink,
    picture,
    content,
    metaTitle,
    tags,
  } = data || {};

  useEffect(() => {
    if (error && error.response && error.response.status === 404) {
      redirect404(history);
    }
  }, [error, history]);

  useGTMDataSend(location, metaTitle, tags);

  return (
    <div className={cnSingleService()}>
      {!loading && (
        <>
          <div className="container">
            <div className={cnSingleService('Breadcrumbs')}>
              <Breadcrumbs
                getLabel={page => page.text}
                getLink={page => page.url}
                history={history}
                pages={breadcrumbs}
              />
            </div>
            <Grid
              className={cnSingleService('Head')}
              breakpoints={{
                desktop: { cols: 12, gap: 'xl' },
                tablet: { cols: 8, gap: 'm' },
              }}
            >
              <GridItem
                breakpoints={{ desktop: { col: 8 }, tablet: { col: 5 } }}
              >
                <Typography.Title
                  size="hg"
                  className={cnSingleService('Title')}
                  as="h1"
                >
                  {title}
                </Typography.Title>
                <Article content={text} className={cnSingleService('Text')} />
                {headerLink && (
                  <div className={cnSingleService('Cta')}>
                    <CtaScrollerBtn
                      title={headerLink.title}
                      link={headerLink.link}
                    />
                  </div>
                )}
              </GridItem>
              {picture && (
                <GridItem
                  breakpoints={{
                    desktop: { col: 2, colStart: 10 },
                    tablet: { col: 3, colStart: 6 },
                  }}
                  className={cnSingleService('Picture')}
                >
                  <Picture
                    desktop={picture.url}
                    desktopWebp={picture.urlWebp}
                  />
                </GridItem>
              )}
            </Grid>
          </div>
          {content?.length > 0 && (
            <div className={cnSingleService('Content')}>
              <ContentBuilder
                dictionariesForm={{
                  success: {
                    title:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormLetsTalkSuccessTitle,
                    text:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormLetsTalkSuccessText,
                  },
                  addInfo: {
                    label:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormLetsTalkAddInfo,
                  },
                  company: {
                    label:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormLetsTalkCompany,
                  },
                  email: {
                    label: 'Email',
                    error:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormLetsTalkEmail
                        .companySiteFormLetsTalkEmailError,
                  },
                  name: {
                    label:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormLetsTalkName,
                  },
                  phone: {
                    label: 'Phone',
                    error:
                      formContent.companySiteFormLetsTalk
                        .companySiteFormPhoneLetsTalkError,
                  },
                  required:
                    formContent.companySiteFormLetsTalk
                      .companySiteFormLetsTalkRequiredField,
                  button:
                    formContent.companySiteFormLetsTalk
                      .companySiteFormLetsTalkSubmit,
                  submitButtonID:
                    formContent.companySiteFormLetsTalk
                      .companySiteFormLetsTalkTrackingID,
                  policy:
                    formContent.companySiteFormLetsTalk
                      .companySiteFormLetsTalkSubscribePolicy,
                }}
                items={content}
                aliases={PATH}
                recaptchaKey={recaptchaKey}
                onSubmitForm={async body =>
                  await mutationMainForm({
                    body: {
                      ...body,
                      [`${recaptchaFormKey}`]: body.token,
                      pageId: data.id,
                    },
                  })
                }
                onSubmitFileForm={body =>
                  mutationFileForm({
                    body: {
                      ...body,
                      pageId: data.id,
                      [`${recaptchaFormKey}`]: body.token,
                    },
                  })
                }
                pageId={data.id}
                CtaScrollerBtnLink="expert-form-01"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
