// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { Typography } from '@dataartdev/uikit/Typography';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate0 = forwardRefWithAs<Props>((props, ref) => {
  const { type, title, items, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Title
          size="lg"
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        >
          {title}
        </Typography.Title>
      )}
      {items?.length > 0 && (
        <div className={cnContentBuilderTemplate('List')}>
          {items.map(item => (
            <div key={item.id} className={cnContentBuilderTemplate('Item')}>
              <Typography.Title size="lg" as="h3">
                {item.title}
              </Typography.Title>
              {item.items?.length > 0 && (
                <div className="Article">
                  <ul className="styled-list inline-styled-list">
                    {item.items.map(item => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

export default ContentBuilderTemplate0;
