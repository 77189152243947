import './CaseStudyAdvantages.scss';

import React from 'react';

import { cn as classNames } from 'ui/utils/bem';
import { Typography } from '@dataartdev/uikit/Typography';

const cnCasePage = classNames('CasePage');

type Column = {
  id: string;
  title: string;
  subTitle: string;
  text: string;
};
type Props = {
  achievements: {
    id: string;
    color: string;
    columnI: Column;
    columnII: Column;
  };
};

export const CaseStudyAdvantages: React.FC<Props> = ({ achievements }) => {
  return (
    <>
      {achievements && (
        <div
          className={cnCasePage('Advantages')}
          style={{ backgroundColor: '#' + achievements.color }}
        >
          {achievements.columnI && (
            <div className={cnCasePage('Advantages-Column')}>
              {achievements.columnI.title && (
                <Typography.Title
                  size="lg"
                  className={cnCasePage('Advantages-Title')}
                  as="h2"
                >
                  {achievements.columnI.title}
                </Typography.Title>
              )}
              {achievements.columnI.subTitle && (
                <Typography.Title
                  size="tiny"
                  className={cnCasePage('Advantages-SubTitle')}
                  as="h2"
                >
                  {achievements.columnI.subTitle}
                </Typography.Title>
              )}
              {achievements.columnI.text && (
                <Typography.Title
                  size="tiny"
                  className={cnCasePage('Advantages-Text')}
                  as="h2"
                >
                  {achievements.columnI.text}
                </Typography.Title>
              )}
            </div>
          )}
          {achievements.columnII && (
            <div className={cnCasePage('Advantages-Column')}>
              {achievements.columnII.title && (
                <Typography.Title
                  size="lg"
                  className={cnCasePage('Advantages-Title')}
                  as="h2"
                >
                  {achievements.columnII.title}
                </Typography.Title>
              )}
              {achievements.columnII.subTitle && (
                <Typography.Title
                  size="tiny"
                  className={cnCasePage('Advantages-SubTitle')}
                  as="h2"
                >
                  {achievements.columnII.subTitle}
                </Typography.Title>
              )}
              {achievements.columnII.text && (
                <Typography.Title
                  size="tiny"
                  className={cnCasePage('Advantages-Text')}
                  as="h2"
                >
                  {achievements.columnII.text}
                </Typography.Title>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
