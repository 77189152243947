import { useState, useEffect } from 'react';
import { DataQueryFunction } from 'network';
import { DataQueryOptions, QueryFilter } from './models';
import { AxiosError } from 'axios';

export const useDataQuery = (
  query: DataQueryFunction | null,
  variables: object | null = null,
  options?: DataQueryOptions
) => {
  const { filter: varFilter, pagination: varPagination }: any = variables || {};
  const { page: varPage } = varPagination || {};
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [params, setParams] = useState<{
    pagination: {
      page: number;
    };
    filter: QueryFilter;
  }>({
    pagination: {
      page: varPage || 1,
    },
    filter: varFilter || {},
  });

  const { skip = null } = options || {};

  const fetchData = () => {
    if (skip || !query) {
      setLoading(false);
      return;
    }
    setLoading(true);
    query({
      ...variables,
      ...params,
    })
      .then(res => {
        setResponse(res);
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  };

  const refetch = () => {
    if (!loading) fetchData();
  };

  const updateFilter = (newFilter: QueryFilter) => {
    setParams(state => ({
      pagination: {
        ...state.pagination,
        page: 1,
      },
      filter: { ...state.filter, ...newFilter },
    }));

    setLoading(true);
  };

  const updateData = (data: any) => {
    setResponse((res: any) => ({ ...res, data }));
  };

  useEffect(() => {
    fetchData();
  }, [query, skip, params]); // eslint-disable-line

  return {
    response,
    data: (response && response.data) || null,
    totals: (response && response.data && response.data?.page?.totalPages) || 0,
    loading,
    error,
    filter: params.filter,
    refetch,
    updateData,
    updateFilter,
  };
};
