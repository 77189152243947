import './AllCasesPage.scss';

import React, { useState } from 'react';
import { useDataPagination } from 'common/hooks';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import { useInfiniteScroll } from '@dataartdev/uikit/useInfiniteScroll';

import { useSelector } from 'react-redux';
import { dictionariesSelector } from 'store';
import { PATH } from 'router';
import { queryAllCasesFeed } from '../../../network/api/allCases/queryAllCasesFeed';

import { cn } from 'ui/utils/bem';

import { SiteBlock } from '../../../common/components/SiteBlock/SiteBlock';
import { CaseCard } from '../../../common/components/CaseCard/CaseCard';
import { Loader } from '@dataartdev/uikit/Loader';
import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Banner } from '../../../common/components';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { TestimonialCard } from '../../../common/components/TestimonialCard/TestimonialCard';
import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';

const cnAllCasesPage = cn('AllCasesPage');

type Props = RouteComponentProps & {
  data: any;
};

export const AllCasesPage: React.FC<Props> = ({
  match,
  history,
  location,
  data,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { companySiteAllCasesStudy }: any =
    useSelector(dictionariesSelector) || {};

  const {
    companySiteAllCasesStudyLinkLabel: linkLabel,
    companySiteAllCasesStudyBadgeLabel: badgeLabel,
  } = companySiteAllCasesStudy || {};

  const {
    isShortCasesPage,
    breadcrumbs,
    content,
    contentType,
    id,
    isIndustryParent,
    metaTitle,
    nodeLanguages,
    popupForm,
    showFooterForm,
    title,
    newsInMedia,
  } = data || {};

  //===================================START INFINITY SCROLL=====================================

  const {
    pagination,
    updatePagination,
    data: dataFeed,
    loading: loadingFeed,
    error,
  } = useDataPagination(queryAllCasesFeed, null, {
    skip: currentPage < 2,
  });

  const [sentryRef] = useInfiniteScroll({
    loading: loadingFeed,
    hasNextPage: pagination?.page < newsInMedia?.pagesTotal,
    onLoadMore: () => {
      updatePagination({ page: pagination.page + 1 });
    },
    disabled: !!error,
  });

  //===================================STOP INFINITY SCROLL=====================================

  return (
    <>
      <div className={cnAllCasesPage()}>
        <div className="container">
          <div className={cnAllCasesPage('Breadcrumbs')}>
            {breadcrumbs && (
              <Breadcrumbs
                getLabel={page => page.text}
                getLink={page => page.url}
                history={history}
                pages={data.breadcrumbs}
              />
            )}
          </div>
          {!isShortCasesPage && (
            <Grid
              breakpoints={{
                desktop: { cols: 12, gap: 'xl' },
                tablet: { cols: 8, gap: 'xl' },
                mobile: { cols: 4, gap: 'xl' },
              }}
              className={cnAllCasesPage('Content')}
            >
              {content.length > 0 &&
                content.map(item => {
                  return item.quote ? (
                    <GridItem
                      breakpoints={{
                        desktop: { col: 8 },
                        tablet: { col: 8 },
                        mobile: { col: 4 },
                      }}
                    >
                      <TestimonialCard
                        {...item.author}
                        quote={item.quote}
                        link={
                          item?.externalCase
                            ? {
                                title: item.externalCase.linkTitle,
                                link: item.externalCase.link,
                                targetBlank: item.externalCase.targetBlank,
                              }
                            : {
                                link: generatePath(PATH.CASE_PAGE, {
                                  slug: item.case.slug,
                                }),
                                title: item.case.buttonTitle,
                              }
                        }
                      />
                    </GridItem>
                  ) : item?.externalCase ? (
                    <GridItem
                      breakpoints={{
                        desktop: { col: 4 },
                        tablet: { col: 4 },
                        mobile: { col: 4 },
                      }}
                    >
                      <CaseCard
                        {...item.externalCase}
                        shortDescription={item.externalCase.text}
                        buttonTitle={
                          item.externalCase.linkTitle || 'case Study'
                        }
                        buttonIcon={IconOpenInNew}
                        routePath={generatePath(item?.externalCase.link)}
                      />
                    </GridItem>
                  ) : item.case?.slug ? (
                    <GridItem
                      breakpoints={{
                        desktop: { col: 4 },
                        tablet: { col: 4 },
                        mobile: { col: 4 },
                      }}
                    >
                      <CaseCard
                        {...item.case}
                        buttonTitle={item.case.buttonTitle || 'case Study'}
                        routePath={generatePath(PATH.CASE_PAGE, {
                          slug: item.case.slug,
                        })}
                      />
                    </GridItem>
                  ) : null;
                })}
            </Grid>
          )}
          {isShortCasesPage && (
            <Grid
              breakpoints={{
                desktop: { cols: 12, gap: 'xl' },
                tablet: { cols: 8, gap: 'xl' },
                mobile: { cols: 4, gap: 'xl' },
              }}
              className={cnAllCasesPage('Content')}
            >
              {content.length > 0 &&
                content.map(item => (
                  <GridItem
                    breakpoints={{
                      desktop: { col: 12 },
                      tablet: { col: 8 },
                      mobile: { col: 4 },
                    }}
                  >
                    {item.case?.slug ? (
                      <Banner
                        title={item?.case?.title}
                        text={item?.case?.shortDescription}
                        view="case"
                        bgColor="#fff"
                        inverted={item.isInverted}
                        titleSize="md"
                        iconRight={IconArrowRight}
                        buttonIconSize="m"
                        picture={item?.case?.caseCropper || item?.case?.logo}
                        className={cnAllCasesPage('Item')}
                        pictureLabel={
                          item?.newCaseBadge && (badgeLabel || 'new case')
                        }
                        link={item?.case?.link}
                        labelButton={linkLabel || 'case Study'}
                        buttonAttrs={{
                          isLink: 'isLink',
                          to: generatePath(PATH.CASE_PAGE, {
                            slug: item?.case?.slug,
                          }),
                        }}
                      />
                    ) : (
                      <Banner
                        {...item.externalCase}
                        view="case"
                        className={cnAllCasesPage('Item')}
                        titleSize="md"
                        bgColor={item?.externalCase.color}
                        labelButton={
                          item.externalCase.linkTitle || 'case Study'
                        }
                        buttonAttrs={{
                          as: 'a',
                          href: generatePath(item?.externalCase.link),
                          target: item?.externalCase.targetBlank
                            ? '_blank'
                            : '_self',
                        }}
                        buttonIcon={IconOpenInNew}
                        buttonIconSize="s"
                      />
                    )}
                  </GridItem>
                ))}
            </Grid>
          )}
        </div>
        {loadingFeed && (
          <SiteBlock topGap="xxl">
            <Loader />
          </SiteBlock>
        )}
        {newsInMedia?.pagesTotal > currentPage && <div ref={sentryRef} />}
      </div>
    </>
  );
};
