import './LandingFooterLong.scss';

import React from 'react';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Typography } from '@dataartdev/uikit/Typography';
import { SocialButton } from '@dataartdev/uikit/SocialButton';
import { Picture } from '@dataartdev/uikit/Picture';
import { FooterLong } from './models/footer_long';
import { cn } from 'ui/utils/bem';

const cnLandingFooterLong = cn('LandingFooterLong');

export const LandingFooterLong: React.FC<FooterLong> = props => {
  const {
    contactsLink,
    contactsTitle,
    copyright,
    logo,
    socialLinks,
    text,
    logoLink,
  } = props || {};

  return (
    <footer className={cnLandingFooterLong()}>
      <div className="container">
        <Grid
          breakpoints={{
            desktop: { cols: 12, colGap: 'xl', rowGap: '2xl' },
            tablet: { cols: 12, colGap: 'xl', rowGap: '2xl' },
            mobile: { cols: 12, gap: 'xl', rowGap: '2xl' },
          }}
        >
          {logo && (
            <GridItem
              breakpoints={{
                desktop: { col: 12 },
                tablet: { col: 12 },
                mobile: { col: 12 },
              }}
            >
              <a
                className={cnLandingFooterLong('Logo')}
                href={logoLink || '#'}
                target="_blank"
              >
                <Picture {...logo} />
              </a>
            </GridItem>
          )}
          {text && (
            <GridItem
              breakpoints={{
                desktop: { col: 3 },
                tablet: { col: 6 },
                mobile: { col: 12 },
              }}
              className={cnLandingFooterLong('Info')}
            >
              <Typography.Text as="span" size="s">
                {text}
              </Typography.Text>
            </GridItem>
          )}
          <GridItem
            breakpoints={{
              desktop: { col: 3, colStart: 10 },
              tablet: { col: 6 },
              mobile: { col: 12 },
            }}
            className={cnLandingFooterLong('Contacts')}
          >
            {contactsTitle && (
              <Typography.Text
                as="span"
                size="xs"
                weight="bold"
                transform="uppercase"
              >
                {contactsTitle}
              </Typography.Text>
            )}
            {contactsLink?.link && (
              <a
                className={cnLandingFooterLong('Email')}
                href={contactsLink?.link}
              >
                <Typography.Text as="span" size="m" weight="bold">
                  {contactsLink?.title}
                </Typography.Text>
              </a>
            )}
            {socialLinks && (
              <div className={cnLandingFooterLong('Socials')}>
                {socialLinks?.map(social => (
                  <SocialButton
                    key={social.id}
                    as="a"
                    href={social.link}
                    icon={() => <Picture {...social.icon} />}
                  />
                ))}
              </div>
            )}
          </GridItem>
          {copyright && (
            <GridItem
              breakpoints={{
                desktop: { col: 12 },
                tablet: { col: 12 },
                mobile: { col: 12 },
              }}
              className={cnLandingFooterLong('Copyright')}
            >
              <Typography.Text as="span" size="s" parse>
                {copyright}
              </Typography.Text>
            </GridItem>
          )}
        </Grid>
      </div>
    </footer>
  );
};
