// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { Typography } from '@dataartdev/uikit/Typography';
import { Article } from '../../Article/Article';
import { CollapseGroup } from '@dataartdev/uikit/CollapseGroup';
import { Picture } from '@dataartdev/uikit/Picture';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { CtaScrollerBtn } from '../../CtaScrollerBtn';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const {
    type,
    title,
    titleTag,
    text,
    questions,
    picture,
    CtaScrollerBtnLink,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Title size="lg" as="h2">
          {title}
        </Typography.Title>
      )}
      {text && <Article content={text} />}

      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: 'xl', yAlign: 'bottom' },
          tablet: { cols: 8 },
        }}
      >
        {questions && (
          <GridItem breakpoints={{ desktop: { col: 8 }, tablet: { col: 8 } }}>
            <CollapseGroup
              hoverEffect
              size="m"
              items={questions}
              getItemLabel={item => item.question ?? item.title}
              getItemContent={item => {
                return (
                  <>
                    <Article content={item.answer ?? item.text} />
                    {item.button && (
                      <div
                        className={cnContentBuilderTemplate('CtaScrollerBtn')}
                      >
                        <CtaScrollerBtn
                          isPopup={item.isPopup}
                          title={item.button?.title || 'Get in Touch'}
                          link={CtaScrollerBtnLink}
                        />
                      </div>
                    )}
                  </>
                );
              }}
            />
          </GridItem>
        )}
        {picture && (
          <GridItem
            breakpoints={{
              desktop: { col: 2, colStart: 11 },
              tablet: { col: 8 },
            }}
          >
            <Picture desktop={picture.url} desktopWebp={picture.urlWebp} />
          </GridItem>
        )}
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
