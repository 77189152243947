import './NewsRoomPage.scss';

import React, { useState, useEffect } from 'react';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import Sticky from 'react-stickynode';

import {
  queryNewsRoomPage,
  queryNewsRoomFeed,
  queryNewsRoomPressFeed,
} from 'network';
import { useDataQuery, useDataPagination, useGTMDataSend } from 'common/hooks';
import { cn } from 'ui/utils/bem';
import { isDesktopSelector } from 'store';

import { SiteBlock } from '../../../common/components/SiteBlock/SiteBlock';
import { NewsCard } from '../../../common/components/NewsCard/NewsCard';
import { Typography } from '@dataartdev/uikit/Typography';
import { Loader } from '@dataartdev/uikit/Loader';
import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { useSelector } from 'react-redux';
import { dictionariesSelector } from 'store';
import { ExternalCard } from '../../../common/components/ExternalCard/ExternalCard';
import { PATH } from 'router';
import { useInfiniteScroll } from '@dataartdev/uikit/useInfiniteScroll';
import { Pagination } from '@dataartdev/uikit/Pagination';
import { useIntl } from 'react-intl';

const cnNewsRoomPage = cn('NewsRoomPage');

type Props = RouteComponentProps & {};

export const NewsRoomPage: React.FC<Props> = ({ history, location }) => {
  const isDesktop = useSelector(isDesktopSelector);
  const { data, loading } = useDataQuery(queryNewsRoomPage);
  const { companySiteNewsroom: staticContent }: any =
    useSelector(dictionariesSelector) || {};
  const {
    title,
    breadcrumbs,
    newsInMedia,
    newsAndPressRelease,
    metaTitle,
    tags,
  } = data || {};

  const [items, setItems] = useState<any>([]);
  const [itemsPress, setItemsPress] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pressCurrentPage, setPressCurrentPage] = useState<number>(1);

  const { locale } = useIntl();

  const {
    updatePagination: updatePaginationPress,
    data: dataPressFeed,
  } = useDataPagination(queryNewsRoomPressFeed, null, {
    skip: pressCurrentPage < 2,
  });

  const {
    pagination,
    updatePagination,
    data: dataFeed,
    loading: loadingFeed,
    error,
  } = useDataPagination(queryNewsRoomFeed, null, {
    skip: currentPage < 2,
  });

  useEffect(() => {
    if (data) {
      setItems(prev => [...prev, ...newsInMedia?.items]);
      setItemsPress(newsAndPressRelease?.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsInMedia]);

  useEffect(() => {
    if (dataPressFeed) {
      setItemsPress(dataPressFeed.items);
    }
  }, [dataPressFeed]);

  useEffect(() => {
    if (dataFeed) {
      setItems(prev => [...prev, ...dataFeed.items]);
    }
  }, [dataFeed]);

  useEffect(() => {
    setCurrentPage(pagination.page);
  }, [pagination]);

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [loadingFeed]);

  const [sentryRef] = useInfiniteScroll({
    loading: loadingFeed,
    hasNextPage: pagination?.page < newsInMedia?.pagesTotal,
    onLoadMore: () => {
      updatePagination({ page: pagination.page + 1 });
    },
    disabled: !!error,
  });

  const paginationChangePage = (pageNumber: number): void => {
    setPressCurrentPage(pageNumber);
    updatePaginationPress({ page: pageNumber });
  };

  useGTMDataSend(location, metaTitle, tags);

  return (
    <React.Fragment>
      <div className={cnNewsRoomPage()}>
        <div className="container">
          <div className={cnNewsRoomPage('Breadcrumbs')}>
            {breadcrumbs && (
              <Breadcrumbs
                getLabel={page => page.text}
                getLink={page => page.url}
                history={history}
                pages={data.breadcrumbs}
              />
            )}
          </div>
          {title && (
            <Typography.Title
              className={cnNewsRoomPage('Title')}
              size="lg"
              as="h1"
              parse
            >
              {title}
            </Typography.Title>
          )}

          <Grid
            breakpoints={{
              desktop: { cols: 12, gap: 'xl' },
              tablet: { cols: 8, rowGap: '3xxl' },
              mobile: { cols: 1, rowGap: '3xxl' },
            }}
          >
            {newsAndPressRelease?.items?.length > 0 && (
              <GridItem
                breakpoints={{ desktop: { col: 4 }, tablet: { col: 8 } }}
              >
                <Sticky
                  enabled={isDesktop}
                  top={110}
                  bottomBoundary={'.NewsRoomPage'}
                >
                  <Typography.Text
                    size="xs"
                    weight="bold "
                    transform="uppercase"
                    className={cnNewsRoomPage('SectionTitle')}
                    as="h2"
                  >
                    {staticContent.companySiteNewsroomSliderTitle}
                  </Typography.Text>
                  <div className={cnNewsRoomPage('ListSlider')}>
                    <Grid cols="1" gap="3xl">
                      {itemsPress.map(item => (
                        <GridItem col="1" key={item.id}>
                          <NewsCard
                            {...item}
                            size="s"
                            path={
                              item.parentSlug && item.slug
                                ? generatePath(PATH.NEWSROOM_PAGE, {
                                    parentSlug: item.parentSlug,
                                    slug: item.slug,
                                    lang: locale === 'en' ? null : locale,
                                  })
                                : null
                            }
                            picture={false}
                            onClick={() => {
                              history.push(PATH.NEWSROOM_PAGE, {
                                parentSlug: item.parentSlug,
                                slug: item.slug,
                              });
                            }}
                          />
                        </GridItem>
                      ))}
                    </Grid>
                    <div className={cnNewsRoomPage('ListSliderPagination')}>
                      <Pagination
                        currentPage={pressCurrentPage}
                        totalPages={newsAndPressRelease.pagesTotal}
                        size="xs"
                        onChange={paginationChangePage}
                      />
                    </div>
                  </div>
                </Sticky>
              </GridItem>
            )}
            {!loading && items?.length > 0 && (
              <GridItem
                breakpoints={{ desktop: { col: 8 }, tablet: { col: 8 } }}
              >
                <Typography.Text
                  size="xs"
                  weight="bold "
                  transform="uppercase"
                  className={cnNewsRoomPage('SectionTitle')}
                  as="h2"
                >
                  {staticContent.companySiteNewsroomInMediaTitle}
                </Typography.Text>

                <Grid cols="1" className={cnNewsRoomPage('ListBorder')}>
                  {items.map((item, index) => (
                    <GridItem col="1" key={item.id}>
                      {item.contentType === 0 && (
                        <NewsCard
                          {...item}
                          labelButton={
                            staticContent.companySiteNewsroomReadMoreText
                          }
                          path={
                            item.parentSlug && item.slug
                              ? generatePath(PATH.NEWSROOM_PAGE, {
                                  parentSlug: item.parentSlug,
                                  slug: item.slug,
                                  lang: locale === 'en' ? null : locale,
                                })
                              : null
                          }
                        />
                      )}
                      {item.contentType === 1 && <ExternalCard {...item} />}
                    </GridItem>
                  ))}
                </Grid>
                {loadingFeed && (
                  <SiteBlock topGap="xxl">
                    <Loader />
                  </SiteBlock>
                )}
                {newsInMedia?.pagesTotal > currentPage && (
                  <div ref={sentryRef} />
                )}
              </GridItem>
            )}
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};
