// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';
import { generatePath } from 'react-router-dom';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { Typography } from '@dataartdev/uikit/Typography';
import { Article } from '../../Article/Article';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { ListLinks } from '@dataartdev/uikit/ListLinks';
import { Button } from '@dataartdev/uikit/Button';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const {
    type,
    title,
    text,
    industry,
    aliases,
    columnI,
    columnII,
    industryColor,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <Typography.Title
        size="lg"
        className={cnContentBuilderTemplate('Title')}
        as="h2"
      >
        {title}
      </Typography.Title>
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: 'xl' },
          tablet: { cols: 8, rowGap: '3xl', colGap: 'm' },
          mobile: { cols: 2, gap: '3xl' },
        }}
      >
        {text && (
          <GridItem
            breakpoints={{
              desktop: { col: 4 },
              tablet: { col: 8 },
              mobile: { col: 2 },
            }}
            className={cnContentBuilderTemplate('Column')}
          >
            <Article content={text} />

            {industry && (
              <div className={cnContentBuilderTemplate('Button')}>
                <Button
                  view="link-primary"
                  isLink
                  label={industry.title}
                  to={generatePath(aliases.INDUSTRIES_PAGE, {
                    slug: industry.slug,
                  })}
                  iconRight={IconArrowRight}
                  decorBorder={industryColor}
                />
              </div>
            )}
          </GridItem>
        )}
        {columnI?.length > 0 && (
          <GridItem
            breakpoints={{
              desktop: { col: 4 },
              tablet: { col: 4 },
              mobile: { col: 2 },
            }}
          >
            <ListLinks
              items={columnI}
              getLabel={item => item.title}
              getPath={item =>
                generatePath(aliases.INDUSTRIES_PAGE_INNER, {
                  slug: industry.slug,
                  inner_page: item.slug,
                })
              }
            />
          </GridItem>
        )}
        {columnII?.length > 0 && (
          <GridItem
            breakpoints={{
              desktop: { col: 4 },
              tablet: { col: 4 },
              mobile: { col: 2 },
            }}
          >
            <ListLinks
              items={columnII}
              getLabel={item => item.title}
              getPath={item =>
                generatePath(aliases.INDUSTRIES_PAGE_INNER, {
                  slug: industry.slug,
                  inner_page: item.slug,
                })
              }
            />
          </GridItem>
        )}
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
