import './ContentBuilderTemplate.scss';
import './ContentBuilderTemplate60.scss';

import React from 'react';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { cnContentBuilderTemplate } from './index';
import { cn } from '@bem-react/classname';
import { Button } from '@dataartdev/uikit/Button';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography } from '@dataartdev/uikit/Typography';
import { prepareButtonAttrs } from '../../../utils/prepareButtonAttrs';
import { CtaScrollerBtn } from '../../CtaScrollerBtn';
import { IRoot60 } from '../models/CTI_60.props';

export const ContentBuilderTemplate: React.FC<IRoot60> = props => {
  const { type, title, text, button, picture, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );
  const typeNumber = type.toString();

  const cnHeaderSquarePictureSection = cn('HeaderSquarePictureSection');

  const buttonAttr = prepareButtonAttrs(
    button?.link,
    button?.title,
    button?.targetBlank
  );
  const buttonView = button?.color === 'blue' ? 'primary' : 'secondary';

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: 'xl' },
          tablet: { cols: 12, gap: '3xl' },
          mobile: { cols: 12 },
        }}
        className={cnHeaderSquarePictureSection()}
      >
        <GridItem
          className={cnHeaderSquarePictureSection('Content')}
          breakpoints={{
            desktop: { col: 6 },
            tablet: { col: 6 },
            mobile: { col: 12 },
          }}
        >
          <div className={cnHeaderSquarePictureSection('Content-Inner')}>
            {title && (
              <Typography.Title size="lg" parse>
                {title}
              </Typography.Title>
            )}
            {text && (
              <Typography.Text
                className={cnHeaderSquarePictureSection('Description')}
                size="m"
                parse
              >
                {text}
              </Typography.Text>
            )}
            {Object.keys(buttonAttr).length > 0 && (
              <div className={cnHeaderSquarePictureSection('Button')}>
                {'isCTA' in buttonAttr ? (
                  <CtaScrollerBtn {...buttonAttr} view={buttonView} />
                ) : (
                  <Button
                    iconRight={IconArrowRight}
                    {...buttonAttr}
                    view={buttonView}
                  />
                )}
              </div>
            )}
          </div>
        </GridItem>
        {picture && (
          <GridItem
            className={cnHeaderSquarePictureSection('Image')}
            breakpoints={{
              desktop: { col: 6 },
              tablet: { col: 6 },
              mobile: { col: 12 },
            }}
          >
            <Picture {...picture} />
          </GridItem>
        )}
      </Grid>
    </div>
  );
};

export default ContentBuilderTemplate;
