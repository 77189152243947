import './IndustriesPage.scss';

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PATH } from 'router';

import { useDataQuery, useGTMDataSend } from 'common/hooks';
import {
  queryIndustriesPage,
  mutationMainForm,
  mutationFileForm,
} from 'network';

import { cn } from 'ui/utils/bem';

import { SiteBlock } from '../../../common/components/SiteBlock/SiteBlock';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { ContentBuilder } from 'common/components/ContentBuilder/ContentBuilder';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';
import { dictionariesSelector } from 'store';
import { useSelector } from 'react-redux';

import sbjs from 'sourcebuster';

sbjs.init();

const cnIndustriesPage = cn('IndustriesPage');

type Props = RouteComponentProps & {};

export const IndustriesPage: React.FC<Props> = ({ location }) => {
  const { data, loading } = useDataQuery(queryIndustriesPage) || {};
  const { title, content, metaTitle, tags } = data || {};
  const { companySiteForm: formContent } = useSelector(dictionariesSelector);

  useGTMDataSend(location, metaTitle, tags);

  const cameFrom = `typ: ${sbjs.get.current.typ} | src: ${sbjs.get.current.src} | mdm: ${sbjs.get.current.mdm} | cmp: ${sbjs.get.current.cmp} | cnt: ${sbjs.get.current.cnt} | trm: ${sbjs.get.current.trm}`;
  const submitButtonID =
    formContent?.companySiteFormDownloadFile
      ?.companySiteFormDownloadFileTrackingID || 'file_download';

  return (
    <div className={cnIndustriesPage()}>
      {!loading && (
        <>
          <SiteBlock
            className={cnIndustriesPage('TopBlock')}
            breakpoints={{
              desktop: {
                topGap: '7xl',
                bottomGap: '2xl',
              },
              tablet: {
                topGap: '3xl',
                bottomGap: '6xl',
              },
              mobile: {
                topGap: '3xl',
              },
            }}
          >
            <div className="container">
              <Grid
                breakpoints={{
                  desktop: { cols: 12 },
                  tablet: { cols: 8 },
                  mobile: { cols: 2 },
                }}
              >
                <GridItem
                  breakpoints={{
                    desktop: { col: 10 },
                    tablet: { col: 7 },
                    mobile: { col: 2 },
                  }}
                >
                  <Typography.Title size="lg" as="h1" parse>
                    {title}
                  </Typography.Title>
                </GridItem>
              </Grid>
            </div>
          </SiteBlock>

          <ContentBuilder
            items={content}
            aliases={PATH}
            titleTag="h2"
            recaptchaKey={recaptchaKey}
            onSubmitForm={async body =>
              await mutationMainForm({
                body: { ...body, pageId: data.id },
                [`${recaptchaFormKey}`]: body.token,
                cameFrom: cameFrom,
                trackingId: submitButtonID,
              })
            }
            onSubmitFileForm={body =>
              mutationFileForm({
                body: {
                  ...body,
                  pageId: data.id,
                  [`${recaptchaFormKey}`]: body.token,
                  cameFrom: cameFrom,
                  trackingId: submitButtonID,
                },
              })
            }
            pageId={data.id}
            dictionariesForm={{
              success: {
                title:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkSuccessTitle,
                text:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkSuccessText,
              },
              addInfo: {
                label:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkAddInfo,
              },
              company: {
                label:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkCompany,
              },
              email: {
                label: 'Email',
                error:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkEmail
                    .companySiteFormLetsTalkEmailError,
              },
              name: {
                label:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkName,
              },
              phone: {
                label: 'Phone',
                error:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormPhoneLetsTalkError,
              },
              required:
                formContent.companySiteFormLetsTalk
                  .companySiteFormLetsTalkRequiredField,
              button:
                formContent.companySiteFormLetsTalk
                  .companySiteFormLetsTalkSubmit,
              policy:
                formContent.companySiteFormLetsTalk
                  .companySiteFormLetsTalkSubscribePolicy,
            }}
          />
        </>
      )}
    </div>
  );
};
