import './ContentBuilderTemplate.scss';
import './ContentBuilderTemplate61.scss';

import React from 'react';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { Slider } from '@dataartdev/uikit/Slider';
import { SliderItem } from '@dataartdev/uikit/Slider';
import { SucessStoryCard } from '../../SuccessStoryCard/SuccessStoryCard';
import { cn } from '@bem-react/classname';
import { IRoot61 } from '../models/CTI_61.props';

export const ContentBuilderTemplate: React.FC<IRoot61> = props => {
  const { type, items, title, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );
  const typeNumber = type.toString();

  const cnCustomerSuccessStoriesSection = cn('CustomerSuccessStoriesSection');

  const customerSuccessStoriesSlider = {
    watchOverflow: true,
    navigation: true,
    slidesPerView: 'auto',
    spaceBetween: 16,
    breakpoints: {
      767: {
        spaceBetween: 16,
      },
      1200: {
        spaceBetween: 24,
      },
      1540: {
        spaceBetween: 24,
      },
    },
  };

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <div className={cnCustomerSuccessStoriesSection()}>
        <Typography.Title size="lg" parse>
          {title}
        </Typography.Title>
        <Slider
          {...customerSuccessStoriesSlider}
          className={cnCustomerSuccessStoriesSection('Slider')}
        >
          {items?.map(item => (
            <SliderItem key={item.id}>
              <SucessStoryCard {...item} />
            </SliderItem>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ContentBuilderTemplate;
