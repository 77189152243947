import './ContentBuilderTemplate.scss';

import React from 'react';
import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Picture } from '@dataartdev/uikit/Picture';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { IRoot51, Fact } from '../models/CTI_51.props';

export const ContentBuilderTemplate = forwardRefWithAs<IRoot51>(props => {
  const { facts, factsSecond, text, type, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  const sliderConfig = {
    watchOverflow: true,
    navigation: true,
    slidesPerView: 1,
    spaceBetween: 16,
    breakpoints: {
      767: {
        slidesPerView: 1,
        spaceBetween: 16,
      },
      1200: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
    },
  };

  const groupFacts = (facts: Fact[] = []): Fact[][] => {
    const groupedFacts = [];
    for (let i = 0; i < facts.length; i += 2) {
      groupedFacts.push(facts.slice(i, i + 2));
    }
    return groupedFacts;
  };

  return (
    <div className={cnContentBuilderTemplate({ type: typeNumber })}>
      <Grid
        id={contentId}
        breakpoints={{
          desktop: { cols: 12, gap: 'xl' },
          tablet: { cols: 12, gap: 'xl' },
          mobile: { cols: 12, gap: 'xl' },
        }}
      >
        <GridItem
          breakpoints={{
            desktop: { col: 6 },
            tablet: { col: 12 },
            mobile: { col: 12 },
          }}
          className={cnContentBuilderTemplate('Value-Profits')}
        >
          <Slider {...sliderConfig}>
            {groupFacts(facts)?.map((factPair, index) => (
              <SliderItem key={index}>
                <div
                  className={cnContentBuilderTemplate('Value-Profit-Container')}
                >
                  {factPair.map(fact => {
                    const { percent, text, id } = fact || {};
                    return (
                      <div
                        key={id}
                        className={cnContentBuilderTemplate('Value-Profit')}
                      >
                        {percent && (
                          <Typography.Title as="span" size="lg">
                            {percent}
                          </Typography.Title>
                        )}
                        {text && (
                          <Typography.Text as="span" size="m">
                            {text}
                          </Typography.Text>
                        )}
                      </div>
                    );
                  })}
                </div>
              </SliderItem>
            ))}

            {factsSecond?.map(fact => {
              const { id, text, picture } = fact || {};
              return (
                <SliderItem key={id}>
                  <div className={cnContentBuilderTemplate('Value-Profit Lg')}>
                    {picture && <Picture {...picture} />}
                    {text && (
                      <Typography.Text as="span" size="m" parse>
                        {text}
                      </Typography.Text>
                    )}
                  </div>
                </SliderItem>
              );
            })}
          </Slider>
        </GridItem>
        {text && (
          <GridItem
            breakpoints={{
              desktop: { col: 5, colStart: 8 },
              tablet: { col: 12 },
              mobile: { col: 12 },
            }}
          >
            <Typography.Title
              className={cnContentBuilderTemplate('Slogan')}
              as="p"
              size="lg"
              parse
            >
              {text}
            </Typography.Title>
          </GridItem>
        )}
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
