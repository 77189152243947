// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';
import { DownloadableBanner } from '../../Banner/DownloadableBanner';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const {
    type,
    title,
    text,
    picture,
    linkTitle,
    link,
    targetBlank,
    color,
    isInverted,
    onSubmitFileForm,
    recaptchaKey,
    removeHandler,
    columnI,
    columnII,
    contentId,
    ...otherProps
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();
  const [fileData, ...other] = columnI || [];

  return (
    <>
      {fileData && (
        <div
          className={cnContentBuilderTemplate({ type: typeNumber })}
          id={contentId}
        >
          <DownloadableBanner
            inverted={isInverted}
            bgColor={color}
            view="section"
            title={title}
            titleSize="md"
            text={text}
            picture={picture}
            labelButton={linkTitle}
            buttonIcon={IconOpenInNew}
            buttonIconSize="xs"
            {...fileData}
            url={fileData.file.url}
            onSubmit={async body =>
              onSubmitFileForm({ ...body, filePath: fileData.file.url })
            }
            recaptchaKey={recaptchaKey}
          />
        </div>
      )}
    </>
  );
});

export default ContentBuilderTemplate;
