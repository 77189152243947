import './MainForm.scss';

import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Typography } from '@dataartdev/uikit/Typography';
import { Button } from '@dataartdev/uikit/Button';
import { TextField } from '@dataartdev/uikit/TextField';
import { Pagination } from '@dataartdev/uikit/Pagination';
import { Checkbox } from '@dataartdev/uikit/Checkbox';
import { Mq } from '@dataartdev/uikit/Mq';
import { Modal } from '@dataartdev/uikit/Modal';
import { Form, FormBody } from '../../components';
import parse from 'html-react-parser';

import { cnGetInTouchForm } from 'common/components/GetInTouchForm/GetInTouchForm';

import { mutationMainForm } from 'network';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

import { cn } from 'ui/utils/bem';
import { schemaLetsTalk } from 'common/utils';

import { dictionariesSelector, layoutSelector } from 'store';

import TagManager from 'react-gtm-module';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';
import ReCAPTCHA from 'react-google-recaptcha';
import sbjs from 'sourcebuster';
import { usePagePath } from '../../hooks/usePagePath';

sbjs.init();

type Props = {};

const cnMainForm = cn('MainForm');

const stepsTotal = 6;

export const MainForm: React.FC<Props> = () => {
  const { companySiteForm: staticContent }: any =
    useSelector(dictionariesSelector) || {};
  const { companySiteFormLetsTalk } = staticContent || {};
  const { pageId }: any = useSelector(layoutSelector) || {};
  const [currentStep, setCurrentStep] = useState(1);
  const [successForm, setSuccessForm] = useState(false);
  const reCaptchaRef = useRef(null);

  const {
    companySiteFormLetsTalk: {
      companySiteFormLetsTalkTrackingID: id,
      companySiteFormLetsTalkTitle: title,
      companySiteFormLetsTalkText: text,
      companySiteFormLetsTalkName: name,
      companySiteFormLetsTalkCompany: company,
      companySiteFormLetsTalkAddInfo: addInfo,
      companySiteFormLetsTalkSubscribePolicy: subscribePolicy,
      companySiteFormLetsTalkSuccessTitle: successTitle,
      companySiteFormLetsTalkSuccessText: successText,
      companySiteFormLetsTalkEmail: { companySiteFormLetsTalkEmailError },
      companySiteFormLetsTalkPhone: { companySiteFormPhoneLetsTalkError },
      companySiteFormLetsTalkRequiredField,
      companySiteFormLetsTalkCompanyErrors: {
        companySiteFormLetsTalkCompanyMaxCharactersError,
        companySiteFormLetsTalkCompanyMinCharactersError,
      },
    },
  } = staticContent || {};

  const schema = schemaLetsTalk(
    companySiteFormLetsTalkEmailError,
    companySiteFormLetsTalkRequiredField,
    companySiteFormLetsTalkCompanyMaxCharactersError,
    companySiteFormLetsTalkCompanyMinCharactersError
  );

  const pagePath = usePagePath();

  const cameFrom = `typ: ${sbjs.get.current.typ} | src: ${sbjs.get.current.src} | mdm: ${sbjs.get.current.mdm} | cmp: ${sbjs.get.current.cmp} | cnt: ${sbjs.get.current.cnt} | trm: ${sbjs.get.current.trm}`;
  const submitButtonID = id || '';

  const onSubmit = async (values, actions) => {
    if (currentStep < 6) {
      setCurrentStep(currentStep + 1);
      actions.validateForm();
    } else {
      const token = await reCaptchaRef.current.executeAsync();

      await mutationMainForm({
        body: {
          ...values,
          pageId: pageId,
          [`${recaptchaFormKey}`]: token,
          cameFrom: cameFrom,
          trackingId: submitButtonID,
        },
      }).then(() => {
        setSuccessForm(true);
        setCurrentStep(1);
        actions.resetForm();

        TagManager.dataLayer({
          dataLayer: {
            event: 'send_form',
            form_name: 'get_in_touch_form',
            page_path: pagePath,
            tags: 'not set',
          },
        });
      });
    }
  };

  const initialValues = {
    name: '',
    phone: '',
    company: '',
    email: '',
    additionalInfo: '',
    privacyAgreement: false,
  };

  return (
    <>
      {companySiteFormLetsTalk && (
        <div className={cnMainForm()} id="main-form-01">
          <div className="container">
            <Grid
              breakpoints={{
                desktop: { cols: 12, gap: 'xl', yAlign: 'bottom' },
                tablet: { gap: '4xl' },
              }}
            >
              <GridItem breakpoints={{ desktop: { col: 6, colStart: 2 } }}>
                {title && (
                  <Typography.Title
                    size="lg"
                    className={cnMainForm('Title')}
                    as="span"
                  >
                    {title}
                  </Typography.Title>
                )}
                {text && (
                  <Mq query="--mobile">
                    <Typography.Text
                      size="s"
                      className={cnMainForm('SubTitle')}
                    >
                      {parse(text)}
                    </Typography.Text>
                  </Mq>
                )}
                <Form
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={schema[currentStep - 1]}
                  onSubmit={onSubmit}
                >
                  {({
                    isSubmitting,
                    errors,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    dirty,
                  }) => {
                    return (
                      <FormBody>
                        <div className={cnMainForm('Inner')}>
                          <div className={cnMainForm('Body')}>
                            {currentStep === 1 && (
                              <TextField
                                name="name"
                                onChange={({ e }) => handleChange(e)}
                                onBlur={handleBlur}
                                value={values.name}
                                label={name}
                                view="outline"
                                width="full"
                                caption={touched.name && errors.name}
                                status={
                                  touched.name && !!errors.name && 'alert'
                                }
                              />
                            )}
                            {currentStep === 2 && (
                              <TextField
                                name="phone"
                                onChange={({ e }) => handleChange(e)}
                                onBlur={handleBlur}
                                value={values.phone}
                                label={'Phone'}
                                view="outline"
                                width="full"
                                type="tel"
                                pattern="^(?=[^A-Za-zЀ-ӿ]+$).*[0-9].*$"
                                caption={touched.phone && errors.phone}
                                status={
                                  touched.phone && !!errors.phone && 'alert'
                                }
                              />
                            )}
                            {currentStep === 3 && (
                              <TextField
                                name="email"
                                onChange={({ e }) => handleChange(e)}
                                onBlur={handleBlur}
                                value={values.email}
                                label={'Email'}
                                view="outline"
                                width="full"
                                required
                                caption={touched.email && errors.email}
                                status={
                                  touched.email && !!errors.email && 'alert'
                                }
                              />
                            )}
                            {currentStep === 4 && (
                              <TextField
                                name="company"
                                onChange={({ e }) => handleChange(e)}
                                onBlur={handleBlur}
                                value={values.company}
                                label={company}
                                view="outline"
                                width="full"
                                type="tel"
                                caption={touched.company && errors.company}
                                status={
                                  touched.company && !!errors.company && 'alert'
                                }
                              />
                            )}
                            {currentStep === 5 && (
                              <TextField
                                name="additionalInfo"
                                onChange={({ e }) => handleChange(e)}
                                onBlur={handleBlur}
                                value={values.additionalInfo}
                                label={addInfo}
                                view="outline"
                                width="full"
                                caption={
                                  touched.additionalInfo &&
                                  errors.additionalInfo
                                }
                                status={
                                  touched.additionalInfo &&
                                  !!errors.additionalInfo &&
                                  'alert'
                                }
                              />
                            )}
                            {currentStep === 6 && (
                              <Checkbox
                                required
                                label={subscribePolicy}
                                onChange={({ e }) => handleChange(e)}
                                onBlur={handleBlur}
                                name="privacyAgreement"
                                checked={values.privacyAgreement}
                              />
                            )}
                            <Pagination
                              className={cnMainForm('Pagination')}
                              currentPage={currentStep}
                              totalPages={stepsTotal}
                              size="xs"
                              hideControls
                            />
                          </div>
                          <div className={cnMainForm('Button')}>
                            <Button
                              id={currentStep >= 6 ? id : null}
                              iconRight={IconArrowRight}
                              disabled={
                                isSubmitting ||
                                !dirty ||
                                !!Object.keys(errors).length
                              }
                              type="submit"
                            />
                            <ReCAPTCHA
                              ref={reCaptchaRef}
                              sitekey={recaptchaKey}
                              // onChange={value => {
                              //   console.log('$$$$', isSubmitting, value);
                              //   setSubmitting(false);
                              // }}
                              size="invisible"
                            />
                          </div>
                        </div>
                      </FormBody>
                    );
                  }}
                </Form>
              </GridItem>
              {text && (
                <Mq query="--tablet-min">
                  <GridItem
                    breakpoints={{ desktop: { col: 5 } }}
                    className={cnMainForm('Right')}
                  >
                    <Typography.Text size="s">{parse(text)}</Typography.Text>
                  </GridItem>
                </Mq>
              )}
            </Grid>
          </div>

          <Modal
            view="secondary"
            hasOverlay
            onOpen={() => {
              document.body.style.overflow = 'hidden';
            }}
            onClose={() => {
              document.body.style.overflow = 'unset';
            }}
            isOpen={successForm}
            onOverlayClick={(): void => {
              setSuccessForm(false);
              document.body.style.overflow = 'unset';
            }}
            onEsc={(): void => {
              setSuccessForm(false);
              document.body.style.overflow = 'unset';
            }}
            showCloseButton
            inverted
          >
            <div className={cnGetInTouchForm('ModalSuccess')}>
              <Typography.Title
                size="lg"
                className={cnGetInTouchForm('Title')}
                as="h2"
              >
                {successTitle}
              </Typography.Title>
              <Typography.Text className={cnGetInTouchForm('SubTitle')}>
                {successText}
              </Typography.Text>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};
