import './SingleInnerIndustries.scss';

import { FC, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PATH } from 'router';
import { mutationMainForm } from 'network';
import { cn } from 'ui/utils/bem';
import { useGTMDataSend } from '../../../common/hooks/useGTMDataSend';

import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';
import { TreeNav } from '@dataartdev/uikit/TreeNav';
import { Typography } from '@dataartdev/uikit/Typography';
import { Mq } from '@dataartdev/uikit/Mq';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';

import { dictionariesSelector } from 'store';

import sbjs from 'sourcebuster';
import { ContentBuilder } from '../../../common/components/ContentBuilder/ContentBuilder';

sbjs.init();

const cnPage = cn('SingleInnerIndustries');

type Props = RouteComponentProps & {
  data: any;
  loading?: boolean;
};

export const OfferingPage: FC<Props> = ({
  data,
  history,
  location,
  loading,
}) => {
  const staticContent = useSelector(dictionariesSelector) || {};

  const {
    companySiteForm: formContent,
    companySiteTableOfContent,
  }: any = staticContent;

  const { breadcrumbs, tags, metaTitle, content, title, subtitle } = data || {};

  const pageRef = useRef(null);
  const reCaptchaRef = useRef(null);

  useGTMDataSend(location, metaTitle, tags);

  return (
    <>
      {!loading && (
        <div className={cnPage()}>
          <Mq query="--desktop">
            <TreeNav
              title={companySiteTableOfContent}
              pageRef={pageRef}
              fixed
            />
          </Mq>
          <div className="container">
            {breadcrumbs && (
              <Breadcrumbs
                getLabel={page => page.text}
                getLink={page => page.url}
                history={history}
                pages={breadcrumbs}
              />
            )}
          </div>

          <div className="container SingleInnerIndustries-Nogap">
            <Typography.Title size="sm" as="h1">
              {title}
            </Typography.Title>
            <Typography.Text as="p">{subtitle}</Typography.Text>
          </div>

          <ContentBuilder
            items={content}
            aliases={PATH}
            recaptchaKey={recaptchaKey}
            onSubmitForm={async body =>
              await mutationMainForm({
                body: { ...body, pageId: data.id },
                [`${recaptchaFormKey}`]: body.token,
              })
            }
            pageId={data.id}
            dictionariesForm={{
              success: {
                title:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkSuccessTitle,
                text:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkSuccessText,
              },
              addInfo: {
                label:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkAddInfo,
              },
              company: {
                label:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkCompany,
              },
              email: {
                label: 'Email',
                error:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkEmail
                    .companySiteFormLetsTalkEmailError,
              },
              name: {
                label:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkName,
              },
              phone: {
                label: 'Phone',
                error:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormPhoneLetsTalkError,
              },
              required:
                formContent.companySiteFormLetsTalk
                  .companySiteFormLetsTalkRequiredField,
              button:
                formContent.companySiteFormLetsTalk
                  .companySiteFormLetsTalkSubmit,
              policy:
                formContent.companySiteFormLetsTalk
                  .companySiteFormLetsTalkSubscribePolicy,
            }}
          />
        </div>
      )}
    </>
  );
};
