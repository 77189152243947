import './EventCard.scss';

import React from 'react';
import { NavLink } from 'react-router-dom';
import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { cn } from '../../utils/bem';
import { IconPlay } from '@dataartdev/uikit/IconPlay';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { Tag } from '@dataartdev/uikit/Tag';
import { TagGroup } from '@dataartdev/uikit/TagGroup';
import { Typography } from '@dataartdev/uikit/Typography';
import { User } from '@dataartdev/uikit/User';
import { ILogo } from '../../../types/common';
import { IEvent } from '../../../types/event';
import { nanoid } from '@reduxjs/toolkit';

interface Props extends IEvent {
  routePath?: string;
  view?: string;
  date?: string;
  isOnline?: boolean;
  slug?: string;
  size?: 'l';
  invertedStyle?: any;
  picture?: ILogo;
  redirectTo?: string;
  onlineTitle?: string;
}

export const cnEventCard = cn('EventCard');

export const EventCard = forwardRefWithAs<Props>(props => {
  const {
    routePath,
    view,
    picture,
    size = 'm',
    invertedStyle,
    redirectTo,
    onlineTitle,
    date,
    tags,
    isOnline,
    languages,
    title,
    locations = [],
    speakers = [],
    registrationStatus,
    eventType,
    fullUrl,
    eventTypeText,
    location,
    eventWithoutPage,
    video,
    cardDictionary,
  } = usePropsHandler(cnEventCard(), props);

  const { companySiteEventCardOnline = '' } = cardDictionary?.eventCard || {};

  let redirectToPage = redirectTo;

  if (
    Number(process.env.REACT_APP_EVENT_TYPE) !== Number(eventType) &&
    !redirectToPage &&
    fullUrl
  ) {
    redirectToPage = fullUrl;
  }

  return (
    <div
      className={cnEventCard({
        view,
        size,
        invertedStyle,
        noHover: eventWithoutPage,
      })}
    >
      {!eventWithoutPage && (
        <>
          {redirectToPage && (
            <a
              className={cnEventCard('Link')}
              href={redirectToPage}
              target="_blank"
              rel="noreferrer"
              aria-label={title}
            />
          )}
          {!redirectToPage && routePath && (
            <NavLink
              className={cnEventCard('Link')}
              to={routePath}
              aria-label={title}
            />
          )}
        </>
      )}

      {picture && (
        <div className={cnEventCard('Picture')}>
          <Picture {...picture} />
        </div>
      )}
      <div className={cnEventCard('Inner')}>
        {(date || locations || location) && isOnline && (
          <div className={cnEventCard('Date')}>
            {date && (
              <Typography.Text size="xs" weight="bold" transform="uppercase">
                {date}
              </Typography.Text>
            )}
            {locations.length > 0 && (
              <Typography.Text size="xs" weight="bold" transform="uppercase">
                |{' '}
                {isOnline
                  ? companySiteEventCardOnline ?? onlineTitle
                  : locations.map(
                      location => `${location.country}, ${location.location}`
                    )}
              </Typography.Text>
            )}
            {location && (
              <Typography.Text size="xs" weight="bold" transform="uppercase">
                |{' '}
                {isOnline
                  ? companySiteEventCardOnline ?? onlineTitle
                  : `${location.country}, ${location.location}`}
              </Typography.Text>
            )}
            {!location && !locations && isOnline && (
              <Typography.Text size="xs" weight="bold" transform="uppercase">
                |&nbsp;
                {isOnline ? companySiteEventCardOnline ?? onlineTitle : null}
              </Typography.Text>
            )}
          </div>
        )}
        <div className={cnEventCard('Title')}>
          <Typography.Title size="tiny" weight="bold" as="h3">
            {title}
          </Typography.Title>
        </div>
        {eventTypeText && (
          <div className={cnEventCard('SubTitle')}>
            <Typography.Text size="s">{eventTypeText}</Typography.Text>
          </div>
        )}
        <div className={cnEventCard('Tags')}>
          {tags && <TagGroup items={tags} />}
        </div>
        <div className={cnEventCard('Speakers')}>
          {video && (
            <div
              className={cnEventCard('VideoCircle')}
              style={{ zIndex: speakers?.length > 0 ? speakers.length + 1 : 1 }}
            >
              <Button
                view="primary"
                form="circle"
                iconRight={IconPlay}
                size="4xl"
                iconSize="s"
                onlyIcon
                aria-label="play button"
              />
            </div>
          )}
          {speakers.map((speaker, index) => (
            <React.Fragment key={nanoid()}>
              {speaker.photo && (
                <User
                  size="m"
                  avatarUrl={speaker.photo?.url}
                  style={{ zIndex: speakers.length - index }}
                />
              )}
            </React.Fragment>
          ))}
        </div>
        {languages && (
          <div className={cnEventCard('Bottom')}>
            <TagGroup items={languages} mode="label" gap="xs" />
          </div>
        )}
        {registrationStatus && (
          <div className={cnEventCard('Bottom')}>
            <Tag
              mode="label"
              label={registrationStatus.title}
              colorBorder={registrationStatus.color}
              gap="xs"
            />
          </div>
        )}
      </div>
    </div>
  );
});
