import './BlogPage.scss';

import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { useSelector } from 'react-redux';
import { isDesktopSelector } from 'store';
import { useDataQuery, useDataPagination, useGTMDataSend } from 'common/hooks';
import { queryBlogFeed, queryBlogPage } from 'network';
import { EssenceCard } from '../../../common/components/EssenceCard/EssenceCard';
import { SiteBlock } from '../../../common/components/SiteBlock/SiteBlock';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { cn } from 'ui/utils/bem';
import { Mq } from '@dataartdev/uikit/Mq';
import { Button } from '@dataartdev/uikit/Button';
import { Tag } from '@dataartdev/uikit/Tag';
import { Picture } from '@dataartdev/uikit/Picture';
import { Loader } from '@dataartdev/uikit/Loader';
import { Typography } from '@dataartdev/uikit/Typography';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { IconFilter } from '@dataartdev/uikit/IconFilter';

import { Filters } from '../components/Filters';

import { getUrlFilter, updateQueryParams } from 'router';
import { dictionariesSelector } from 'store';
import { useInfiniteScroll } from '@dataartdev/uikit/useInfiniteScroll';
import { FilterModal } from 'common/components';

import { BlogForm } from '../components/BlogForm';

type Props = RouteComponentProps & {};

export const cnBlogPage = cn('BlogPage');
export const cnFilterModal = cn('FilterModal');

export const BlogPage: React.FC<Props> = ({ location, history }) => {
  const urlFilter = getUrlFilter(location);

  const isDesktop = useSelector(isDesktopSelector);
  const [items, setItems] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const { companySiteBlog: staticContent } =
    useSelector(dictionariesSelector) || {};

  const {
    data,
    loading: loadingInitial,
    updateFilter,
    filter: filterData,
  } = useDataQuery(
    queryBlogPage,
    {
      filter: {
        industriesIds: urlFilter.industriesIds,
        tagsIds: urlFilter.tagsIds,
      },
    },
    {
      skip: currentPage > 1,
    }
  );

  const {
    featuredPost,
    mainPost,
    industryPosts,
    filter,
    bannerTitle,
    bannerPicture,
    bannerLink,
    bannerLinkText,
    popularPosts,
    metaTitle,
  } = data || [];
  const { shortTags, tags: filterTags, industries: filterIndustries } =
    filter || {};
  const [industriesSelected, setIndustriesSelected] = useState<any>();
  const [tagsSelected, setTagsSelected] = useState<any>();

  const {
    pagination,
    updateFilter: updateFilterFeed,
    updatePagination,
    data: dataFeed,
    loading: loadingFeed,
    error,
    filter: filterCards,
  } = useDataPagination(
    queryBlogFeed,
    {
      filter: {
        industriesIds: urlFilter.industriesIds,
        tagsIds: urlFilter.tagsIds,
      },
    },
    {
      skip: currentPage < 2,
    }
  );

  const changeFilter = filter => {
    setItems([]);

    updateFilter(filter);
    updateFilterFeed(filter);
  };

  useEffect(() => {
    if (data && !items.length) {
      setItems(prev => data.feed.posts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (dataFeed) {
      setItems(prev => [...prev, ...dataFeed.posts]);
    }
  }, [dataFeed]);

  useEffect(() => {
    setCurrentPage(pagination.page);
  }, [pagination]);

  useEffect(() => {
    setIndustriesSelected(
      filterIndustries?.filter(item =>
        urlFilter.industriesIds.includes(item.id.toString())
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, filterIndustries]);

  useEffect(() => {
    if (urlFilter.tagsIds) {
      let tagParams = urlFilter.tagsIds;
      if (typeof urlFilter.tagsIds === 'string') {
        tagParams = [tagParams];
      }

      setTagsSelected(
        filterTags?.filter(item =>
          tagParams.some(i => item.id.toString() === i)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTags, history]);

  useEffect(() => {
    const { industriesIds, tagsIds } =
      currentPage > 2 ? filterCards : filterData;

    updateQueryParams(history, {
      industriesIds: industriesIds,
      tagsIds: tagsIds,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCards, history, filterData]);

  const [sentryRef] = useInfiniteScroll({
    loading: loadingFeed,
    hasNextPage: pagination?.page < data?.feed?.totalPages,
    onLoadMore: () => {
      updatePagination({ page: pagination.page + 1 });
    },
    disabled: !!error,
  });

  const [modalIndustries, setModalIndustries] = React.useState(null);
  const [modalTags, setModalTags] = React.useState(null);
  const filterModalRef = React.useRef(null);

  const resetForm = () => {
    setTagsSelected([]);
    setIndustriesSelected([]);
    hideModalFilter();
    changeFilter({
      industriesIds: '',
      tagsIds: '',
    });
  };

  const submitModalForm = () => {
    setTagsSelected(modalTags);
    setIndustriesSelected(modalIndustries);
    hideModalFilter();
    changeFilter({
      industriesIds: modalIndustries?.map(item => item.id),
      tagsIds: modalTags?.map(item => item.id),
    });
  };

  const showModalFilter = () => {
    if (filterModalRef) {
      filterModalRef.current.show();
    }
  };

  const hideModalFilter = () => {
    if (filterModalRef) {
      filterModalRef.current.hide();
    }
  };

  useEffect(() => {
    setModalIndustries(industriesSelected);
    setModalTags(tagsSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsSelected, industriesSelected]);

  useGTMDataSend(location, metaTitle);

  return (
    <div className={cnBlogPage()}>
      <div className="container">
        <Mq query="--tablet-max">
          <div className={cnBlogPage('Controls')}>
            <Grid cols="2" yAlign="center">
              <GridItem>
                <Typography.Title size="lg" as="h1">
                  Blog
                </Typography.Title>
              </GridItem>
              <GridItem className={cnBlogPage('Controls-Button')}>
                <Button
                  view="ghost"
                  iconRight={IconFilter}
                  active={
                    tagsSelected?.length > 0 || industriesSelected?.length > 0
                  }
                  onClick={showModalFilter}
                />

                <FilterModal
                  ref={filterModalRef}
                  onSubmitForm={submitModalForm}
                  onResetForm={resetForm}
                  industries={{
                    title: staticContent.companySiteBlogIndustriesFilter,
                    items: filterIndustries,
                    onChange: ({ value }) => {
                      setModalIndustries(value);
                    },
                    value: modalIndustries,
                  }}
                  tags={{
                    title: staticContent.companySiteBlogTagsFilter,
                    items: filterTags,
                    onChange: ({ value }) => {
                      setModalTags(value);
                    },
                    value: modalTags,
                  }}
                />
              </GridItem>
            </Grid>
            {(tagsSelected?.length > 0 || industriesSelected?.length > 0) && (
              <div className={cnBlogPage('Controls-Selected')}>
                <Typography.Text
                  size="xs"
                  weight="bold"
                  transform="uppercase"
                  className={cnBlogPage('Controls-Selected-Title')}
                  as="h2"
                >
                  Search results for:
                </Typography.Text>
                <div className={cnBlogPage('Controls-Selected-List')}>
                  {tagsSelected?.length > 0 &&
                    tagsSelected.map(item => (
                      <div key={item.id + item.title}>
                        <Tag
                          onCancel={() => {
                            const actualArray = tagsSelected.filter(
                              w => w.id !== item.id
                            );
                            setTagsSelected(actualArray);
                            changeFilter({
                              tagsIds: actualArray?.map(item => item.id),
                            });
                          }}
                          label={item.title}
                          mode="cancel"
                        />
                      </div>
                    ))}

                  {industriesSelected?.length > 0 &&
                    industriesSelected.map(item => (
                      <div key={item.id + item.title}>
                        <Tag
                          onCancel={() => {
                            const actualArray = industriesSelected.filter(
                              w => w.id !== item.id
                            );
                            setIndustriesSelected(actualArray);
                            changeFilter({
                              industriesIds: actualArray?.map(item => item.id),
                            });
                          }}
                          label={item.title}
                          mode="cancel"
                        />
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </Mq>
        <div className={cnBlogPage('Head')}>
          <Grid
            cols="12"
            breakpoints={{
              mobile: { rowGap: '4xl' },
              tablet: { rowGap: 'xxl', colGap: 'm' },
              desktop: { gap: 'xl' },
            }}
          >
            {mainPost && (
              <GridItem
                breakpoints={{
                  mobile: { col: 12 },
                  tablet: { col: 12 },
                  desktop: {
                    col: 7,
                  },
                }}
              >
                <EssenceCard
                  {...mainPost}
                  size="l"
                  path={mainPost.relativeUrl}
                />
              </GridItem>
            )}
            {featuredPost && (
              <Mq query="--tablet-max">
                <GridItem
                  breakpoints={{
                    mobile: { col: 12 },
                    tablet: { col: 6 },
                  }}
                >
                  <EssenceCard
                    {...featuredPost}
                    size="l"
                    path={featuredPost.relativeUrl}
                  />
                </GridItem>
              </Mq>
            )}
            <GridItem
              breakpoints={{
                mobile: { col: 12 },
                tablet: { col: 6, colStart: 7 },
                desktop: {
                  col: 4,
                  colStart: 9,
                },
              }}
            >
              <div className={cnBlogPage('ListBorder')}>
                {industryPosts &&
                  industryPosts.map((article, index) => {
                    const categoryName = () => {
                      let string = '';

                      article.industries.map(
                        (item, index) =>
                          (string += index > 0 ? item.title + ', ' : item.title)
                      );

                      return string;
                    };

                    return (
                      <EssenceCard
                        {...article}
                        descriptionSmall={categoryName()}
                        view="text"
                        size="s"
                        path={article.relativeUrl}
                        key={article.slug + index}
                      />
                    );
                  })}
              </div>
            </GridItem>
          </Grid>
        </div>

        {filter && (
          <Mq query="--desktop">
            <div className={cnBlogPage('Filters')}>
              <Filters
                industries={filterIndustries}
                industriesSelected={industriesSelected}
                industriesPlaceholder={
                  staticContent.companySiteBlogIndustriesFilter
                }
                industriesPlaceholderSelected={
                  staticContent.companySiteBlogChosenIndustries
                }
                industriesChange={({ value }) => {
                  setIndustriesSelected(value);
                  changeFilter({
                    industriesIds: value?.map(item => item.id),
                  });
                }}
                tags={shortTags}
                moreTags={filterTags}
                moreTagsButton={'see all'}
                tagsPlaceholder={staticContent.companySiteBlogTagsFilter}
                tagsPlaceholderSelected={
                  staticContent.companySiteBlogChosenTags
                }
                tagsSelected={tagsSelected}
                tagsChange={({ value }) => {
                  setTagsSelected(value);
                  changeFilter({
                    tagsIds: value?.map(item => item.id),
                  });
                }}
              />
            </div>
          </Mq>
        )}

        {!loadingInitial && (
          <div className={cnBlogPage('Body')}>
            <Grid cols="12" gap="xl" className={cnBlogPage('Body-Main')}>
              <GridItem
                breakpoints={{
                  mobile: { col: 12 },
                  tablet: { col: 12 },
                  desktop: {
                    col: 7,
                  },
                }}
              >
                <Grid
                  breakpoints={{
                    mobile: { cols: 1, rowGap: '4xl' },
                    tablet: { cols: 2, colGap: 'm', rowGap: '4xl' },
                    desktop: { cols: 1, gap: '3xl' },
                  }}
                >
                  {items &&
                    items.map((post, index) => (
                      <React.Fragment key={post.id}>
                        {index <= 5 && (
                          <>
                            {index === 4 ? (
                              <GridItem
                                breakpoints={{
                                  tablet: { col: 2 },
                                  desktop: { col: 1 },
                                }}
                              >
                                <div className={cnBlogPage('Item')}>
                                  <div className={cnBlogPage('Banner')}>
                                    <div className={cnBlogPage('Banner-Left')}>
                                      <Typography.Title
                                        size="md"
                                        className={cnBlogPage('Banner-Title')}
                                        as="h2"
                                      >
                                        {bannerTitle}
                                      </Typography.Title>
                                      <Button
                                        view="link-primary"
                                        className={cnBlogPage('Banner-Button')}
                                        label={bannerLinkText}
                                        iconRight={IconArrowRight}
                                        as="a"
                                        href={bannerLink}
                                      />
                                    </div>
                                    <div className={cnBlogPage('Banner-Right')}>
                                      <Picture {...bannerPicture} />
                                    </div>
                                  </div>
                                </div>
                              </GridItem>
                            ) : null}
                            <GridItem
                              breakpoints={{
                                tablet: { col: 1 },
                                desktop: { col: 1 },
                              }}
                            >
                              <EssenceCard
                                {...post}
                                direction="row"
                                path={post.relativeUrl}
                                linkTitle={
                                  post.contentType === 2
                                    ? staticContent.companySiteBlogWatchOnYoutube
                                    : null
                                }
                              />
                            </GridItem>
                          </>
                        )}
                      </React.Fragment>
                    ))}
                </Grid>
              </GridItem>
              {featuredPost && (
                <Mq query="--desktop">
                  <GridItem col="4" colStart="9">
                    <Sticky
                      enabled={isDesktop}
                      top={110}
                      bottomBoundary={'.BlogPage-Body-Main'}
                    >
                      <EssenceCard
                        {...featuredPost}
                        path={featuredPost.relativeUrl}
                        size="l"
                      />
                    </Sticky>
                  </GridItem>
                </Mq>
              )}
            </Grid>
            <Grid cols="12" gap="xl" className={cnBlogPage('Body-Secondary')}>
              {items?.length > 6 && (
                <>
                  <GridItem
                    breakpoints={{
                      mobile: { col: 12 },
                      tablet: { col: 12 },
                      desktop: {
                        col: 7,
                      },
                    }}
                  >
                    <Grid
                      breakpoints={{
                        mobile: { cols: 1, rowGap: '4xl' },
                        tablet: { cols: 2, colGap: 'm', rowGap: '4xl' },
                        desktop: { cols: 1, gap: '3xl' },
                      }}
                    >
                      {items.map((post, index) => (
                        <React.Fragment key={post.id + 'more'}>
                          {index > 5 && (
                            <>
                              {index === 8 ? (
                                <Mq query="--tablet-max">
                                  {filterIndustries && (
                                    <GridItem
                                      breakpoints={{
                                        tablet: { col: 2 },
                                        desktop: { col: 1 },
                                      }}
                                    >
                                      <div className={cnBlogPage('Form')}>
                                        <BlogForm
                                          selectItems={filterIndustries}
                                        />
                                      </div>
                                    </GridItem>
                                  )}
                                </Mq>
                              ) : null}
                              <GridItem
                                key={post.id + index + post.slug}
                                breakpoints={{
                                  tablet: { col: 1 },
                                  desktop: { col: 1 },
                                }}
                              >
                                <EssenceCard
                                  {...post}
                                  direction="row"
                                  path={post.relativeUrl}
                                  linkTitle={
                                    post.contentType === 2
                                      ? staticContent.companySiteBlogWatchOnYoutube
                                      : null
                                  }
                                />
                              </GridItem>
                            </>
                          )}
                        </React.Fragment>
                      ))}
                    </Grid>

                    {loadingFeed && (
                      <SiteBlock topGap="xxl">
                        <Loader />
                      </SiteBlock>
                    )}
                  </GridItem>

                  <Mq query="--desktop">
                    <GridItem col="4" colStart="9">
                      <Sticky
                        enabled={isDesktop}
                        top={110}
                        bottomBoundary={'.BlogPage-Body-Secondary'}
                      >
                        <div className={cnBlogPage('Form')}>
                          {filterIndustries && (
                            <BlogForm selectItems={filterIndustries} />
                          )}
                        </div>
                      </Sticky>
                    </GridItem>
                  </Mq>
                </>
              )}
            </Grid>
            {data?.feed?.totalPages > currentPage && <div ref={sentryRef} />}
          </div>
        )}
      </div>
    </div>
  );
};
