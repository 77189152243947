import './CategoryCard.scss';
import React from 'react';
import { cn } from '../../utils/bem';
import { Typography } from '@dataartdev/uikit/Typography';
import { ListLinks } from '@dataartdev/uikit/ListLinks';
import TagManager from 'react-gtm-module';
import { Service } from '../ContentBuilder/models/CTI_56.props';

export const cnCategoryCard = cn('CategoryCard');

type Props = Service & { index: number };

export const CategoryCard: React.FC<Props> = props => {
  const { title, text, links, index } = props || {};

  const servicesHandler = (title: string) => {
    const service = title.trim().toLowerCase();
    let dataLayer = {
      dataLayer: {
        event: 'click_services',
        services: `click_${service.split(' ').join('_')}`,
      },
    };
    TagManager.dataLayer(dataLayer);
  };

  return (
    <div className={cnCategoryCard()}>
      {title && (
        <div className={cnCategoryCard('Head')}>
          <Typography.Text
            as="span"
            size="xs"
            weight="bold"
            transform="uppercase"
          >
            {index + 1}
          </Typography.Text>
          <Typography.Title as="span" size="md">
            {title}
          </Typography.Title>
        </div>
      )}
      {text && (
        <Typography.Text
          as="p"
          size="m"
          className={cnCategoryCard('Description')}
        >
          {text}
        </Typography.Text>
      )}
      {links?.length > 0 && (
        <div className={cnCategoryCard('List')}>
          <ListLinks
            id={item => item.id}
            items={links}
            getPath={item => item.link}
            getLabel={item => item.title}
            onLinkClick={servicesHandler}
          />
        </div>
      )}
    </div>
  );
};
