import TagManager from 'react-gtm-module';

export const gtmInit = () => {
  const gtmId = process.env.REACT_APP_GTM || '';
  const tagManagerArgs = {
    gtmId,
  };
  // if (gtmId && process.env.NODE_ENV === 'production') {
  TagManager.initialize(tagManagerArgs);
  // }
};
