import './EventFormRegister.scss';

import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import { Typography } from '@dataartdev/uikit/Typography';

import { cn } from 'ui/utils/bem';

import { useSelector } from 'react-redux';
import { dictionariesSelector, layoutSelector } from 'store';

import { Form, FormBody } from '../../../common/components';
import { TextField } from '@dataartdev/uikit/TextField';
import { Button } from '@dataartdev/uikit/Button';
import { Checkbox } from '@dataartdev/uikit/Checkbox';
import { Mq } from '@dataartdev/uikit/Mq';
import { Modal } from '@dataartdev/uikit/Modal';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';

import { mutationEventFormRegister } from 'network';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

import TagManager from 'react-gtm-module';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';
import ReCAPTCHA from 'react-google-recaptcha';

import sbjs from 'sourcebuster';
import { usePagePath } from '../../../common/hooks/usePagePath';

sbjs.init();

type Props = {
  tags?: any;
};

const cnEventFormRegister = cn('EventFormRegister');

export const EventFormRegister: React.FC<Props> = (props, ref) => {
  const { tags, ...otherProps } = props;
  const { companySiteEvents: staticContent } =
    useSelector(dictionariesSelector) || {};

  const [successForm, setSuccessForm] = useState<boolean>(false);
  const reCaptchaRef = useRef(null);
  const { pageId }: any = useSelector(layoutSelector) || {};

  const pagePath = usePagePath();

  const cameFrom = `typ: ${sbjs.get.current.typ} | src: ${sbjs.get.current.src} | mdm: ${sbjs.get.current.mdm} | cmp: ${sbjs.get.current.cmp} | cnt: ${sbjs.get.current.cnt} | trm: ${sbjs.get.current.trm}`;
  const submitButtonID =
    staticContent?.companySiteEventsForm?.companySiteEventsFormRegistration
      ?.companySiteEventsFormRegistrationTrackingID || 'webinar_registration';

  const onSubmit = async (values, actions) => {
    const token = await reCaptchaRef.current.executeAsync();
    await mutationEventFormRegister({
      body: {
        ...values,
        pageId,
        [`${recaptchaFormKey}`]: token,
        cameFrom: cameFrom,
        trackingId: submitButtonID,
      },
    }).then(() => {
      actions.resetForm();
      TagManager.dataLayer({
        dataLayer: {
          event: 'send_form',
          form_name: submitButtonID,
          page_path: pagePath,
          tags: tags,
        },
      });
      setSuccessForm(true);
    });
  };

  const schema = yup.object({
    email: yup
      .string()
      .email(
        staticContent.companySiteEventsForm.companySiteEventsFormRegistration
          .companySiteEventsFormRegistrationEmail
          .companySiteEventsFormRegistrationEmailError
      )
      .required(
        staticContent.companySiteEventsForm.companySiteEventsFormRegistration
          .companySiteEventsFormRegistrationRequiredField
      )
      .nullable(),
    firstName: yup
      .string()
      .min(3)
      .required(
        staticContent.companySiteEventsForm.companySiteEventsFormRegistration
          .companySiteEventsFormRegistrationRequiredField
      )
      .nullable(),
    lastName: yup
      .string()
      .min(3)
      .required(
        staticContent.companySiteEventsForm.companySiteEventsFormRegistration
          .companySiteEventsFormRegistrationRequiredField
      )
      .nullable(),
    company: yup
      .string()
      .min(3)
      .required(
        staticContent.companySiteEventsForm.companySiteEventsFormRegistration
          .companySiteEventsFormRegistrationRequiredField
      )
      .nullable(),
    privacyAgreement: yup
      .boolean()
      .oneOf([true], 'on')
      .required(),
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderForm = () => (
    <div className={cnEventFormRegister()}>
      <Typography.Title
        size="md"
        className={cnEventFormRegister('Title')}
        as="h2"
      >
        {
          staticContent.companySiteEventsForm.companySiteEventsFormRegistration
            .companySiteEventsFormRegistrationTitle
        }
      </Typography.Title>
      <div className={cnEventFormRegister('Body')}>
        <Form
          enableReinitialize
          initialValues={{
            email: '',
            name: '',
            lastName: '',
            company: '',
            privacyAgreement: false,
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({
            isSubmitting,
            errors,
            dirty,
            isValid,
            handleBlur,
            handleChange,
            touched,
            values,
          }) => {
            return (
              <FormBody>
                <Grid
                  breakpoints={{
                    desktop: { cols: 12, gap: 'm' },
                    tablet: { cols: 8, gap: 'm' },
                    mobile: { cols: 1, gap: 'm' },
                  }}
                >
                  <GridItem
                    breakpoints={{ desktop: { col: 12 }, tablet: { col: 4 } }}
                  >
                    <TextField
                      name="firstName"
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values.firstName}
                      label={
                        staticContent.companySiteEventsForm
                          .companySiteEventsFormRegistration
                          .companySiteEventsFormRegistrationName
                      }
                      view="outline"
                      width="full"
                      required
                      caption={touched.firstName && errors.firstName}
                      status={
                        touched.firstName && !!errors.firstName && 'alert'
                      }
                    />
                  </GridItem>
                  <GridItem
                    breakpoints={{ desktop: { col: 12 }, tablet: { col: 4 } }}
                  >
                    <TextField
                      name="lastName"
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values.lastName}
                      label={
                        staticContent.companySiteEventsForm
                          .companySiteEventsFormRegistration
                          .companySiteEventsFormRegistrationSurname
                      }
                      view="outline"
                      width="full"
                      required
                      caption={touched.lastName && errors.lastName}
                      status={touched.lastName && !!errors.lastName && 'alert'}
                    />
                  </GridItem>
                  <GridItem
                    breakpoints={{ desktop: { col: 12 }, tablet: { col: 4 } }}
                  >
                    <TextField
                      name="email"
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values.email}
                      label="E-mail"
                      view="outline"
                      width="full"
                      required
                      caption={touched.email && errors.email}
                      status={touched.email && !!errors.email && 'alert'}
                    />
                  </GridItem>
                  <GridItem
                    breakpoints={{ desktop: { col: 12 }, tablet: { col: 4 } }}
                  >
                    <TextField
                      name="company"
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values.company}
                      label={
                        staticContent.companySiteEventsForm
                          .companySiteEventsFormRegistration
                          .companySiteEventsFormRegistrationCompany
                      }
                      view="outline"
                      width="full"
                      required
                      caption={touched.company && errors.company}
                      status={touched.company && !!errors.company && 'alert'}
                    />
                  </GridItem>
                  <GridItem
                    breakpoints={{ desktop: { col: 12 }, tablet: { col: 8 } }}
                  >
                    <div className={cnEventFormRegister('Message')}>
                      {
                        staticContent.companySiteEventsForm
                          .companySiteEventsFormRegistration
                          .companySiteEventsFormRegistrationNoteText
                      }
                    </div>
                  </GridItem>
                  <GridItem
                    breakpoints={{ desktop: { col: 12 }, tablet: { col: 8 } }}
                    className={cnEventFormRegister('Bottom')}
                  >
                    <Checkbox
                      required
                      label={
                        staticContent.companySiteEventsForm
                          .companySiteEventsFormRegistration
                          .companySiteEventsFormRegistrationSubscribePolicy
                      }
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      name="privacyAgreement"
                      checked={values.privacyAgreement}
                    />
                    <div className={cnEventFormRegister('Button')}>
                      <Button
                        id={submitButtonID}
                        iconRight={IconArrowRight}
                        disabled={isSubmitting || !dirty || !isValid}
                        label={
                          staticContent.companySiteEventsForm
                            .companySiteEventsFormRegistration
                            .companySiteEventsFormRegistrationSubmit
                        }
                        type="submit"
                      />
                      <ReCAPTCHA
                        ref={reCaptchaRef}
                        sitekey={recaptchaKey}
                        size="invisible"
                      />
                    </div>
                  </GridItem>
                </Grid>
              </FormBody>
            );
          }}
        </Form>
      </div>
      {successForm && (
        <div className={cnEventFormRegister('Success')}>
          <Typography.Title
            size="md"
            className={cnEventFormRegister('Title')}
            as="h2"
          >
            {
              staticContent.companySiteEventsForm
                .companySiteEventsFormRegistration
                .companySiteEventsFormRegistrationSuccessTitle
            }
          </Typography.Title>
          <Typography.Text className={cnEventFormRegister('SubTitle')}>
            {
              staticContent.companySiteEventsForm
                .companySiteEventsFormRegistration
                .companySiteEventsFormRegistrationSuccessText
            }
          </Typography.Text>
        </div>
      )}
    </div>
  );

  return (
    <>
      <Mq query="--desktop">{renderForm()}</Mq>
      <Mq query="--tablet-max">
        <div className={cnEventFormRegister('ToggleButton')}>
          <div className="container">
            <Button
              width="full"
              iconRight={IconArrowRight}
              label={
                staticContent.companySiteEventsForm
                  .companySiteEventsFormRegistration
                  .companySiteEventsFormRegistrationSubmit
              }
              align="center"
              onClick={() => setIsModalOpen(true)}
            />
          </div>
        </div>
        <Modal
          view="secondary"
          showCloseButton
          isOpen={isModalOpen}
          onOverlayClick={(): void => setIsModalOpen(false)}
          onEsc={(): void => {
            setIsModalOpen(false);
            document.body.style.overflow = 'unset';
          }}
          hasOverlay
          rootClassName={'EventFormRegisterModal'}
          onOpen={() => {
            document.body.style.overflow = 'hidden';
          }}
          onClose={() => {
            document.body.style.overflow = 'unset';
          }}
          {...otherProps}
        >
          {renderForm()}
        </Modal>
      </Mq>
    </>
  );
};
