// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';

import { PartnerCard } from '../../PartnerCard/PartnerCard';
import { Article } from '../../Article/Article';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Typography } from '@dataartdev/uikit/Typography';

import { cnContentBuilderTemplate } from './index';

export type Props = {
  type?: number;
  title: string;
  partners: Partner[];
  id: number;
  background: string;
};

type Picture4 = {
  urlWebp: string;
  alt: string;
  name: string;
  url: string;
  type: string;
  size: number;
  updatedAt: string;
};

type Link = {
  id: string;
  title: string;
  link: string;
  targetBlank: boolean;
};

type Partner = {
  link: Link;
  id: number;
  title: string;
  text: string;
  picture: Picture4;
  color: string;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>(props => {
  const { type, title, text, partners, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );
  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Title
          size="lg"
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        >
          {title}
        </Typography.Title>
      )}
      {text && (
        <Article
          content={text}
          className={cnContentBuilderTemplate('SubTitle')}
        />
      )}

      {partners?.length > 0 && (
        <Grid
          breakpoints={{
            desktop: { cols: 12, gap: 'xl' },
            tablet: { cols: 8, rowGap: 'xl', colGap: 'm' },
            mobile: { cols: 1, gap: 'xl' },
          }}
        >
          {partners.map(item => {
            const link = {
              link: item?.link?.link,
              title: item?.link?.title,
              icon: null,
              targetBlank: item.link?.targetBlank,
            };
            return (
              <>
                {item.link.link && (
                  <GridItem
                    breakpoints={{
                      desktop: { col: 3 },
                      tablet: { col: 4 },
                      mobile: { col: 1 },
                    }}
                  >
                    <PartnerCard {...item} link={link} />
                  </GridItem>
                )}
              </>
            );
          })}
        </Grid>
      )}
    </div>
  );
});

export default ContentBuilderTemplate;
