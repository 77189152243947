import './ContentBuilderTemplate.scss';
import './ContentBuilderTemplate63.scss';

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { screenSelector } from '../../../../store';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cn } from '@bem-react/classname';
import { cnContentBuilderTemplate } from './index';
import { IconPlay } from '@dataartdev/uikit/IconPlay';
import { Button } from '@dataartdev/uikit/Button';
import ReactPlayer from 'react-player/youtube';
import { IRoot63 } from '../models/CTI_63.props';

const cnVideoFullWidth = cn('VideoFullWidth');

export const ContentBuilderTemplate: React.FC<IRoot63> = props => {
  const { type, preview, video, autoplay, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );
  const typeNumber = type.toString();

  const [playVideo, setPlayVideo] = useState(false);
  const [imageVisible, setImageVisible] = useState(true);

  const videoRef = useRef<HTMLElement>(null);

  const handlePlay = () => {
    setPlayVideo(true);
    setImageVisible(false);
  };

  const { isMobile, isTablet, isDesktop, isDesktopXL } = useSelector(
    screenSelector
  );

  const filterDeviceImages = (
    isMobile: boolean,
    isTablet: boolean,
    isDesktop: boolean,
    isDesktopXL: boolean,
    deviceImages: any
  ) => {
    const selectedKeys = isMobile
      ? ['mobileWebp', 'mobile', 'mobile2xWebp', 'mobile2x']
      : isTablet
      ? ['tabletWebp', 'tablet', 'tablet2xWebp', 'tablet2x']
      : isDesktop
      ? ['desktopWebp', 'desktop', 'desktop2xWebp', 'desktop2x']
      : isDesktopXL
      ? ['desktop2xWebp', 'desktop2x']
      : [];

    for (const key of selectedKeys) {
      if (deviceImages[key]) {
        return deviceImages[key];
      }
    }
  };

  const light = filterDeviceImages(
    isMobile,
    isTablet,
    isDesktop,
    isDesktopXL,
    preview
  );

  useEffect(() => {
    if (!autoplay) return;

    const handleScroll = () => {
      const videoSection = videoRef.current;
      if (!videoSection) return;

      const videoRect = videoSection.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      const scrollPosition = window.scrollY;
      const scrollThresholdStart =
        videoRect.top + scrollPosition - viewportHeight * 0.75;
      const scrollThresholdEnd =
        videoRect.top + scrollPosition + viewportHeight * 0.8 - 96;

      if (
        scrollPosition > scrollThresholdEnd ||
        scrollPosition < scrollThresholdStart
      ) {
        setPlayVideo(false);
        setImageVisible(true);
      } else {
        setPlayVideo(true);
        setImageVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [autoplay]);

  const overlayVisible = imageVisible
    ? 'VideoFullWidth-Preview--Visible'
    : 'VideoFullWidth-Preview--Hidden';

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <div className={cnVideoFullWidth()}>
        <div className={cnVideoFullWidth('Frame')} ref={videoRef}>
          <div className={`VideoFullWidth-Preview ${overlayVisible}`}>
            <img
              src={light}
              alt="video_preview"
              className={cnVideoFullWidth('Preview')}
            />
            <Button
              form="circle"
              size="3xxl"
              iconRight={IconPlay}
              iconSize="m"
              onClick={handlePlay}
            />
          </div>
          <ReactPlayer
            url={video?.videoUrl}
            playing={playVideo}
            width="100%"
            height="100%"
            muted={true}
            controls={true}
            loop={true}
            config={{
              playerVars: {
                showinfo: 0,
                rel: 0,
              },
            }}
            // light={autoplay ? undefined : light}
            // playIcon={
            //   autoplay ? (
            //     undefined
            //   ) : (
            //     <Button
            //       form="circle"
            //       size="3xxl"
            //       iconRight={IconPlay}
            //       onClick={handlePlay}
            //     />
            //   )
            // }
          />
        </div>
      </div>
    </div>
  );
};

export default ContentBuilderTemplate;
