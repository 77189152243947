import './EventPage.scss';

import React, { useEffect } from 'react';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { SiteBlock } from '../../../common/components/SiteBlock/SiteBlock';
import { Typography } from '@dataartdev/uikit/Typography';
import { VideoCard } from '../../../common/components/VideoCard/VideoCard';
import { MemberCard } from '@dataartdev/uikit/MemberCard';
import { Picture } from '@dataartdev/uikit/Picture';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';
import { Article } from '../../../common/components/Article/Article';
import { TagGroup } from '@dataartdev/uikit/TagGroup';
import { SocialsShare } from '@dataartdev/uikit/SocialsShare';
import { User } from '@dataartdev/uikit/User';
import { Button } from '@dataartdev/uikit/Button';
import { ContentBuilderItem } from 'common/components/ContentBuilder/ContentBuilderItem';
import { IconCalendar } from '@dataartdev/uikit/IconCalendar';

import { PATH, redirect404 } from 'router';

import { cn } from 'ui/utils/bem';

import { TIME_FORMAT } from 'common/constants/dateFormat';
import { parseToLocalTime } from 'common/utils/formateDateToLocalUserTime';

import { dictionariesSelector } from 'store';
import { queryEventPage, queryEventArchivePage } from 'network';
import { useDataQuery, useGTMDataSend } from 'common/hooks';

import { EventFormRegister } from '../components/EventFormRegister';
import { EventsForm } from '../components/EventsForm';
import { EventFormRecordVideo } from '../components/EventFormRecordVideo';

const cnEventPage = cn('EventPage');

type Props = RouteComponentProps & {};

export const EventPage: React.FC<Props> = ({ match, history, location }) => {
  const { companySiteSingleEvent: staticContent } =
    useSelector(dictionariesSelector) || {};

  const {
    companySiteSingleEventAddToCalendar,
    companySiteSingleEventContactTitle,
    companySiteSingleEventFreeWebinarTitle,
    companySiteSingleEventRecommendationTitle,
    companySiteSingleEventRegistrationByUrlButton,
    companySiteSingleEventRegistrationStatus,
    companySiteSingleEventShareTitle,
    companySiteSingleEventSpeakersTitle,
    companySiteSingleEventContactTitleHeaderLocationTimePicker: at,
  } = staticContent || {};

  const { data, loading, error } =
    useDataQuery(
      match.path === PATH.EVENTS_ARCHIVE_PAGE
        ? queryEventArchivePage
        : queryEventPage,
      {
        slug: match.params.slug,
      }
    ) || {};

  const {
    title,
    picture,
    breadcrumbs,
    text,
    tags,
    eventTypeText,
    speakers,
    topiks,
    date,
    video,
    // timeZone,
    // isOnline,
    // languages,
    fullDate,
    // price,
    // address,
    // googleMapsUrl,
    scheduleUrl,
    staff,
    // videoAsPrewiew,
    // video,
    // noteText,
    // allHotVacancies,
    relatedItems,
    // partners,
    registerationOpen,
    watchVideoPopup,
    isArchived,
    showSubscribeForm,
    registerByUrl,
    registerToEventUrl,
    metaTitle,
    location: eventLocacion,
  } = data || {};

  const dataLayerTags = tags?.map(tag => tag.title)?.join(', ');

  useEffect(() => {
    if (isArchived) {
      history.replace({
        ...history.location,
        pathname: generatePath(PATH.EVENTS_ARCHIVE_PAGE, match.params),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArchived]);

  const userLocalTime = parseToLocalTime(fullDate, TIME_FORMAT);
  const eventLocationFull = eventLocacion
    ? `${eventLocacion?.country}, ${eventLocacion?.location}`
    : `Online`;

  useEffect(() => {
    if (error && error.response && error.response.status === 404) {
      redirect404(history);
    }
  }, [error, history]);

  useGTMDataSend(location, metaTitle, tags);

  return (
    <React.Fragment>
      {!loading && (
        <div className={cnEventPage()}>
          <div className="container">
            <div className={cnEventPage('Breadcrumbs')}>
              {breadcrumbs && (
                <Breadcrumbs
                  getLabel={page => page.text}
                  getLink={page => page.url}
                  history={history}
                  pages={data.breadcrumbs}
                />
              )}
            </div>
            <Grid gap="xl" cols="12">
              <GridItem
                breakpoints={{
                  desktop: { col: 8 },
                  tablet: { col: 12 },
                  mobile: { col: 12 },
                }}
              >
                <div className={cnEventPage('Header')}>
                  <div className={cnEventPage('Header-Left')}>
                    <Typography.Title size="tiny">{date}</Typography.Title>
                    <Typography.Title size="tiny">
                      {`${eventLocationFull} ${at || 'at'} ${userLocalTime}`}
                    </Typography.Title>
                  </div>
                  {scheduleUrl && (
                    <div className={cnEventPage('Header-Right')}>
                      <Button
                        as="a"
                        view="link-secondary"
                        label={companySiteSingleEventAddToCalendar}
                        iconRight={IconCalendar}
                        iconSize="xs"
                        target="_blank"
                        href={scheduleUrl}
                      />
                    </div>
                  )}
                </div>

                {title && (
                  <Typography.Title
                    className={cnEventPage('Title')}
                    size="lg"
                    as="h1"
                  >
                    {title}
                  </Typography.Title>
                )}

                {eventTypeText && (
                  <Typography.Text className={cnEventPage('Text')}>
                    {eventTypeText}
                  </Typography.Text>
                )}

                {tags?.length > 0 && (
                  <div className={cnEventPage('Tags')}>
                    <TagGroup items={tags} />
                  </div>
                )}

                {video ? (
                  <SiteBlock topGap="3xl" bottomGap="6xl" useMargin>
                    <VideoCard
                      picture={video.picture}
                      video={video.video}
                      videoUrl={video.videoUrl}
                      bottomDescription={video.title}
                      buttonSize="3xxl"
                      buttonIconSize="l"
                    />
                  </SiteBlock>
                ) : picture ? (
                  <SiteBlock topGap="3xl" bottomGap="6xl" useMargin>
                    <Picture {...picture} />
                  </SiteBlock>
                ) : null}

                {text && (
                  <Article content={text} className={cnEventPage('MainText')} />
                )}

                {topiks?.length > 0 && (
                  <div className={cnEventPage('Topics')}>
                    <div className={cnEventPage('Topics-List')}>
                      {topiks.map(item => (
                        <div className={cnEventPage('Topics-Item')}>
                          <Article
                            className={cnEventPage('Topics-Article')}
                            content={item.text}
                          />

                          {item.speakers?.length > 0 && (
                            <>
                              <Typography.Title
                                size="sm"
                                className={cnEventPage('Topics-Title')}
                                as="h2"
                              >
                                {companySiteSingleEventSpeakersTitle}
                              </Typography.Title>
                              <div className={cnEventPage('Topics-Authors')}>
                                {item.speakers.map(speaker => (
                                  <div className={cnEventPage('Topics-Author')}>
                                    <User
                                      key={`${speaker.firstName} ${speaker.lastName}`}
                                      name={`${speaker.firstName} ${speaker.lastName}`}
                                      info={speaker.title}
                                      avatarUrl={speaker.photo?.url}
                                      description={() => (
                                        <Typography.Text parse>
                                          {speaker.description}
                                        </Typography.Text>
                                      )}
                                    />
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {speakers?.length > 0 && (
                  <div className={cnEventPage('Speakers')}>
                    <Typography.Title
                      size="sm"
                      className={cnEventPage('Speakers-Title')}
                      as="h2"
                    >
                      {companySiteSingleEventSpeakersTitle}
                    </Typography.Title>
                    <div className={cnEventPage('Speakers-List')}>
                      {speakers.map(speaker => (
                        <div className={cnEventPage('Speakers-Item')}>
                          <User
                            key={`${speaker.firstName} ${speaker.lastName}`}
                            name={`${speaker.firstName} ${speaker.lastName}`}
                            info={speaker.title}
                            avatarUrl={speaker.photo?.url}
                            description={() => (
                              <Typography.Text>
                                {speaker.description}
                              </Typography.Text>
                            )}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {staff?.length > 0 && (
                  <SiteBlock useMargin topGap="xxl">
                    <SiteBlock useMargin bottomGap="3xl">
                      <Typography.Text
                        size="xs"
                        weight="bold"
                        transform="uppercase"
                      >
                        {companySiteSingleEventContactTitle}
                      </Typography.Text>
                    </SiteBlock>

                    <div>
                      {staff.map((member, index) => (
                        <SiteBlock
                          useMargin
                          key={member.id}
                          topGap={index > 0 ? '3xl' : null}
                        >
                          <MemberCard size="xs" {...member} view="ghost" />
                        </SiteBlock>
                      ))}
                    </div>
                  </SiteBlock>
                )}
              </GridItem>
              <GridItem
                breakpoints={{
                  desktop: { col: 4 },
                  tablet: { col: 12 },
                  mobile: { col: 12 },
                }}
              >
                <div className={cnEventPage('RightSide')}>
                  <div className={cnEventPage('RightSide-FixedButton')}>
                    {registerByUrl && (
                      <Button
                        target="_blank"
                        view="secondary"
                        as="a"
                        href={registerToEventUrl}
                        label={companySiteSingleEventRegistrationByUrlButton}
                        width="full"
                        align="center"
                      />
                    )}
                  </div>
                  {showSubscribeForm && (
                    <EventsForm view="row" tags={dataLayerTags} />
                  )}
                  {registerationOpen && (
                    <EventFormRegister tags={dataLayerTags} />
                  )}
                  {watchVideoPopup && watchVideoPopup.video && (
                    <EventFormRecordVideo
                      video={watchVideoPopup.video}
                      tags={dataLayerTags}
                    />
                  )}
                </div>
              </GridItem>
            </Grid>
            <div className={cnEventPage('Bottom')}>
              <Grid
                cols="2"
                yAlign="center"
                breakpoints={{ mobile: { gap: 'xl' } }}
              >
                <GridItem
                  className={cnEventPage('BottomLeft')}
                  breakpoints={{ mobile: { col: 2 } }}
                >
                  {tags?.length > 0 && <TagGroup items={tags} />}
                </GridItem>
                <GridItem
                  className={cnEventPage('BottomRight')}
                  breakpoints={{ mobile: { col: 2 } }}
                >
                  <SocialsShare
                    title={staticContent?.companySiteSingleEventShareTitle}
                    url={window.location.href}
                  />
                </GridItem>
              </Grid>
            </div>
          </div>

          {relatedItems?.length > 0 && (
            <div className={cnEventPage('RelatedItems')}>
              <ContentBuilderItem
                type={28}
                items={relatedItems}
                title={companySiteSingleEventRecommendationTitle}
                background="right-triangle-down-2"
                topGap="3xxl"
                bottomGap="3xxl"
                aliases={PATH}
              />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
