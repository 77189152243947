import { useState, useEffect } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import TagManager from 'react-gtm-module';
import { usePagePath } from './usePagePath';

export const useGTMDataSend = (
  location: any,
  metaTitle: string,
  stringTags = ''
) => {
  const isVisible = usePageVisibility();
  const [seconds, setSeconds] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [timeSendGA, setTimeSendGA] = useState(true);
  const [scrollSendGA, setScrollSendGA] = useState(true);
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });
  const pagePath = usePagePath();
  let tags: any = null;
  if (Array.isArray(stringTags) && stringTags.length !== 0) {
    tags = stringTags.map(i => i.title).join(', ');
  } else tags = stringTags || undefined;

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimenion]);

  useEffect(() => {
    if (!isVisible) {
      return;
    }
    const interval = setInterval(() => {
      setSeconds(seconds => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [isVisible]);

  useEffect(() => {
    const halfScroll = Math.round(
      (document.body.scrollHeight - windowDimenion.winHeight) / 2
    );
    if (
      (timeSendGA && seconds === 30) ||
      (halfScroll < scrollPosition && scrollSendGA)
    ) {
      setTimeSendGA(false);
      setScrollSendGA(false);
      TagManager.dataLayer({
        dataLayer: {
          event: 'engagement_30sec_50%',
          page_path: pagePath,
          page_title: metaTitle || 'Blog',
          tags: tags,
        },
      });
    }
  }, [seconds, scrollPosition]);
};
