export const PATH = {
  MAIN: '/:lang([a-zA-Z]{2})?/:slug?/:slug_2?/:slug_3?',
  // BLOG: '/:lang([a-zA-Z]{2})?/blog',
  // BLOG_SINGLE: '/:lang([a-zA-Z]{2})?/blog/:slug',
  // FAQ: '/:lang([a-zA-Z]{2})?/faq',
  // TEAM: '/:lang([a-zA-Z]{2})?/company/team/',
  // TEAM_ADVISOR: '/:lang([a-zA-Z]{2})?/company/team/advisor/:slug',
  // TEAM_MEMBER: '/:lang([a-zA-Z]{2})?/company/team/:slug',
  // ABOUT_US: '/:lang([a-zA-Z]{2})?/company/about-us',
  // WHY_DATAART: '/:lang([a-zA-Z]{2})?/company/why-dataart',
  // LOCATIONS: '/:lang([a-zA-Z]{2})?/contact-us',
  // LOCATIONS_PRESS_KIT: '/:lang([a-zA-Z]{2})?/contact-us/press-kit',
  // LOCATIONS_SINGLE: '/:lang([a-zA-Z]{2})?/contact-us/:slug',
  //
  // CLIENTS: '/:lang([a-zA-Z]{2})?/clients',
  // CLIENT_PAGE: '/:lang([a-zA-Z]{2})?/clients/:slug',
  // CASE_PAGE: '/:lang([a-zA-Z]{2})?/clients/case-studies/:slug',
  // EVENTS: '/:lang([a-zA-Z]{2})?/company/events/',
  // EVENTS_ARCHIVE: '/:lang([a-zA-Z]{2})?/company/events/archive',
  // EVENTS_PAGE: '/:lang([a-zA-Z]{2})?/company/events/:slug',
  // EVENTS_ARCHIVE_PAGE: '/:lang([a-zA-Z]{2})?/company/events/archive/:slug',
  // LIFE_PAGE: '/:lang([a-zA-Z]{2})?/life',
  // INTERNSHIP_PAGE: '/:lang([a-zA-Z]{2})?/internship',
  // SUSTAINABILITY_PAGE: '/:lang([a-zA-Z]{2})?/company/sustainability/',
  //
  // SERVICES: '/:lang([a-zA-Z]{2})?/services',
  // SERVICES_PAGE: '/:lang([a-zA-Z]{2})?/services/:slug',
  // SERVICES_PAGE_INNER: '/:lang([a-zA-Z]{2})?/services/:slug/:inner_page',
  // SERVICES_PAGE_INNER_SECONDARY:
  //   '/:lang([a-zA-Z]{2})?/services/:slug/:inner_page/:inner_page_secondary',
  //
  // INDUSTRIES: '/:lang([a-zA-Z]{2})?/industries',
  // INDUSTRIES_PAGE: '/:lang([a-zA-Z]{2})?/industries/:slug/',
  // INDUSTRIES_PAGE_INNER: '/:lang([a-zA-Z]{2})?/industries/:slug/:inner_page',
  //
  // NEWSROOM: '/:lang([a-zA-Z]{2})?/company/newsroom',
  // NEWSROOM_PAGE: '/company/newsroom/:parentSlug/:slug',
  // PLAIN_TEXT: '/:lang([a-zA-Z]{2})?/:slug?/:slug_2?/:slug_3?',
};
