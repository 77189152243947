// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { Typography } from '@dataartdev/uikit/Typography';
import { Article } from '../../Article/Article';
import { DiagramsBlock } from '../../DiagramsBlock/DiagramsBlock';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const {
    type,
    title,
    text,
    diagrams,
    contentId,
    ...otherProps
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Title
          size="lg"
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        >
          {title}
        </Typography.Title>
      )}
      {text && (
        <Article
          content={text}
          className={cnContentBuilderTemplate('SubTitle')}
        />
      )}
      {diagrams?.length > 0 && (
        <div className={cnContentBuilderTemplate('Diagrams')}>
          <DiagramsBlock items={diagrams} {...otherProps} />
        </div>
      )}
    </div>
  );
});

export default ContentBuilderTemplate;
