import './VideoTestimonialCard.scss';
import '../VideoCard/VideoCard.scss';

import React, { useState } from 'react';
import { Picture } from '@dataartdev/uikit/Picture';
import { IconPlay } from '@dataartdev/uikit/IconPlay';
import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { cn } from '../../utils/bem';
import { Typography } from '@dataartdev/uikit/Typography';
import { Button } from '@dataartdev/uikit/Button';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Item } from '../ContentBuilder/templates/models/CTI_69';
import { prepareButtonAttrs } from '../../utils/prepareButtonAttrs';
import { IconClose } from '@dataartdev/uikit/IconClose';
import { Modal } from '@dataartdev/uikit/Modal';

export const cnVideoTestimonialCard = cn('VideoTestimonialCard');
export const cnVideoCard = cn('VideoCard');

export const VideoTestimonialCard: React.FC<Item> = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { link, title, text, videoUrl, picture } = usePropsHandler(
    cnVideoTestimonialCard(),
    props
  );
  const { link: testimonialLink, title: testimonialLinkTitle, targetBlank } =
    link || {};
  const buttonAttr = prepareButtonAttrs(
    testimonialLink,
    testimonialLinkTitle,
    targetBlank
  );

  return (
    <div className={cnVideoTestimonialCard()}>
      <div
        className={cnVideoTestimonialCard('Image')}
        onClick={() => setIsModalOpen(true)}
      >
        <Picture {...picture} />
        <Button
          view="primary"
          form="circle"
          iconRight={IconPlay}
          size="m"
          iconSize="m"
          onlyIcon
          className={cnVideoTestimonialCard('Play')}
          onClick={() => setIsModalOpen(true)}
        />
      </div>
      <div className={cnVideoTestimonialCard('Content')}>
        {title && (
          <Typography.Title
            size="md"
            className={cnVideoTestimonialCard('Title')}
          >
            {title}
          </Typography.Title>
        )}
        {text && (
          <Typography.Text
            size="m"
            className={cnVideoTestimonialCard('Description')}
          >
            {text}
          </Typography.Text>
        )}
        <Button
          className={cnVideoTestimonialCard('Button')}
          view="link-secondary"
          iconRight={IconArrowRight}
          iconSize="m"
          label="learn more"
          {...buttonAttr}
        />
      </div>
      <Modal
        className={cnVideoCard('Modal')}
        isOpen={isModalOpen}
        hasOverlay
        width="full"
        onOverlayClick={(): void => setIsModalOpen(false)}
        onEsc={(): void => setIsModalOpen(false)}
      >
        <iframe
          title="modal-iframe"
          src={videoUrl}
          frameBorder="0"
          width="100%"
          height="100%"
        />
        <Button
          view="ghost"
          iconRight={IconClose}
          className="Modal-Close"
          onClick={(): void => setIsModalOpen(false)}
        />
      </Modal>
    </div>
  );
};
