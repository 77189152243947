// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';
import { generatePath } from 'react-router-dom';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { Typography } from '@dataartdev/uikit/Typography';
import { Picture } from '@dataartdev/uikit/Picture';
import Marquee from 'react-fast-marquee';
import { BackgroundDecor } from '@dataartdev/uikit/BackgroundDecor';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { SiteBlock } from '../../SiteBlock/SiteBlock';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const { type, title, facts, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  const factsSlider = {
    slidesPerView: 'auto',
    spaceBetween: 24,
    navigation: true,
    watchOverflow: true,
  };

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <SiteBlock
        breakpoints={{
          desktop: { bottomGap: '6xl', topGap: '6xl' },
          tablet: { bottomGap: '6xl', topGap: '6xl' },
          mobile: { bottomGap: '3xl', topGap: '7xl' },
        }}
        className={cnContentBuilderTemplate('Facts')}
      >
        <BackgroundDecor view="left-triangle-up-7" />

        {title && (
          <Typography.Title
            size="lg"
            className={cnContentBuilderTemplate('Facts-Title')}
            as="h2"
          >
            {title}
          </Typography.Title>
        )}

        <Slider {...factsSlider}>
          {facts?.map(item => (
            <SliderItem key={item.id}>
              {item.contentType === 3 && (
                <div className={cnContentBuilderTemplate('Facts-Values')}>
                  {item.firstLabel && item.firstValue && (
                    <div
                      className={cnContentBuilderTemplate('Facts-ValueCard')}
                    >
                      <Typography.Title
                        size="lg"
                        weight="bold"
                        transform="uppercase"
                        as="p"
                      >
                        {item.firstValue}
                      </Typography.Title>
                      <Typography.Text as="p">
                        {item.firstLabel}
                      </Typography.Text>
                    </div>
                  )}
                  {item.secondLabel && item.secondValue && (
                    <div
                      className={cnContentBuilderTemplate('Facts-ValueCard')}
                    >
                      <Typography.Title
                        size="lg"
                        weight="bold"
                        transform="uppercase"
                        as="p"
                      >
                        {item.secondValue}
                      </Typography.Title>
                      <Typography.Text as="p">
                        {item.secondLabel}
                      </Typography.Text>
                    </div>
                  )}
                </div>
              )}

              {item.contentType === 2 && (
                <div
                  className={cnContentBuilderTemplate('Facts-Circle')}
                  style={{ backgroundColor: item.color }}
                >
                  {item.title && (
                    <Typography.Text
                      size="xs"
                      weight="bold"
                      transform="uppercase"
                      as="h3"
                    >
                      {item.title}
                    </Typography.Text>
                  )}
                  <Typography.Title
                    size="sm"
                    className={cnContentBuilderTemplate('Facts-Circle-Title')}
                    as="h4"
                  >
                    {item.text}
                  </Typography.Title>
                </div>
              )}

              {item.contentType === 1 && (
                <div className={cnContentBuilderTemplate('Facts-Content')}>
                  {item.picture && <Picture {...item.picture} />}
                  <Typography.Title
                    size="sm"
                    className={cnContentBuilderTemplate('Facts-Content-Title')}
                    as="h3"
                  >
                    {item.text}
                  </Typography.Title>
                </div>
              )}

              {item.contentType === 0 && (
                <div className={cnContentBuilderTemplate('Facts-Slider2')}>
                  {item.pictures?.length > 0 && (
                    <div
                      className={cnContentBuilderTemplate(
                        'Facts-Slider2-Inner2'
                      )}
                    >
                      <div
                        className={cnContentBuilderTemplate(
                          'Facts-Slider2-Inner'
                        )}
                      >
                        <Marquee loop={100} speed={100}>
                          {item.pictures.map(pic => (
                            <Picture {...pic} key={pic.url} />
                          ))}
                        </Marquee>
                      </div>
                    </div>
                  )}
                  <Typography.Text>{item.text}</Typography.Text>
                </div>
              )}
            </SliderItem>
          ))}
        </Slider>
      </SiteBlock>
    </div>
  );
});

export default ContentBuilderTemplate;
