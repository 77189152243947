import './ContentBuilderTemplate.scss';
import './ContentBuilderTemplate62.scss';

import React from 'react';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { cnContentBuilderTemplate } from './index';
import { cn } from '@bem-react/classname';
import { Button } from '@dataartdev/uikit/Button';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography } from '@dataartdev/uikit/Typography';
import { Slider } from '@dataartdev/uikit/Slider';
import { SliderItem } from '@dataartdev/uikit/Slider';
import { IRoot62 } from '../models/CTI_62.props';
import { prepareButtonAttrs } from '../../../utils/prepareButtonAttrs';
import { CtaScrollerBtn } from '../../CtaScrollerBtn';

export const ContentBuilderTemplate: React.FC<IRoot62> = props => {
  const { type, color, items, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );
  const typeNumber = type.toString();

  const cnColoredCarouselSection = cn('ColoredCarouselSection');

  const coloredCarouselSlider = {
    slidesPerView: 1,
    observer: true,
    observeParents: true,
    spaceBetween: 0,
    navigation: true,
    watchOverflow: true,
  };

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <div className={cnColoredCarouselSection()}>
        <div
          className={cnColoredCarouselSection('Background')}
          style={{ backgroundColor: color }}
        />
        <Slider
          {...coloredCarouselSlider}
          className={cnColoredCarouselSection('Slider')}
        >
          {items?.map(
            ({
              button,
              id,
              picture,
              text,
              title,
              pictureToLeft,
              isInverted,
            }) => {
              const { color, link, title: label, targetBlank } = button || {};
              const buttonAttr = prepareButtonAttrs(link, label, targetBlank);
              return (
                <SliderItem key={id}>
                  <Grid
                    breakpoints={{
                      desktop: { cols: 12, gap: 'xl' },
                      tablet: { cols: 12, gap: '3xl' },
                      mobile: { cols: 12 },
                    }}
                  >
                    <GridItem
                      className={`ColoredCarouselSection-Content ${
                        pictureToLeft
                          ? 'ColoredCarouselSection-Content--Push'
                          : ''
                      }`}
                      breakpoints={{
                        desktop: { col: 6 },
                        tablet: { col: 6 },
                        mobile: { col: 12 },
                      }}
                    >
                      <div
                        className={cnColoredCarouselSection('Content-Inner')}
                      >
                        {title && (
                          <Typography.Title
                            size="lg"
                            parse
                            className={`ColoredCarouselSection-Title ${
                              isInverted
                                ? 'ColoredCarouselSection-Title_isInverted'
                                : ''
                            }`}
                          >
                            {title}
                          </Typography.Title>
                        )}
                        {text && (
                          <Typography.Text
                            className={`ColoredCarouselSection-Description ${
                              isInverted
                                ? 'ColoredCarouselSection-Description_isInverted'
                                : ''
                            }`}
                            size="m"
                            parse
                          >
                            {text}
                          </Typography.Text>
                        )}

                        {buttonAttr && Object.keys(buttonAttr).length > 0 && (
                          <div className={cnColoredCarouselSection('Button')}>
                            {'isCTA' in buttonAttr ? (
                              <CtaScrollerBtn {...buttonAttr} />
                            ) : (
                              <Button
                                iconRight={IconArrowRight}
                                {...buttonAttr}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </GridItem>
                    <GridItem
                      className={cnColoredCarouselSection('Image')}
                      breakpoints={{
                        desktop: { col: 6 },
                        tablet: { col: 6 },
                        mobile: { col: 12 },
                      }}
                    >
                      <Picture {...picture} />
                    </GridItem>
                  </Grid>
                </SliderItem>
              );
            }
          )}
        </Slider>
      </div>
    </div>
  );
};

export default ContentBuilderTemplate;
