import './MainHeader.scss';

import React, { useState, useEffect } from 'react';
import { generatePath, RouteComponentProps } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { menuSelector, headerInvertedSelector } from 'store';
import { PATH } from 'router';
import { cn } from 'ui/utils/bem';
import { Button } from '@dataartdev/uikit/Button';
import { Modal } from '@dataartdev/uikit/Modal';
import { Tooltip } from '@dataartdev/uikit/Tooltip';
import { IconBurger } from '@dataartdev/uikit/IconBurger';
import { IconClose } from '@dataartdev/uikit/IconClose';
import { Mq } from '@dataartdev/uikit/Mq';
import { Combobox } from '@dataartdev/uikit/Combobox';
import { BannerStandWithUkraine } from '../../components/BannerStandWithUkraine';
import { dictionariesSelector, languagesSelector } from 'store';
import {
  Header,
  HeaderModule,
  HeaderLogo,
  HeaderMenu,
  HeaderMenuMobile,
} from '../../components/Header/Header';
import { useIntl } from 'react-intl';
import parse from 'html-react-parser';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

const cnHeaderWrapper = cn('HeaderWrapper');

type Props = RouteComponentProps & {};

export const MainHeader: React.FC<Props> = ({ history, match }) => {
  const menu = useSelector(menuSelector) || {};
  const [isHovered, setIsHovered] = useState(false);
  const [showBanner, setShowBanner] = React.useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [isLangPopupNeeded, setIsLangPopupNeeded] = useState(false);
  const [isLangPopupStartHide, setIsLangPopupStartHide] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { locale } = useIntl();
  const isInverted = useSelector(headerInvertedSelector);

  const { companySiteHome: staticContent } =
    useSelector(dictionariesSelector) || {};

  const language = window.INITIAL_STATE.languages.find(
    w => w.slug === localStorage.getItem('currentLang')
  );

  // const availableTranslations: Array<string> =
  //   useSelector(languagesSelector) || [];
  //
  // const languages = window.INITIAL_STATE.languages.filter(w =>
  //   availableTranslations.includes(w.iso.toLowerCase())
  // );

  const { button, logo, logoLink, languages, nodes: menuItems } = menu || {};

  const desktopMenuItems = menuItems?.filter(
    item => !item.node?.nodeUrl?.includes('contact-us')
  );

  const bubbleText =
    staticContent?.companySiteHomeDifferentLanguagesBubble || `bubble text`;

  // const reloadMainLayout = () => {
  //   if (locale === 'en') {
  //     history.push('/');
  //   } else {
  //     history.push(generatePath(PATH.MAIN, { lang: locale }));
  //   }
  // };

  const mouseEnterHandler = () => {
    setIsHovered(true);
  };

  const mouseLeaveHandler = () => {
    setIsHovered(false);
  };

  const onHeaderMenuHandler = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    const onScrollHandler = (): void => {
      if (window.scrollY > 20) {
        setScrolling(true);
      } else if (window.scrollY <= 20) {
        setScrolling(false);
      }

      if (window.scrollY > 80) {
        setShowBanner(true);
      } else if (window.scrollY <= 80) {
        setShowBanner(false);
      }
    };

    window.addEventListener('scroll', onScrollHandler);
    return () => window.removeEventListener('scroll', onScrollHandler);
  });

  useEffect(() => {
    setIsModalOpen(false);
  }, [history.location.pathname]);

  useEffect(() => {
    if (localStorage.getItem('needLangPopup') !== null) {
      return;
    }
    localStorage.setItem('needLangPopup', 'true');
    setIsLangPopupNeeded(true);

    const onTimeoutPopupFunction = setTimeout(() => {
      setIsLangPopupStartHide(true);
    }, 5000);

    return () => clearTimeout(onTimeoutPopupFunction);
  }, []);

  useEffect(() => {
    const onSetPopupStateHandler = () => {
      setIsLangPopupNeeded(false);
    };
    document.addEventListener('click', onSetPopupStateHandler);

    return () => document.removeEventListener('click', onSetPopupStateHandler);
  }, []);

  useEffect(() => {
    const onMouseEnterHandler = () => {
      setIsLangPopupNeeded(false);
    };
    document
      .querySelector('.Header')
      ?.addEventListener('mouseenter', onMouseEnterHandler);
    return () =>
      document
        .querySelector('.Header')
        ?.removeEventListener('mouseenter', onMouseEnterHandler);
  }, []);

  const announcement = window.INITIAL_STATE.announcement;

  const langToolTip = (
    <Tooltip
      className={isLangPopupStartHide ? 'Tooltip-Hidden' : ''}
      view="secondary"
      staticPosition
      directionPosition="downStartRight"
      size="l"
      secondLine={parse(
        bubbleText ||
          'You are opening our <b>English language</b> website. You can keep reading or switch to other languages.'
      )}
    ></Tooltip>
  );

  return (
    <div className={cnHeaderWrapper({ showBanner: scrolling })}>
      {/*{announcement && !showBanner && (*/}
      {/*  <BannerStandWithUkraine openMobileMenu={isModalOpen} />*/}
      {/*)}*/}
      <Header
        hovered={isHovered}
        scrolling={scrolling}
        openMobileMenu={isModalOpen}
        inverted={isInverted && !isModalOpen}
        className={announcement && 'Header_announcement'}
        leftSide={
          logo && (
            <>
              <HeaderModule>
                <HeaderLogo
                  onClick={() => (window.location = '/')}
                  inverted={isInverted}
                  logo={logo}
                  mode="link"
                />
              </HeaderModule>
            </>
          )
        }
        rightSide={
          <>
            {desktopMenuItems?.length > 0 && (
              <Mq query="--desktop">
                <HeaderModule>
                  <HeaderMenu
                    inverted={isInverted && !isModalOpen}
                    items={desktopMenuItems}
                    onHover={mouseEnterHandler}
                    onBlur={mouseLeaveHandler}
                    hoverState={isHovered}
                    onHeaderMenuHandler={onHeaderMenuHandler}
                  />
                </HeaderModule>
              </Mq>
            )}
            {(button || languages) && (
              <Mq query="--desktop">
                <div className="Header-Push">
                  {button && (
                    <Button
                      className="Header-Contact"
                      view="secondary"
                      as={'a'}
                      href={button?.link}
                      iconRight={IconArrowRight}
                      label={button?.title}
                    />
                  )}
                  {languages?.length > 1 && (
                    <div className="Header-Languages">
                      <Combobox
                        view="transparent"
                        dropdownView="transparent"
                        items={languages.filter(
                          w => w.title !== language?.title
                        )}
                        getItemLabel={item => item.title}
                        value={language}
                        onChange={({ value }) => {
                          window.location = value.link;
                        }}
                      />
                      {isLangPopupNeeded && langToolTip}
                    </div>
                  )}
                </div>
              </Mq>
            )}
            <Mq query="--tablet-max">
              <HeaderModule className="Header-Burger">
                <Button
                  inverted={isInverted && !isModalOpen}
                  view="ghost"
                  iconRight={isModalOpen ? IconClose : IconBurger}
                  onClick={
                    !isModalOpen
                      ? (): void => setIsModalOpen(!isModalOpen)
                      : null
                  }
                />
                {isLangPopupNeeded && langToolTip}
              </HeaderModule>
            </Mq>
          </>
        }
      />
      <Modal
        isOpen={isModalOpen}
        className={cnHeaderWrapper('Modal', { scrolling: scrolling })}
        hasOverlay
        width="full"
        onOverlayClick={(): void => setIsModalOpen(false)}
        rootClassName={'MobileMenu'}
        position="right"
        visibleHeader
        onOpen={() => {
          document.body.style.overflow = 'hidden';
        }}
        onClose={() => {
          document.body.style.overflow = 'unset';
        }}
        onEsc={(): void => {
          setIsModalOpen(false);
          document.body.style.overflow = 'unset';
        }}
      >
        {menuItems?.length > 0 && (
          <HeaderMenuMobile
            items={menuItems}
            language={language}
            languages={languages}
            match={match}
            onClick={(): void => setIsModalOpen(false)}
          />
        )}
      </Modal>
    </div>
  );
};
