import './ServicesPage.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import { PATH } from 'router';

import { useDataQuery, useGTMDataSend } from 'common/hooks';
import { queryServicesPage } from 'network';

import { cn } from 'ui/utils/bem';

import { EssenceCard } from '../../../common/components/EssenceCard/EssenceCard';
import { SiteBlock } from '../../../common/components/SiteBlock/SiteBlock';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { BackgroundDecor } from '@dataartdev/uikit/BackgroundDecor';
import { Article } from '../../../common/components/Article/Article';
import { ListLinks } from '@dataartdev/uikit/ListLinks';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { dictionariesSelector } from 'store';
import { useIntl } from 'react-intl';

const cnServicesPage = cn('ServicesPage');

type Props = RouteComponentProps & {};

export const ServicesPage: React.FC<Props> = ({ location }) => {
  const { data, loading } = useDataQuery(queryServicesPage) || {};
  const { title, servicesGroups, metaTitle, tags } = data || {};

  const { companySiteServices: staticContent }: any =
    useSelector(dictionariesSelector) || {};

  useGTMDataSend(location, metaTitle, tags);
  const { locale } = useIntl();

  return (
    <div className={cnServicesPage()}>
      {!loading && (
        <>
          <SiteBlock
            className={cnServicesPage('TopBlock')}
            breakpoints={{
              desktop: {
                topGap: '7xl',
                bottomGap: '6xl',
              },
              tablet: {
                topGap: '3xl',
                bottomGap: '6xl',
              },
              mobile: {
                topGap: '3xl',
              },
            }}
          >
            <div className="container">
              <Grid
                breakpoints={{
                  desktop: { cols: 12 },
                  tablet: { cols: 8, gap: 'm' },
                  mobile: { cols: 2 },
                }}
              >
                <GridItem
                  breakpoints={{
                    desktop: { col: 10 },
                    tablet: { col: 7 },
                    mobile: { col: 2 },
                  }}
                >
                  <Typography.Title size="lg" as="h1" parse>
                    {title}
                  </Typography.Title>
                </GridItem>
                <GridItem
                  breakpoints={{
                    desktop: { col: 2, colStart: 11 },
                    tablet: { col: 1, colStart: 8 },
                    mobile: { col: 2 },
                  }}
                >
                  <div className={cnServicesPage('TopBlock-Decor')} />
                </GridItem>
              </Grid>
            </div>
          </SiteBlock>

          <div className={cnServicesPage('ServicesList')}>
            {servicesGroups?.length > 0 &&
              servicesGroups.map((item, index) => (
                <SiteBlock
                  className={cnServicesPage('Service')}
                  breakpoints={{
                    desktop: {
                      topGap: '3xl',
                      bottomGap:
                        index + 1 === servicesGroups.length ? '3xxl' : '3xl',
                    },
                    tablet: {
                      topGap: '3xl',
                      bottomGap:
                        index + 1 === servicesGroups.length ? '3xxl' : '3xl',
                    },
                    mobile: {
                      topGap: '5xl',
                      bottomGap:
                        index + 1 === servicesGroups.length ? '3xxl' : '6xl',
                    },
                  }}
                  key={item.title}
                >
                  <BackgroundDecor view={item.background} />
                  <div className="container">
                    {item.title && (
                      <Typography.Title size="lg" as="h2" parse>
                        {item.title}
                      </Typography.Title>
                    )}
                    <Grid
                      breakpoints={{
                        desktop: { cols: 12, gap: 'xl' },
                        tablet: { cols: 8, rowGap: '3xl', colGap: 'm' },
                        mobile: { cols: 2, gap: '3xl' },
                      }}
                    >
                      {item.text && (
                        <GridItem
                          breakpoints={{
                            desktop: { col: 4 },
                            tablet: { col: 8 },
                            mobile: { col: 2 },
                          }}
                        >
                          <Article content={item.text} />
                        </GridItem>
                      )}
                      <GridItem
                        breakpoints={{
                          desktop: { col: 4 },
                          tablet: { col: 4 },
                          mobile: { col: 2 },
                        }}
                      >
                        <ListLinks
                          items={item.services}
                          getLabel={item => item.title}
                          getPath={item =>
                            generatePath(PATH.SERVICES_PAGE, {
                              slug: item.slug,
                              lang: locale === 'en' ? null : locale,
                            })
                          }
                        />
                      </GridItem>
                      {item.items.length > 0 && (
                        <GridItem
                          breakpoints={{
                            desktop: { col: 3, colStart: 10 },
                            tablet: { col: 3, colStart: 6 },
                            mobile: { col: 2 },
                          }}
                        >
                          {!item.isCarousel ? (
                            <div className={cnServicesPage('ArticlesList')}>
                              {item.items.map(item => (
                                <div
                                  key={item.id}
                                  className={cnServicesPage(
                                    'ArticlesList-Item'
                                  )}
                                >
                                  <EssenceCard
                                    {...item}
                                    size="xs"
                                    path={generatePath(PATH.BLOG_SINGLE, {
                                      slug: item.slug,
                                      lang: locale === 'en' ? null : locale,
                                    })}
                                  />
                                </div>
                              ))}
                            </div>
                          ) : (
                            <Slider
                              pagination={{ clickable: true }}
                              watchOverflow
                              spaceBetween={24}
                              observer={true}
                              observeSlideChildren={true}
                              observeParents={true}
                            >
                              {item.items.map(item => (
                                <SliderItem key={item.id}>
                                  <EssenceCard
                                    {...item}
                                    size="xs"
                                    path={generatePath(PATH.BLOG_SINGLE, {
                                      slug: item.slug,
                                      lang: locale === 'en' ? null : locale,
                                    })}
                                    descriptionSmall={
                                      staticContent.companySiteServicesMoreOnTopic
                                    }
                                  />
                                </SliderItem>
                              ))}
                            </Slider>
                          )}
                        </GridItem>
                      )}
                    </Grid>
                  </div>
                </SiteBlock>
              ))}
          </div>
        </>
      )}
    </div>
  );
};
