// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { Typography } from '@dataartdev/uikit/Typography';
import { Article } from '../../Article/Article';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { CollapseGroup } from '@dataartdev/uikit/CollapseGroup';
import { CtaScrollerBtn } from '../../CtaScrollerBtn';

import { ContentBuilderItem } from '../ContentBuilderItem';

export type Props = {
  type?: number;
  renderContent: (item: any) => void;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const {
    type,
    title,
    titleTag,
    text,
    items,
    contentBuilderItem,
    showCounter,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();

  const renderItemContent = item => {
    if (contentBuilderItem) {
      return (
        <>
          <ContentBuilderItem {...item} {...contentBuilderItem} />
          {item.button && (
            <div className={cnContentBuilderTemplate('CtaScrollerBtn')}>
              <CtaScrollerBtn
                isPopup={item.isPopup}
                title={item.button?.title || 'Get in Touch'}
                link={item.button?.link}
              />
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          <Article content={item.text} />
          {item.button && (
            <div className={cnContentBuilderTemplate('CtaScrollerBtn')}>
              <CtaScrollerBtn
                isPopup={item.isPopup}
                title={item.button?.title || 'Get in Touch'}
                link={item.button?.link}
              />
            </div>
          )}
        </>
      );
    }
  };

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <Grid
        breakpoints={{
          desktop: { cols: 12 },
          tablet: { cols: 12 },
          mobile: { cols: 12 },
        }}
      >
        <GridItem
          breakpoints={{
            desktop: { col: 8 },
            tablet: { col: 12 },
            mobile: { col: 12 },
          }}
        >
          {title && (
            <Typography.Title size="lg" as="h2">
              {title}
            </Typography.Title>
          )}
          {text && <Article content={text} />}
        </GridItem>
        {items && (
          <GridItem
            breakpoints={{
              desktop: { col: 12 },
              tablet: { col: 12 },
              mobile: { col: 12 },
            }}
          >
            <CollapseGroup
              hoverEffect
              size="l"
              items={items}
              getItemLabel={item => item.title}
              getItemContent={renderItemContent}
              showCounter={showCounter}
            />
          </GridItem>
        )}
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
