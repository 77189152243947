import axios, { AxiosResponse, AxiosError } from 'axios';
import {
  camelizeKeys,
  // decamelizeKeys
} from 'humps';
import { stringify } from 'query-string';
import Cookies from 'js-cookie';
import { CSRF_COOKIE_NAME, CSRF_HEADER_NAME } from 'common/constants';
import { history } from 'services/history/history';

const BACKEND_API = process.env.REACT_APP_BACKEND_API;

declare const window: any;

const httpClient = axios.create({
  baseURL: BACKEND_API,
  responseType: 'json',
  paramsSerializer: params => {
    // return stringify(decamelizeKeys(params));
    return stringify(params);
  },
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Accept-Language': localStorage.getItem('currentLang')
      ? window.INITIAL_STATE.languages.find(
          (w: { slug: string | null }) =>
            w.slug === localStorage.getItem('currentLang')
        )?.iso
      : window.INITIAL_STATE.detectedLanguage.iso,
  },
  auth: {
    username: 'web-api-login',
    password: 'vqYh2WV37vjKDMG8',
  },
});

httpClient.interceptors.request.use(request => {
  if (
    request.method === 'post' ||
    request.method === 'put' ||
    request.method === 'patch' ||
    request.method === 'delete'
  ) {
    const csrfToken = Cookies.get(CSRF_COOKIE_NAME);
    if (csrfToken) {
      request.headers[CSRF_HEADER_NAME] = csrfToken;
    }
  }
  return request;
});

// httpClient.interceptors.request.use(request => {
// request.data =
//   request.data && !(request.data instanceof FormData)
//     ? decamelizeKeys(request.data)
//     : request.data;
// return request;
// });

httpClient.interceptors.response.use(
  response => {
    if (response.config.responseType === 'json') {
      return (camelizeKeys(response) as unknown) as AxiosResponse;
    } else {
      return response;
    }
  },
  error => {
    return Promise.reject((camelizeKeys(error) as unknown) as AxiosError);
  }
);

httpClient.interceptors.response.use(undefined, (error: AxiosError) => {
  if (
    error.response &&
    error.response.status === 400 &&
    error.config.method === 'get'
  ) {
    history.replace({ ...history.location, state: { is400: true } });
  }

  if (error.response && error.response.status >= 500) {
    if (error.config.method === 'get') {
      history.replace({ ...history.location, state: { is500: true } });
    } else {
      console.info(error);
    }
  }

  return Promise.reject((error as unknown) as AxiosError);
});

export { httpClient };
