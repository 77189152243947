// @ts-nocheck
import './ContentCard.scss';

import React from 'react';

import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { cn } from '../../utils/bem';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { getSizeByMap } from '@dataartdev/uikit/__internal__/cjs-src/utils/getSizeByMap';
import { Article } from '../Article/Article';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography } from '@dataartdev/uikit/Typography';

export type Props = {
  photo?: object;
  title?: string;
  text?: string;
  view?: string;
  lineColor?: string;
};

const sizeMap = {
  filled: 'sm',
  default: 'tiny',
  secondary: 'sm',
};

export const cnContentCard = cn('ContentCard');

export const ContentCard = forwardRefWithAs<Props>((props, ref) => {
  const {
    photo,
    title,
    titleSize,
    text,
    view = 'default',
    lineColor,
    picture,
    linkTitle,
    color,
    link,
    titleTag,
    isInverted,
    pictureAtBottom,
    ...otherProps
  } = usePropsHandler(cnContentCard(), props);

  return (
    <div
      className={cnContentCard({ view, isInverted, pictureAtBottom })}
      {...otherProps}
    >
      {lineColor && (
        <div
          className={cnContentCard('Line')}
          style={{ backgroundColor: lineColor }}
        />
      )}
      {photo && <Picture {...photo} className={cnContentCard('Photo')} />}
      {picture && <Picture {...picture} className={cnContentCard('Photo')} />}
      {title && (
        <Typography.Title
          size={titleSize ?? getSizeByMap(sizeMap, view)}
          className={cnContentCard('Title')}
          // as={titleTag}
          as="h3"
        >
          {title}
        </Typography.Title>
      )}
      {text && <Article className={cnContentCard('Text')} content={text} />}

      {link && view === 'default' && (
        <div className={cnContentCard('Button')}>
          <Button
            view="link-primary"
            as="a"
            label={linkTitle}
            href={link}
            iconRight={IconArrowRight}
            decorBorder={color?.replace('#', '')}
          />
        </div>
      )}
    </div>
  );
});
