import './TeamPage.scss';

import React from 'react';
import { generatePath, RouteComponentProps } from 'react-router-dom';

import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { useSelector } from 'react-redux';
import { dictionariesSelector } from 'store';

import { useDataQuery, useGTMDataSend } from 'common/hooks';
import { queryTeamPage } from 'network';
import { PATH } from 'router';

import { cn } from 'ui/utils/bem';
import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';
import { Typography } from '@dataartdev/uikit/Typography';
import { MemberCard } from '@dataartdev/uikit/MemberCard';
import { BackgroundDecor } from '@dataartdev/uikit/BackgroundDecor';
import { Picture } from '@dataartdev/uikit/Picture';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Mq } from '@dataartdev/uikit/Mq';
import { TwitterCard } from '../../../common/components/TwitterCard/TwitterCard';

import DecorDesktop from 'assets/img/team_page_feed_decor.png';
import DecorDesktop2x from 'assets/img/team_page_feed_decor@2x.png';
import { useIntl } from 'react-intl';

export const cnTeamPage = cn('TeamPage');

type Props = RouteComponentProps & {};

export const TeamPage: React.FC<Props> = ({ history, location }) => {
  const { companySiteTeam: staticContent } =
    useSelector(dictionariesSelector) || {};
  const { data, loading } = useDataQuery(queryTeamPage) || {};
  const { breadcrumbs, members, advisors, quotes, metaTitle, tags } =
    data || {};

  const optionsSlider = {
    watchOverflow: true,
    navigation: true,
    slidesPerView: 'auto',
    spaceBetween: 8,
    breakpoints: {
      767: {
        spaceBetween: 16,
      },
      1199: {
        spaceBetween: 24,
      },
    },
  };

  const quotesSliderConfig = {
    watchOverflow: true,
    navigation: true,
    spaceBetween: 64,
    autoHeight: true,
    breakpoints: {
      767: {
        slidesPerView: 2,
      },
    },
  };

  const { locale } = useIntl();

  useGTMDataSend(location, metaTitle, tags);

  return (
    <React.Fragment>
      {!loading && (
        <div className={cnTeamPage()}>
          <div className={cnTeamPage('Breadcrumbs')}>
            <div className="container">
              {breadcrumbs && (
                <Breadcrumbs
                  getLabel={page => page.text}
                  getLink={page => page.url}
                  history={history}
                  pages={data.breadcrumbs}
                />
              )}
            </div>
          </div>

          <div className={cnTeamPage('Body')}>
            <div className="container">
              <div className={cnTeamPage('List')}>
                <Grid
                  breakpoints={{
                    mobile: {
                      cols: 3,
                      gap: 'xl',
                    },
                    tablet: {
                      cols: 6,
                      colGap: 'm',
                      rowGap: 'xl',
                    },
                    desktop: {
                      cols: 12,
                      colGap: 'xl',
                      rowGap: '3xl',
                    },
                  }}
                >
                  {members &&
                    members.map(member => (
                      <GridItem
                        breakpoints={{
                          desktop: { col: 4 },
                          tablet: { col: 3 },
                          mobile: { col: 3 },
                        }}
                        key={member.id}
                      >
                        <MemberCard
                          {...member}
                          routePath={generatePath(PATH.TEAM_MEMBER, {
                            slug: member.slug,
                            lang: locale === 'en' ? null : locale,
                          })}
                          socials
                        />
                      </GridItem>
                    ))}
                </Grid>
              </div>
              {quotes?.length > 0 && (
                <div className={cnTeamPage('Quotes')}>
                  <Mq query="--tablet-max">
                    <Typography.Text
                      className={cnTeamPage('Quotes-Title')}
                      size="xs"
                      weight="bold"
                      transform="uppercase"
                      as="h2"
                    >
                      Media
                    </Typography.Text>
                  </Mq>
                  <div className={cnTeamPage('Quotes-Decor')}>
                    <Picture
                      desktop={DecorDesktop}
                      desktop2x={DecorDesktop2x}
                    />
                  </div>
                  <Mq query="--desktop">
                    <div className={cnTeamPage('Quotes-List')}>
                      {quotes.map(qoute => (
                        <TwitterCard {...qoute} key={qoute.id} />
                      ))}
                    </div>
                  </Mq>
                  <Mq query="--tablet-max">
                    <div className={cnTeamPage('Quotes-Slider')}>
                      <Slider {...quotesSliderConfig}>
                        {quotes.map(qoute => (
                          <SliderItem key={qoute.id}>
                            <TwitterCard {...qoute} />
                          </SliderItem>
                        ))}
                      </Slider>
                    </div>
                  </Mq>
                </div>
              )}
            </div>
          </div>

          {advisors && (
            <div className={cnTeamPage('Leaders')}>
              <BackgroundDecor view="right-triangle-down-3" />
              <div className="container">
                {staticContent?.companySiteTeamAdvisorsTitle && (
                  <div className={cnTeamPage('LeadersTitle')}>
                    <Typography.Text
                      size="xs"
                      weight="bold"
                      transform="uppercase"
                      as="h2"
                    >
                      {staticContent.companySiteTeamAdvisorsTitle}
                    </Typography.Text>
                  </div>
                )}

                <div className={cnTeamPage('LeadersSlider')}>
                  <Slider {...optionsSlider} className="centered-navigation">
                    {advisors.map((member, index) => (
                      <SliderItem key={member.id + '_' + index}>
                        <div>
                          <MemberCard
                            {...member}
                            routePath={generatePath(PATH.TEAM_ADVISOR, {
                              slug: member.slug,
                              lang: locale === 'en' ? null : locale,
                            })}
                          />
                        </div>
                      </SliderItem>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
