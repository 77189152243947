import './PlainTextPage.scss';

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useDataQuery, useGTMDataSend } from 'common/hooks';
import { queryPlainPage } from 'network';

import { redirect404 } from 'router';
import { Loader } from '@dataartdev/uikit/Loader';
import { ContentBuilder } from '../../../common/components/ContentBuilder/ContentBuilder';
import { PATH } from '../../../router';
import { mutationMainAllForm, mutationMainForm } from '../../../network';
import { recaptchaFormKey } from '../../../common/constants';
import { useSelector } from 'react-redux';
import {
  dictionariesSelector,
  landingBreadcrumbsSelector,
} from '../../../store';
import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';

import { cn } from 'ui/utils/bem';

const cnPlainTextPage = cn('PlainTextPage');

type Props = RouteComponentProps & {};

export const PlainTextPage: React.FC<Props> = ({
  match,
  history,
  location,
}) => {
  const { data, error, loading } = useDataQuery(queryPlainPage);

  const { content } = data || {};

  const { title, text, metaTitle, tags } = data || {};
  const breadcrumbs = useSelector(landingBreadcrumbsSelector) || {};
  const staticContent = useSelector(dictionariesSelector) || {};

  const isBreadcrumbsEmpty = Object.keys(breadcrumbs).length === 0;
  const { companySiteForm: formContent }: any = staticContent;

  const {
    companySiteFormLetsTalkName,
    companySiteFormLetsTalkAddInfo,
    companySiteFormLetsTalkEmail: { companySiteFormLetsTalkEmailError },
    companySiteFormLetsTalkTrackingID,
    companySiteFormLetsTalkShowAll,
    companySiteFormLetsTalkRequiredField,
    companySiteFormLetsTalkSubmit,
    companySiteFormLetsTalkHideAll,
    companySiteFormLetsTalkCompany,
    companySiteFormLetsTalkSuccessTitle,
    companySiteFormLetsTalkSuccessText,
    companySiteFormLetsTalkTitle,
    companySiteFormLetsTalkText,
    companySiteFormLetsTalkCompanyErrors: {
      companySiteFormLetsTalkCompanyMinCharactersError,
      companySiteFormLetsTalkCompanyMaxCharactersError,
    },
    companySiteFormLetsTalkSubscribePolicy,
    companySiteFormLetsTalkPhone: { companySiteFormPhoneLetsTalkError },
    companySiteFormLetsTalkFirstName,
    companySiteFormLetsTalkLastName,
    companySiteFormLetsTalkMessage,
  } = formContent.companySiteFormLetsTalk || {};

  useEffect(() => {
    if (error && error.response && error.response.status === 404) {
      redirect404(history);
    }

    if (error && error.response && error.response.status === 400) {
      history.replace({ ...history.location, state: { is400: true } });
    }
  }, [error, history]);

  return (
    <React.Fragment>
      {loading && <Loader />}
      {!loading && (
        <div className={cnPlainTextPage()}>
          {!isBreadcrumbsEmpty && (
            <div className="container">
              <div className={cnPlainTextPage('Breadcrumbs')}>
                <Breadcrumbs
                  getLabel={page => page.title}
                  getLink={page => page.link}
                  history={history}
                  pages={breadcrumbs}
                  onClick={(page, e) => {
                    e.preventDefault();
                    window.location = page.link;
                  }}
                />
              </div>
            </div>
          )}
          <ContentBuilder
            items={content}
            aliases={PATH}
            onSubmitForm={async body =>
              await mutationMainForm({
                body: { ...body },
              })
            }
            dictionariesForm={{
              name: {
                label: companySiteFormLetsTalkName,
              },
              firstName: {
                label: companySiteFormLetsTalkFirstName,
              },
              lastName: {
                label: companySiteFormLetsTalkLastName,
              },
              phone: {
                label: 'Phone',
                error: companySiteFormPhoneLetsTalkError,
              },
              company: {
                label: companySiteFormLetsTalkCompany,
                error: {
                  minError: companySiteFormLetsTalkCompanyMinCharactersError,
                  maxError: companySiteFormLetsTalkCompanyMaxCharactersError,
                },
              },
              email: {
                label: 'Email',
                error: companySiteFormLetsTalkEmailError,
              },
              message: {
                label: companySiteFormLetsTalkMessage,
              },
              addInfo: {
                label: companySiteFormLetsTalkAddInfo,
              },
              success: {
                title: companySiteFormLetsTalkSuccessTitle,
                text: companySiteFormLetsTalkSuccessText,
              },
              required: companySiteFormLetsTalkRequiredField,
              button: companySiteFormLetsTalkSubmit,
              submitButtonID: companySiteFormLetsTalkTrackingID,
              policy: companySiteFormLetsTalkSubscribePolicy,
            }}
          />
        </div>
      )}
    </React.Fragment>
  );
};
