import React from 'react';

import { cn } from '../../utils/bem';
import { usePropsHandler } from '../EventInterceptor/usePropsHandler';

import { ContentBuilderItem } from './ContentBuilderItem';
import { nanoid } from '@reduxjs/toolkit';

export interface BodyType {
  [key: string]: string | number | boolean | null | undefined;
}

type Props = {
  items: any;
  aliases?: any;
};

export const cnContentBuilder = cn('ContentBuilder');

export const ContentBuilder: React.FC<Props> = props => {
  const { items, aliases, ...otherProps } = usePropsHandler(
    cnContentBuilder(),
    props
  );
  return (
    <div className={cnContentBuilder()} key={nanoid()}>
      {items?.map(item => (
        <div className={cnContentBuilder('Item')} key={nanoid()}>
          <ContentBuilderItem {...item} aliases={aliases} {...otherProps} />
        </div>
      ))}
    </div>
  );
};

export { ContentBuilderItem } from './ContentBuilderItem';
