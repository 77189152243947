import './ContentBuilderTemplate.scss';
import './ContentBuilderTemplate104.scss';

import React from 'react';
import { cn } from '../../../utils/bem';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { FeaturedCard } from '../../FeaturedCard/FeaturedCard';
import { cnContentBuilderTemplate } from './index';
import { useIntl } from 'react-intl';
import { IRoot104 } from '../models/CTI_104.props';

const cnFeaturedSection = cn('FeaturedSection');

export const ContentBuilderTemplate: React.FC<IRoot104> = props => {
  const { type, title, items, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();
  const { locale } = useIntl();
  const lang = locale === 'en' ? undefined : locale;

  return (
    <div className={cnContentBuilderTemplate({ type: typeNumber })}>
      <div className={cnFeaturedSection()}>
        <Grid
          breakpoints={{
            desktop: { cols: 12, gap: 'xl' },
            tablet: { cols: 12, gap: 'xl' },
            mobile: { cols: 12, gap: 'xl' },
          }}
        >
          <GridItem
            breakpoints={{
              desktop: { col: 8 },
              tablet: { col: 10 },
              mobile: { col: 12 },
            }}
          >
            {title && (
              <Typography.Title size="lg" as="h2" id={contentId}>
                {title}
              </Typography.Title>
            )}
          </GridItem>
          <GridItem
            breakpoints={{
              desktop: { col: 12 },
              tablet: { col: 12 },
              mobile: { col: 12 },
            }}
          >
            <Grid
              breakpoints={{
                desktop: { cols: 12, gap: 'xl' },
                tablet: { cols: 12, gap: 'xl' },
                mobile: { cols: 12, gap: 'xl' },
              }}
            >
              {items &&
                items.length > 0 &&
                items.map(item => (
                  <GridItem
                    key={item.id}
                    breakpoints={{
                      desktop: { col: 3 },
                      tablet: { col: 6 },
                      mobile: { col: 12 },
                    }}
                    className={cnFeaturedSection('Item')}
                  >
                    <FeaturedCard {...item} />
                  </GridItem>
                ))}
            </Grid>
          </GridItem>
        </Grid>
      </div>
    </div>
  );
};

export default ContentBuilderTemplate;
