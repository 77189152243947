import './ValuesCard.scss';

import React from 'react';
import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { cn } from '../../utils/bem';
import { getSizeByMap } from '@dataartdev/uikit/__internal__/cjs-src/utils/getSizeByMap';
import { Article } from '../Article/Article';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography } from '@dataartdev/uikit/Typography';
import { Item } from '../ContentBuilder/models/CTI_2.props';
import { TypographyPropSize } from '@dataartdev/uikit/__internal__/src/components/Typography/Title/Title';
import { Button } from '@dataartdev/uikit/Button';
import { prepareButtonAttrs } from '../../utils/prepareButtonAttrs';

export const cardPropSize = ['s', 'm'] as const;
export type CardPropSize = typeof cardPropSize[number];
export const cardPropSizeDefault: CardPropSize = cardPropSize[0];

export const cnValuesCard = cn('ValuesCard');

const sizeMapTitle: Record<string, TypographyPropSize> = {
  s: 'tiny',
  m: 'sm',
  l: 'md',
};

export const ValuesCard: React.FC<Item> = props => {
  const {
    color,
    picture,
    text,
    title,
    view,
    size = cardPropSizeDefault,
    renderContent,
    number,
    subtitle,
    link,
    overTitle,
  } = usePropsHandler(cnValuesCard(), props);

  const buttonAttrs = prepareButtonAttrs(
    link?.link,
    link?.title,
    link?.targetBlank
  );

  return (
    <div className={cnValuesCard({ size, view, number: !!number })}>
      {overTitle && (
        <Typography.Text
          size="xs"
          weight="bold"
          transform="uppercase"
          className={cnValuesCard('Over-Title')}
        >
          {overTitle}
        </Typography.Text>
      )}
      {number && (
        <Typography.Text
          size="xs"
          weight="bold"
          transform="uppercase"
          className={cnValuesCard('Number')}
        >
          {number}
        </Typography.Text>
      )}
      {color && (
        <div
          className={cnValuesCard('Border')}
          style={{ backgroundColor: color }}
        />
      )}
      {picture && (
        <Picture
          desktop={picture.url}
          desktopWebp={picture.urlWebp}
          className={cnValuesCard('Picture')}
        />
      )}
      {title && (
        <Typography.Title
          size={getSizeByMap(sizeMapTitle, size)}
          className={cnValuesCard('Title')}
          parse
          as="h3"
        >
          {title}
        </Typography.Title>
      )}
      {subtitle && (
        <Typography.Text
          weight="bold"
          className={cnValuesCard('SubTitle')}
          parse
        >
          {subtitle}
        </Typography.Text>
      )}
      {text && <Article className={cnValuesCard('Text')} content={text} />}
      {renderContent && (
        <div className={cnValuesCard('Content')}>{renderContent}</div>
      )}
      {link && (
        <Button
          className={cnValuesCard('Button')}
          view="link-secondary"
          iconSize="m"
          {...buttonAttrs}
        />
      )}
    </div>
  );
};
