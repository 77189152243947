import './PressKitPage.scss';

import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { dictionariesSelector } from 'store';

import { useDataQuery, useGTMDataSend } from 'common/hooks';
import { queryPressKitPage } from '../../../network/api/presskit';
import { mutationFileForm } from '../../../network';
import { recaptchaKey, recaptchaFormKey } from '../../../common/constants';
import { cn } from 'ui/utils/bem';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';
import { MemberCard } from '@dataartdev/uikit/MemberCard';
import { FileCard } from '../../../common/components';
import { MaterialItem } from '../../../common/components/MaterialItem';
import { SiteBlock } from '../../../common/components/SiteBlock/SiteBlock';

import sbjs from 'sourcebuster';

sbjs.init();

export const cnPressKitPage = cn('PressKitPage');

type Props = RouteComponentProps & {};

export const PressKitPage: React.FC<Props> = ({ history }) => {
  const { companySitePressKit: staticContent, companySiteForm }: any =
    useSelector(dictionariesSelector) || {};

  const title =
    staticContent.companySitePressKitBrandMaterialsTitle ||
    `Download brand materials`;
  const legalNotice =
    staticContent.companySitePressKitLegalNotice ||
    `Legal Notice: © DataArt and DataArt logo are registered trademarks of DataArt Solutions, Inc. All rights reserved.`;
  const mediaContact =
    staticContent.companySitePressKitMediaContactTitle ||
    `USA, UK & Europe media contact`;

  const { data, loading, error, ...other } =
    useDataQuery(queryPressKitPage) || {};

  const { brand, breadcrumbs, documents, mediaPerson, metaTitle, tags } =
    data || {};

  const path = useLocation();
  useGTMDataSend(path, metaTitle, tags);

  const cameFrom = `typ: ${sbjs.get.current.typ} | src: ${sbjs.get.current.src} | mdm: ${sbjs.get.current.mdm} | cmp: ${sbjs.get.current.cmp} | cnt: ${sbjs.get.current.cnt} | trm: ${sbjs.get.current.trm}`;
  const submitButtonID =
    companySiteForm?.companySiteFormDownloadFile
      ?.companySiteFormDownloadFileTrackingID || 'file_download';

  return (
    <React.Fragment>
      {!loading && (
        <div className={cnPressKitPage()}>
          <SiteBlock topGap="xl" bottomGap="3xxl">
            <Grid
              breakpoints={{
                desktop: { cols: 12 },
                tablet: { cols: 12 },
                mobile: { cols: 12 },
              }}
              className={cnPressKitPage('PressKitWrapper container')}
            >
              <GridItem
                breakpoints={{
                  desktop: { col: 12 },
                  tablet: { col: 12 },
                }}
              >
                <div className={cnPressKitPage('Breadcrumbs')}>
                  {breadcrumbs && (
                    <Breadcrumbs
                      getLabel={page => page.text}
                      getLink={page => page.url}
                      history={history}
                      pages={breadcrumbs}
                    />
                  )}
                </div>
              </GridItem>
            </Grid>
            <Grid
              breakpoints={{
                desktop: { cols: 12, gap: 'xl' },
                tablet: { cols: 8, gap: 'xxl' },
                mobile: { cols: 1, gap: 'xxl' },
              }}
              className={cnPressKitPage('PressKitWrapper container')}
            >
              <GridItem
                breakpoints={{
                  desktop: { col: 9 },
                  tablet: { col: 8 },
                }}
                className={cnPressKitPage('Left')}
              >
                <div className={cnPressKitPage('Materials')}>
                  <Typography.Text
                    size="xs"
                    weight="bold"
                    transform="uppercase"
                    as="h2"
                  >
                    {title || `title`}
                  </Typography.Text>
                  <Grid
                    breakpoints={{
                      desktop: { cols: 9, gap: 'xl' },
                      tablet: { cols: 8, gap: 'xl' },
                      mobile: { cols: 1, gap: 'xl' },
                    }}
                    className={cnPressKitPage('Materials-List')}
                  >
                    {brand?.map((item: any) => {
                      if (!item?.logoPreview) {
                        return;
                      }
                      return (
                        <GridItem
                          key={item.id}
                          breakpoints={{
                            desktop: { col: 3 },
                            tablet: { col: 4 },
                          }}
                          className={cnPressKitPage('Materials-Item')}
                        >
                          <MaterialItem {...item} />
                        </GridItem>
                      );
                    })}
                  </Grid>
                  <Typography.Text
                    size="s"
                    as="p"
                    className={cnPressKitPage('Legal')}
                  >
                    {legalNotice}
                  </Typography.Text>
                </div>
                <Grid
                  breakpoints={{
                    desktop: { cols: 8, gap: 'm' },
                    tablet: { cols: 8, gap: 'm' },
                    mobile: { cols: 1, gap: 'm' },
                  }}
                  className={cnPressKitPage('Files')}
                >
                  {documents?.map((item: any) => (
                    <GridItem
                      breakpoints={{
                        desktop: { col: 4 },
                        tablet: { col: 4 },
                      }}
                      className={cnPressKitPage('File-Item')}
                    >
                      <FileCard
                        size={item.file.size}
                        extension={item.file.type}
                        date={item.date}
                        title={item.title}
                        view="null"
                        url={item.file.url}
                        recaptchaKey={recaptchaKey}
                        onSubmit={async body =>
                          await mutationFileForm({
                            body: {
                              ...body,
                              filePath: item.file.url,
                              pageId: data.id,
                              [`${recaptchaFormKey}`]: body.token,
                              cameFrom: cameFrom,
                              trackingId: submitButtonID,
                            },
                          })
                        }
                      />
                    </GridItem>
                  ))}
                </Grid>
              </GridItem>
              <GridItem
                breakpoints={{
                  desktop: { col: 3 },
                  tablet: { col: 8 },
                }}
                className={cnPressKitPage('Right')}
              >
                {mediaPerson && (
                  <div className={cnPressKitPage('Contact')}>
                    <Typography.Text
                      size="xs"
                      weight="bold"
                      transform="uppercase"
                      as="h2"
                    >
                      {mediaContact || `media contact`}
                    </Typography.Text>
                    <div className={cnPressKitPage('Contact-Card')}>
                      <MemberCard {...mediaPerson} socials contacts />
                    </div>
                  </div>
                )}
              </GridItem>
            </Grid>
          </SiteBlock>
        </div>
      )}
    </React.Fragment>
  );
};
