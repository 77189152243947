import './LocationsPage.scss';

import React, { useState } from 'react';
import {
  generatePath,
  RouteComponentProps,
  useLocation,
} from 'react-router-dom';

import StackGrid from 'react-stack-grid';
import { useSelector } from 'react-redux';
import {
  isMobileSelector,
  isTabletSelector,
  isDesktopSelector,
  dictionariesSelector,
} from 'store';

import { useDataQuery, useGTMDataSend } from 'common/hooks';
import { queryLocationsPage } from 'network';
import { PATH } from 'router';
import Sticky from 'react-stickynode';

import { cn } from 'ui/utils/bem';
import { PageCard } from '../../../common/components/PageCard/PageCard';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Button } from '@dataartdev/uikit/Button';
import { Mq } from '@dataartdev/uikit/Mq';
import { Tooltip } from '@dataartdev/uikit/Tooltip';
import { Picture } from '@dataartdev/uikit/Picture';
import { BackgroundDecor } from '@dataartdev/uikit/BackgroundDecor';
import { SocialButton } from '@dataartdev/uikit/SocialButton';
import { SiteBlock } from '../../../common/components/SiteBlock/SiteBlock';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { IconPin } from '@dataartdev/uikit/IconPin';
import { IconPhone } from '@dataartdev/uikit/IconPhone';
import { IconEmail } from '@dataartdev/uikit/IconEmail';
import { IconFacebook } from '@dataartdev/uikit/IconFacebook';
import { IconTwitter } from '@dataartdev/uikit/IconTwitter';
import { IconMedium } from '@dataartdev/uikit/IconMedium';
import { IconLinkedin } from '@dataartdev/uikit/IconLinkedin';
import { IconGithub } from '@dataartdev/uikit/IconGithub';

import { LocationsForm } from '../components/LocationsForm';
import { useIntl } from 'react-intl';

export const cnLocationsPage = cn('LocationsPage');
export const cnCountry = cn('Country');
export const cnGlobe = cn('Globe');

type Props = RouteComponentProps & {};

export const LocationsPage: React.FC<Props> = ({ history }) => {
  const { companySiteLocations: staticContent, companySiteForm: formsContent } =
    useSelector(dictionariesSelector) || {};
  const isMobile = useSelector(isMobileSelector);
  const isTablet = useSelector(isTabletSelector);
  const isDesktop = useSelector(isDesktopSelector);

  const { data, loading } = useDataQuery(queryLocationsPage) || {};

  const {
    countries,
    text,
    pressKitTitle,
    pressKitText,
    pressKitPicture,
    pressKitLink,
    pressKitLinkText,
    phone,
    email,
    facebook,
    github,
    linkedin,
    medium,
    twitter,
    metaTitle,
    tags,
  } = data || {};

  const [currentGlobe, setCurrentGlobe] = useState(0);
  const [currentMarker, setCurrentMarker] = useState(0);
  const [locationData, setLocationData] = useState(null);

  const { locale } = useIntl();

  const handleMouseEnter = (
    countryIndex,
    locationId,
    skip = false,
    location = null
  ) => {
    if (!skip) setCurrentGlobe(countryIndex);
    setCurrentMarker(locationId);

    setLocationData(location);
  };

  const handleMouseLeave = e => {
    setCurrentMarker(0);
    setLocationData(null);
  };

  const path = useLocation();
  useGTMDataSend(path, metaTitle, tags);

  return (
    <React.Fragment>
      {!loading && (
        <div className={cnLocationsPage()}>
          <SiteBlock topGap="7xl" bottomGap="xxl">
            <BackgroundDecor view="right-triangle-down-0" />
            <Grid
              breakpoints={{
                desktop: { cols: 12, gap: 'xl' },
                tablet: { cols: 8, gap: 'm' },
                mobile: { cols: 1, gap: 'xxl' },
              }}
              className={cnLocationsPage('ContactsWrapper container')}
            >
              <GridItem
                breakpoints={{
                  desktop: { col: 6, colStart: 2 },
                  tablet: { col: 4 },
                }}
              >
                <LocationsForm
                  dictionaries={formsContent.companySiteFormContactUs}
                />
              </GridItem>
              <GridItem
                breakpoints={{
                  desktop: { col: 4, colStart: 9 },
                  tablet: { col: 4 },
                }}
              >
                <div className={cnLocationsPage('Contacts')}>
                  <Typography.Text
                    size="xs"
                    weight="bold"
                    transform="uppercase"
                    className={cnLocationsPage('Contacts-Title')}
                    as="h2"
                  >
                    {
                      staticContent.companySiteLocationsContactUs
                        .companySiteLocationsContactUsTitle
                    }
                  </Typography.Text>
                  <div className={cnLocationsPage('Contacts-List')}>
                    <div className={cnLocationsPage('Contacts-List-Item')}>
                      <Typography.Text
                        size="xs"
                        weight="bold"
                        transform="uppercase"
                        className={cnLocationsPage('Contacts-List-Left')}
                      >
                        <IconPhone size="xs" />
                        {
                          staticContent.companySiteLocationsContactUs
                            .companySiteLocationsContactUsPhone
                        }
                      </Typography.Text>
                      <div className={cnLocationsPage('Contacts-List-Right')}>
                        <Typography.Text
                          weight="bold"
                          href={'tel:' + phone}
                          as="a"
                        >
                          {phone}
                        </Typography.Text>
                      </div>
                    </div>
                    <div className={cnLocationsPage('Contacts-List-Item')}>
                      <Typography.Text
                        size="xs"
                        weight="bold"
                        transform="uppercase"
                        className={cnLocationsPage('Contacts-List-Left')}
                      >
                        <IconEmail size="xs" />
                        {
                          staticContent.companySiteLocationsContactUs
                            .companySiteLocationsContactUsEmail
                        }
                      </Typography.Text>
                      <div className={cnLocationsPage('Contacts-List-Right')}>
                        <Typography.Text
                          weight="bold"
                          href={'mailto:' + email}
                          as="a"
                        >
                          {email}
                        </Typography.Text>
                      </div>
                    </div>
                  </div>

                  <div className={cnLocationsPage('Contacts-Socials')}>
                    <Typography.Text
                      size="xs"
                      weight="bold"
                      transform="uppercase"
                      className={cnLocationsPage('Contacts-Socials-Title')}
                      as="h2"
                    >
                      {
                        staticContent.companySiteLocationsContactUs
                          .companySiteLocationsContactUsSocialMediaTitle
                      }
                    </Typography.Text>
                    <div className={cnLocationsPage('Contacts-Socials-List')}>
                      {facebook && (
                        <SocialButton
                          icon={IconFacebook}
                          as="a"
                          href={facebook}
                        />
                      )}
                      {twitter && (
                        <SocialButton
                          icon={IconTwitter}
                          as="a"
                          href={twitter}
                        />
                      )}
                      {medium && (
                        <SocialButton icon={IconMedium} as="a" href={medium} />
                      )}
                      {linkedin && (
                        <SocialButton
                          icon={IconLinkedin}
                          as="a"
                          href={linkedin}
                        />
                      )}
                      {github && (
                        <SocialButton icon={IconGithub} as="a" href={github} />
                      )}
                    </div>
                  </div>
                </div>
              </GridItem>
            </Grid>
          </SiteBlock>
          <div className="container">
            {(!!staticContent.companySiteLocationsTitle ||
              countries?.length) && (
              <div className={cnLocationsPage('Body')}>
                <Typography.Text
                  className={cnLocationsPage('Title')}
                  size="xs"
                  weight="bold"
                  transform="uppercase"
                  as="h2"
                >
                  {staticContent.companySiteLocationsTitle}
                </Typography.Text>
                <Grid
                  breakpoints={{
                    mobile: {
                      cols: 2,
                    },
                    tablet: {
                      cols: 8,
                      gap: 'm',
                    },
                    desktop: {
                      cols: 12,
                      gap: 'xl',
                    },
                  }}
                >
                  <GridItem
                    breakpoints={{
                      mobile: {
                        col: 2,
                      },
                      tablet: {
                        col: 4,
                      },
                      desktop: {
                        col: 5,
                        colStart: 1,
                      },
                    }}
                  >
                    <Sticky
                      enabled={isTablet || isDesktop}
                      top={110}
                      bottomBoundary={'.LocationsPage-Body'}
                    >
                      <div className={cnLocationsPage('Globe')}>
                        {countries &&
                          countries.map((country, index) => (
                            <div
                              className={cnLocationsPage('GlobeItem', {
                                active: currentGlobe === index,
                              })}
                              key={country.id}
                            >
                              <div className={cnGlobe()}>
                                <div className={cnGlobe('Picture')}>
                                  <Picture
                                    desktop={country.picture.url}
                                    desktopWebp={country.picture.urlWebp}
                                  />
                                </div>
                                <div className={cnGlobe('Marks')}>
                                  {country.locations.map(
                                    (location, countryIndex) => {
                                      const style = {
                                        left: location.picturePointX + '%',
                                        top: location.picturePointY + '%',
                                      };
                                      return (
                                        <div
                                          key={location.id}
                                          style={style}
                                          className={cnGlobe('Marker', {
                                            active:
                                              currentMarker === location.id,
                                          })}
                                          onMouseEnter={() =>
                                            handleMouseEnter(
                                              countryIndex,
                                              location.id,
                                              true,
                                              location
                                            )
                                          }
                                          onMouseLeave={() =>
                                            handleMouseLeave(countryIndex)
                                          }
                                        >
                                          <IconPin size="xs" />
                                          {currentMarker === location.id && (
                                            <div className={cnGlobe('Pin')}>
                                              <div
                                                className={cnGlobe('PinTitle')}
                                              >
                                                <Typography.Text
                                                  size="xs"
                                                  transform="uppercase"
                                                  weight="bold"
                                                >
                                                  {location.title}
                                                </Typography.Text>
                                              </div>
                                              {location.pictureTooltipText && (
                                                <div
                                                  className={cnGlobe(
                                                    'PinSubTitle'
                                                  )}
                                                >
                                                  <Typography.Text size="s">
                                                    {
                                                      location.pictureTooltipText
                                                    }
                                                  </Typography.Text>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Sticky>

                    {!!currentMarker && locationData && (
                      <Mq query="--mobile">
                        <div className={cnLocationsPage('GlobeTooltip')}>
                          <Tooltip
                            view="secondary"
                            staticPosition
                            directionPosition="downRight"
                            size="xl"
                          >
                            {locationData.title && (
                              <Typography.Text
                                size="xs"
                                weight="bold"
                                transform="uppercase"
                                className="TitleItem"
                              >
                                {locationData.title}
                              </Typography.Text>
                            )}
                            {locationData.pictureTooltipText && (
                              <Typography.Text size="xs" className="TextItem">
                                {locationData.pictureTooltipText}
                              </Typography.Text>
                            )}
                          </Tooltip>
                        </div>
                      </Mq>
                    )}
                  </GridItem>
                  <GridItem
                    breakpoints={{
                      mobile: {
                        col: 2,
                      },
                      tablet: {
                        col: 4,
                      },
                      desktop: {
                        col: 8,
                        colStart: 7,
                      },
                    }}
                  >
                    <StackGrid
                      gutterHeight={48}
                      gutterWidth={isTablet || isMobile ? 16 : 0}
                      duration={0}
                      columnWidth={isTablet || isMobile ? '50%' : '33.33%'}
                      className={cnLocationsPage('List')}
                    >
                      {countries &&
                        countries.map((country, countryIndex) => (
                          <div
                            className={cnLocationsPage('ListItem')}
                            key={country.id}
                          >
                            <div className={cnCountry()}>
                              <div className={cnCountry('Name')}>
                                <Typography.Text size="s">
                                  {country.title}
                                </Typography.Text>
                              </div>
                              <div className={cnCountry('List')}>
                                {country.locations.map(location => (
                                  <div key={location.slug}>
                                    <Button
                                      view="link-primary"
                                      size="sm"
                                      onMouseEnter={() => {
                                        if (!isMobile && !isTablet) {
                                          handleMouseEnter(
                                            countryIndex,
                                            location.id
                                          );
                                        }
                                      }}
                                      onMouseLeave={() => {
                                        if (!isMobile && !isTablet) {
                                          handleMouseLeave(countryIndex);
                                        }
                                      }}
                                      onClick={() => {
                                        if (isMobile || isTablet) {
                                          if (currentMarker === location.id) {
                                            history.push(
                                              generatePath(
                                                PATH.LOCATIONS_SINGLE,
                                                {
                                                  slug: location.slug,
                                                  lang:
                                                    locale === 'en'
                                                      ? null
                                                      : locale,
                                                }
                                              )
                                            );
                                          }
                                          setCurrentGlobe(countryIndex);
                                          setCurrentMarker(location.id);
                                        } else {
                                          history.push(
                                            generatePath(
                                              PATH.LOCATIONS_SINGLE,
                                              {
                                                slug: location.slug,
                                                lang:
                                                  locale === 'en'
                                                    ? null
                                                    : locale,
                                              }
                                            )
                                          );
                                        }
                                      }}
                                    >
                                      <Typography.Title size="sm">
                                        {location.title}
                                      </Typography.Title>
                                    </Button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        ))}
                    </StackGrid>
                  </GridItem>
                </Grid>
              </div>
            )}
            <div className={cnLocationsPage('Bottom')}>
              {text && (
                <div className={cnLocationsPage('Text')}>
                  <Typography.Title size="md">{text}</Typography.Title>
                </div>
              )}

              <div className={cnLocationsPage('BottomLink')}>
                <Button
                  view="link-secondary"
                  iconRight={IconArrowRight}
                  iconSize={'m'}
                  label={staticContent.companySiteLocationsServicesLinkText}
                  isLink
                  to={generatePath(PATH.SERVICES, {
                    lang: locale === 'en' ? null : locale,
                  })}
                />
              </div>
            </div>
            <div className={cnLocationsPage('BottomCard')}>
              <PageCard
                size="l"
                title={pressKitTitle}
                subtitle={pressKitText}
                link={pressKitLink}
                linkText={pressKitLinkText}
                linkView="link-primary"
                decor={pressKitPicture}
                decorPosition="bottomRight"
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
