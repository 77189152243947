import './RedirectForm.scss';

import React from 'react';
import { Typography } from '@dataartdev/uikit/Typography';

import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';

import { cnModalPage } from '../ModalPage';

import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';

type Props = {
  onSubmitForm: (val: any) => void;
  modalData: any;
};

export const RedirectForm: React.FC<Props> = ({ modalData }, ref) => {
  return (
    <div className={cnModalPage({ redirectForm: true })}>
      {modalData.title && (
        <Typography.Text
          size="xs"
          weight="bold"
          transform="uppercase"
          className={cnModalPage('Title')}
        >
          {modalData.title}
        </Typography.Text>
      )}
      <div className={cnModalPage('LeftSide')}>
        {modalData.subtitle && (
          <Typography.Title size="md" className={cnModalPage('SubTitle')}>
            {modalData.subtitle}
          </Typography.Title>
        )}
        {modalData.text && (
          <Typography.Text className={cnModalPage('Text')}>
            {modalData.text}
          </Typography.Text>
        )}
        {modalData.link && (
          <Button
            view="secondary"
            label={modalData.link.title}
            as="a"
            href={modalData.link.link}
            target={modalData.link.targetBlank ? '_blank' : null}
            iconRight={IconOpenInNew}
            iconSize="xs"
            className={cnModalPage('Button')}
          />
        )}
      </div>
      {modalData.picture && (
        <div className={cnModalPage('RightSide')}>
          <Picture {...modalData.picture} />
        </div>
      )}
    </div>
  );
};
