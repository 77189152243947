import { PATH } from '../paths';
import { PackageRoutesConfig } from 'router/models';
import { MainPage } from 'features/main/pages';
import { BlogPage, BlogSingle } from 'features/blog/pages';
import {
  LocationsPage,
  LocationPage,
  PressKitPage,
} from 'features/contact/pages';
import { ClientsPage, ClientPage, CasePage } from 'features/clients/pages';
import { EventsPage, EventPage } from 'features/events/pages';
import { ServicesPage, SingleService } from 'features/services/pages';
import {
  IndustriesPage,
  SingleIndustries,
  SingleInnerIndustries,
} from 'features/industries/pages';
import { NewsRoomPage, NewsRoomSinglePage } from 'features/newsroom/pages';
import {
  SustainabilityPage,
  TeamPage,
  TeamMemberPage,
  TeamAdvisorPage,
  AboutUsPage,
  WhyDataart,
} from 'features/company/pages';
import { PlainTextPage } from 'features/plainText/pages';
import { LAYOUT } from 'common/layouts';
import { Global404, Global500 } from 'router/components';

export const mainRoutesConfig: PackageRoutesConfig = {
  routes: [
    {
      path: PATH.MAIN,
      layout: LAYOUT.MAIN,
      component: MainPage,
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.BLOG,
      component: BlogPage,
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.BLOG_SINGLE,
      component: BlogSingle,
      update: 'path',
    },
    {
      path: PATH.ABOUT_US,
      layout: LAYOUT.MAIN,
      component: AboutUsPage,
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.WHY_DATAART,
      component: WhyDataart,
      exact: true,
    },
    {
      path: PATH.TEAM,
      layout: LAYOUT.MAIN,
      component: TeamPage,
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.TEAM_ADVISOR,
      component: TeamAdvisorPage,
      update: 'path',
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.TEAM_MEMBER,
      component: TeamMemberPage,
      update: 'path',
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.LOCATIONS,
      component: LocationsPage,
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.LOCATIONS_PRESS_KIT,
      component: PressKitPage,
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.LOCATIONS_SINGLE,
      component: LocationPage,
      exact: true,
    },
    {
      path: PATH.CLIENTS,
      component: ClientsPage,
      layout: LAYOUT.MAIN,
      exact: true,
    },
    {
      path: PATH.CLIENT_PAGE,
      component: ClientPage,
      layout: LAYOUT.MAIN,
      exact: true,
      update: 'path',
    },
    {
      path: PATH.CASE_PAGE,
      component: CasePage,
      exact: true,
      layout: LAYOUT.MAIN,
      update: 'path',
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.EVENTS,
      component: EventsPage,
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.EVENTS_ARCHIVE,
      component: EventsPage,
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.EVENTS_PAGE,
      component: EventPage,
      exact: true,
      update: 'path',
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.EVENTS_ARCHIVE_PAGE,
      component: EventPage,
      exact: true,
      update: 'path',
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.SUSTAINABILITY_PAGE,
      component: SustainabilityPage,
      exact: true,
    },
    {
      path: PATH.SERVICES,
      component: ServicesPage,
      layout: LAYOUT.MAIN,
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.SERVICES_PAGE,
      component: SingleService,
      exact: true,
      update: 'path',
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.SERVICES_PAGE_INNER,
      component: SingleInnerIndustries,
      exact: true,
      update: 'path',
    },
    {
      path: PATH.INDUSTRIES,
      component: IndustriesPage,
      exact: true,
      layout: LAYOUT.MAIN,
    },
    {
      path: PATH.INDUSTRIES_PAGE,
      component: SingleIndustries,
      exact: true,
      update: 'path',
      layout: LAYOUT.MAIN,
    },
    {
      path: PATH.INDUSTRIES_PAGE_INNER,
      component: SingleInnerIndustries,
      exact: true,
      update: 'path',
      layout: LAYOUT.MAIN,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.NEWSROOM,
      component: NewsRoomPage,
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.NEWSROOM_PAGE,
      component: NewsRoomSinglePage,
      exact: true,
      update: 'path',
    },
    {
      layout: LAYOUT.MAIN,
      path: '/500',
      component: Global500,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.PLAIN_TEXT,
      component: PlainTextPage,
      exact: true,
      update: 'path',
    },
    { layout: LAYOUT.MAIN, path: '*', component: Global404 },
  ],
};
