import './ContentBuilderTemplate.scss';

import React from 'react';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { Article } from '../../Article/Article';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { ListLinks } from '@dataartdev/uikit/ListLinks';
import { IRoot24 } from '../models/CTI_24.props';

export const ContentBuilderTemplate: React.FC<IRoot24> = props => {
  const {
    type,
    title,
    text,
    columnI,
    columnII,
    contentId,
    // industry,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Title
          size="lg"
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        >
          {title}
        </Typography.Title>
      )}
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: 'xl' },
          tablet: { cols: 8, rowGap: '3xl', colGap: 'm' },
          mobile: { cols: 2 },
        }}
      >
        {text && (
          <GridItem
            breakpoints={{
              desktop: { col: 4 },
              tablet: { col: 8 },
              mobile: { col: 2 },
            }}
            className={cnContentBuilderTemplate('Column')}
          >
            <Article content={text} />

            {/*{industry && (*/}
            {/*  <div className={cnContentBuilderTemplate('Button')}>*/}
            {/*    <Button*/}
            {/*      view="link-primary"*/}
            {/*      isLink*/}
            {/*      label={industry.title}*/}
            {/*      to={generatePath(aliases.INDUSTRIES_PAGE, {*/}
            {/*        slug: industry.slug,*/}
            {/*      })}*/}
            {/*      iconRight={IconArrowRight}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}
          </GridItem>
        )}
        {columnI && columnI.length > 0 && (
          <GridItem
            breakpoints={{
              desktop: { col: 4 },
              tablet: { col: 4 },
              mobile: { col: 2 },
            }}
          >
            <ListLinks
              items={columnI}
              getLabel={item => item?.title}
              getPath={item => item?.fullUrl}
            />
          </GridItem>
        )}
        {columnII && columnII.length > 0 && (
          <GridItem
            breakpoints={{
              desktop: { col: 4 },
              tablet: { col: 4 },
              mobile: { col: 2 },
            }}
          >
            <ListLinks
              items={columnII}
              getLabel={item => item?.title}
              getPath={item => item?.fullUrl}
            />
          </GridItem>
        )}
      </Grid>
    </div>
  );
};

export default ContentBuilderTemplate;
