import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { PATH } from '../paths';

type Props = RouteComponentProps<any, any, { fromProtected: boolean }> & {};

export const Global404: React.FC<Props> = ({ location }) => {
  if (location.state && location.state.fromProtected) {
    return <Redirect to={PATH.MAIN} />;
  }
  return <Redirect to={{ ...location, state: { is404: true } }} />;
};
