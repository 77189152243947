import React, { useRef } from 'react';
import { TextField } from '@dataartdev/uikit/TextField';
import {
  ErrorMessage,
  Formik,
  Form,
  FormikValues,
  FormikHelpers,
} from 'formik';
import * as yup from 'yup';
import { cn } from 'ui/utils/bem';
import { Checkbox } from '@dataartdev/uikit/Checkbox';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Button } from '@dataartdev/uikit/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { usePagePath } from '../../hooks/usePagePath';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';
import { clearFalsyProps } from '../../utils';
import './FormBuilder.scss';
import { IFormBuilder, IFormBuilderDict } from './models';
import { Typography } from '@dataartdev/uikit/Typography';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

export const cnFormBuilder = cn('FormBuilder');

type Props = {
  formTitle?: string | null;
  formText?: string | null;
  form: IFormBuilder | null | undefined;
  onSubmitForm: <T, D>(data: D | undefined) => Promise<T>;
  dictionariesForm: IFormBuilderDict | null | undefined;
  setModalOpen?: (value: boolean) => void;
  submitText?: string;
  setSuccessForm: (value: boolean) => void;
  buttonAttr?: { [key: string]: string | boolean };
  cameFrom?: string;
  utm?: string;
};

const FormBuilder: React.FC<Props> = ({
  formTitle,
  formText,
  form,
  onSubmitForm,
  setModalOpen,
  setSuccessForm,
  submitText,
  dictionariesForm,
  cameFrom,
  utm,
  buttonAttr,
}) => {
  const {
    dataLayerFormName,
    dataLayerTags,
    emailFieldError = 'Invalid email address',
    phoneFieldError = 'Invalid phone number',
    requiredFieldError = 'Field is required',
    formFields,
  } = form || {};

  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  const validationSchema = (
    emailError: string,
    phoneError: string,
    requiredError: string
  ) =>
    yup.object(
      formFields?.reduce((acc, curr) => {
        if (curr.fieldType === 0) {
          if (curr.name === 'email') {
            return {
              ...acc,
              [curr.name]: yup
                .string()
                .email(emailError)
                .test('is-required', requiredError, value => {
                  if (!!curr.validation) {
                    return !!value;
                  }
                  return true;
                })
                .nullable(),
            };
          }
          return {
            ...acc,
            [curr.name]: yup
              .string()
              .min(3)
              .nullable()
              .test('is-required', requiredError, value => {
                if (!!curr.validation) {
                  return !!value;
                }
                return true;
              }),
          };
        }
        if (curr.fieldType === 1 && !!curr.validation?.length) {
          return {
            ...acc,
            [curr.name]: yup
              .boolean()
              .oneOf([!!curr.validation?.length], requiredError)
              .required(),
          };
        }
        if (curr.fieldType === 4) {
          return {
            ...acc,
            [curr.name]:
              curr && curr.validation && curr.validation.length > 0
                ? yup
                    .string()
                    .required(requiredError)
                    .test('is-valid-phone', phoneError, value =>
                      value ? isValidPhoneNumber(value) : false
                    )
                : yup
                    .string()
                    .nullable()
                    .test('is-valid-phone', phoneError, value =>
                      value ? isValidPhoneNumber(value) : true
                    ),
          };
        } else return acc;
      }, {})
    );

  const initialValues =
    formFields?.reduce((acc, curr) => {
      const { fieldType, name, value, valueType: type } = curr;
      switch (fieldType) {
        case 0:
        case 2:
        case 4:
          return { ...acc, [name]: value || '' };

        case 1:
          return { ...acc, [name]: false };
        case 3:
          if (type === 'utm') return { ...acc, [name]: utm || '' };
          if (type === 'cameFrom') return { ...acc, [name]: cameFrom || '' };
          return acc;
        default:
          return acc;
      }
    }, {} as FormikValues) || {};

  const privacyAgreement = formFields?.filter(field => field.fieldType === 1);

  const sendDataToGTM = useGTMDispatch();
  const pagePath = usePagePath();

  const onSubmit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    if (onSubmitForm) {
      let token = null;
      if (recaptchaKey) token = await reCaptchaRef.current?.executeAsync();
      const data = {
        ...values,
        [`${recaptchaFormKey}`]: token,
        trackingId: dictionariesForm?.submitButtonID || '',
      };
      await onSubmitForm(clearFalsyProps(data))
        .then(() => {
          setSuccessForm(true);
          actions.resetForm();
          // setModalOpen(false);
          sendDataToGTM({
            event: 'send_form',
            form_name: 'contact_us_form',
            label: values.email,
            page_path: pagePath,
          });
        })
        .catch(err => console.log(err));
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema(
        emailFieldError,
        phoneFieldError,
        requiredFieldError
      )}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        errors,
        dirty,
        handleBlur,
        handleChange,
        setFieldValue,
        values,
        touched,
      }) => {
        const isPhoneError = !!(touched.phone && errors.phone);
        return (
          <Form className={cnFormBuilder()}>
            {formTitle && (
              <Typography.Title parse className={cnFormBuilder('Title')}>
                {formTitle}
              </Typography.Title>
            )}
            {formText && (
              <Typography.Text parse className={cnFormBuilder('Description')}>
                {formText}
              </Typography.Text>
            )}
            <div className={cnFormBuilder('Fields')}>
              {formFields?.map(field => {
                if (field.fieldType === 0) {
                  return (
                    <TextField
                      key={field.name + field.fieldType + field.order}
                      label={field.title + (field?.validation ? '*' : '')}
                      name={field.name}
                      value={values[field.name]}
                      view="outline"
                      width="full"
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      caption={
                        touched[field.name] &&
                        typeof errors[field.name] === 'string'
                          ? (errors[field.name] as string)
                          : undefined
                      }
                      status={
                        touched[field.name] && !!errors[field.name]
                          ? 'alert'
                          : undefined
                      }
                    />
                  );
                }
                if (field.fieldType === 2 || field.fieldType === 3) {
                  return (
                    <TextField
                      key={field.name + field.fieldType + field.order}
                      style={{ display: 'none' }}
                      label={field.title}
                      name={field.name}
                      value={values[field.name]}
                      view="outline"
                      width="full"
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      caption={
                        touched[field.name] &&
                        typeof errors[field.name] === 'string'
                          ? (errors[field.name] as string)
                          : undefined
                      }
                      status={
                        touched[field.name] && !!errors[field.name]
                          ? 'alert'
                          : undefined
                      }
                    />
                  );
                }
                if (field.fieldType === 4) {
                  return (
                    <div
                      key={field.name + field.fieldType + field.order}
                      className="TextField TextField_labelPosition_top TextField_size_m TextField_view_outline TextField_width_full"
                    >
                      <label
                        htmlFor="phone"
                        className="Text Text_size_s Text_view_secondary FieldLabel TextField-Label TextField-Label_labelPosition_top"
                      >
                        {field.title + (field?.validation ? '*' : '')}
                      </label>
                      <PhoneInput
                        id="phone"
                        name="phone"
                        value={values.phone}
                        onChange={e => {
                          setFieldValue('phone', e);
                        }}
                        onBlur={handleBlur}
                        international
                        initialValueFormat="national"
                        countryCallingCodeEditable={false}
                        defaultCountry="US"
                        error={isPhoneError ? phoneFieldError : undefined}
                      />
                      <ErrorMessage
                        name={field.name}
                        component="span"
                        className="Text Text_size_s Text_view_alert FieldCaption TextField-Caption"
                      />
                    </div>
                  );
                }
              })}
            </div>
            <div className={cnFormBuilder('Bottom')}>
              <div className={cnFormBuilder('Checkboxes')}>
                {privacyAgreement?.map(item => (
                  <div key={`${item.name}_${item.order}`}>
                    <Checkbox
                      required={!!item.validation?.length}
                      label={item.text + (item?.validation ? '*' : '')}
                      name={item.name}
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      checked={values[item.name]}
                    />
                    <ErrorMessage name={item.name} component="div" />
                  </div>
                ))}
              </div>
              <Button
                id={dictionariesForm?.submitButtonID}
                iconRight={IconArrowRight}
                disabled={
                  isSubmitting || !!Object.keys(errors).length || !dirty
                }
                label={submitText || dictionariesForm?.button}
                type="submit"
              />
            </div>
            {recaptchaKey && (
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey={recaptchaKey}
                size="invisible"
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormBuilder;
