import './TestimonialsSection.scss';

import React from 'react';

import { cn } from 'ui/utils/bem';

import { Typography } from '@dataartdev/uikit/Typography';
import { BackgroundDecor } from '@dataartdev/uikit/BackgroundDecor';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { TestimonialBlockCard } from '../TestimonialBlockCard/TestimonialBlockCard';

type Props = {
  title?: string;
  buttonTitle?: string;
  items?: any;
};

const cnTestimonialsSection = cn('TestimonialsSection');

const sliderConfig = {
  watchOverflow: true,
  navigation: true,
  spaceBetween: 24,
  breakpoints: {
    768: {
      spaceBetween: 70,
    },
    1200: {
      spaceBetween: 24,
    },
  },
};

export const TestimonialsSection: React.FC<Props> = (
  { title, items, background = 'left-triangle-up-0' },
  ref
) => {
  return (
    <div className={cnTestimonialsSection()}>
      <BackgroundDecor view={background} />
      <div className="container">
        {title && (
          <Typography.Title
            className={cnTestimonialsSection('Title')}
            size="lg"
            as="h2"
          >
            {title}
          </Typography.Title>
        )}
        {items?.length > 0 && (
          <div className={cnTestimonialsSection('Slider')}>
            <Slider {...sliderConfig}>
              {items.map(item => (
                <SliderItem key={item.id}>
                  <TestimonialBlockCard {...item} />
                </SliderItem>
              ))}
            </Slider>
          </div>
        )}
      </div>
    </div>
  );
};
