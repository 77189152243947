import './NoEvents.scss';
import React from 'react';
import { Typography } from '@dataartdev/uikit/Typography';
import { Button } from '@dataartdev/uikit/Button';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Tooltip } from '@dataartdev/uikit/Tooltip';
import { cn } from 'ui/utils/bem';

type Props = RouteComponentProps & {};

const cnNoEvents = cn('NoEvents');

export const NoEvents: React.FC<Props> = props => {
  const { title, subtitle, relatedLinks } = props || {};
  return (
    <div className={cnNoEvents()}>
      <Typography.Title size="lg" as="h2" className={cnNoEvents('Title')}>
        {title}
      </Typography.Title>
      <div className={cnNoEvents('Body')}>
        <div className={cnNoEvents('Links')}>
          <Typography.Text
            size="xs"
            weight="bold"
            transform="uppercase"
            as="h3"
          >
            {subtitle}
          </Typography.Text>
          <div className={cnNoEvents('Links-List')}>
            {relatedLinks &&
              relatedLinks.map((link: any) => (
                <Button
                  label={link.title}
                  view="link-primary"
                  iconRight={IconArrowRight}
                  isLink={!link.targetBlank}
                  to={link.link}
                  href={link.targetBlank ? link.link : null}
                  target={link.targetBlank && '_blank'}
                  as={link.targetBlank ? 'a' : null}
                />
              ))}
          </div>
        </div>
        <div className={cnNoEvents('Dude')}>
          <Tooltip
            staticPosition
            directionPosition="upStartRight"
            className={cnNoEvents('Tooltip')}
          >
            After all, tomorrow is another day!
          </Tooltip>
          <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg">
            <g fillRule="nonzero" fill="none">
              <path
                d="M80 80 40 40 0 80h80ZM20 40h40V0H20v40Z"
                fill="#2BC6BF"
              />
              <g fill="#000">
                <path d="M34.662 53.334 44.74 63.412l-4.738 4.737-10.077-10.077z" />
                <path d="M50.078 58.072 40 68.15l-4.737-4.738L45.34 53.335z" />
              </g>
              <path fill="#000" d="M0 8.889h35.556v14.815H0z" />
              <path fill="#FFF" d="M11.852 11.852h20.741v8.889H11.852z" />
              <path fill="#000" d="M41.481 8.889h35.556v14.815H41.481z" />
              <path fill="#FFF" d="M53.333 11.852h20.741v8.889H53.333z" />
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};
