// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { Typography } from '@dataartdev/uikit/Typography';
import { Banner } from '../../Banner';
import { Slider, SliderItem, renderFraction } from '@dataartdev/uikit/Slider';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const { type, title, items, id, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  const options = {
    watchOverflow: true,
    spaceBetween: 1000,
    navigation: true,
    pagination: {
      el: `#pagination_${id.split('-')[0]}`,
      clickable: true,
      type: 'fraction',
      renderFraction: renderFraction,
    },
  };

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Text
          size="xs"
          weight="bold"
          transform="uppercase"
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        >
          {title}
        </Typography.Text>
      )}

      {items?.length > 0 && (
        <div className={cnContentBuilderTemplate('Slider')}>
          <Slider {...options}>
            {items.map(item => (
              <SliderItem>
                <Banner
                  {...item}
                  labelButton={item?.linkTitle || item?.labelButton}
                  view="case"
                  bgColor={item.color}
                  inverted={item.isInverted}
                  titleSize="lg"
                />
              </SliderItem>
            ))}
          </Slider>
          <div
            className="swiper-pagination"
            id={'pagination_' + id.split('-')[0]}
          ></div>
        </div>
      )}
    </div>
  );
});

export default ContentBuilderTemplate;
