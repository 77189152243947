import { configureStore } from '@reduxjs/toolkit';
import { layoutReducer, screenReducer } from './reducers';

const store = configureStore({
  reducer: {
    layout: layoutReducer,
    screen: screenReducer,
  },
});

export { store };
