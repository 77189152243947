// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';
import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { PriceCard } from '../../PriceCard/PriceCard';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Typography } from '@dataartdev/uikit/Typography';
import { cnContentBuilderTemplate } from './index';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const { type, title, prices, columns, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();
  const gridCols = +columns === 4 ? 3 : 4;

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <Grid
        breakpoints={{
          desktop: { cols: 12 },
          tablet: { cols: 12 },
          mobile: { cols: 12 },
        }}
      >
        <GridItem
          breakpoints={{
            desktop: { col: 8 },
            tablet: { col: 12 },
            mobile: { col: 12 },
          }}
        >
          <Typography.Title size="lg" as="h2">
            {title}
          </Typography.Title>
        </GridItem>
      </Grid>
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: 'xl' },
          tablet: { cols: 12, gap: 'xl' },
          mobile: { cols: 12, gap: 'm' },
        }}
        className={cnContentBuilderTemplate('Prices-List')}
      >
        {prices &&
          prices.length > 0 &&
          prices.map(item => (
            <GridItem
              breakpoints={{
                desktop: { col: gridCols },
                tablet: { col: 6 },
                mobile: { col: 12 },
              }}
            >
              <PriceCard {...item} />
            </GridItem>
          ))}
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
