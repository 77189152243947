// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { Typography } from '@dataartdev/uikit/Typography';
import { Article } from '../../Article/Article';
import { ListLinks } from '@dataartdev/uikit/ListLinks';
import { Mq } from '@dataartdev/uikit/Mq';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const { type, title, text, items, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <Grid breakpoints={{ desktop: { cols: 12, gap: 'xl' } }}>
        {(title || text) && (
          <GridItem breakpoints={{ desktop: { col: 5 } }}>
            {title && (
              <Typography.Title
                size="lg"
                className={cnContentBuilderTemplate('Title')}
                as="h2"
              >
                {title}
              </Typography.Title>
            )}
            {text && (
              <Article
                content={text}
                className={cnContentBuilderTemplate('SubTitle')}
              />
            )}
          </GridItem>
        )}
        {items?.length > 0 && (
          <GridItem breakpoints={{ desktop: { col: 5, colStart: 7 } }}>
            <Mq query="--desktop">
              <ListLinks
                items={items}
                getLabel={item => item.title}
                getPath={item => item.path}
              />
            </Mq>
            <Mq query="--mobile">
              <ListLinks
                items={items}
                getLabel={item => item.title}
                getPath={item => item.path}
              />
            </Mq>
            <Mq query="--tablet">
              <Grid cols="2" gap="m">
                <GridItem col="1">
                  <ListLinks
                    items={items.filter((w, index) => (index & 1) === 0)}
                    getLabel={item => item.title}
                    getPath={item => item.path}
                  />
                </GridItem>
                <GridItem col="1">
                  <ListLinks
                    items={items.filter((w, index) => (index & 1) !== 0)}
                    getLabel={item => item.title}
                    getPath={item => item.path}
                  />
                </GridItem>
              </Grid>
            </Mq>
          </GridItem>
        )}
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
