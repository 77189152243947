/* eslint-disable react/style-prop-object */
// @ts-nocheck
import './HeaderLogo.scss';

import React from 'react';

import { cn } from '../../../utils/bem';
import { Picture } from '@dataartdev/uikit/Picture';

export const cnHeaderLogo = cn('HeaderLogo');

export type HeaderProps<
  As extends keyof JSX.IntrinsicElements
> = PropsWithAsAttributes<
  {
    children: never;
  },
  As
>;

export function HeaderLogo<As extends keyof JSX.IntrinsicElements = 'div'>(
  props: HeaderProps<As>
): React.ReactElement {
  const { as = 'div', className, inverted, ...otherProps } = props;
  const { logo } = otherProps || {};
  const Tag = as as string;
  return (
    <Tag className={cnHeaderLogo(null, [className])} {...otherProps}>
      <Picture {...logo} />
    </Tag>
  );
}
