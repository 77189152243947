// @ts-nocheck
import './NoteText.scss';

import React from 'react';

import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { cn } from '../../utils/bem';

import { IconFacebook } from '@dataartdev/uikit/IconFacebook';
import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';
import { IconTwitter } from '@dataartdev/uikit/IconTwitter';
import { Article } from '../Article/Article';
import { Button } from '@dataartdev/uikit/Button';
import { Typography } from '@dataartdev/uikit/Typography';
import { SiteBlock } from '../SiteBlock/SiteBlock';

type Props = {};

const cnNoteText = cn('NoteText');

export const NoteText = forwardRefWithAs<Props>((props, ref) => {
  const { title, titleTag, content, link, twitter, facebook, strings } = props;

  return (
    <div className={cnNoteText()}>
      {title && (
        // <Typography.Text size="xs" weight="bold" transform="uppercase" as={titleTag}>
        <Typography.Text size="xs" weight="bold" transform="uppercase" as="h3">
          {title}
        </Typography.Text>
      )}
      <Article content={content} />
      {strings?.length > 0 && (
        <SiteBlock useMargin topGap="s">
          <article className="Article">
            <ul>
              {strings.map(item => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </article>
        </SiteBlock>
      )}
      {(link || twitter || facebook) && (
        <div className={cnNoteText('Bottom')}>
          {link && (
            <div className={cnNoteText('Link')}>
              <Button
                as="a"
                target={link.targetBlank ? '_blank' : null}
                label={link.title}
                href={link.link}
                view="link-secondary"
                iconRight={link.targetBlank ? IconOpenInNew : null}
                iconSize="xs"
              />
            </div>
          )}
          {(twitter || facebook) && (
            <div className={cnNoteText('Socials')}>
              {twitter && (
                <Button
                  href={twitter}
                  target="_blank"
                  view="ghost"
                  as="a"
                  iconRight={IconTwitter}
                  size="xs"
                />
              )}
              {facebook && (
                <Button
                  view="ghost"
                  as="a"
                  href={facebook}
                  target="_blank"
                  iconRight={IconFacebook}
                  size="xs"
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
});
