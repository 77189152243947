import './ContentBuilderTemplate.scss';
import './ContentBuilderTemplate76.scss';

import React, { useEffect, useRef } from 'react';
import { cn } from '../../../utils/bem';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { Typography } from '@dataartdev/uikit/Typography';
import { cnContentBuilderTemplate } from './index';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Button } from '@dataartdev/uikit/Button';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { prepareButtonAttrs } from '../../../utils/prepareButtonAttrs';
import { CtaScrollerBtn } from '../../CtaScrollerBtn';
import { IRoot76 } from '../models/CTI_76.props';

const cnAnimatedTitleSection = cn('AnimatedTitleSection');

export const ContentBuilderTemplate: React.FC<IRoot76> = props => {
  const {
    type,
    button,
    text,
    title,
    animation,
    animationElement,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();

  const buttonAttr = prepareButtonAttrs(
    button?.link,
    button?.title,
    button?.targetBlank
  );
  const animatedText = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    let wordIndex = 0;
    const changeWord = () => {
      setTimeout(() => {
        if (animatedText.current) {
          animatedText.current.style.animation =
            'changeWord 1.5s linear infinite';
        }
      }, 0);

      setTimeout(() => {
        if (animation && animatedText.current) {
          animatedText.current.textContent = animation[wordIndex];
          wordIndex = (wordIndex + 1) % animation.length;
        }
      }, 1);
    };

    const intervalId = setInterval(changeWord, 1500);

    return () => clearInterval(intervalId);
  }, []);

  const titleParts = title?.split('{{animationElement}}');

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <div className={cnAnimatedTitleSection()}>
        <Grid
          breakpoints={{
            desktop: { cols: 12 },
            tablet: { cols: 12 },
            mobile: { cols: 12 },
          }}
        >
          <GridItem
            breakpoints={{
              desktop: { col: 6, colStart: 4 },
              tablet: { col: 8, colStart: 3 },
              mobile: { col: 12 },
            }}
            className={cnAnimatedTitleSection('Inner')}
          >
            {titleParts && (
              <Typography.Title
                size="lg"
                as="h2"
                className={cnAnimatedTitleSection('Title')}
              >
                {titleParts[0]}
                <span className={cnAnimatedTitleSection('Changing-Line')}>
                  <span ref={animatedText} />
                </span>
                {titleParts[1]}
              </Typography.Title>
            )}
            {text && (
              <Typography.Text
                size="m"
                as="p"
                className={cnAnimatedTitleSection('Text')}
              >
                {text}
              </Typography.Text>
            )}
            {Object.keys(buttonAttr).length > 0 &&
              ('isCTA' in buttonAttr ? (
                <CtaScrollerBtn
                  {...buttonAttr}
                  view="secondary"
                  className={cnAnimatedTitleSection('Btn')}
                />
              ) : (
                <Button
                  view="secondary"
                  iconRight={IconArrowRight}
                  className={cnAnimatedTitleSection('Btn')}
                  {...buttonAttr}
                />
              ))}
          </GridItem>
        </Grid>
      </div>
    </div>
  );
};

export default ContentBuilderTemplate;
