import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { ColorfullCard } from '../../ColorfullCard';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Typography } from '@dataartdev/uikit/Typography';

import { cnContentBuilderTemplate } from './index';
import { IRoot59, Card } from '../models/CTI_59.props';

export const ContentBuilderTemplate = forwardRefWithAs<IRoot59>(props => {
  const { type, title, text, cards, contentId, is3Columns } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  const cardsQuantity = 3;

  const mobileCol = 12;
  const tabletCol = cardsQuantity === 1 ? 12 : 6;
  const desktopCol = cardsQuantity === 1 ? 12 : cardsQuantity === 2 ? 6 : 4;

  const prepareAttrs = (card: Card) => {
    return {
      title: card?.title,
      description: card?.text,
      background: card?.color,
      isInverted: card?.isInverted,
      image: card?.picture?.urlWebp || card?.picture?.url,
      pictureAtBottom: card?.pictureAtBottom,
    };
  };

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {(title || text) && (
        <Grid
          breakpoints={{
            desktop: { cols: 12, gap: 'xl' },
            tablet: { cols: 12, gap: 'xl' },
            mobile: { cols: 12, gap: 'xl' },
          }}
        >
          <GridItem
            breakpoints={{
              desktop: { col: 8 },
              tablet: { col: 10 },
              mobile: { col: 12 },
            }}
            className={cnContentBuilderTemplate('Top')}
          >
            {title && (
              <Typography.Title size="lg" as="h2">
                {title}
              </Typography.Title>
            )}
            {text && (
              <Typography.Text size="m" as="p">
                {text}
              </Typography.Text>
            )}
          </GridItem>
        </Grid>
      )}
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: 'xl' },
          tablet: { cols: 12, gap: 'xl' },
          mobile: { cols: 12, gap: 'xl' },
        }}
      >
        {cards &&
          cards.length > 0 &&
          cards.map(card => {
            return (
              <GridItem
                key={card.Id}
                breakpoints={{
                  desktop: { col: desktopCol },
                  tablet: { col: tabletCol },
                  mobile: { col: mobileCol },
                }}
              >
                <ColorfullCard {...prepareAttrs(card)} />
              </GridItem>
            );
          })}
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
