// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';
import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { cn } from 'ui/utils/bem';
import { InlineWidget, PopupWidget, PopupButton } from 'react-calendly';

export type Props = {
  type?: number;
  calendlyType?: string;
  url?: string;
  buttonLabel?: string;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const { url, calendlyType, buttonLabel, type, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );
  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {calendlyType === 'popup' && (
        <div className={cnContentBuilderTemplate('CalendlyPopup')}>
          <PopupWidget
            url={url}
            rootElement={document.getElementById('root')}
            text={buttonLabel}
          />
        </div>
      )}

      {calendlyType === 'CTA' && (
        <div className={cnContentBuilderTemplate('CalendlyCTA')}>
          <PopupButton
            url={url}
            rootElement={document.getElementById('root')}
            text={buttonLabel}
            className="Button Button_size_m Button_view_primary Button_width_default"
          />
        </div>
      )}

      {calendlyType === 'inline' && (
        <div className={cnContentBuilderTemplate('CalendlyInline')}>
          <InlineWidget url={url} />
        </div>
      )}
    </div>
  );
});

export default ContentBuilderTemplate;
