// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';

import { cnContentBuilderTemplate } from './index';

import { Article } from '../../Article/Article';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate0 = forwardRefWithAs<Props>((props, ref) => {
  const { type, text, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {text && (
        <Grid breakpoints={{ desktop: { cols: 12 } }}>
          <GridItem breakpoints={{ desktop: { col: 8 } }}>
            <Article content={text} />
          </GridItem>
        </Grid>
      )}
    </div>
  );
});

export default ContentBuilderTemplate0;
