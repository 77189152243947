/* eslint-disable jsx-a11y/control-has-associated-label */
// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-has-content */
import './PageCard.scss';

import React, { useMemo } from 'react';

import { cn } from '../../utils/bem';
import { getSizeByMap } from '@dataartdev/uikit/__internal__/cjs-src/utils/getSizeByMap';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Button } from '@dataartdev/uikit/Button';
import { Link } from '@dataartdev/uikit/Link';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography } from '@dataartdev/uikit/Typography';

const cnPageCard = cn('PageCard');

type Props = {
  title?: string;
  titleTag?: string;
  subtitle?: string;
  link?: string;
  linkText?: string;
  lineColor?: string;
  linkView?: string;
  size?: string;
  decorPosition?: string;
  decor?: object;
  view?: string;
};

const sizeMapTitle = {
  s: 'tiny',
  m: 'lg',
  l: 'lg',
};

const sizeMapSubTitle = {
  m: 'l',
};

export const PageCard: React.FC<Props> = ({
  view,
  title,
  titleTag,
  subtitle,
  linkText,
  link,
  linkImage,
  linkView = 'link-secondary',
  decor,
  size = 'm',
  lineColor = '#0F161A',
  decorPosition = 'default',
  picture,
  buttonAttrs,
}) => {
  const handleClick = link => {
    if (link.includes('http') || buttonAttrs?.target === '_blank') {
      window.open(link, '_blank');
    } else {
      window.location = link;
    }
  };

  const LinkImageComponent = useMemo(
    () => <>{linkImage && <img src={linkImage.url} alt="alt" />}</>,
    [linkImage]
  );

  return (
    <div
      className={cnPageCard({
        decorPosition,
        size,
        view,
        picture: !!picture,
        hoverState: !!link,
      })}
    >
      {link && view !== 'secondary' && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href={link}
          onClick={e => {
            e.preventDefault();
            handleClick(link);
          }}
          className={cnPageCard('LinkOverlay')}
          aria-label={title}
        />
      )}
      {lineColor && (
        <div
          className={cnPageCard('Line')}
          style={{ backgroundColor: lineColor }}
        />
      )}
      {picture && <Picture className={cnPageCard('Picture')} {...picture} />}
      <div className={cnPageCard('Title')}>
        <Typography.Title
          size={view === 'secondary' ? 'xs' : getSizeByMap(sizeMapTitle, size)}
          // as={titleTag}
          as="h3"
        >
          {title}
        </Typography.Title>
      </div>
      {subtitle && (
        <div className={cnPageCard('SubTitle')}>
          <Typography.Text
            size={
              view === 'secondary' ? 'm' : getSizeByMap(sizeMapSubTitle, size)
            }
          >
            {subtitle}
          </Typography.Text>
        </div>
      )}
      {linkText && link && (
        <div className={cnPageCard('Button')}>
          <Button
            view={linkView}
            iconRight={!linkImage && IconArrowRight}
            iconSize="m"
            icon={LinkImageComponent}
            label={linkText}
            onClick={() => handleClick(link)}
          />
        </div>
      )}

      {!linkText && link && (
        <div className={cnPageCard('Link')}>
          <Link href={link.link} target={link.targetBlank ? '_blank' : null}>
            <Typography.Text>{link.title}</Typography.Text>
          </Link>
        </div>
      )}

      {decor && (
        <div className={cnPageCard('Decor')}>
          <Picture desktop={decor.url} desktopWebp={decor.urlWebp} />
        </div>
      )}
    </div>
  );
};
