// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';
import parse from 'html-react-parser';

import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { Article } from '../../Article/Article';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Typography } from '@dataartdev/uikit/Typography';
import { ValuesCard } from '../../ValuesCard/ValuesCard';
import { cnContentBuilderTemplate } from './index';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const { type, title, text, items, contentId } = usePropsHandler(
    cnContentBuilderTemplate(),
    props
  );

  const typeNumber = type.toString();

  const renderList = items => {
    return (
      <div className="Article">
        <ul className="styled-list">
          {items.map(item => (
            <li key={item}>{parse(item)}</li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Text
          size="xs"
          weight="bold"
          transform="uppercase"
          dangerouslySetInnerHTML={{ __html: title }}
          className={cnContentBuilderTemplate('Title')}
          as="h2"
        />
      )}
      {text && (
        <Article content={text} className={cnContentBuilderTemplate('Text')} />
      )}

      {items?.length > 0 && (
        <Grid
          breakpoints={{
            desktop: { cols: 12, gap: 'xl' },
            tablet: { cols: 8, colGap: 'm', rowGap: 'xl' },
            mobile: { gap: 'm' },
          }}
        >
          {items.map(item => (
            <GridItem
              key={item.id}
              breakpoints={{ desktop: { col: 6 }, tablet: { col: 4 } }}
            >
              <ValuesCard
                {...item}
                size="m"
                renderContent={renderList(item.items)}
              />
            </GridItem>
          ))}
        </Grid>
      )}
    </div>
  );
});

export default ContentBuilderTemplate;
