// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React, { useState, useRef, useEffect } from 'react';
import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import ReactPlayer from 'react-player';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const {
    aliases,
    autoPlay,
    background,
    cover,
    id,
    title,
    type,
    video,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();

  const [playVideo, setPlayVideo] = useState(false);
  const videoRef = useRef<HTMLElement>(null);

  function detectVideoScroll() {
    const videoBox = videoRef.current;

    if (
      typeof videoBox === 'object' &&
      videoBox !== null &&
      'getBoundingClientRect' in videoBox
    ) {
      const { top, height, bottom } = videoBox.getBoundingClientRect();
      const startVideoHeight = height * 0.25;
      const stopVideoHeight = height * 0.2;

      if (
        window.innerHeight - top > startVideoHeight &&
        bottom - stopVideoHeight - 96 > 0
      ) {
        setPlayVideo(true);
      }
      if (
        bottom - stopVideoHeight - 96 < 0 ||
        top + startVideoHeight > window.innerHeight
      ) {
        setPlayVideo(false);
      }
    }
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', detectVideoScroll);
    }

    watchScroll();
    return () => {
      window.removeEventListener('scroll', detectVideoScroll);
    };
  });

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <div className={cnContentBuilderTemplate('Video-Section')} ref={videoRef}>
        <ReactPlayer
          url={video?.videoUrl}
          playing={autoPlay ? playVideo : false}
          width="560"
          height="315"
          muted={true}
          controls={true}
        />
      </div>
    </div>
  );
});

export default ContentBuilderTemplate;
