import './ClientPage.scss';

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MainPage } from '../../main/pages';

type Props = RouteComponentProps & {};

export const ClientPage: React.FC<Props> = ({ history, match, location }) => (
  <MainPage history={history} location={location} match={match} />
);
