// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Message } from 'yup/lib/types';
import * as yup from 'yup';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { Article } from '../../Article/Article';
import { Button } from '@dataartdev/uikit/Button';
import { Checkbox } from '@dataartdev/uikit/Checkbox';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { Form, FormBody } from '../../Form';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { MemberCard } from '@dataartdev/uikit/MemberCard';
import { Modal } from '@dataartdev/uikit/Modal';
import { Mq } from '@dataartdev/uikit/Mq';
import { TextField } from '@dataartdev/uikit/TextField';
import { Typography } from '@dataartdev/uikit/Typography';

import sbjs from 'sourcebuster';

import { cnContentBuilderTemplate } from './index';
import { usePagePath } from '../../../hooks/usePagePath';

sbjs.init();

export const schemaLetsTalkAll = (
  emailError: Message<{ regex: RegExp }> | undefined,
  requiredError: Message<{}> | undefined
) =>
  yup.object({
    firstName: yup
      .string()
      .min(3)
      .nullable(),
    email: yup
      .string()
      .email(emailError)
      .required(requiredError)
      .nullable(),
    message: yup
      .string()
      .min(3)
      .nullable(),
    lastName: yup
      .string()
      .min(3)
      .nullable(),
    privacyAgreement: yup
      .boolean()
      .oneOf([true], 'on')
      .required(),
  });

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const {
    type,
    title,
    text,
    member,
    aliases,
    trackID,
    onSubmitForm,
    pageId,
    recaptchaKey,
    dictionariesForm,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();
  const [successForm, setSuccessForm] = useState(false);
  const reCaptchaRef = useRef(null);
  const schemaAll = schemaLetsTalkAll(
    dictionariesForm.email.error,
    dictionariesForm.required
  );

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    message: '',
    privacyAgreement: false,
  };

  const pagePath = usePagePath();

  const sendDataToGTM = useGTMDispatch();

  const cameFrom = `typ: ${sbjs.get.current.typ} | src: ${sbjs.get.current.src} | mdm: ${sbjs.get.current.mdm} | cmp: ${sbjs.get.current.cmp} | cnt: ${sbjs.get.current.cnt} | trm: ${sbjs.get.current.trm}`;
  const submitButtonID = dictionariesForm?.submitButtonID || '';

  const onSubmit = async (values, actions) => {
    if (onSubmitForm) {
      let token = null;

      if (recaptchaKey) token = await reCaptchaRef.current.executeAsync();

      await onSubmitForm({
        pageId: pageId,
        token,
        ...values,
        cameFrom: cameFrom,
        trackingId: submitButtonID,
      })
        .then(() => {
          setSuccessForm(true);
          actions.resetForm();
          sendDataToGTM({
            event: 'send_form',
            form_name: trackID || 'contact_us_form',
            page_path: pagePath,
          });
        })
        .catch(() => alert('error, look at network'));
    }
  };

  return (
    <div
      className={cnContentBuilderTemplate({
        type: typeNumber,
        noMember: !member,
      })}
    >
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: 'xl' },
          tablet: { cols: 8, gap: 'm' },
        }}
      >
        <GridItem
          breakpoints={{ desktop: { col: 5 }, tablet: { col: 4 } }}
          className={cnContentBuilderTemplate('Left')}
        >
          {title && (
            <Typography.Title
              size="lg"
              dangerouslySetInnerHTML={{ __html: title }}
              as="h2"
            />
          )}
          {text && <Article content={text} />}
          {member && (
            <Mq query="--tablet-min">
              <MemberCard
                {...member}
                view="ghost"
                routePath={aliases.TEAM_MEMBER}
                socials
              />
            </Mq>
          )}
        </GridItem>

        <GridItem
          breakpoints={{ desktop: { col: 6, colStart: 7 }, tablet: { col: 4 } }}
          className="GridItem-Form"
        >
          <Form
            enableReinitialize
            initialValues={initialValues}
            validationSchema={schemaAll}
            onSubmit={onSubmit}
            validateOnChange
          >
            {({
              isSubmitting,
              errors,
              dirty,
              handleChange,
              handleBlur,
              values,
              touched,
            }) => {
              return (
                <FormBody className={cnContentBuilderTemplate('Form')}>
                  <div className={cnContentBuilderTemplate('Inner')}>
                    <div className={cnContentBuilderTemplate('Body')}>
                      <TextField
                        name="firstName"
                        onChange={({ e }) => handleChange(e)}
                        onBlur={handleBlur}
                        value={values.firstName}
                        label={dictionariesForm.firstName?.label}
                        view="outline"
                        width="full"
                        caption={touched.firstName && errors.firstName}
                        status={
                          touched.firstName && !!errors.firstName && 'alert'
                        }
                      />
                      <TextField
                        name="lastName"
                        onChange={({ e }) => handleChange(e)}
                        onBlur={handleBlur}
                        value={values.lastName}
                        label={dictionariesForm.lastName?.label}
                        view="outline"
                        width="full"
                        caption={touched.lastName && errors.lastName}
                        status={
                          touched.lastName && !!errors.lastName && 'alert'
                        }
                      />
                      <TextField
                        name="email"
                        onChange={({ e }) => handleChange(e)}
                        onBlur={handleBlur}
                        value={values.email}
                        label={dictionariesForm.email?.label}
                        view="outline"
                        width="full"
                        required
                        caption={touched.email && errors.email}
                        status={touched.email && !!errors.email && 'alert'}
                      />
                      <TextField
                        name="message"
                        onChange={({ e }) => handleChange(e)}
                        onBlur={handleBlur}
                        value={values.message}
                        label={dictionariesForm.message?.label}
                        view="outline"
                        width="full"
                        caption={touched.message && errors.message}
                        status={touched.message && !!errors.message && 'alert'}
                      />
                    </div>
                    <div className={cnContentBuilderTemplate('Bottom')}>
                      <div className={cnContentBuilderTemplate('Checkbox')}>
                        <Checkbox
                          required
                          label={dictionariesForm.policy}
                          onChange={({ e }) => handleChange(e)}
                          onBlur={handleBlur}
                          name="privacyAgreement"
                          checked={values.privacyAgreement}
                        />
                      </div>
                      <div className={cnContentBuilderTemplate('Button')}>
                        <Button
                          iconRight={IconArrowRight}
                          disabled={
                            isSubmitting ||
                            !!Object.keys(errors).length ||
                            !dirty
                          }
                          label={dictionariesForm.button}
                          type="submit"
                        />
                        {recaptchaKey && (
                          <ReCAPTCHA
                            ref={reCaptchaRef}
                            sitekey={recaptchaKey}
                            size="invisible"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </FormBody>
              );
            }}
          </Form>

          <Modal
            view="secondary"
            hasOverlay
            onOpen={() => {
              document.body.style.overflow = 'hidden';
            }}
            onClose={() => {
              document.body.style.overflow = 'unset';
            }}
            isOpen={successForm}
            onOverlayClick={(): void => {
              setSuccessForm(false);
              document.body.style.overflow = 'unset';
            }}
            onEsc={(): void => {
              setSuccessForm(false);
              document.body.style.overflow = 'unset';
            }}
            showCloseButton
            inverted
          >
            <div className={cnContentBuilderTemplate('ModalSuccess')}>
              <Typography.Title
                size="lg"
                className={cnContentBuilderTemplate('ModalSuccess-Title')}
              >
                {dictionariesForm.success.title}
              </Typography.Title>
              <Typography.Text
                className={cnContentBuilderTemplate('ModalSuccess-SubTitle')}
              >
                {dictionariesForm.success.text}
              </Typography.Text>
            </div>
          </Modal>
        </GridItem>

        {member && (
          <GridItem breakpoints={{ desktop: { col: 5 }, tablet: { col: 4 } }}>
            <Mq query="--mobile">
              <MemberCard
                {...member}
                view="ghost"
                routePath={aliases.TEAM_MEMBER}
                socials
              />
            </Mq>
          </GridItem>
        )}
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
