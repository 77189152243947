import './SingleInnerIndustries.scss';

import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { redirect404 } from 'router';

import { querySingleInnerIndustries } from 'network';
import { useDataQuery, useGTMDataSend } from 'common/hooks';

import { setHeaderInverted } from 'store';
import { OfferingPage } from './OfferingPage';
import { SquadPage } from './SquadPage';
import { AllCasesPage } from './AllCasesPage';

type Props = RouteComponentProps & {};

export const SingleInnerIndustries: FC<Props> = ({
  match,
  history,
  location,
}) => {
  const { data, loading, error } = useDataQuery(querySingleInnerIndustries, {
    slug: match.params.inner_page,
    slug2: match.params.slug,
  });

  const { metaTitle, tags, backgroundColor } = data || {};

  useEffect(() => {
    if (error && error.response && error.response.status === 404) {
      redirect404(history);
    }
  }, [error, history]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.contentType === 0 && backgroundColor) {
      dispatch(setHeaderInverted(true));
    }

    return () => {
      dispatch(setHeaderInverted(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useGTMDataSend(location, metaTitle, tags);

  return (
    <React.Fragment>
      {!loading && (
        <>
          {data.contentType === 0 && (
            <SquadPage data={data} history={history} />
          )}
          {data.contentType === 1 && (
            <OfferingPage data={data} history={history} loading={loading} />
          )}
          {data.contentType === 2 && (
            <AllCasesPage
              data={data}
              history={history}
              match={match}
              location={location}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
};
