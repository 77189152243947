// @ts-nocheck
import './ContentBuilderTemplate.scss';

import React from 'react';
import { generatePath } from 'react-router-dom';

import { EssenceCard } from '../../EssenceCard/EssenceCard';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { forwardRefWithAs } from '../../../utils/types/PropsWithAsAttributes';
import { Article } from '../../Article/Article';
import { Button } from '@dataartdev/uikit/Button';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { ListLinks } from '@dataartdev/uikit/ListLinks';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { Typography } from '@dataartdev/uikit/Typography';

import { cnContentBuilderTemplate } from './index';
import { useIntl } from 'react-intl';

export type Props = {
  type?: number;
};

export const ContentBuilderTemplate = forwardRefWithAs<Props>((props, ref) => {
  const {
    type,
    title,
    titleTag,
    text,
    squads,
    items,
    industry,
    industryColor,
    aliases,
    services,
    link,
    linkColor,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();
  const { locale } = useIntl();

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      <Typography.Title
        size="lg"
        className={cnContentBuilderTemplate('Title')}
        // as={titleTag}
        as="h2"
      >
        {title}
      </Typography.Title>
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: 'xl' },
          tablet: { cols: 8, rowGap: '3xl', colGap: 'm' },
          mobile: { cols: 2, gap: '3xl' },
        }}
      >
        {text && (
          <GridItem
            breakpoints={{
              desktop: { col: 4 },
              tablet: { col: 8 },
              mobile: { col: 2 },
            }}
            className={cnContentBuilderTemplate('Column')}
          >
            <Article content={text} />

            {industry && (
              <div className={cnContentBuilderTemplate('Button')}>
                <Button
                  view="link-primary"
                  isLink
                  label={industry.title}
                  to={generatePath(aliases.INDUSTRIES_PAGE, {
                    slug: industry.slug,
                    lang: locale === 'en' ? null : locale,
                  })}
                  iconRight={IconArrowRight}
                  decorBorder={
                    industryColor?.replace('#', '') ??
                    linkColor?.replace('#', '')
                  }
                />
              </div>
            )}

            {link && (
              <div className={cnContentBuilderTemplate('Button')}>
                <Button
                  view="link-primary"
                  isLink
                  label={link.title}
                  to={link.link}
                  iconRight={IconArrowRight}
                  decorBorder={
                    industryColor?.replace('#', '') ??
                    linkColor?.replace('#', '')
                  }
                />
              </div>
            )}
          </GridItem>
        )}
        {squads?.length > 0 && (
          <GridItem
            breakpoints={{
              desktop: { col: 4 },
              tablet: { col: 4 },
              mobile: { col: 2 },
            }}
          >
            <ListLinks
              items={squads}
              getLabel={item => item.title}
              getPath={item => {
                if (item.slug) {
                  return generatePath(aliases.INDUSTRIES_PAGE_INNER, {
                    slug: item.industrySlug,
                    inner_page: item.slug,
                    lang: locale === 'en' ? null : locale,
                  });
                } else {
                  return item.link;
                }
              }}
            />
          </GridItem>
        )}
        {services?.length > 0 && (
          <GridItem
            breakpoints={{
              desktop: { col: 4 },
              tablet: { col: 4 },
              mobile: { col: 2 },
            }}
          >
            <ListLinks
              items={services}
              getLabel={item => item.title}
              getPath={item =>
                generatePath(aliases.SERVICES_PAGE, {
                  slug: item.slug,
                  lang: locale === 'en' ? null : locale,
                })
              }
            />
          </GridItem>
        )}
        {items?.length > 0 && (
          <GridItem
            breakpoints={{
              desktop: { col: 3, colStart: 10 },
              tablet: { col: 3, colStart: 6 },
              mobile: { col: 2 },
            }}
          >
            <Slider pagination={{ clickable: true }} watchOverflow>
              {items.map(item => (
                <SliderItem key={item.id}>
                  <EssenceCard
                    {...item}
                    path={generatePath(aliases.BLOG_SINGLE, {
                      slug: item.slug,
                      lang: locale === 'en' ? null : locale,
                    })}
                    descriptionSmall={item.detailLinkTitle}
                    size="s"
                    detailLinkTitle={null}
                  />
                </SliderItem>
              ))}
            </Slider>
          </GridItem>
        )}
      </Grid>
    </div>
  );
});

export default ContentBuilderTemplate;
