/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
// @ts-nocheck
import './MemberCard.scss';

import React from 'react';
import { cn } from '../../utils/bem';
import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { IconFacebook } from '@dataartdev/uikit/IconFacebook';
import { IconLinkedin } from '@dataartdev/uikit/IconLinkedin';
import { IconMedium } from '@dataartdev/uikit/IconMedium';
import { IconSkype } from '@dataartdev/uikit/IconSkype';
import { IconTwitter } from '@dataartdev/uikit/IconTwitter';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography } from '@dataartdev/uikit/Typography';
import { User } from '@dataartdev/uikit/User';
import { SocialButton } from '@dataartdev/uikit/SocialButton';

export type Props = {
  routePath?: string;
  view?: string;
  slug?: string;
  photo?: object;
  firstName?: string;
  lastName?: string;
  title?: string;
  location?: string;
  position?: string;
  showTitle?: boolean;
  decor?: string;
};

export const cnMemberCard = cn('MemberCard');

export const MemberCard = forwardRefWithAs<Props>((props, ref) => {
  const { routePath, ...data } = usePropsHandler(cnMemberCard(), props);
  const {
    slug,
    photo,
    firstName,
    lastName,
    title,
    location,
    position,
    view,
    size,
    phones,
    email,
    skype,
    contacts,
    socials,
    linkedIn,
    twitter,
    medium,
    facebook,
    isHiddenPage,
    brush,
  } = data;

  if (size === 'xs') {
    return (
      <>
        <User
          className={cnMemberCard({ view, size })}
          avatarUrl={photo?.desktop ?? photo?.url}
          info={position}
          name={`${firstName} ${lastName}`}
          contacts={() => (
            <>
              {phones &&
                phones.map((phone, index) => (
                  <div key={phone + index}>
                    <Typography.Text
                      as="a"
                      href={'tel:' + phone}
                      size="s"
                      key={phone}
                    >
                      {phone}
                    </Typography.Text>
                  </div>
                ))}
              {email && (
                <div>
                  <Typography.Text as="a" href={'mailto:' + email} size="s">
                    {email}
                  </Typography.Text>
                </div>
              )}
            </>
          )}
          socials={
            skype || twitter || facebook || medium || linkedIn
              ? () => (
                  <>
                    {skype && (
                      <SocialButton
                        icon={() => IconSkype}
                        as="a"
                        href={'skype:' + skype}
                      />
                      // <Button
                      //   view="ghost"
                      //   onlyIcon
                      //   iconRight={IconSkype}
                      //   as="a"
                      //   href={'skype:' + skype}
                      //   iconSize="xs"
                      // />
                    )}
                    {twitter && (
                      <Button
                        view="ghost"
                        onlyIcon
                        iconRight={IconTwitter}
                        as="a"
                        href={twitter}
                        iconSize="xs"
                      />
                    )}
                    {facebook && (
                      <Button
                        view="ghost"
                        onlyIcon
                        iconRight={IconFacebook}
                        as="a"
                        href={facebook}
                        iconSize="xs"
                      />
                    )}
                    {medium && (
                      <Button
                        view="ghost"
                        onlyIcon
                        iconRight={IconMedium}
                        as="a"
                        href={medium}
                        iconSize="xs"
                      />
                    )}
                    {linkedIn && (
                      <Button
                        view="ghost"
                        onlyIcon
                        iconRight={IconLinkedin}
                        as="a"
                        href={linkedIn}
                        iconSize="xs"
                      />
                    )}
                  </>
                )
              : null
          }
        />
      </>
    );
  }

  return (
    <div
      className={cnMemberCard({
        view,
        size,
        clicked: !!routePath && !!slug && !isHiddenPage,
      })}
    >
      {routePath && slug && !isHiddenPage && (
        <a className={cnMemberCard('Link')} href={routePath} />
      )}
      <div className={cnMemberCard('Inner')}>
        {position && (
          <div className={cnMemberCard('Location')}>
            <Typography.Text weight="bold" size="xs" transform="uppercase">
              {position}
            </Typography.Text>
          </div>
        )}
        {location && (
          <div className={cnMemberCard('Location')}>
            <Typography.Text weight="bold" size="xs" transform="uppercase">
              {location}
            </Typography.Text>
          </div>
        )}
        {photo && Object.keys(photo).length > 0 && (
          <div className={cnMemberCard('Picture')}>
            <Picture {...photo} />
            {brush && (
              <>
                {brush === 'brush-line-1' && (
                  <div className={cnMemberCard('Decor Decor-1')} />
                )}
                {brush === 'brush-line-2' && (
                  <div className={cnMemberCard('Decor Decor-2')} />
                )}
                {brush === 'brush-line-3' && (
                  <div className={cnMemberCard('Decor Decor-3')} />
                )}
                {brush === 'brush-line-4' && (
                  <div className={cnMemberCard('Decor Decor-4')} />
                )}
                {brush === 'brush-line-5' && (
                  <div className={cnMemberCard('Decor Decor-5')} />
                )}
                {brush === 'brush-line-6' && (
                  <div className={cnMemberCard('Decor Decor-6')} />
                )}
                {brush === 'brush-line-7' && (
                  <div className={cnMemberCard('Decor Decor-7')} />
                )}
              </>
            )}
          </div>
        )}
        <div className={cnMemberCard('Title')}>
          <Typography.Text weight="bold" as="span">
            {firstName} {lastName}
          </Typography.Text>
        </div>
        {title && (
          <div className={cnMemberCard('SubTitle')}>
            <Typography.Text size="s">{title}</Typography.Text>
          </div>
        )}
        {contacts && (
          <div className={cnMemberCard('Contacts')}>
            {phones.map(item => (
              <Typography.Text size="s" as="a" href={'tel:' + item}>
                {item}
              </Typography.Text>
            ))}
            {email && (
              <Typography.Text size="s" as="a" href={'mailto:' + email}>
                {email}
              </Typography.Text>
            )}
          </div>
        )}
        {socials && (skype || linkedIn || twitter || twitter || medium) && (
          <div className={cnMemberCard('Socials-Lg')}>
            {skype && (
              <SocialButton icon={IconSkype} as="a" href={'skype:' + skype} />
            )}
            {twitter && (
              <SocialButton icon={IconTwitter} as="a" href={twitter} />
            )}
            {linkedIn && (
              <SocialButton icon={IconLinkedin} as="a" href={linkedIn} />
            )}
            {medium && <SocialButton icon={IconMedium} as="a" href={medium} />}
            {facebook && (
              <SocialButton icon={IconFacebook} as="a" href={facebook} />
            )}
          </div>
        )}
      </div>
    </div>
  );
});
