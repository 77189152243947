import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { DataQueryVariables } from 'common/hooks';

export const queryEventArchivePage: DataQueryFunction = (
  variables: DataQueryVariables | undefined
): Promise<any> => {
  const { slug } = variables || {};
  return httpClient.get(`/event/archive/${slug}`);
};
