import './DesignCSPage.scss';

import React from 'react';
import { useHistory } from 'react-router-dom';
import { generatePath, NavLink, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { dictionariesSelector } from 'store';
import { PATH } from '../../../router';
import { recaptchaKey, recaptchaFormKey } from '../../../common/constants';
import { mutationMainForm } from '../../../network';
import parse from 'html-react-parser';

import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography } from '@dataartdev/uikit/Typography';
import { Breadcrumbs } from '@dataartdev/uikit/Breadcrumbs';
import { CaseStudyAdvantages } from '../../../common/components/CaseStudyAdvantages/CaseStudyAdvantages';
import { NoteText } from '../../../common/components/NoteText/NoteText';
import { TagGroup } from '@dataartdev/uikit/TagGroup';
import { ContentBuilder } from '../../../common/components/ContentBuilder/ContentBuilder';
import { SiteBlock } from '../../../common/components/SiteBlock/SiteBlock';
import { cn } from 'ui/utils/bem';
import { useIntl } from 'react-intl';

export const cnDesignCSPage = cn('DesignCSPage');

type Props = {
  data?: any;
};

export const DesignCSPage: React.FC<Props> = ({ data }) => {
  const { companySiteCaseStudy, companySiteForm: formContent }: any =
    useSelector(dictionariesSelector) || {};

  const history = useHistory();
  const { locale } = useIntl();

  const {
    breadcrumbs,
    clientTitle,
    locationText,
    title,
    achievements,
    clientDescription,
    challenge,
    members,
    servicesBlock,
    conclusion,
    files,
    tags,
    industry,
    services,
    clientLogo,
    solution,
    relatedCaseStudies,
    text,
    contentCarouselShotCode,
    contentCarousel,
    contentCarouselText,
    sideChat,
    techStock,
    metaTitle,
    designed,
    picture,
    content,
    clientSlug,
  } = data || {};

  const {
    companySiteCaseStudyCompanyName,
    companySiteCaseStudyLocation,
    companySiteCaseStudyIndustry,
    companySiteCaseStudyServices,
    companySiteCaseStudyTechnologies,
    companySiteCaseStudyMoreCases,
  } = companySiteCaseStudy || {};

  return (
    <div className={cnDesignCSPage()}>
      <div>
        <div className={cnDesignCSPage('Breadcrumbs')}>
          {breadcrumbs && (
            <div className="container">
              <Breadcrumbs
                getLabel={page => page.text}
                getLink={page => page.url}
                history={history}
                pages={breadcrumbs}
              />
            </div>
          )}

          <div className="container">
            <Grid
              breakpoints={{
                desktop: { cols: 12 },
                tablet: { cols: 8 },
                mobile: { cols: 2 },
              }}
              className={cnDesignCSPage('Top')}
            >
              <GridItem
                breakpoints={{
                  desktop: { col: 10 },
                  tablet: { col: 8 },
                  mobile: { col: 2 },
                }}
              >
                {title && (
                  <Typography.Title size="lg" as="h1" parse>
                    {title}
                  </Typography.Title>
                )}
              </GridItem>
            </Grid>
          </div>

          {picture && (
            <div className={cnDesignCSPage('Preview')}>
              <Picture {...picture} />
            </div>
          )}
          <div className="container">
            <SiteBlock useMargin topGap="xxl" bottomGap="xxl">
              <Grid
                breakpoints={{
                  desktop: { cols: 12, gap: 'xl' },
                  tablet: { cols: 8 },
                }}
              >
                {(achievements ||
                  clientDescription ||
                  challenge ||
                  solution) && (
                  <GridItem
                    breakpoints={{ desktop: { col: 8 }, tablet: { col: 8 } }}
                  >
                    {achievements && (
                      <CaseStudyAdvantages achievements={achievements} />
                    )}
                    {clientDescription && (
                      <NoteText
                        title={clientDescription.title}
                        content={clientDescription.text}
                        strings={clientDescription.strings}
                      />
                    )}
                    {challenge && (
                      <NoteText
                        title={challenge.title}
                        content={challenge.text}
                        strings={challenge.strings}
                      />
                    )}
                    {solution && (
                      <NoteText
                        title={solution.title}
                        content={solution.text}
                        strings={solution.strings}
                      />
                    )}
                  </GridItem>
                )}

                <GridItem
                  breakpoints={{
                    desktop: { col: 4, colStart: 9 },
                    tablet: { col: 8 },
                  }}
                >
                  <div className={cnDesignCSPage('RightBox')}>
                    {(clientTitle ||
                      locationText ||
                      industry ||
                      services?.length > 0 ||
                      techStock?.length > 0) && (
                      <div className={cnDesignCSPage('InfoBlock')}>
                        {clientTitle && (
                          <div className={cnDesignCSPage('InfoBlock-Item')}>
                            <div className={cnDesignCSPage('InfoBlock-Left')}>
                              <Typography.Text
                                weight="bold"
                                transform="uppercase"
                                size="xs"
                              >
                                {companySiteCaseStudyCompanyName ||
                                  'COMPANY NAME'}
                              </Typography.Text>
                            </div>
                            <div className={cnDesignCSPage('InfoBlock-right')}>
                              <NavLink
                                to={generatePath(PATH.CLIENT_PAGE, {
                                  slug: clientSlug,
                                  lang: locale === 'en' ? null : locale,
                                })}
                              >
                                <Typography.Text weight="bold" size="m">
                                  {clientTitle}
                                </Typography.Text>
                              </NavLink>
                            </div>
                          </div>
                        )}
                        {locationText && (
                          <div className={cnDesignCSPage('InfoBlock-Item')}>
                            <div className={cnDesignCSPage('InfoBlock-Left')}>
                              <Typography.Text
                                weight="bold"
                                transform="uppercase"
                                size="xs"
                              >
                                {companySiteCaseStudyLocation || 'LOCATION'}
                              </Typography.Text>
                            </div>
                            <div className={cnDesignCSPage('InfoBlock-right')}>
                              <Typography.Text weight="bold" size="m">
                                {locationText}
                              </Typography.Text>
                            </div>
                          </div>
                        )}
                        {industry && (
                          <div className={cnDesignCSPage('InfoBlock-Item')}>
                            <div className={cnDesignCSPage('InfoBlock-Left')}>
                              <Typography.Text
                                weight="bold"
                                transform="uppercase"
                                size="xs"
                              >
                                {companySiteCaseStudyIndustry || 'INDUSTRY'}
                              </Typography.Text>
                            </div>
                            <div className={cnDesignCSPage('InfoBlock-right')}>
                              <NavLink
                                to={generatePath(PATH.INDUSTRIES_PAGE, {
                                  slug: industry.slug,
                                })}
                              >
                                <Typography.Text weight="bold" size="m">
                                  {industry.title}
                                </Typography.Text>
                              </NavLink>
                            </div>
                          </div>
                        )}
                        {services?.length > 0 && (
                          <div className={cnDesignCSPage('InfoBlock-Item')}>
                            <div className={cnDesignCSPage('InfoBlock-Left')}>
                              <Typography.Text
                                weight="bold"
                                transform="uppercase"
                                size="xs"
                              >
                                {companySiteCaseStudyServices || 'SERVICES'}
                              </Typography.Text>
                            </div>
                            <div className={cnDesignCSPage('InfoBlock-right')}>
                              {services.map(page => (
                                <NavLink
                                  key={page.slug}
                                  to={generatePath(PATH.SERVICES_PAGE, {
                                    slug: page.slug,
                                  })}
                                >
                                  <Typography.Text weight="bold" size="m">
                                    {page.title}
                                  </Typography.Text>
                                </NavLink>
                              ))}
                            </div>
                          </div>
                        )}
                        {techStock?.length > 0 && (
                          <div className={cnDesignCSPage('InfoBlock-Item')}>
                            <div className={cnDesignCSPage('InfoBlock-Left')}>
                              <Typography.Text
                                weight="bold"
                                transform="uppercase"
                                size="xs"
                                nowrap
                              >
                                {companySiteCaseStudyTechnologies ||
                                  'TECHNOLOGIES'}
                              </Typography.Text>
                            </div>
                            <div className={cnDesignCSPage('InfoBlock-right')}>
                              <TagGroup items={techStock} gap="xs" />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {clientLogo && Object.keys(clientLogo).length > 0 && (
                      <NavLink
                        to={generatePath(PATH.CLIENT_PAGE, {
                          slug: clientSlug,
                          lang: locale === 'en' ? null : locale,
                        })}
                      >
                        <div className={cnDesignCSPage('Logo')}>
                          <Picture {...clientLogo} />
                        </div>
                      </NavLink>
                    )}

                    {relatedCaseStudies?.length > 0 && (
                      <div className={cnDesignCSPage('Recommendation')}>
                        {companySiteCaseStudy && (
                          <div
                            className={cnDesignCSPage('RecommendationTitle')}
                          >
                            <Typography.Text
                              size="xs"
                              weight="bold"
                              transform="uppercase"
                              as="span"
                            >
                              {companySiteCaseStudyMoreCases || 'TAGS'}
                            </Typography.Text>
                          </div>
                        )}
                        <div className={cnDesignCSPage('RecommendationList')}>
                          {relatedCaseStudies.map(item => (
                            <Typography.Title
                              size="tiny"
                              key={item.id}
                              as="link"
                              to={generatePath(PATH.CASE_PAGE, {
                                slug: item.slug,
                              })}
                            >
                              {item.title}
                            </Typography.Title>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </GridItem>
              </Grid>
            </SiteBlock>
          </div>

          <ContentBuilder
            aliases={PATH}
            items={content}
            recaptchaKey={recaptchaKey}
            onSubmitForm={async body =>
              await mutationMainForm({
                body: { ...body, pageId: data.id },
                [`${recaptchaFormKey}`]: body.token,
                pageId: data.id,
              })
            }
            dictionariesForm={{
              firstName: {
                label:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkFirstName,
              },
              lastName: {
                label:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkLastName,
              },
              message: {
                label:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkMessage,
              },
              success: {
                title:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkSuccessTitle,
                text:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkSuccessText,
              },
              addInfo: {
                label:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkAddInfo,
              },
              company: {
                label:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkCompany,
              },
              email: {
                label: 'Email',
                error:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkEmail
                    .companySiteFormLetsTalkEmailError,
              },
              name: {
                label:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormLetsTalkName,
              },
              phone: {
                label: 'Phone',
                error:
                  formContent.companySiteFormLetsTalk
                    .companySiteFormPhoneLetsTalkError,
              },
              required:
                formContent.companySiteFormLetsTalk
                  .companySiteFormLetsTalkRequiredField,
              button:
                formContent.companySiteFormLetsTalk
                  .companySiteFormLetsTalkSubmit,
              submitButtonID:
                formContent.companySiteFormLetsTalk
                  .companySiteFormLetsTalkTrackingID,
              policy:
                formContent.companySiteFormLetsTalk
                  .companySiteFormLetsTalkSubscribePolicy,
            }}
          />

          {/*<div className="container">*/}
          {/*  <SocialShare tags={tags} />*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};
