import React, { useEffect, useState } from 'react';
import { generateRoutes, SwitchRoutes, mainRoutesConfig } from 'router';
import { History } from 'history';
import {
  fetchInitialData,
  setLanguages,
  setModalData,
  setModal,
  setPageId,
  fetchModal,
  setShowFooterForm,
  setMetaTitlePage,
  metaTitlePageSelector,
} from 'store';
import { useThunkDispatch } from 'common/hooks';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { httpClient } from 'network';
import { LocalizedSwitch } from 'i18n';

import { useIntl } from 'react-intl';
import { setLandingBreadcrumbs, setLandingFooter, setMenu } from './store';
import useScrollToHash from './common/hooks/useScrollToHash';

const userRoutes = generateRoutes([mainRoutesConfig]);

type Props = {
  history: History;
};

export const UserProvider: React.FC<Props> = ({ history }) => {
  const [startingApp, setStartingApp] = useState(true);
  const dispatch = useThunkDispatch();
  const dispatchStore = useDispatch();
  const { locale } = useIntl();

  httpClient.interceptors.response.use(response => {
    if (response.data && response.data.hasOwnProperty('menu')) {
      dispatch(setMenu(response.data.menu));
    }

    if (response.data && response.data.hasOwnProperty('footer')) {
      dispatch(setLandingFooter(response.data.footer));
    }

    if (response.data && response.data.hasOwnProperty('breadcrumbs')) {
      dispatch(setLandingBreadcrumbs(response.data.breadcrumbs));
    }

    if (response.data && response.data.hasOwnProperty('nodeLanguages')) {
      dispatch(setLanguages(response.data.nodeLanguages));
    }

    if (response.data && response.data.hasOwnProperty('showFooterForm')) {
      dispatchStore(setShowFooterForm(response.data.showFooterForm));
    }

    if (response.data && response.data.hasOwnProperty('id')) {
      dispatch(setPageId(response.data.id));
      dispatch(setMetaTitlePage(response.data.metaTitle || null));
    }

    if (response.data && response.data.hasOwnProperty('popupForm')) {
      dispatchStore(setModalData(response.data.popupForm));
    }

    return response;
  });

  useEffect(() => {
    const fetchInitData = async () => {
      try {
        await dispatch(fetchInitialData({ keys: ['companySite'] }));
      } catch (error) {
        console.log('fetch data error', error);
      }
      setStartingApp(false);
    };
    fetchInitData();
  }, []); // eslint-disable-line

  useEffect(() => {
    dispatchStore(setModal(null));
    if (new URLSearchParams(history.location.search).get('modal')) {
      const fetchModalInit = async () => {
        await dispatch(
          fetchModal({
            slug: new URLSearchParams(history.location.search).get('modal'),
          })
        );
      };

      fetchModalInit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  const metaTitle = useSelector(metaTitlePageSelector);
  useScrollToHash();

  return startingApp ? null : (
    <>
      <Helmet>
        <html lang={locale} />
        <title>{metaTitle}</title>
      </Helmet>
      <LocalizedSwitch>
        <SwitchRoutes routes={userRoutes} />
      </LocalizedSwitch>
    </>
  );
};
