// @ts-nochecks
import './Header.scss';

import React from 'react';

import { cn } from '../../utils/bem';
import { PropsWithJsxAttributes } from '../../utils/types/PropsWithJsxAttributes';

export type HeaderProps = PropsWithJsxAttributes<
  {
    leftSide?: React.ReactNode;
    rightSide?: React.ReactNode;
    children?: never;
    inverted?: boolean;
    hovered?: boolean;
    openMobileMenu?: boolean;
    fixed?: boolean;
    scrolling?: boolean;
  },
  'header'
>;

export const cnHeader = cn('Header');

export function Header(props: HeaderProps): React.ReactElement {
  const {
    leftSide,
    rightSide,
    className,
    hovered,
    fixed = true,
    scrolling,
    openMobileMenu,
    inverted,
    ...otherProps
  } = props;

  return (
    <header
      {...otherProps}
      className={cnHeader(
        { hovered, fixed, scrolling, openMobileMenu, inverted },
        [className]
      )}
    >
      <div className="container">
        {leftSide && <div className={cnHeader('LeftSide')}>{leftSide}</div>}
        {rightSide && <div className={cnHeader('RightSide')}>{rightSide}</div>}
      </div>
    </header>
  );
}

export { HeaderLogo } from './Logo/HeaderLogo';
export { HeaderMenu } from './Menu/HeaderMenu';
export { HeaderMenuMobile } from './Menu/HeaderMenuMobile';
export { HeaderModule } from './Module/HeaderModule';
