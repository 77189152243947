import React from 'react';

import { cn } from '../../utils/bem';
import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import {
  BackgroundDecor,
  BackgroundDecorPropView,
} from '@dataartdev/uikit/BackgroundDecor';
import { SiteBlock } from '../SiteBlock/SiteBlock';

import Components from './templates';

export type Props = {
  type: number;
  background?: BackgroundDecorPropView;
  topGap?: string;
  bottomGap?: string;
  hideContainer?: boolean;
  otherProps?: any;
};

export const cnContentBuilderItem = cn('ContentBuilderItem');

export const ContentBuilderItem: React.FC<Props> = props => {
  const { type, background, hideContainer, ...otherProps } = usePropsHandler(
    cnContentBuilderItem(),
    props
  );

  const topGap = type != 37 ? '6xl' : '';
  const bottomGap = type != 37 ? '5xl' : '';

  const TemplateTag = Components[type as keyof typeof Components];

  if (!TemplateTag) return null;

  return (
    <SiteBlock topGap={topGap} bottomGap={bottomGap} {...otherProps}>
      {background && <BackgroundDecor view={background} />}
      <div className={(!hideContainer && 'container') || 'container-fluid'}>
        <TemplateTag {...props} />
      </div>
    </SiteBlock>
  );
};
