import React from 'react';
import { IntlProvider } from 'react-intl';
import { Route, Redirect } from 'react-router-dom';
import { Languages } from '../index';
import { httpClient } from 'network';

interface Props {
  RouterComponent: React.ComponentClass<any>;
  languages: { [k: number]: string };
  defaultLanguage?: Languages;
}

export const LocalizedRouter: React.FC<Props> = ({
  children,
  RouterComponent,
  defaultLanguage,
}) => (
  <RouterComponent>
    <Route path="/:lang([a-zA-Z]{2})?">
      {({ match, location }) => {
        /**
         * Get current language
         * Set default locale to en if base path is used without a language
         */
        const params = match ? match.params : {};

        let {
          lang = defaultLanguage || window.INITIAL_STATE.detectedLanguage?.slug,
        } = params;
        let findCurrentLang = window.INITIAL_STATE.languages.find(
          w => w.slug === lang
        );

        if (lang === 'en' || !params.lang) {
          lang = '';
          findCurrentLang = 'en';
          localStorage.setItem('currentLang', '');
        }

        localStorage.setItem('currentLang', lang);

        if (findCurrentLang) {
          httpClient.defaults.headers['Accept-Language'] = findCurrentLang.iso;
        }

        /**
         * If language is not in route path, redirect to language root
         */
        const { pathname } = location;

        if (!pathname.includes(`/${lang}`)) {
          return <Redirect to={`/${lang}${location.pathname}`} />;
        }

        //find lang in url

        if (!window.INITIAL_STATE.languages.find(w => w.slug === lang)) {
          const curLang = window.INITIAL_STATE.detectedLanguage?.slug;

          httpClient.defaults.headers.common['Accept-Language'] =
            window.INITIAL_STATE.detectedLanguage?.iso;

          if (!curLang) {
            return <Redirect to={`/${location.pathname.substring(3)}`} />;
          }

          return (
            <Redirect to={`/${curLang}${location.pathname.substring(3)}`} />
          );
        }

        /**
         * Return Intl provider with default language set
         */
        return (
          <IntlProvider locale={lang !== '' ? lang : 'en'}>
            {children}
          </IntlProvider>
        );
      }}
    </Route>
  </RouterComponent>
);
