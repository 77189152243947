import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { DataQueryVariables } from 'common/hooks';
import { clearUrl } from 'common/utils';

declare global {
  interface Window {
    UMB_PAGE_ID: any;
  }
}
const pageId = window.UMB_PAGE_ID;

export const queryHomePage: DataQueryFunction = (
  variables: DataQueryVariables | undefined
): Promise<any> => {
  return httpClient.get(clearUrl(`/landing/content/${pageId || '27081'}`));
};
