import './ContentBuilderTemplate.scss';

import React from 'react';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { cnContentBuilderTemplate } from './index';
import { Typography } from '@dataartdev/uikit/Typography';
import { Article } from '../../Article/Article';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import Marquee from 'react-fast-marquee';
import { cn } from '../../../utils/bem';
import { IRoot10 } from '../models/CTI_10.props';

const cnComponent = cn('Cards');

export const ContentBuilderTemplate0: React.FC<IRoot10> = props => {
  const [isOpen, setIsOpen] = React.useState(false);

  const {
    type,
    title,
    text,
    pictures,
    viewMoreText,
    contentId,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();
  const buttonChildren = (
    <Marquee loop={100} speed={50}>
      {Array.from({ length: 50 }, (_, i) => (
        <Typography.Text
          key={i}
          as="span"
          weight="bold"
          transform="uppercase"
          size="xs"
        >
          {viewMoreText}
        </Typography.Text>
      ))}
    </Marquee>
  );

  return (
    <div
      className={cnContentBuilderTemplate({ type: typeNumber })}
      id={contentId}
    >
      {title && (
        <Typography.Title
          className={cnContentBuilderTemplate('Title')}
          size="lg"
          as="h2"
        >
          {title}
        </Typography.Title>
      )}
      {text && (
        <Grid breakpoints={{ desktop: { cols: 12 } }}>
          <GridItem breakpoints={{ desktop: { col: 8 } }}>
            <Article
              content={text}
              className={cnContentBuilderTemplate('SubTitle')}
            />
          </GridItem>
        </Grid>
      )}

      {pictures && pictures.length > 0 && (
        <Grid
          breakpoints={{
            desktop: { cols: 12 },
            tablet: { cols: 8 },
            mobile: { cols: 2 },
          }}
          className={cnComponent({ hideTopBorder: pictures.length <= 4 })}
        >
          {pictures.map((picture, index) => {
            const { url, urlWebp, alt } = picture;
            if (!isOpen && index > 7) return false;

            return (
              <GridItem
                breakpoints={{
                  desktop: { col: 3 },
                  tablet: { col: 4 },
                  mobile: { col: 1 },
                }}
                key={url}
              >
                <Picture desktop={url} desktopWebp={urlWebp} alt={alt} />
              </GridItem>
            );
          })}
        </Grid>
      )}
      {pictures && pictures.length > 8 && !isOpen && (
        <Button
          onClick={() => setIsOpen(true)}
          view="borderY"
          width="full"
          size="l"
          data-text={viewMoreText}
          children={buttonChildren}
        />
      )}
    </div>
  );
};

export default ContentBuilderTemplate0;
