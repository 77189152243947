import './ClientsPage.scss';

import React, { useEffect, useState } from 'react';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { dictionariesSelector } from 'store';

import { cn as classNames } from 'ui/utils/bem';
import { ClientCard } from '../../../common/components/ClientCard/ClientCard';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Button } from '@dataartdev/uikit/Button';
import { useDataQuery, useDataPagination, useGTMDataSend } from 'common/hooks';
import { queryClientsPage, queryClientsPageMore } from 'network';
import { Typography } from '@dataartdev/uikit/Typography';
import { SliderItem, Slider } from '@dataartdev/uikit/Slider';

import { PATH } from 'router';

import { ClientTestimonial } from '../components/ClientTestimonial';

import Marquee from 'react-fast-marquee';

import { CaseStudies } from 'common/components/CaseStudies';
import { TestimonialsSection } from 'common/components/TestimonialsSection';
import { IClient, ITestimonial } from './models';
import { useIntl } from 'react-intl';

const cnClientsPage = classNames('ClientsPage');

type Props = RouteComponentProps & {};

export const ClientsPage: React.FC<Props> = ({ location }) => {
  const [nextPage, setNextPage] = useState<any>(1);
  const [clientsArray, setClientsArray] = useState<any>(null);
  const { companySiteClients }: any = useSelector(dictionariesSelector) || {};

  const { data, loading } = useDataQuery(queryClientsPage);
  const {
    clients,
    testimonials,
    title,
    caseStudies,
    customTestimonials,
    metaTitle,
  } = data || {};

  useEffect(() => {
    setClientsArray(clients && clients.items);
  }, [clients]);

  const { loading: loadingMore, data: clientsMore } = useDataPagination(
    queryClientsPageMore,
    {
      page: nextPage,
    },
    { skip: nextPage === 1 }
  );

  const handleClickMore = () => {
    setNextPage(prevPage => prevPage + 1);
  };

  const handleClickHide = () => {
    setNextPage(1);
    setClientsArray(() => clients.items);
  };

  useEffect(() => {
    if (clientsMore && clientsMore.items.length) {
      setClientsArray(prevClients => {
        const newPosts = [];
        newPosts.push(...prevClients, ...clientsMore.items);
        return newPosts;
      });
      setNextPage(clientsMore.page);
    }
  }, [clientsMore]);

  const testimonialsSlider = {
    pagination: { clickable: true },
    watchOverflow: true,
    breakpoints: {
      1200: {
        touchRatio: '0',
      },
    },
  };

  useGTMDataSend(location, metaTitle);
  const { locale } = useIntl();

  return (
    <React.Fragment>
      {!loading && (
        <React.Fragment>
          <div className={cnClientsPage()}>
            <div className="container">
              {title && (
                <div className={cnClientsPage('Title')}>
                  <Typography.Title as="h1" size="lg" parse>
                    {title}
                  </Typography.Title>
                </div>
              )}
              <div className={cnClientsPage('Body')}>
                <Grid
                  breakpoints={{
                    mobile: { cols: 8 },
                    tablet: {
                      cols: 8,
                    },
                    desktop: {
                      cols: 12,
                    },
                  }}
                >
                  {clientsArray &&
                    clientsArray.map((client: IClient) => (
                      <GridItem
                        breakpoints={{
                          mobile: { col: 4 },
                          tablet: { col: 4 },
                          desktop: { col: 3 },
                        }}
                        key={client.id}
                      >
                        <ClientCard
                          {...client}
                          routePath={generatePath(PATH.CLIENT_PAGE, {
                            slug: client.slug,
                            lang: locale === 'en' ? null : locale,
                          })}
                          btnText={
                            companySiteClients.companySiteClientLearnMoreBtnText
                          }
                          logo={client.logo}
                          description={client.shortDescription}
                        />
                      </GridItem>
                    ))}
                  {testimonials && testimonials.length > 0 && (
                    <GridItem
                      breakpoints={{
                        mobile: {
                          col: 8,
                          row: 1,
                          rowStart: 3,
                        },
                        tablet: {
                          col: 8,
                          rowStart: 3,
                          row: 1,
                        },
                        desktop: {
                          col: 6,
                          colStart: 7,
                          rowStart: 1,
                          row: 3,
                        },
                      }}
                    >
                      <Slider
                        {...testimonialsSlider}
                        className={cnClientsPage('Testimonials')}
                      >
                        {testimonials.map((item: ITestimonial) => (
                          <SliderItem key={item.id}>
                            <ClientTestimonial
                              {...item}
                              logoDesktop={item.logo.url}
                              logoDesktopWebp={item.logo.urlWebp}
                            />
                          </SliderItem>
                        ))}
                      </Slider>
                    </GridItem>
                  )}
                </Grid>
                {clients &&
                  clients.pagesTotal > clients.page &&
                  nextPage !== clients.pagesTotal &&
                  companySiteClients.companySiteClientsMoreBtnText && (
                    <div className={cnClientsPage('More')}>
                      <Button
                        view="borderY"
                        width="full"
                        size="l"
                        data-text={
                          companySiteClients.companySiteClientsMoreBtnText
                        }
                        onClick={handleClickMore}
                        disabled={loadingMore && nextPage !== 1}
                      >
                        <Marquee loop={100} speed={50}>
                          {Array.from({ length: 100 }, (_, i) => (
                            <Typography.Text
                              as="span"
                              weight="bold"
                              transform="uppercase"
                              size="xs"
                              key={i}
                            >
                              {companySiteClients.companySiteClientsMoreBtnText}
                            </Typography.Text>
                          ))}
                        </Marquee>
                      </Button>
                    </div>
                  )}

                {clientsMore && clientsMore.pagesTotal === nextPage && (
                  <div className={cnClientsPage('More')}>
                    <Button
                      view="borderY"
                      width="full"
                      size="l"
                      data-text={companySiteClients.companySiteClientsHideBtn}
                      onClick={handleClickHide}
                      disabled={loadingMore && nextPage !== 1}
                    >
                      <Marquee loop={100} speed={50}>
                        {Array.from({ length: 100 }, (_, i) => (
                          <Typography.Text
                            as="span"
                            weight="bold"
                            transform="uppercase"
                            size="xs"
                            key={i}
                          >
                            {companySiteClients.companySiteClientsHideBtn}
                          </Typography.Text>
                        ))}
                      </Marquee>
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {caseStudies?.length > 0 && (
            <div className={cnClientsPage('CaseStudies')}>
              <CaseStudies
                title={companySiteClients.companySiteClientsCaseStudiesTitle}
                items={caseStudies}
                buttonTitle={
                  companySiteClients.companySiteClientsCaseStudiesItemLink
                }
              />
            </div>
          )}

          {customTestimonials?.length > 0 && (
            <div className={cnClientsPage('CustomTestimonials')}>
              <TestimonialsSection
                title={companySiteClients.companySiteClientsTestimonialsTitle}
                items={customTestimonials}
              />
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
