import './LandingFooterMain.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import { layoutSelector } from '../../../store';

import { LandingFooterShort } from './LandingFooterShort';
import { LandingFooterLong } from './LandingFooterLong';
import { Footer } from './Footer';
import { cn } from '../../../ui/utils/bem';

const cnLandingFooterMain = cn('LandingFooterMain');

export const LandingFooterMain: React.FC = () => {
  const { footer }: any = useSelector(layoutSelector);

  const { type } = footer || {};

  switch (type) {
    case 0:
      return <LandingFooterShort {...footer} />;
      break;

    case 1:
      return <LandingFooterLong {...footer} />;
      break;

    case 2:
      return <Footer {...footer} />;
      break;

    default:
      return (
        <footer className={cnLandingFooterMain()}>
          <div>DataArt</div>
          <div>{new Date().getFullYear()}</div>
        </footer>
      );
  }
};
