import './SuccessStoryCard.scss';
import React from 'react';

import { cn } from '../../utils/bem';
import { Button } from '@dataartdev/uikit/Button';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Typography } from '@dataartdev/uikit/Typography';
import { Item } from '../ContentBuilder/templates/models/CTI_61';
import { CtaScrollerBtn } from '../CtaScrollerBtn';
import { prepareButtonAttrs } from '../../utils/prepareButtonAttrs';

export const cnSucessStoryCard = cn('SucessStoryCard');

export const SucessStoryCard: React.FC<Item> = ({ title, text, link }) => {
  const { link: buttonLink, title: linkTitle, targetBlank } = link || {};
  const buttonAttr = prepareButtonAttrs(buttonLink, linkTitle, targetBlank);

  return (
    <div className={cnSucessStoryCard()}>
      <div className={cnSucessStoryCard('Inner')}>
        {title && <span className={cnSucessStoryCard('Title')}>{title}</span>}
        {text && (
          <Typography.Text className={cnSucessStoryCard('Text')} size="m" parse>
            {text}
          </Typography.Text>
        )}
      </div>
      <div className={cnSucessStoryCard('Link')}>
        {Object.keys(buttonAttr)?.length > 0 && (
          <>
            {buttonAttr?.isCTA ? (
              <CtaScrollerBtn {...buttonAttr} />
            ) : (
              <Button
                view="link-secondary"
                iconRight={IconArrowRight}
                iconSize="m"
                {...buttonAttr}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
